import { Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BasicPageTitle from '../../components/BasicPageTitle'
import { PageLayout } from '../../components/PageLayout'
import TableList from '../../components/TableList'
import routes from '../../helpers/routes'
import useModal from '../../hooks/useModal'
import CategoryItem from './CategoryItem'
import GroupCategoryModalForm from './GroupCategoryModalForm'
import { useProductGroups } from './useProductGroups'

const Main = styled.div`
  display: flex;
  gap: 2em;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 1em;
  }
`

const List = styled.div`
  flex: 4;
`
const Categories = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`

const AddBtn = styled.button`
border: 2px groove;
background-color: transparent;
border-radius: .4em;
padding-top: .2em;
padding-bottom: .3em;
&:hover {
  border: 2px groove #676c79;
  background-color: #5c6d9f;
  color: white;
  font-weight: 600;
}
`
const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: .5em;
`


export type Category = {
  id: number
  name: string
  slug: string
}

function ProductGroupsPage() {
  const history = useHistory<any>()
  const { groups, categories, addCategory, updateCategory } = useProductGroups()
  const modalForm = useModal();

  const handleAddCategory = (category: Category) => {
    addCategory(category);
  }

  const handleUpdateCategory = (updatedCategory: Category, categoryId: number) => {
    updateCategory(updatedCategory, categoryId);
  }

  const linkToNew = () => history.push(routes.newProductGroup)

  const headers = ["Codigo", "Grupo", "Precio", "Detalle"];

  return (
    <PageLayout>
      <BasicPageTitle title="Grupo de productos" subtitle="Administración de grupos" />
      <Main>
        <List>
          <Button variant="success" onClick={linkToNew}>Agregar grupo de productos</Button>
          <TableList headers={headers} items={groups} searchParams={["code", "name"]} numberPerPage={8}>
            {(group) => (
              <>
                <td>{group.code}</td>
                <td>{group.name}</td>
                <td>{group.sellPrice}</td>
                <td className='d-flex justify-content-center'>
                  <Link to={routes.product_group(group.id)}>
                    <button className='btn btn-primary btn-sm'>Ver detalle</button>
                  </Link>
                </td>
              </>
            )}
          </TableList>
        </List>
        <Categories>
          <h1>Categorías de productos</h1>
          <AddBtn onClick={modalForm.toogleModal}>Añadir categoría</AddBtn>
          {categories.length === 0 && <h3>Aún no hay categorías configuradas</h3>}
          {categories.length > 0 &&
            <CategoryList>
              {categories.map(c => (
                <CategoryItem key={c.id} category={c} submitFunction={handleUpdateCategory}></CategoryItem>
              ))}
            </CategoryList>
          }
          <GroupCategoryModalForm show={modalForm.isOpenModal} close={modalForm.toogleModal} submitFunction={handleAddCategory} />
        </Categories>
      </Main>
    </PageLayout>
  )
}

export default ProductGroupsPage

import React from 'react'
import { NavSettings, NavSettingsItem } from './styleds'

const Nav = (props: any) => {
  return (
    <NavSettings>
      {props.role === 'admin' && <NavSettingsItem active={props.onUbigeo} onClick={() => props.toggle('ubigeo')}>Ubigeo</NavSettingsItem>}
      <NavSettingsItem active={props.onTransportista} onClick={() => props.toggle('transportistas')}>Transportistas</NavSettingsItem>
    </NavSettings>
  )
}

export default Nav
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import routes from '../../../helpers/routes';
import useAuth from '../../../hooks/useAuth';
import { getInvoice, getTicket, unConfirmOrder, unDeliveredOrder, deleteOrder, getXml } from '../../../services/sell_order';

const OptionsDropdown = ({ order, refresh }: any) => {
  const { user } = useAuth();
  const history = useHistory();

  const handleCreditNote = () => {
    history.push(routes.creditNote(order.id));
  }

  const _unConfirmOrder = () => {
    unConfirmOrder(order.id).then(() => {
      refresh();
      toast.success("Orden cancelada");
      history.push(routes.ventas.index);
    }).catch((e: any) => {
      toast.error(e.response.data);
    })
  }

  const _unDeliveredOrder = () => {
    unDeliveredOrder(order.id).then(() => {
      refresh();
      toast.success("Despacho deshecho");
      history.push(routes.ventas.index);
    }).catch((e: any) => {
      toast.error(e.response.data);
    })
  }

  const _deleteOrder = () => {
    deleteOrder(order.id).then(() => {
      refresh();
      toast.success("Orden eliminada");
      history.push(routes.ventas.index);
    }).catch((e: any) => {
      toast.error(e.response.data);
    })
  }

  const getPdf = async () => {
    try {
      const link = await getInvoice(order.id);
      window.open(link, "_blank");
    } catch (e) {
      alert(JSON.stringify(e, null, 2))
    }
  }

  const getXMl = async () => {
    try {
      const link = await getXml(order.id);
      window.open(link, "_blank");
    } catch (e) {
      alert(JSON.stringify(e, null, 2))
    }
  }

  const print = async () => {
    try {
      const link = await getTicket(order.id);
      window.open(link, "_blank");
    } catch (e) {
      alert(JSON.stringify(e, null, 2))
    }
  }

  const getCreditNoteLink = () => window.open(order.creditNoteLink, "_blank");

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
          Opciones
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {order.futureDocument === 'PROFORMA' && order.state !== 'created' &&
            <Dropdown.Item onClick={print}>Imprimir orden</Dropdown.Item>
          }
          {order.futureDocument !== 'PROFORMA' && order.emited &&
            <Dropdown.Item onClick={getPdf}>Imprimir comprobante</Dropdown.Item>
          }
          {order.futureDocument !== 'PROFORMA' && order.emited && user && user.role !== 'vendedor' &&
            <Dropdown.Item onClick={getXMl}>Obtener xml</Dropdown.Item>
          }
          {user && (user.role === 'admin' || user.role === 'administrador') && !order.creditNote && order.emited &&
            <Dropdown.Item onClick={handleCreditNote}>Generar NOTA DE CREDITO</Dropdown.Item>
          }
          {order.creditNote &&
            <Dropdown.Item onClick={getCreditNoteLink}>Imprimir nota de crédito</Dropdown.Item>
          }
          {user && (user.role === 'admin' || user.role === 'administrador') && order.state === 'confirmed' &&
            <Dropdown.Item onClick={_unConfirmOrder}>Regresar a borrador</Dropdown.Item>
          }
          {user && user.role === 'admin' && order.state === 'delivered' && order.total === order.toPay &&
            <Dropdown.Item onClick={_unDeliveredOrder}>Deshacer despacho</Dropdown.Item>
          }
          {user && user.role === 'admin' && order.state === 'confirmed' &&
            <Dropdown.Item onClick={_deleteOrder}>Eliminar orden</Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default OptionsDropdown
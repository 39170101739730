import React from "react";
import useSWR from "swr";
import { CustomerType } from ".";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";

interface Props {
  value: CustomerType[];
  selector: React.Dispatch<React.SetStateAction<CustomerType[]>>;
}

const useData = () => {
  const { data } = useSWR<{ id: number, value: string }[]>('/reports/history/customers-array');

  const customersList = data?.map(({ id, value }) => ({ id, name: value }));

  return {
    customersList
  }
}

export function CustomerSelection({ value, selector }: Props) {
  const { customersList } = useData();
  return (
    <>
      {customersList &&
        <Form.Group controlId='customer'>
          <Form.Label>Cliente:</Form.Label>
          <Typeahead
            id="customer"
            labelKey="name"
            options={customersList}
            selected={value}
            onChange={selector}
            placeholder="Buscar cliente"
          />
        </Form.Group>
      }
    </>
  );
}

import { useState } from "react";
import { Alert, Breadcrumb, Button, Spinner } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/PageLayout";
import TableList from "../../../components/TableList1";
import routes from "../../../helpers/routes";
import useAuth from "../../../hooks/useAuth";
import { useProductionHelpers, useProductionOrder } from "../../../hooks/useProductions";
import { axiosInstance } from "../../../utils/axios";
import { round } from "../../../utils/functions";
import { EditAction, OrderViewActions } from "../../common/styled";

export default function ProductProductionOrder() {
  const history = useHistory();
  const { user } = useAuth();
  const { id: orderId } = useParams<any>();
  const { order, isLoading, mutate } = useProductionOrder(orderId, 'products');
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [availability, setAvailability] = useState(false);
  const [outOfStock, setOutOfStock] = useState([]);
  const [notAvailability, setNotAvailability] = useState(true);
  const { checkInputAvailability, requestProduction } = useProductionHelpers();

  const headers = ["Insumo", "Unidad", "Cantidad"];

  const body = order?.orderItems.map((item: any, index: number) => (
    <tr key={index}>
      <td>{item.inputName}</td>
      <td>{item.unit}</td>
      <td>{round(Number(item.amount))}</td>
    </tr>
  ))

  const makeProduction = async () => {
    try {
      setLoader(true);
      await requestProduction(orderId, 'products');
      mutate();
      setLoader(false);
    } catch (e: any) {
      setLoader(false);
      alert(JSON.stringify(e.response.data, null, 2))
    }
  }

  const areInputsAvailable = async () => {
    try {
      setLoading(true)
      const response = await checkInputAvailability(order.orderItems);
      setChecked(true);
      setAvailability(response.availability);
      setNotAvailability(response.availability);
      setOutOfStock(response.outOfStock);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      alert(JSON.stringify(e, null, 2))
    }
  }

  const rollbackProduction = async () => {
    try {
      if (window.confirm('¿Seguro que desea deshacer esta producción?') === true) {
        await axiosInstance.post(`productions/products/rollback/${orderId}`);
        mutate();
      }
    } catch (e) {
      alert(JSON.stringify(e, null, 2))
    }
  }

  if (loader) {
    return (
      <PageLayout>
        <Spinner animation="border" />
      </PageLayout>
    )
  }

  if (isLoading) {
    return <PageLayout>Cargando...</PageLayout>
  }

  return (
    <PageLayout>
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: routes.production.productProductions }} linkAs={Link}>
          Lista de ordenes de producción
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Detalle de orden de producción</Breadcrumb.Item>
      </Breadcrumb>
      <OrderViewActions>
        <h3>Order de producción: {order.id}</h3>
        {order.state === 'draft' &&
          <div style={{ display: 'flex', gap: '.5rem' }}>
            <EditAction as={Link} to={routes.production.editProductProduction(order.id)}>
              <i className="bi bi-pencil"></i>
              Editar
            </EditAction>
            <Button variant="outline-danger" onClick={async () => {
              if (window.confirm('¿Seguro que desea eliminar esta orden de producción?') === true) {
                await axiosInstance.delete(`productions/products/${orderId}`);
                history.push(routes.production.productProductions);
              }
            }}>
              <i className="bi bi-trash"></i>
              Eliminar
            </Button>
          </div>
        }
      </OrderViewActions>
      <div>Fecha de producción: {order.date}</div>
      <div>Receta: {order.recipe.name}</div>
      <div>Cantidad a producir: {order.quantity} unidades</div>
      <div>Estado de la orden: {order.state}</div>
      <div style={{ color: 'red' }}>{order.complete ? <h3>Producción completa</h3> : <h3>Producción parcial</h3>}</div>
      <div className="mt-1">{order.state === 'draft' ? "Insumos necesarios:" : "Insumos usados:"}</div>
      <div>
        {!checked &&
          order.state === 'draft' ?
          <Button className="mt-1" variant="warning" onClick={areInputsAvailable}>
            {loading ?
              <><Spinner animation="border" size="sm" /> Comprobando...</>
              :
              "Comprobar disponilidad de insumos"
            }
          </Button>
          : null
        }
        {availability &&
          order.state === 'draft' ?
          <div>
            <Alert className="mt-2" variant="success"><i className="bi bi-emoji-smile"></i> Insumos suficientes</Alert>
          </div>
          : null
        }
        {!notAvailability &&
          <div>
            <Alert className="mt-2" variant="danger"><i className="bi bi-emoji-frown"></i> Insumos insuficientes</Alert>
          </div>
        }
      </div>
      <TableList headers={headers} body={body} actions={false} />
      {
        user && user.role === 'admin' && order.state === 'produced' &&
        <div>
          <Button variant="outline-danger" onClick={rollbackProduction}>Deshacer producción</Button>
        </div>
      }
      {availability &&
        order.state === 'draft' ?
        <div>
          <Button className="mb-3 ml-1" variant="success" onClick={makeProduction}>Realizar producción</Button>
        </div>
        : null
      }
      {!notAvailability &&
        <div style={{ color: 'red' }}>
          Lista de insumos faltantes:
          <ul>
            {outOfStock.map((e: any, index: number) => (
              <li key={index}>{e.inputName}</li>
            ))}
          </ul>
        </div>
      }
      { }
    </PageLayout>
  )
}
import { useCallback, useEffect, useState } from "react";
import { createSupply, fetchAllSupplies, updateSupply } from "../utils/api/supplies";

export interface Supply {
  id: number
  name: string
  internalCode: string
}

export default function useSupplies() {
  const [supplies, setSupplies] = useState<Supply[]>([]);

  const refresh = useCallback(() => {
    fetchAllSupplies().then(setSupplies)
  }, [])

  useEffect(() => {
    refresh()
  }, [refresh])

  const addSupply = async (supply: Partial<Supply>) => {
    try {
      await createSupply(supply)
    } catch (error) {
      throw (error)
    }
  }

  const patchSupply = async (id: number, supply: Partial<Supply>) => {
    try {
      await updateSupply(id, supply)
    } catch (error) {
      throw (error)
    }
  }

  return { supplies, refresh, addSupply, patchSupply }
}
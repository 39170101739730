import { useState } from 'react';
import useModal from '../../../hooks/useModal';
import Drivers from './Drivers';
import Vehicles from './Vehicles';

function formatVehicles(vehicles: any) {
  let res = [];
  for (const item of vehicles) {
    res.push({ placa: item.placa })
  }
  return res;
}

function formatDrivers(drivers: any) {
  let res = [];
  for (const item of drivers) {
    res.push({ documentType: item.documentType, documentNumber: item.documentNumber, name: item.name })
  }
  return res;
}

const CarrierForm = ({ active, carrier, cancel, submitFunction }: any) => {
  const vehiclesModal = useModal();
  const driversModal = useModal();
  const [name, setName] = useState(carrier ? carrier.name : '');
  const [documentType, setDocumentType] = useState(carrier ? carrier.documentType : '');
  const [documentNumber, setDocumentNumber] = useState(carrier ? carrier.documentNumber : '');
  const [vehicles, setVehicles] = useState(carrier ? formatVehicles(carrier.vehicles) : []);
  const [drivers, setDrivers] = useState(carrier ? formatDrivers(carrier.drivers) : []);

  const addVehicle = (vehicle: any) => {
    setVehicles([...vehicles, vehicle]);
  }
  const removeVehicle = (i: number) => {
    setVehicles(vehicles.filter((d: any, index: number) => i !== index));
  }
  const addDriver = (driver: any) => {
    setDrivers([...drivers, driver]);
  }
  const removeDriver = (i: number) => {
    setDrivers(drivers.filter((d: any, index: number) => i !== index));
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!carrier) {
      //agregar transportista
      submitFunction({
        documentType,
        documentNumber,
        name,
      })
    } else {
      submitFunction({
        id: carrier.id,
        documentType,
        documentNumber,
        name,
        vehicles,
        drivers
      })
    }
  }

  if (!active) return null
  return (
    <>
      <h4>{carrier ? 'Editar transportista' : 'Añadir transportista'}</h4>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <div className="form-group mt-2">
              <label className='mb-1' htmlFor="documentType">Tipo de documento</label>
              <select className='form-control' id="documentType" value={documentType} onChange={(e: any) => setDocumentType(e.target.value)} required>
                <option value="">Seleccionar el tipo</option>
                <option value="DNI">DNI</option>
                <option value="RUC">RUC</option>
                <option value="CEX">Carnet de extranjería</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group mt-2">
              <label className='mb-1' htmlFor="documentNumber">Número de documento</label>
              <input id="documentNumber" className='form-control' type="text" placeholder="Ingresar número" value={documentNumber} onChange={(e: any) => setDocumentNumber(e.target.value)} required />
            </div>
          </div>
        </div>
        <div className="form-group mt-2">
          <label className='mb-1' htmlFor="name">Nombre</label>
          <input id="name" className='form-control' type="text" placeholder="Ingresar nombre o razon social" value={name} onChange={(e: any) => setName(e.target.value)} required />
        </div>
        {carrier?.vehicles &&
          <Vehicles show={vehiclesModal.isOpenModal} toggle={vehiclesModal.toogleModal} submitFunction={addVehicle} vehicles={vehicles} removeFunction={removeVehicle} />
        }
        {carrier?.drivers &&
          <Drivers show={driversModal.isOpenModal} toggle={driversModal.toogleModal} submitFunction={addDriver} drivers={drivers} removeFunction={removeDriver} />
        }
        <div className="d-flex gap-2 mt-3">
          <button className='btn btn-secondary' onClick={() => cancel()}>Cancelar</button>
          <button type='submit' className='btn btn-primary'>Guardar</button>
        </div>
      </form>
    </>
  )
}

export default CarrierForm
import useSWR from "swr"
import { axiosInstance } from "../utils/axios";

export interface Recipe {
  id: number
  name: string
  productName: string
  inputName: string
  quantity: number
  unit: string
  lastCost: number
}

interface ReturnProp {
  recipes: Recipe[],
  isLoading: boolean
  isError: any
  mutate: any
  addRecipe: (recipe: any) => void
}

export const useRecipes = (recipeType: string): ReturnProp => {
  const { data, error, mutate } = useSWR(`/recipes/${recipeType}`);

  async function addRecipe(recipe: any) {
    try {
      await axiosInstance.post(`/recipes/${recipeType}`, recipe);
    } catch (e: any) {
      throw (e.response.data)
    }
  }

  return {
    recipes: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    addRecipe
  }
}

export const useProductRecipe = (recipeId: number) => {
  const { data, error } = useSWR(`/recipes/products/${recipeId}`);

  async function updateRecipe(recipe: any) {
    try {
      await axiosInstance.patch(`/recipes/products/${recipeId}`, recipe);
    } catch (e: any) {
      throw (e)
    }
  }

  return {
    recipe: data,
    isLoading: !error && !data,
    isError: error,
    updateRecipe
  }
}

export const useInputRecipe = (recipeId: number) => {
  const { data, error } = useSWR(`/recipes/inputs/${recipeId}`);

  async function updateRecipe(recipe: any) {
    try {
      await axiosInstance.patch(`/recipes/inputs/${recipeId}`, recipe);
    } catch (e: any) {
      throw (e)
    }
  }

  return {
    recipe: data,
    isLoading: !error && !data,
    isError: error,
    updateRecipe
  }
}
import { Button, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { confirmSellOrder } from "../../../utils/api/sellOrders"

export default function ConfirmOrderModal({ show, close, order, refresh }: any) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      orderType: '',
      deliveryDate: order.deliveryDate ? order.deliveryDate : ''
    }
  })

  const confirmOrder = async (data: any) => {
    const { orderType, deliveryDate, order } = data
    try {
      if (orderType === 'FACTURA' && order['customer'].documentType !== 'RUC') {
        throw Error('El cliente debe tener RUC para poder emitir una factura')
      }
      if (orderType === 'BOLETA' && order['customer'].documentType !== 'DNI') {
        throw Error('El cliente debe registrar DNI para poder emitir una boleta')
      }
      await confirmSellOrder(orderType, deliveryDate, order.id)
    } catch (error) {
      throw (error)
    }
  }

  const handleClose = () => {
    reset();
    close();
  }

  const onSubmit = async (data: any) => {
    try {
      await confirmOrder({
        orderType: data.orderType,
        deliveryDate: data.deliveryDate,
        order,
      });
      toast.success("Orden confirmada");
      refresh();
      handleClose();
    } catch (error: any) {
      if (error.message) {
        toast.error(error.message)
      } else {
        toast.error(error.response.data)
      }
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Orden de venta</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <select {...register("orderType")} className="form-select" aria-label="Future Document" required>
            <option value="">Selecciona el tipo de orden</option>
            <option value="PROFORMA">Proforma</option>
            <option value="FACTURA">Factura</option>
            <option value="BOLETA">Boleta</option>
          </select>
          <br />
          <div style={{ display: 'flex', gap: '1em' }}>
            <label htmlFor="delivery-date">Fecha de entrega</label>
            <input style={{ flex: 1 }} type="date" {...register("deliveryDate")} required />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Confirmar orden
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

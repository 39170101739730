import { useHistory } from "react-router-dom";
import routes from "../../helpers/routes";
import { OrderItem, OrderItemData, OrderItemDataBody, OrderItemDataHeader, OrderItemOptions, VendorBadge } from "./styleds";

function FinishedOrderItem({ order }: any) {
  let history = useHistory();
  return (
    <OrderItem>
      <OrderItemData>
        <OrderItemDataHeader>
          <div>
            <span style={{ fontWeight: 'bold' }}>Orden Nº{order.id}</span>
            <span style={{ fontWeight: 'bold', color: 'red', marginLeft: '.5rem' }}>S/. {order.total}</span>
            {!order.nulled && <span style={{ fontWeight: 'bold', color: 'forestgreen', marginLeft: '.5rem' }}>[PAGADA]</span>}
            {order.nulled && <span style={{ fontWeight: 'bold', color: 'red', marginLeft: '.5rem' }}>[ANULADA]</span>}
          </div>
          <div><VendorBadge>{order.user.username}</VendorBadge></div>
        </OrderItemDataHeader>
        <OrderItemDataBody>{order.customer.name}</OrderItemDataBody>
      </OrderItemData>
      <OrderItemOptions>
        <button onClick={() => history.push(routes.viewOrder(order.id))}>Ver detalles</button>
      </OrderItemOptions>
    </OrderItem>
  );
}

export default FinishedOrderItem;
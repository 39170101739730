import InputsList from "./InputsList"
import { createInput, fetchAllInputs, registerWithdrawal, registerIncome } from "../../utils/api/inputs"
import InputForm from "./InputForm"
import withFecthData, { WraperPorps } from "../../hocs/withFetchData"
import { PageLayout } from "../../components/PageLayout"
import BasicPageTitle from "../../components/BasicPageTitle"
import { axiosInstance } from "../../utils/axios"
import React, { useState } from "react"
import CargarDatos from "../../components/CargarDatos"
import useAuth from "../../hooks/useAuth"
import useModal from "../../hooks/useModal"
import InputMovementModal from "./InputMovementModal"
import toast from "react-hot-toast"
import writeXlsxFile from "write-excel-file"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import routes from "../../helpers/routes"

export interface InputModel {
    id: number
    name: string
    internalCode: string
    stock: number
    unit: string
    minimumStock: number
    manufactured: boolean
    storable: boolean
    isMaterialPrime: boolean
}

function Page(props: WraperPorps<InputModel>) {
    const { items, addingNewItem, loading, error, toogleNewItemForm, addNewItem, mutate } = props
    const { user } = useAuth();
    const [upload, setUpload] = useState(false);
    const toggleUpload = () => setUpload(!upload);
    const inputMovementModal = useModal();
    const incomeModal = useModal();

    const filteredItem = React.useMemo(() => {
        return items.sort(function (a, b) {
            return (a.stock - a.minimumStock) - (b.stock - b.minimumStock)
        })
    }, [items]);

    const registerMovement = (data: any) => {
        registerWithdrawal(data).then(() => {
            toast.success("Salida registrada");
            mutate();
        }).catch((err: any) => {
            toast.error(err.response.data);
        });
    }

    const registerIncomeMovement = (data: any) => {
        registerIncome(data).then(() => {
            toast.success("Ingreso registrado");
            mutate();
        }).catch((err: any) => {
            toast.error(err.response.data);
        });
    }

    const massiveupload = async (data: any) => {
        try {
            await axiosInstance.post('/excel-uploads/inputs', data);
        } catch (e) {
            throw (e)
        }
    }

    const getInputsCosts = async () => {
        try {
            const res = await axiosInstance.get('/inputs/costs-info');
            const schema = [
                {
                    column: "Insumo",
                    type: String,
                    value: (item: any) => item.inputName
                },
                {
                    column: "Unidad",
                    type: String,
                    value: (item: any) => item.inputUnit
                },
                {
                    column: "Proveedor",
                    type: String,
                    value: (item: any) => item.providerName
                },
                {
                    column: "Costo",
                    type: Number,
                    value: (item: any) => Number(item.price)
                },
                {
                    column: "Moneda",
                    type: String,
                    value: (item: any) => item.currency
                },
            ];
            return await writeXlsxFile(res.data, { schema, fileName: `Costo_de_insumos_al_${dayjs().format("DDMMYYYY")}.xlsx` });
        } catch (e: any) {
            alert("Error: " + e.message);
        }
    }

    const getStockReport = async () => {
        try {
            const res = await axiosInstance.get('/inputs/stock-report');
            const { rawMaterial, noRawMaterial } = res.data;
            const schema1 = [
                {
                    column: "Insumo",
                    type: String,
                    value: (item: any) => item.inputName
                },
                {
                    column: "Stock",
                    type: Number,
                    value: (item: any) => Number(item.stock)
                },
                {
                    column: "Unidad",
                    type: String,
                    value: (item: any) => item.inputUnit
                },
                {
                    column: "Proveedor",
                    type: String,
                    value: (item: any) => item.providerName
                },
                {
                    column: "Costo",
                    type: Number,
                    value: (item: any) => Number(item.price)
                },
                {
                    column: "Moneda",
                    type: String,
                    value: (item: any) => item.currency
                },
            ];
            const schema2 = [
                {
                    column: "Insumo",
                    type: String,
                    value: (item: any) => item.inputName
                },
                {
                    column: "Stock",
                    type: Number,
                    value: (item: any) => Number(item.stock)
                },
                {
                    column: "Unidad",
                    type: String,
                    value: (item: any) => item.inputUnit
                },
                {
                    column: "Proveedor",
                    type: String,
                    value: (item: any) => item.providerName
                },
                {
                    column: "Costo",
                    type: Number,
                    value: (item: any) => Number(item.price)
                },
                {
                    column: "Moneda",
                    type: String,
                    value: (item: any) => item.currency
                },
            ];
            return await writeXlsxFile([rawMaterial, noRawMaterial], {
                schema: [schema1, schema2],
                sheets: ['Materia Prima', 'No Materia Prima'],
                fileName: `Stock_de_insumos_al_${dayjs().format("DDMMYYYY")}.xlsx`
            });
        } catch (e: any) {
            alert("Error: " + e.message);
        }
    }

    return (
        <PageLayout>
            <BasicPageTitle title="Insumos" subtitle="Administración de insumos" />
            {!addingNewItem && !upload &&
                <>
                    {user &&
                        <div className="d-flex justify-content-between mb-3">
                            {user.role === 'admin' && <button className="btn btn-success" onClick={toogleNewItemForm}>Agregar insumo</button>}
                            <div>
                                {user.role === 'admin' &&
                                    <>
                                        <Link to={routes.inputsState} className="btn btn-outline-primary me-2">Estado de insumos</Link>
                                        <button className="btn btn-outline-success me-2" onClick={incomeModal.toogleModal}>Ingresar insumos</button>
                                        <button className="btn btn-outline-success me-2" onClick={inputMovementModal.toogleModal}>Ingresar salida</button>
                                        <button className="btn btn-warning me-2" onClick={toggleUpload}>Cargar datos</button>
                                        <button className="btn btn-info" onClick={getInputsCosts}>Costos de insumos</button>
                                    </>
                                }
                                {user.role !== 'vendedor' &&
                                    <>
                                        <button className="btn btn-outline-warning ms-2" onClick={getStockReport}>Reporte de Stock</button>
                                    </>
                                }
                            </div>
                        </div>
                    }
                    <InputsList inputs={filteredItem} isLoading={loading} isError={error} />
                </>
            }
            {addingNewItem &&
                <InputForm handleSubmit={addNewItem} toogle={toogleNewItemForm} />
            }
            {upload &&
                <CargarDatos subject="insumos" toogle={toggleUpload} uploadFunction={massiveupload} />
            }
            <InputMovementModal type="Salida" show={inputMovementModal.isOpenModal} toggle={inputMovementModal.toogleModal} submitFunction={registerMovement} />
            <InputMovementModal type="Ingreso" show={incomeModal.isOpenModal} toggle={incomeModal.toogleModal} submitFunction={registerIncomeMovement} />
        </PageLayout>
    )
}

const InputsPage = withFecthData(Page)(fetchAllInputs, createInput);
export default InputsPage
import React from "react";
import { axiosInstance } from "../../../utils/axios";
import { handleAxiosError } from "../../../utils/functions/handleAxiosError";
import { toLocalDateFormat } from "../../../utils/functions";
import { Box } from "../AdminHome";

export default function ProductionsBox() {
  const [fills, setFills] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const response = await axiosInstance('/fills/get-last-10-fills');
        setFills(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleAxiosError(error);
      }
    })()
  }, []);

  if (loading) {
    return <Box>Cargando...</Box>
  }

  return (
    <Box>
      <h3 style={{ marginBottom: '0' }}>Últimas Producciones</h3>
      <div style={{ display: 'grid', gridTemplateColumns: '0.3fr 1fr 0.2fr', gap: '0.2rem', border: '1px solid grey', padding: '0.5rem' }}>
        <div style={{ display: 'contents', fontWeight: 'bold', borderBottom: '1px solid grey', textAlign: 'center' }}>
          <span style={{ border: '1px solid grey', padding: '0.3rem' }}>Fecha</span>
          <span style={{ border: '1px solid grey', padding: '0.3rem' }}>Producto</span>
          <span style={{ border: '1px solid grey', padding: '0.3rem' }}>Cantidad</span>
        </div>
        {fills.map((fill, index) => (
          <div key={index} style={{ display: 'contents' }}>
            <span style={{ padding: '0.2rem' }}>{toLocalDateFormat(fill.createdAt.split('T')[0])}</span>
            <span style={{ padding: '0.2rem' }}>{fill.product.name}</span>
            <span style={{ textAlign: 'center', padding: '0.2rem' }}>{fill.amount}</span>
          </div>
        ))}
      </div>
    </Box>
  )
}
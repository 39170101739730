import { useCallback, useEffect, useState } from "react";
import { Box } from "../pages/money/BoxPage";
import { fetchAllBoxes } from "../utils/api/boxes";
import { axiosInstance } from "../utils/axios";

export default function useBoxes() {
  const [boxes, setBoxes] = useState<Box[]>([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const refresh = useCallback(() => {
    fetchAllBoxes().then(setBoxes);
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const addBox = async (data: any) => {
    try {
      setLoading(true);
      await axiosInstance.post('/boxes', data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error);
    }
  }

  return { loading, error, boxes, addBox, refresh }
}
import React, { useState } from 'react'
import { Form, Modal, Row, Col, Button } from 'react-bootstrap'

const DriverModalForm = ({ show, toggle, submitFunction }: any) => {
  const [name, setName] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');

  const close = () => {
    setName('');
    setDocumentType('');
    setDocumentNumber('');
    toggle();
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    submitFunction({
      name,
      documentType,
      documentNumber,
    });
    close();
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar conductor</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Group controlId="documentType">
                <Form.Label>Tipo de documento</Form.Label>
                <Form.Select value={documentType} onChange={(e: any) => setDocumentType(e.target.value)} required>
                  <option value="">Selecciona el tipo de documento</option>
                  <option value="DNI">DNI</option>
                  <option value="RUC">RUC</option>
                  <option value="CEX">Carnet de extranjería</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="documentNumber">
                <Form.Label>Número de documento</Form.Label>
                <Form.Control type="text" placeholder="Documento de identidad" value={documentNumber} onChange={(e: any) => setDocumentNumber(e.target.value)} required />
              </Form.Group>
            </Col>
          </Row>
          <Row className="g-2">
            <Col>
              <Form.Group controlId="name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control type="text" placeholder="Nombre o razón social" value={name} onChange={(e: any) => setName(e.target.value)} required />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default DriverModalForm
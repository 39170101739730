import React, { useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { PageLayout } from "../../components/PageLayout";
import useModal from "../../hooks/useModal";
import AddUserModal from "./AddUserModal";
import { useUsers } from "./useUsers";
import toast from "react-hot-toast";
import { axiosInstance } from "../../utils/axios";
import { handleAxiosError } from "../../utils/functions/handleAxiosError";

function DeactivateUserModal({ show, close, onConfirm }: { show: boolean, close: () => void, onConfirm: () => void }) {
    const [confirmationText, setConfirmationText] = useState("");

    const handleConfirm = () => {
        if (confirmationText === "desactivar") {
            onConfirm();
            close();
        } else {
            toast.error("El texto de confirmación es incorrecto.");
            handleClose();
        }
    };

    const handleClose = () => {
        setConfirmationText("");
        close();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmar desactivación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Para confirmar la desactivación, por favor escriba "desactivar" en el cuadro de texto.</p>
                <Form.Control
                    type="text"
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={handleConfirm}>
                    Desactivar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default function UsersPage() {
    const { users, refresh } = useUsers();
    const modal = useModal();
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);

    const handleDeactivate = (user: any) => {
        setSelectedUser(user);
        setShowDeactivateModal(true);
    };

    const confirmDeactivate = async () => {
        if (selectedUser) {
            try {
                await axiosInstance.post(`/users/deactivate/${selectedUser.id}`);
                toast.success("Usuario desactivado");
                await refresh();
            } catch (error) {
                handleAxiosError(error);
            }
        }
    };

    return (
        <PageLayout>
            <h1>Usuarios del sistema</h1>
            <Button onClick={() => modal.toogleModal()}>Nuevo usuario</Button>
            <Table className="mt-3" bordered size="sm">
                <thead>
                    <tr>
                        <th>Nombre de usuario</th>
                        <th>Correo electrónico</th>
                        <th>Rol</th>
                        <th>Estado</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user: any) => (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>{user.state ? 'Activo' : 'Desactivado'}</td>
                            <td align="center">
                                {user.state && <button
                                    className="btn btn-outline-danger btn-sm"
                                    onClick={() => handleDeactivate(user)}
                                >
                                    Desactivar
                                </button>}
                                {!user.state && <button className="btn btn-outline-success btn-sm">Activar</button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <AddUserModal show={modal.isOpenModal} close={modal.toogleModal} refresh={refresh} />
            <DeactivateUserModal
                show={showDeactivateModal}
                close={() => setShowDeactivateModal(false)}
                onConfirm={confirmDeactivate}
            />
        </PageLayout>
    );
}
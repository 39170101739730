import React from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import routes from '../../../../helpers/routes';
import useModal from '../../../../hooks/useModal';
import { round } from '../../../../utils/functions';
import AddBoxModal from '../../AddBoxModal';
import { Box, MoneyContext } from '../../money.provider'
import "./index.css";

const Conatiner = styled.div`
  grid-area: boxes;
  display: flex;
  flex-direction: column;
`

function currencySymbol(currency: string): string {
  if (currency !== 'soles') return '$'
  return 'S/.'
}

function formatCurrencyBox(value: number, currency: string) {
  return `${currencySymbol(currency)} ${value}`
}

function BoxItem({ box }: { box: Box }) {
  const history = useHistory();
  const handleViewBox = () => {
    history.push(routes.box(box.id))
  }

  return (
    <div className="box">
      <div className="box-body">
        <div className="box-header">{formatCurrencyBox(box.total, box.currency)}</div>
        <div className="box-name">{box.name}</div>
      </div>
      <div className="box-icon" onClick={handleViewBox}>
        {box.isCci && <i className="icon bi bi-bank"></i>}
        {!box.isCci && <i className="icon bi bi-cash-stack"></i>}
      </div>
    </div>
  )
}

function BoxesComponent() {
  const { boxes, fetch, boxesSumary } = React.useContext(MoneyContext);
  const addBoxModal = useModal();

  return (
    <>
      <Conatiner>
        <div className='boxes-header'><button onClick={addBoxModal.toogleModal} className='btn btn-primary'>Nueva Caja</button></div>
        <div className='boxes-body'>
          <div className="box box-sumary">
            <div className="box-body">
              <div className="box-header box-header-sumary">{formatCurrencyBox(round(boxesSumary.total), 'soles')}</div>
              <div className="box-name">{boxesSumary.name}</div>
            </div>
            <div className="box-icon">
              <i className="icon bi bi-wallet2"></i>
            </div>
          </div>
          {boxes.map(box => (
            <BoxItem key={box.id} box={box} />
          ))}
        </div>
      </Conatiner>
      <AddBoxModal show={addBoxModal.isOpenModal} toogle={addBoxModal.toogleModal} refresh={fetch} />
    </>
  )
}

export default BoxesComponent
import styled from "styled-components";

export const PageTitle = styled.h1`
    font-size: 3rem;
`

export const TopActionsBar = styled.div`
    margin-top: 1em;
    display: flex;
    justify-content: flex-start;
`

export const AddButtonLink = styled.div`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    gap: .5em;
    border: 1px solid;
    border-radius: 5px;
    padding: .2em;
    color: goldenrod;
`
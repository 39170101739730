import { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { PageLayout } from "../../components/PageLayout";
import routes from "../../helpers/routes";
import useInputs, { Input } from "../../hooks/useInputs";
import useModal from "../../hooks/useModal";
import { useProvider } from "../../hooks/useProviders";
import { formatMoney } from "../../utils/functions";

export default function ViewProvider() {
    const { id: providerId } = useParams<any>();
    const { provider, isLoading, mutate, addInput: addInputToCatalog, removeInput, role } = useProvider(providerId);
    const addInputModal = useModal();

    const addInput = async (input: any) => {
        try {
            await addInputToCatalog(provider.id, input);
            mutate();
        } catch (e) {
            alert(JSON.stringify(e, null, 2));
        }
    }

    const removeItem = async (id: number) => {
        try {
            await removeInput(id);
            mutate();
        } catch (e) {
            alert(JSON.stringify(e, null, 2));
        }
    }

    const breadcrumbs = {
        links: [
            {
                to: routes.providers,
                text: "Lista de proveedores"
            }
        ],
        active: "Detalle de proveedor"
    }

    if (isLoading) return <PageLayout>Cargando...</PageLayout>

    return (
        <PageLayout>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <div>
                <h4>Datos generales</h4>
                <div>{provider.name}</div>
                <div>{provider.documentType}: {provider.documentNumber}</div>
                <div>{provider.address}</div>
            </div>
            <div className="mt-3">
                <h5>Catálogo de insumos</h5>
                {role === 'admin' && <Button variant="primary" onClick={() => addInputModal.toogleModal()}>Agregar inumo</Button>}
                <Table bordered size="sm" className="mt-3">
                    <thead>
                        <tr>
                            <th>Código de proveedor</th>
                            <th>Nombre de insumo</th>
                            <th>Insumo asociado</th>
                            <th>Último precio</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {provider.inputs.map((input: any) => (
                            <tr key={input.id}>
                                <td>{input.providerCode}</td>
                                <td>{input.inputName}</td>
                                <td>{input.input['name']}</td>
                                <td>{formatMoney(input.lastPrice, input.currency)}</td>
                                <td>
                                    {role === 'admin' && <div className="d-grid">
                                        <Button size="sm" variant="outline-danger" onClick={() => removeItem(input.id)}>X</Button>
                                    </div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <AddInputModal show={addInputModal.isOpenModal} toogle={addInputModal.toogleModal} addFunction={addInput} />
        </PageLayout>
    )
}

function AddInputModal({ show, toogle, addFunction }: any) {
    const { register, handleSubmit, setValue } = useForm();
    const { inputs } = useInputs();
    const [selectedInput, setSelectedInput] = useState<Input[]>([]);

    const handleClose = () => {
        setSelectedInput([])
        setValue("providerCode", "")
        setValue("inputName", "")
        toogle();
    }

    const onSubmit = (data: any) => {
        const input = {
            input: {
                id: selectedInput[0].id,
                name: selectedInput[0].name
            },
            providerCode: data.providerCode,
            inputName: data.inputName
        }
        addFunction(input);
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar insumo</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Form.Group controlId="input">
                        <Form.Label>Insumo asociado</Form.Label>
                        <Typeahead
                            id="input-selected"
                            labelKey="name"
                            onChange={setSelectedInput}
                            options={inputs}
                            selected={selectedInput}
                            placeholder="Buscar insumo"
                            inputProps={{ required: true }}
                        />
                    </Form.Group>
                    <Form.Group controlId="providerCode" className="mt-3">
                        <Form.Label>Código del proveedor</Form.Label>
                        <Form.Control type="text" placeholder="Código del proveedor" {...register("providerCode")} required />
                    </Form.Group>
                    <Form.Group controlId="inputName" className="mt-3">
                        <Form.Label>Nombre de insumo</Form.Label>
                        <Form.Control type="text" placeholder="Nombre de catálogo" {...register("inputName")} required />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" type="submit">
                        Agregar
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
import { Button } from "react-bootstrap"
import BasicPageTitle from "../../components/BasicPageTitle"
import ProvidersList from "./ProvidersList"
import { useProviders } from "../../hooks/useProviders"
import { useState } from "react"
import { PageLayout } from "../../components/PageLayout"
import EntityForm, { Entity } from "../../components/EntityForm"
import { createProvider } from "../../utils/api/providers"
import toast from "react-hot-toast"

export default function ProviderPage() {
    const { providers, isLoading, isError, mutate } = useProviders()
    const [showNew, setShowNew] = useState(false)

    const toggle = () => setShowNew(!showNew);

    const addProvider = (provider: Entity) => {
        createProvider(provider).then(() => {
            toast.success("Proveedor registrado");
            mutate();
        }).catch((e: any) => {
            toast.error(e);
        })
    }

    const body = () => {
        if (showNew) {
            return <EntityForm subject="Proveedor" submitFunction={addProvider} toggle={toggle} />
        } else {
            return (
                <>
                    <Button className="mb-2" variant="success" onClick={toggle}>Nuevo Proveedor</Button>
                    <ProvidersList providers={providers} isLoading={isLoading} isError={isError} />
                </>
            )
        }
    }

    return (
        <PageLayout>
            <BasicPageTitle title="Proveedores" subtitle="Administración de proveedores" />
            {body()}
        </PageLayout>
    )
}
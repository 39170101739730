import React from 'react'
import { FullPageLayout } from '../../components/FullPageLayout'
import { Container } from '../common/styled'
import PageTitle from '../../components/PageTitle'
import PageHeader from '../../components/PageHeader'
import { Link } from 'react-router-dom'
import routes from '../../helpers/routes'
import ProductionForm from './ProductionForm'
import { axiosInstance } from '../../utils/axios'
import toast from 'react-hot-toast'

export default function NewProductionPage() {
  const [newProduction, setNewProduction] = React.useState<any>(null)

  const handleSubmit = (production: any) => {
    axiosInstance.post('/productions/add-production', production).then((response) => {
      console.log(response.data)
      setNewProduction(response.data)
      toast.success('Producción creada')
    }).catch((error) => {
      console.error(error)
      toast.error('Error creando producción')
    })
  }

  return (
    <FullPageLayout>
      <Container>
        <PageHeader>
          <PageTitle title={!newProduction ? "Nueva Producción" : "Producción creada"} />
          <Link className="btn btn-secondary" to={routes.productions.index}>Volver</Link>
        </PageHeader>
        {!newProduction && <div>
          <ProductionForm submitFunction={handleSubmit} />
        </div>}
        {newProduction && <div>
          <p>{newProduction.recipe.name}</p>
          <p>{newProduction.productionDate}</p>
        </div>}
      </Container>
    </FullPageLayout>
  )
}

import React, { useMemo, useState } from 'react'
import { Form } from 'react-bootstrap';
import { useUbigeos } from './PartidaSection';
import { BorderedSection, BorderedSectionBody, BorderedSectionBodyItem, BorderedSectionTitle, BorderedSectionBodyFooter } from './styleds';

const DestinoSection = (props: any) => {
  const { departamentos, provincias, distritos, isLoading, isError } = useUbigeos();
  const [departamentoSelectValue, setDepartamentoSelectValue] = useState("");
  const [provinciaSelectValue, setProvinciaSelectValue] = useState("");

  const filterProvincias = useMemo(() => {
    return provincias.filter((p: any) => p.departamentoId.toString() === departamentoSelectValue)
  }, [provincias, departamentoSelectValue])

  const filterUbigeos = useMemo(() => {
    return distritos.filter((d: any) => d.provinciaId.toString() === provinciaSelectValue)
  }, [distritos, provinciaSelectValue])

  const handleChangeDepartamento = (e: any) => {
    setDepartamentoSelectValue(e.target.value);
    setProvinciaSelectValue("");
    props.setDestinoUbigeo("");
  }

  const handleChangeProvincia = (e: any) => {
    setProvinciaSelectValue(e.target.value);
    props.setDestinoUbigeo("");
  }

  if (isLoading) return <h5>Cargando datos...</h5>
  if (isError) return <h5>Error al obtener los datos del servidor</h5>
  return (
    <BorderedSection>
      <BorderedSectionTitle>Destino</BorderedSectionTitle>
      <BorderedSectionBody>
        <BorderedSectionBodyItem>
          <Form.Group controlId='departamentoDeDestino'>
            <Form.Label>Departamento</Form.Label>
            <Form.Select value={departamentoSelectValue} onChange={handleChangeDepartamento} required>
              <option value="">Seleccionar</option>
              {departamentos.map((d: any) => (
                <option key={d.id} value={d.id}>{d.descripcion}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </BorderedSectionBodyItem>
        <BorderedSectionBodyItem>
          <Form.Group controlId='provinciaDeDestino'>
            <Form.Label>Provincia</Form.Label>
            <Form.Select value={provinciaSelectValue} onChange={handleChangeProvincia} required>
              <option value="">Seleccionar</option>
              {filterProvincias.map((p: any) => (
                <option key={p.id} value={p.id}>{p.descripcion}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </BorderedSectionBodyItem>
        <BorderedSectionBodyItem>
          <Form.Group controlId='distritoDeDestino'>
            <Form.Label>Distito</Form.Label>
            <Form.Select value={props.destinoUbigeo} onChange={(e: any) => props.setDestinoUbigeo(e.target.value)} required>
              <option value="">Seleccionar</option>
              {filterUbigeos.map((u: any) => (
                <option key={u.id} value={u.ubigeo}>{u.descripcion}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </BorderedSectionBodyItem>
      </BorderedSectionBody>
      <BorderedSectionBodyFooter>
        <Form.Group controlId='direccionDeDestino'>
          <Form.Label>Dirección</Form.Label>
          <Form.Control type='text' value={props.destinoDireccion} onChange={(e: any) => props.setDestinoDireccion(e.target.value)} required />
        </Form.Group>
      </BorderedSectionBodyFooter>
    </BorderedSection>
  )
}

export default DestinoSection
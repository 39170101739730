interface Props {
  subject: string;
  toggle: () => void;
  toggleUpload: () => void;
  downloadReport: () => void;
  role: string;
}

const TopBar = ({ subject, toggle, toggleUpload, downloadReport, role }: Props) => {
  return <div className="d-flex justify-content-between mb-3">
    <button className="btn btn-success" onClick={() => toggle()}>Agregar {subject}</button>
    {subject === 'insumo' &&
      <button>hola</button>
    }
    <div>
      {role === 'admin' && <button className="btn btn-warning" onClick={() => toggleUpload()}>Cargar datos</button>}
      {role !== 'vendedor' && <button className="btn btn-outline-info ms-2" onClick={downloadReport}>Reporte de clientes</button>}
    </div>
  </div>
}

export default TopBar;
import { axiosInstance } from "../axios";

export const fetchAllInputs = async () => {
    try {
        const res = await axiosInstance.get('/inputs');
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const fetchAllManufacturedInputs = async () => {
    try {
        const res = await axiosInstance.get('/inputs/manufactured');
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const fetchAllNoManufacturedInputs = async () => {
    try {
        const res = await axiosInstance.get('/inputs/no-manufactured');
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const fetchInput = async (id: number) => {
    try {
        const res = await axiosInstance.get(`/inputs/${id}`);
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const createInput = async (body: {}) => {
    try {
        return await axiosInstance.post('/inputs', body)
    } catch (error) {
        throw (error)
    }
}

export const updateInput = async (id: number, body: {}) => {
    try {
        return await axiosInstance.patch(`/inputs/${id}`, body)
    } catch (error) {
        throw (error)
    }
}

export const registerWithdrawal = async (data: any) => {
    try {
        return await axiosInstance.post('/inputs/register-withdrawal', data);
    } catch (error) {
        throw (error)
    }
}

export const registerIncome = async (data: any) => {
    try {
        return await axiosInstance.post('/inputs/register-income', data);
    } catch (error) {
        throw (error)
    }
}
import styled from "styled-components";
import { useRootClose } from 'react-overlays';
import { useRef } from "react";
import BottomModal from "../../components/BottomModal";

const Wrapper = styled.div`
    background: white;
    border-radius: .6em .6em 0 0;
    padding: .5em .5em;
    display: flex;
    flex-direction: column;
`

const FilterItem = styled.button`
  border: none;
  padding: .25em .5em;
  border-radius: .4em;
  font-size: .9rem;
  font-weight: 600;
  color: white;
  background: darkslategray;
  &:hover {
    background: lightslategrey;
  }
`

interface Props {
  show: boolean
  close: () => void
  states: { value: string, text: string }[]
  setter: React.Dispatch<React.SetStateAction<string>>
}

function FilterOrdersModal({ show, close, states, setter }: Props) {
  const ref = useRef(null);
  useRootClose(ref, close, { disabled: !show });

  function handleFilter(value: string) {
    setter(value);
    close();
  }

  if (!show) return null

  return (
    <BottomModal>
      <Wrapper ref={ref}>
        <div>
          Filtrar órdenes
        </div>
        <div className="d-flex flex-wrap gap-2 mt-2">
          {states.map((s, index) => (
            <FilterItem key={index} onClick={() => handleFilter(s.value)}>{s.text}</FilterItem>
          ))}
        </div>
      </Wrapper>
    </BottomModal>
  );
}

export default FilterOrdersModal;
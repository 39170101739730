import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { axiosInstance } from "../../utils/axios";

export default function ChangeUserPasswordModal({ show, close, user, refresh }: any) {
  const { register, handleSubmit, setValue } = useForm()

  const handleClose = () => {
    setValue("lastPassword", "")
    setValue("newPassword", "")
    close()
  }

  const onSubmit = async (data: any) => {
    try {
      await axiosInstance.post(`/users/changePass/${user.id}`, {
        lastPassword: data.lastPassword,
        newPassword: data.newPassword,
      })
      refresh()
      handleClose()
      alert('Contraseña modificada')
    } catch (error: any) {
      alert(error.response.data)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar datos personales</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Col>
            <Row>
              <Form.Group className="mb-3" controlId="lastPassword">
                <Form.Label>Contraseña antigua</Form.Label>
                <Form.Control type="password" placeholder="Última constraseña" {...register("lastPassword")} required />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="newPassword">
                <Form.Label>Nueva contraseña</Form.Label>
                <Form.Control type="password" placeholder="Nueva Contraseña" {...register("newPassword")} required />
              </Form.Group>
            </Row>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Actualizar constraseña
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
import React from 'react'
import styled from 'styled-components'
import Admin from '../home/Admin'
import { FullPageLayout } from '../../components/FullPageLayout'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
`

const IndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  gap: 2rem;
`

const IndexHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const IndexDashboardSelector = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

function DashboardsPage() {
  const [onIndex, setOnIndex] = React.useState(true)
  const [onSummaryDashboard, setOnSummaryDashboard] = React.useState(false)

  const handleBackToIndex = () => setOnIndex(true)
  const handleGoToSummaryDashboard = () => {
    setOnIndex(false)
    setOnSummaryDashboard(true)
  }

  if (!onIndex && onSummaryDashboard) return (
    <Container>
      <Header>
        <Title>Resumen</Title>
        <button onClick={handleBackToIndex}>Volver</button>
      </Header>
      <Admin />
    </Container>
  )

  return (
    <IndexContainer>
      <IndexHeader>
        <Title>Mira entre los diferentes dashboards</Title>
      </IndexHeader>
      <IndexDashboardSelector>
        <button onClick={handleGoToSummaryDashboard}>Resumen</button>
      </IndexDashboardSelector>
    </IndexContainer>
  )
}

function Dashboards() {
  return (
    <FullPageLayout>
      <DashboardsPage />
    </FullPageLayout>
  )
}

export default Dashboards
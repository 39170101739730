import { axiosInstance } from "../axios";
import { formatToSelect } from "../functions";

export interface IGroup {
    id: number
    code?: string
    barCode?: string
    listPrice?: number
    name: string
    sellPrice: number
    state: boolean
    createdAt: Date
    updatedAt: Date
}

export const fetchAllProducts = async () => {
    try {
        const res = await axiosInstance.get('/products');
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const fetchProduct = async (id: number) => {
    try {
        const res = await axiosInstance.get(`/products/${id}`);
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const createProduct = async (body: object) => {
    try {
        return await axiosInstance.post('/products', body)
    } catch (error) {
        throw (error)
    }
}

export const updateProduct = async (id: number, body: {}) => {
    try {
        return await axiosInstance.patch(`/products/${id}`, body)
    } catch (error) {
        throw (error)
    }
}

export const productsForSelect = async () => {
    try {
        const res = await axiosInstance.get('/products');
        return formatToSelect(res.data)
    } catch (error) {
        throw (error)
    }
}

export const groupsForSelect = async () => {
    try {
        const res = await axiosInstance.get('/products/groups');
        return formatToSelect(res.data)
    } catch (error) {
        throw (error)
    }
}

export const fetchAllGroups = async () => {
    try {
        const res = await axiosInstance.get('/products/groups');
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const fetchGroup = async (id: number) => {
    try {
        const res = await axiosInstance.get(`/products/groups/${id}`);
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const createGroup = async (body: object) => {
    try {
        return await axiosInstance.post('/products/groups', body)
    } catch (error) {
        throw (error)
    }
}

export const updateGroup = async (id: number, body: {}) => {
    try {
        return await axiosInstance.patch(`/products/groups/${id}`, body)
    } catch (error) {
        throw (error)
    }
}

export const registerProductMovement = async (data: any) => {
    try {
        return await axiosInstance.post('/products/register-movement', data);
    } catch (error) {
        throw (error)
    }

}
export const getGroupsCategories = async () => {
    try {
        const res = await axiosInstance.get('/products/groups/categories');
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const addGroupCategory = async (data: any) => {
    try {
        return await axiosInstance.post('/products/groups/categories', data);
    } catch (error) {
        throw (error)
    }
}

export const updateGroupCategory = async (data: any, id: number) => {
    try {
        return await axiosInstance.patch(`/products/groups/categories/${id}`, data);
    } catch (error) {
        throw (error)
    }
}


import React from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import useModal from '../../../../hooks/useModal';
import { fetchAllGroups, IGroup } from '../../../../utils/api/products';
import { BorderedSection, BorderedSectionTitle } from './styleds'

function AddItemModal({ show, toggle, submitFunction }: any) {
  const [groups, setGroups] = React.useState<IGroup[]>([])
  const [selectedGroup, setSelectedGroup] = React.useState<IGroup[]>([])
  const [amount, setAmount] = React.useState(1)

  React.useEffect(() => {
    fetchAllGroups().then(setGroups)
  }, []);

  const close = () => {
    setSelectedGroup([])
    setAmount(1)
    toggle()
  }

  const addItem = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    submitFunction({
      id: selectedGroup[0].id,
      code: selectedGroup[0].code,
      name: selectedGroup[0].name,
      amount
    });
    close();
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Producto</Modal.Title>
      </Modal.Header>
      <Form onSubmit={addItem}>
        <Modal.Body>
          <Form.Group controlId="product">
            <Form.Label>Producto</Form.Label>
            <Typeahead
              id="group-selection"
              labelKey="name"
              onChange={setSelectedGroup}
              options={groups}
              placeholder="Seleccionar producto..."
              selected={selectedGroup}
              inputProps={{ required: true }}
            />
          </Form.Group>
          <Form.Group controlId="amount">
            <Form.Label>Cantidad</Form.Label>
            <Form.Control type="number" min="1" placeholder="Cantidad" value={amount} onChange={(e) => setAmount(Number(e.target.value))} required />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const ItemsSection = ({ items, setItems }: any) => {
  const modal = useModal();

  const addToItems = (item: any) => {
    setItems([...items, item])
  }

  const removeItem = (item: any) => {
    setItems(items.filter((i: any) => i !== item))
  }

  return (
    <>
      <BorderedSection>
        <BorderedSectionTitle>Items</BorderedSectionTitle>
        <div style={{ padding: '.25rem .5rem' }}>
          <Table>
            <thead>
              <tr>
                <th>Código</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item: any, index: any) => (
                <tr key={index}>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{item.amount}</td>
                  <td>
                    <div className="d-grid">
                      <Button size="sm" variant="outline-danger" onClick={() => removeItem(item)}>X</Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div style={{ padding: '.25rem .5rem' }}>
          <button onClick={modal.toogleModal} type='button' className='btn btn-outline-primary' style={{ width: '100%' }}>Añadir item</button>
        </div>
      </BorderedSection>
      <AddItemModal show={modal.isOpenModal} toggle={modal.toogleModal} submitFunction={addToItems} />
    </>
  )
}

export default ItemsSection
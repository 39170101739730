import React from 'react'
import useSWR from 'swr';
import SelectElementComponent from '../../sells/HistorySellOrders/FiltersForm/SelectElementComponent';
interface Props {
  value: string;
  selector: React.Dispatch<React.SetStateAction<string>>;
}

const useData = () => {
  const { data } = useSWR<{ id: string, value: string }[]>('/reports/products/groups-array');
  return {
    groupList: data
  }
}

function ProductGroupSelect({ value, selector }: Props) {
  const { groupList } = useData();
  return (
    <>
      {groupList &&
        <SelectElementComponent
          name="group"
          label="Producto"
          arrayData={groupList}
          value={value}
          setData={selector}
        />
      }
    </>
  )
}

export default ProductGroupSelect
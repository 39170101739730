import React from 'react'
import styled from 'styled-components'
import MovmentReasonsForm from '../MovmentReasonsForm'

const Container = styled.div`
  margin: 1rem 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
  }
`

const Header = styled.div`
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  padding: .25rem .5rem;
  font-size: 1.6rem;
  font-weight: 700;
  border-bottom: 1px solid rgba(99, 99, 99, 0.2)
`

const Sidebar = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(99, 99, 99, 0.2);
  margin-top: .5rem;
  @media (max-width: 1100px) {
    flex-direction: row;
    alig-items: center;
    border-right: none;
  }
`

const SidebarItem = styled.div`
  cursor: pointer;
  margin: .25rem .5rem;
  padding: .25rem 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 1rem;
  text-align: center;
  &:hover {
    background-color: darkgrey;
  }
`

const Main = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  @media (max-width: 1100px) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
`

function BoxesSettings() {
  const [movementsSelected, setMovementsSelected] = React.useState(false);
  const toggleMovements = () => {
    setMovementsSelected(true)
  }

  return <Container>
    <Header>Configuraciones varias</Header>
    <Sidebar>
      <SidebarItem onClick={toggleMovements} style={{ backgroundColor: movementsSelected ? 'darkgrey' : 'transparent' }}>Movimientos</SidebarItem>
    </Sidebar>
    <Main>
      {movementsSelected && <MovmentReasonsForm />}
    </Main>
  </Container>
}

export default BoxesSettings
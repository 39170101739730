import React, { useState } from "react"
import styled from "styled-components"
import { CustomerSelection } from "./CustomerSelection"
import { VendorSelection } from "./VendorSelection";
import dayjs from "dayjs";
import { axiosInstance } from "../../../../utils/axios";
import { Form } from "react-bootstrap";

const FilterFormDiv = styled.div`
  padding: .5rem 1rem;
`

const FlexItem = styled.div`
  flex-grow: 1;
`;

export type CustomerType = {
  id: number;
  name: string;
}

function FiltersForm(props: any) {
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerType[]>([]);
  const [selectedVendor, setSelectedVendor] = useState('all');
  const [documentType, setDocumentType] = useState('all');
  const [desde, setDesde] = useState('');
  const [hasta, setHasta] = useState(dayjs().format('YYYY-MM-DD'));

  const handleSearch = async (e: any) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.get('/reports/sales/history', {
        params: {
          selectedCustomer: selectedCustomer.length > 0 ? selectedCustomer[0].id : "all",
          selectedVendor,
          documentType,
          desde,
          hasta
        }
      });
      props.setData(data)
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <FilterFormDiv>
      <form onSubmit={handleSearch}>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem", flexGrow: 1 }}>
            <CustomerSelection value={selectedCustomer} selector={setSelectedCustomer}></CustomerSelection>
            <div style={{ display: "flex", gap: "1rem" }}>
              <FlexItem>
                <Form.Group controlId="documentType">
                  <Form.Label>Tipo de documento:</Form.Label>
                  <Form.Control as="select" value={documentType} onChange={(e) => setDocumentType(e.target.value)}>
                    <option value="all">Todos</option>
                    <option value="FACTURA">Facturas</option>
                    <option value="BOLETA">Boletas</option>
                    <option value="PROFORMA">Guías</option>
                  </Form.Control>
                </Form.Group>
              </FlexItem>
              <FlexItem>
                <VendorSelection value={selectedVendor} selector={setSelectedVendor}></VendorSelection>
              </FlexItem>
              <FlexItem>
                <Form.Group controlId="desde">
                  <Form.Label>Desde:</Form.Label>
                  <Form.Control type="date" value={desde} onChange={(e) => setDesde(e.target.value)} />
                </Form.Group>
              </FlexItem>
              <FlexItem>
                <Form.Group controlId="hasta">
                  <Form.Label>Hasta:</Form.Label>
                  <Form.Control type="date" value={hasta} onChange={(e) => setHasta(e.target.value)} required />
                </Form.Group>
              </FlexItem>
            </div>
          </div>
          <div style={{ display: "flex", alignContent: "center" }}>
            <input className="btn btn-primary" type="submit" value="Buscar ventas" />
          </div>
        </div>
      </form>
    </FilterFormDiv>
  )
}

export default FiltersForm
import { useState } from "react";
import { useHistory } from "react-router-dom";
import TableList from "../../../../components/TableList1";
import routes from "../../../../helpers/routes";
import useAuth from "../../../../hooks/useAuth";
import useModal from "../../../../hooks/useModal";
import { formatMoney } from "../../../../utils/functions";
import PayOrderModal from "../PayOrderModal";
import { PayButton, ViewButton } from "../../../common/styled";
import { useOrders } from "../useOrders";
import toast from "react-hot-toast";

export default function DeliveredTab({ orders, refresh, boxes }: any) {
  const { payOrder: apiPayOrder } = useOrders()
  let history = useHistory<any>()
  const { user } = useAuth()
  const { isOpenModal, toogleModal } = useModal()
  const [order, setOrder] = useState(null)

  const payOrder = async (data: any) => {
    try {
      if (order) {
        await apiPayOrder({ ...data, order });
        toast.success("Pago registrado");
        refresh();
      }
    } catch (e: any) {
      toast.error(e.response.data);
    }
  }

  const handlePayment = (order: any) => {
    setOrder(order)
    toogleModal()
  }

  const headers = (user?.role !== 'vendedor') ? ['ID', 'Vendedor', 'Cliente', 'Importe Total', 'Tipo', 'Por pagar', ''] : ['Cliente', 'Importe Total', 'Estado', 'Por pagar', ''];
  const body = orders?.map((order: any) => (
    <tr key={order.id}>
      {user?.role !== 'vendedor' && <td>{order.id}</td>}
      {user?.role !== 'vendedor' && <td>{order.user.username}</td>}
      <td>{order.customer.name}</td>
      <td>{formatMoney(order.total)}</td>
      <td>{order.futureDocument}</td>
      <td>{formatMoney(order.toPay)}</td>
      <td className="d-flex gap-1">
        <ViewButton title="Ver detalle" onClick={() => history.push(routes.viewOrder(order.id))}><i className="bi bi-eye"></i></ViewButton>
        {user?.role === 'admin' && order.state !== 'payed' && order.state !== 'finished' &&
          <PayButton title="Pagar orden" onClick={() => handlePayment(order)}><i className="bi bi-piggy-bank"></i></PayButton>
        }
      </td>
    </tr>
  ))

  return (
    <>
      <TableList headers={headers} body={body} />
      <PayOrderModal show={isOpenModal} close={toogleModal} order={order} submitFunction={payOrder} boxes={boxes} />
    </>
  )
}

import dayjs from 'dayjs';
import React from 'react'
import { Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components'
import writeXlsxFile from 'write-excel-file';
import useVendors from '../../../hooks/useVendors';
import { axiosInstance } from '../../../utils/axios';
import { toLocalDateFormat } from '../../../utils/functions';
import { periods } from '../../purchase/common';

const INIT_YEAR = 2022;

const Container = styled.div`
  padding: .5rem;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`

const FormDiv = styled.div`
  display: flex;
  gap: 1rem;
`

const ResultDiv = styled.div`
  margin-top: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`
function SalesReportPage() {
  const { vendorsArray } = useVendors();
  const { handleSubmit, register } = useForm();
  const [resultData, setResultData] = React.useState<any>([]);
  const [years, setYears] = React.useState<{ value: number, text: string }[]>([]);

  React.useEffect(() => {
    let year = dayjs().year();
    let arrayOfYears = [];
    while (year >= INIT_YEAR) {
      arrayOfYears.push({
        value: year,
        text: year.toString(),
      });
      year--;
    }
    setYears(arrayOfYears);
  }, []);

  async function downloadExcel() {
    try {
      const schema = [
        {
          column: "Nº",
          type: String,
          value: (order: any) => order.id.toString()
        },
        {
          column: "Fecha",
          type: String,
          value: (order: any) => toLocalDateFormat(order.saleDate)
        },
        {
          column: "Documento",
          type: String,
          value: (order: any) => order.futureDocument
        },
        {
          column: "Cliente",
          type: String,
          value: (order: any) => order.customer.name
        },
        {
          column: "Sub Total",
          type: Number,
          format: '#,##0.00',
          value: (order: any) => Number(order.subTotal)
        },
        {
          column: "IGV",
          type: Number,
          format: '#,##0.00',
          value: (order: any) => Number(order.igv)
        },
        {
          column: "Total",
          type: Number,
          format: '#,##0.00',
          value: (order: any) => Number(order.total)
        },
        {
          column: "Por Pagar",
          type: Number,
          format: '#,##0.00',
          value: (order: any) => Number(order.toPay)
        },
        {
          column: "Estado",
          type: String,
          value: (order: any) => order.state
        },
      ];
      return await writeXlsxFile(resultData, { schema, fileName: 'ventas.xlsx' });
    } catch (error) {
      console.error(error)
    }
  }

  async function onsubmit(data: any) {
    try {
      const res = await axiosInstance.get('/sales/vendors-report', {
        params: {
          vendor: data.vendor,
          period: data.period,
          year: data.year,
        }
      });
      setResultData(res.data)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onsubmit)}>
        <FormDiv>
          <select id="vendors" {...register('vendor')} required>
            <option value="">Selecciona el vendedor</option>
            {vendorsArray && vendorsArray.map((vendor, index) => (
              <option key={index} value={vendor.value}>{vendor.label}</option>
            ))}
          </select>
          <select id="period" {...register('period')} required>
            <option value="">Selecciona el periodo</option>
            {periods.map((p, i) => (
              <option key={i} value={p.value}>{p.text}</option>
            ))}
          </select>
          <select id="year" {...register('year')} required>
            <option value="">Selecciona el año</option>
            {years.map((y, i) => (
              <option key={i} value={y.value}>{y.text}</option>
            ))}
          </select>
          <button type="submit">Buscar ventas</button>
        </FormDiv>
      </form>
      <ResultDiv>
        {resultData.length > 0 &&
          <>
            <div>
              <button className='btn btn-success' onClick={downloadExcel}>Descargar reporte</button>
            </div>
            <Table responsive size='sm'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Fecha</th>
                  <th>Cliente</th>
                  <th>Total</th>
                  <th>Por Pagar</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {resultData.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{toLocalDateFormat(item.saleDate)}</td>
                    <td>{item.customer.name}</td>
                    <td>{item.total}</td>
                    <td>{item.toPay}</td>
                    <td>{item.state}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        }
      </ResultDiv>
    </Container>
  )
}

export default SalesReportPage
import React from 'react'

interface Props {
  itemsPerPage: number
  totalItems: number
  paginate: (pageNumber: number) => void
}

const Pagination = ({ itemsPerPage, totalItems, paginate }: Props) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
    <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
          <li key={number} className="page-item">
            <span onClick={() => paginate(number)} className='page-link' style={{ cursor: 'pointer' }}>
              {number}
            </span>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Pagination
import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ConfirmModal from "../../../components/ConfirmModal";
import { PageLayout } from "../../../components/PageLayout";
import TableList from "../../../components/TableList";
import routes from "../../../helpers/routes";
import useModal from "../../../hooks/useModal";
import usePurchase from "../../../hooks/usePurchase";
import { Purchase, usePurchases } from "../../../hooks/usePurchases";
import { formatMoney, toLocalDateFormat } from "../../../utils/functions";
import { ConfirmButton } from "../../common/styled";
import toast from 'react-hot-toast'
import InputManualPurchaseForm from "./PurchaseManualForm";

const styles = {
    flexHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
} as const;

export default function PurchasePage() {
    const { addPurchase } = usePurchase('inputs');
    const [onCreation, setOnCreation] = useState(false);
    const { purchases, isLoading, isError, mutate } = usePurchases('inputs');

    const toogle = () => setOnCreation(!onCreation)

    const registerPurchase = async (purchase: any) => {
        try {
            await addPurchase(purchase);
            mutate();
            toogle();
            toast.success("Compra registrada");
        } catch (error) {
            alert(error)
        }
    }

    if (isLoading) {
        return <PageLayout>Cargando...</PageLayout>
    }
    if (isError) {
        return <PageLayout>{JSON.stringify(isError, null, 2)}</PageLayout>
    }

    return (
        <PageLayout>
            {onCreation ?
                <>
                    <InputManualPurchaseForm submitFunction={registerPurchase} toogle={toogle}></InputManualPurchaseForm>
                </>
                :
                <>
                    <div style={styles.flexHeader}>
                        <h2>Compra de insumos</h2>
                        <Button variant="primary" onClick={toogle}>Ingresar compra</Button>
                    </div>
                    <List purchases={purchases} refreshPurchases={mutate} />
                </>
            }
        </PageLayout>
    )
}

interface ListProps {
    purchases: Purchase[]
    refreshPurchases: any
}

function List({ purchases, refreshPurchases }: ListProps) {
    const { confirmPurchase: apiConfirm } = usePurchase('inputs');
    const [selectedPurchase, setSelectedPurchase] = useState<any>(null);
    const [showPayables, setShowPayables] = useState(false);
    const confirmModal = useModal();

    const filteredPurchases = useMemo(() => {
        if (showPayables) return purchases.filter(p => p.state === 'confirmed')
        else return purchases
    }, [purchases, showPayables])

    const confirmPurchase = async () => {
        try {
            if (selectedPurchase) {
                await apiConfirm(selectedPurchase.id);
                refreshPurchases();
                handleClose();
                toast.success("Compra confirmada");
            }
        } catch (e) {
            alert(JSON.stringify(e, null, 2));
        }
    }

    const handleConfirm = (purchase: any) => {
        setSelectedPurchase(purchase);
        confirmModal.toogleModal();
    }

    const handleClose = () => {
        setSelectedPurchase(null);
        confirmModal.toogleModal();
    }

    function formatState(state: string) {
        switch (state) {
            case 'draft':
                return 'Borrador';
            case 'confirmed':
                return 'Confirmado';
            case 'payed':
                return 'Pagado';
            default:
                return state;
        }
    }

    const headers = ['Fecha', 'Proveedor', 'Factura', 'Total', 'Estado', 'Cancelado', 'Acciones'];

    return (
        <>
            <div>
                <input type="checkbox" name="filter" id="filter" className="form-check-input" checked={showPayables} onChange={(e) => setShowPayables(e.target.checked)} />
                <label className="form-check-label ms-1" htmlFor="filter">
                    Mostrar compras por pagar
                </label>
            </div>
            <TableList headers={headers} items={filteredPurchases} searchParams={["date", "providerName", "purchaseNumber"]}>
                {(purchase) => (
                    <>
                        <td>{toLocalDateFormat(purchase.date)}</td>
                        <td>{purchase.providerName}</td>
                        <td>{purchase.purchaseNumber}</td>
                        <td>{formatMoney(purchase.total, purchase.currency)}</td>
                        <td>{formatState(purchase.state)}</td>
                        <td>{formatMoney(Math.round((purchase.total - purchase.toPay) * 100) / 100, purchase.currency)}</td>
                        <td className="d-flex gap-1 justify-content-center">
                            <Link to={routes.purchase.viewInput(purchase.id)}>
                                <button className="btn btn-primary btn-sm">Ver detalle</button>
                            </Link>
                            {purchase.state === 'draft' ? <ConfirmButton onClick={() => handleConfirm(purchase)} title="Confirmar orden"><i className="bi bi-cart-check"></i></ConfirmButton> : null}
                        </td>
                    </>
                )}
            </TableList>
            <ConfirmModal show={confirmModal.isOpenModal} close={handleClose}
                primaryText="¿Desea confirmar la compra de insumos?"
                secondaryText="Esto ejecutará el ingreso de insumos al stock"
                handleFunction={confirmPurchase} />
        </>
    )
}
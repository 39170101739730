import { Redirect, Route, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import routes from "../helpers/routes";

export default function PrivateRoute({ vendorAdmit = true, hasRole: role, ...rest }: any) {
    const location = useLocation();
    const { isLogged, hasRole, isVendor } = useAuth();

    if (!vendorAdmit && isVendor()) return <Redirect to={routes.home} />;
    if (role && !hasRole(role)) return <Redirect to={routes.home} />;
    if (!isLogged()) return <Redirect to={{ pathname: routes.login, state: { from: location } }} />;

    return <Route {...rest} />;
}
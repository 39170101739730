import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Breadcrumbs({ breadcrumbs }: any) {
  return <div>
    <Breadcrumb>
      {breadcrumbs.links.map((link: any, index: number) => (
        <Breadcrumb.Item key={index} linkProps={{ to: link.to }} linkAs={Link}>
          {link.text}
        </Breadcrumb.Item>
      ))}
      <Breadcrumb.Item active>{breadcrumbs.active}</Breadcrumb.Item>
    </Breadcrumb>
  </div>;
}

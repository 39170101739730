import React, { useState } from 'react'
import useModal from '../../../../hooks/useModal'
import GuiaRemisionForm from './GuiaRemisionForm'
import SearchForInvoice from './SearchForInvoice'
import { Container, Header, Main } from './styleds'

const NuevaGuiaRemision = ({ toggleAdding }: any) => {
  const [invoice, setInvoice] = useState();

  const modal = useModal();
  const cancel = () => toggleAdding();
  return (
    <>
      <Container>
        <Header>
          <button className='btn btn-outline-warning' onClick={modal.toogleModal}>Cargar datos de factura</button>
        </Header>
        <Main>
          <GuiaRemisionForm invoice={invoice} cancel={cancel} />
        </Main>
      </Container>
      <SearchForInvoice show={modal.isOpenModal} toggle={modal.toogleModal} setInvoice={setInvoice} />
    </>
  )
}

export default NuevaGuiaRemision
import React from 'react'
import { Title } from './styleds';
import { Vendor } from './useVendors';
import VendorForm from './VendorForm';

interface Props {
  editing: boolean;
  editToggle: () => void;
  vendor: Vendor | undefined;
  submitFunction: (id: Number | undefined, v: Vendor) => void;
}

const EditVendor = (props: Props) => {
  const update = (vendor: Vendor) => {
    props.submitFunction(props.vendor?.id, vendor);
  }

  if (!props.editing) return null

  return (
    <>
      <Title>Editar Vendedor</Title>
      <VendorForm vendor={props.vendor} toggle={props.editToggle} submitFunction={update}></VendorForm>
    </>
  )
}

export default EditVendor
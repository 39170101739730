import { useState } from 'react';
import useSWR from 'swr';
import { axiosInstance } from '../utils/axios';

export interface Vendor {
  id?: Number;
  firstName: string;
  lastName: string;
  documentNumber: string;
  email: string;
  status?: boolean;
  userId?: string;
}

const useVendors = () => {
  const { data, error, mutate } = useSWR('/vendors');
  const [vendor, setVendor] = useState<Vendor>();
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);

  const createToggle = () => setCreating(!creating);
  const editToggle = () => setEditing(!editing);

  const addVendor = async (vendor: Vendor) => {
    try {
      await axiosInstance.post('/vendors', vendor);
      mutate();
      createToggle();
    } catch (e) {
      throw (e)
    }
  }

  const editVendor = async (id: Number | undefined, vendor: Vendor) => {
    try {
      await axiosInstance.patch(`/vendors/${id}`, vendor);
      mutate();
      editToggle();
    } catch (e) {
      throw (e)
    }
  }

  return { vendors: data, isLoading: !error && !data, isError: error, creating, createToggle, addVendor, vendor, setVendor, editing, editToggle, editVendor };
};

export default useVendors;

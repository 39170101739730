import React from 'react'
import styled from 'styled-components'
import { SellOrderContext } from '../../../../providers/SellOrdersProvider';
import { formatMoney } from '../../../../utils/functions';
import PayableOrderItem from '../PayableOrderItem';

const Conatiner = styled.div`
  overflow-y: scroll;
  grid-area: watcheds;
  margin: .5rem;
  border: 1px solid yellow;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
`

function WhatchedCustomers() {
  const { watchedSales, watchedSumary } = React.useContext(SellOrderContext);

  return (
    <Conatiner>
      <div className="header">Ventas de clientes observados</div>
      <div className="sumary">
        <div>{watchedSumary.numberOfSales} ventas</div>
        <div>{formatMoney(watchedSumary.moneyTocollet)} por cobrar</div>
      </div>
      <div className="body">
        {watchedSales.length === 0 && <h4>No hay ventas por cobrar</h4>}
        {watchedSales.map(order => (
          <PayableOrderItem key={order.id} order={order} boxes={[]}></PayableOrderItem>
        ))}
      </div>
    </Conatiner>
  )
}

export default WhatchedCustomers
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { fetchAllProviders } from '../utils/api/providers'
import { axiosInstance } from '../utils/axios'
import useAuth from './useAuth';

export interface Provider {
    id: number;
    name: string;
}

export function useProviders() {
    const { data, error, mutate } = useSWR('/providers')

    return {
        providers: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    }
}

export function useProvidersData() {
    const [providers, setProviders] = useState<Provider[]>([])

    useEffect(() => {
        fetchAllProviders().then(setProviders);
    }, [])

    return { providers }
}

export function useProvider(id: number) {
    const { user } = useAuth();
    const { data, error, mutate } = useSWR(`/providers/${id}`)

    const addInput = async (providerId: number, input: any) => {
        try {
            await axiosInstance.post(`/providers/add-input/${providerId}`, input)
        } catch (error) {
            throw (error)
        }
    }

    const removeInput = async (providerInputId: number) => {
        try {
            await axiosInstance.delete(`/providers/remove-input/${providerInputId}`)
        } catch (error) {
            throw (error)
        }
    }

    return {
        provider: data,
        isLoading: !error && !data,
        isError: error,
        role: user?.role,
        mutate,
        addInput,
        removeInput
    }
}
import { axiosInstance } from "../axios";
import { ICredentials } from "../interfaces";

export async function getRole() {
    try {
        const res = await axiosInstance.get('/auth/getRole');
        return res.data;
    } catch (error) {
        throw error;
    }
}

export async function getCurrentUser() {
    try {
        const res = await axiosInstance.get('/auth/user');
        return res.data;
    } catch (error) {
        throw error;
    }
}

export async function callLogin(credentials: ICredentials) {
    try {
        const res = await axiosInstance.post('auth/login', credentials);
        return res.data;
    } catch (error) {
        throw error;
    }
}

export async function callLogout() {
    try {
        await axiosInstance.post('auth/logout', { token: localStorage['refreshToken'] })
    } catch (error) {
        throw error;
    }
}
import { useCallback, useEffect, useState } from "react";
import { axiosInstance } from "../../utils/axios";

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null)

  const refresh = useCallback(async () => {
    try {
      const res = await axiosInstance.get('/users')
      setUsers(res.data)
    } catch (error: any) {
      setError(error)
    }
  }, [])

  useEffect(() => {
    refresh()
  }, [refresh])

  const addUser = async (data: any) => {
    try {
      const withPass = {
        ...data,
        password: '123456'
      }
      await axiosInstance.post('/users', withPass);
    } catch (error: any) {
      setError(error);
    }
  }

  return { users, refresh, error, addUser }
}
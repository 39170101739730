import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import ConfirmModal from "../../../components/ConfirmModal";
import { PageLayout } from "../../../components/PageLayout";
import TableList from "../../../components/TableList";
import routes from "../../../helpers/routes";
import useModal from "../../../hooks/useModal";
import usePurchase from "../../../hooks/usePurchase";
import { Purchase, usePurchases } from "../../../hooks/usePurchases";
import { formatMoney, toLocalDateFormat } from "../../../utils/functions";
import { ConfirmButton } from "../../common/styled";
import SupplyManualPurchaseForm from "./PurchaseManualForm";

export default function SuppliesPurchasePage() {
    const { addPurchase } = usePurchase('supplies');
    const [onManualCreation, setOnManualCreation] = useState(false);
    const { purchases, isLoading, isError, mutate } = usePurchases('supplies');

    const toogleManualPurchase = () => setOnManualCreation(!onManualCreation)

    const registerPurchase = async (purchase: any) => {
        try {
            await addPurchase(purchase)
            mutate()
            toogleManualPurchase()
        } catch (error) {
            alert(error)
        }
    }

    if (isLoading) {
        return <PageLayout>Cargando...</PageLayout>
    }
    if (isError) {
        return <PageLayout>{JSON.stringify(isError, null, 2)}</PageLayout>
    }

    return (
        <PageLayout>
            {onManualCreation &&
                <SupplyManualPurchaseForm submitFunction={registerPurchase} toogle={toogleManualPurchase}></SupplyManualPurchaseForm>
            }
            {!onManualCreation &&
                <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h2>Compra de suministros</h2>
                        <div className="d-flex align-items-center gap-2">
                            <Button variant="primary" onClick={toogleManualPurchase}>Ingresar compra</Button>
                        </div>
                    </div>
                    <List purchases={purchases} refreshPurchases={mutate} />
                </>
            }
        </PageLayout>
    )
}

interface ListProps {
    purchases: Purchase[]
    refreshPurchases: any
}

function List({ purchases, refreshPurchases }: ListProps) {
    const { confirmPurchase: apiConfirm } = usePurchase('supplies');
    const [selectedPurchase, setSelectedPurchase] = useState<any>(null);
    const [showPayables, setShowPayables] = useState(false);
    const confirmModal = useModal();

    const filteredPurchases = useMemo(() => {
        if (showPayables) return purchases.filter(p => p.state === 'confirmed')
        else return purchases
    }, [purchases, showPayables])

    const confirmPurchase = async () => {
        try {
            if (selectedPurchase) {
                await apiConfirm(selectedPurchase.id);
                refreshPurchases();
                handleClose();
                toast.success("Compra confirmada");
            }
        } catch (e: any) {
            toast.error(e.response.data)
        }
    }

    const handleConfirm = (purchase: any) => {
        setSelectedPurchase(purchase);
        confirmModal.toogleModal();
    }

    const handleClose = () => {
        setSelectedPurchase(null);
        confirmModal.toogleModal();
    }

    const headers = ['Fecha', 'Suministrador', 'Factura', 'Total', 'Estado', 'Cancelado', 'Acciones'];

    return (
        <>
            <div>
                <input type="checkbox" name="filter" id="filter" className="form-check-input" checked={showPayables} onChange={(e) => setShowPayables(e.target.checked)} />
                <label className="form-check-label ms-1" htmlFor="filter">
                    Mostrar compras por pagar
                </label>
            </div>
            <TableList headers={headers} items={filteredPurchases} searchParams={["date", "supplierName", "purchaseNumber"]}>
                {(purchase) => (
                    <>
                        <td>{toLocalDateFormat(purchase.date)}</td>
                        <td>{purchase.supplierName}</td>
                        <td>{purchase.purchaseNumber}</td>
                        <td>{formatMoney(purchase.total)}</td>
                        <td>{purchase.state}</td>
                        <td>{formatMoney(Math.round((purchase.total - purchase.toPay) * 100) / 100)}</td>
                        <td className="d-flex gap-1 justify-content-center">
                            <Link to={routes.purchase.viewSupply(purchase.id)}>
                                <button className="btn btn-primary btn-sm">Ver detalle</button>
                            </Link>
                            {purchase.state === 'draft' && <ConfirmButton onClick={() => handleConfirm(purchase)} title="Confirmar orden"><i className="bi bi-cart-check"></i></ConfirmButton>}
                        </td>
                    </>
                )}
            </TableList>
            <ConfirmModal show={confirmModal.isOpenModal} close={handleClose}
                primaryText="¿Desea confirmar la compra?"
                secondaryText="Ya no se podrá editar la factura luego de la confirmación"
                handleFunction={confirmPurchase} />
        </>
    )
}
import React, { useState } from 'react'
import useSWR from 'swr'
import CargarDatos from '../../../components/CargarDatos';
import { axiosInstance } from '../../../utils/axios';
import MostrarUbigeos from './MostrarUbigeos';

const useUbigeo = () => {
  const { data, error, mutate } = useSWR('/ubigeos');
  return {
    ubigeos: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

const UbigeoSettings = () => {
  const { ubigeos, isLoading, isError } = useUbigeo();
  const [goUpload, setGoUpload] = useState(false);

  const upload = async (data: any) => {
    try {
      await axiosInstance.post('/excel-uploads/ubigeos', data);
    } catch (e) {
      console.log(e)
    }
  }

  if (isError) return <h3>Ocurrió un error en el servidor</h3>
  if (isLoading) return <h2>Cargando...</h2>
  return (
    <>
      {!goUpload &&
        <div className="d-flex justify-content-end">
          <button className='btn btn-warning' onClick={() => setGoUpload(!goUpload)}>Cargar datos</button>
        </div>
      }
      {goUpload && <CargarDatos toogle={() => setGoUpload(!goUpload)} subject="ubigeos" uploadFunction={upload} />}
      <MostrarUbigeos isEmpty={ubigeos.isEmpty} departamentos={ubigeos.departamentos} provincias={ubigeos.provincias} ubigeos={ubigeos.ubigeos} />
    </>
  )
}

export default UbigeoSettings
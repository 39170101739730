import TableList from './TableList1';
import { BoxAddButton, BoxContainer, BoxDescription, BoxHeader, BoxMain } from './styleds/boxAggregableTable';

export default function BoxAggregableTable({ description, buttonText, headers, body, toogle, modal }: any) {

  return <div>
    <BoxContainer>
      <BoxHeader>
        <BoxDescription>{description}</BoxDescription>
        <BoxAddButton onClick={() => toogle()}>{buttonText}</BoxAddButton>
      </BoxHeader>
      <BoxMain>
        <TableList headers={headers} body={body} noMargin={true} />
      </BoxMain>
    </BoxContainer>
    {modal}
  </div>;
}

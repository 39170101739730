import React from 'react'
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { FullPageLayout } from '../../components/FullPageLayout'
import PrivateRoute from '../../routers/PrivateRoute';
import { ModuleContainer, NavItem, Sidebar } from '../common/modules.styles';
import Clientes from './clientes/Clientes';
import MaestrosSumary from './MaestrosSumary';

function MaestrosIndex() {
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const handleIndex = () => history.push(path);

  return (
    <FullPageLayout>
      <ModuleContainer>
        <Sidebar>
          <h1 style={{ textAlign: 'center', cursor: 'pointer' }} onClick={handleIndex}>Maestros</h1>
          <NavItem to={`${url}/clientes`}>clientes</NavItem>
          <NavItem to={`${url}/`}>productos</NavItem>
          <NavItem to={`${url}/clientes`}>grupos de productos</NavItem>
          <NavItem to={`${url}/`}>proveedores</NavItem>
          <NavItem to={`${url}/`}>insumos</NavItem>
          <NavItem to={`${url}/`}>suministradores</NavItem>
          <NavItem to={`${url}/`}>suministros</NavItem>
        </Sidebar>
        <React.Fragment>
          <Switch>
            <PrivateRoute exact path={path} component={MaestrosSumary} />
            <PrivateRoute path={`${path}/clientes`} component={Clientes} />
          </Switch>
        </React.Fragment>
      </ModuleContainer>
    </FullPageLayout>
  )
}

export default MaestrosIndex
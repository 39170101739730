import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import { getDNIData, getRUCData } from "../../services/consulta_ruc_dni";
import { CustomerModel } from "./CustomersPage";

interface Props {
    submitFunction: (customer: Partial<CustomerModel>) => void,
    toggle: any
}

export default function NewCustomer({ submitFunction, toggle }: Props) {
    const { user } = useAuth();
    const [disable, setDisable] = useState(true);
    const [documentType, setDocumentType] = useState('RUC');
    const [documentNumber, setDocumentNumber] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [reference, setReference] = useState('');
    const [watched, setWatched] = useState(false);

    function handleDocumentType(e: any) {
        const value = e.target.value
        setDocumentType(value)
        if (value !== 'CEX') {
            setDisable(true)
            return
        }
        setDisable(false)
    }

    async function handleConsultaRUCDNI() {
        try {
            if (documentNumber === '') {
                alert('Ingresa un número de documento a buscar');
            } else {
                if (documentType === 'RUC') {
                    const consulta = await getRUCData(documentNumber);
                    setName(consulta.nombre)
                    setAddress(consulta.direccion)
                } else {
                    const consulta = await getDNIData(documentNumber);
                    setName(consulta.nombre)
                    setAddress(!consulta.direccion ? '-' : consulta.direccion)
                }
            }
        } catch (error: any) {
            toast.error(error)
        }
    }

    function handleSubmit(e: React.FormEvent<EventTarget>) {
        e.preventDefault()
        submitFunction({
            name,
            documentNumber,
            documentType,
            address,
            reference,
            watched,
            userId: localStorage['userId']
        });
    }

    return (
        <>
            <div className="d-flex justify-content-between gap-2">
                <Button variant="secondary" onClick={() => toggle()}>Regresar al listado</Button>
                {user && (user.role === 'admin' || user.role === 'administrador') &&
                    <Button variant="warning" onClick={() => setDisable(false)}>Habililitar campos</Button>
                }
            </div>
            <form className="mt-2" onSubmit={handleSubmit}>
                <h4>Nuevo cliente</h4>
                <Row>
                    <Col>
                        <Form.Group controlId='documentType'>
                            <Form.Label>Tipo de documento</Form.Label>
                            <Form.Select value={documentType} onChange={handleDocumentType} required>
                                <option value="RUC">RUC</option>
                                <option value="DNI">DNI</option>
                                <option value="CEX">Carnet de extranjeria</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='documentNumber'>
                            <Form.Label>Número de documento</Form.Label>
                            <InputGroup>
                                <Form.Control type='text' placeholder='Número' value={documentNumber} onChange={(e) => setDocumentNumber(e.target.value)} required />
                                <Button onClick={handleConsultaRUCDNI} disabled={!disable} variant="outline-secondary" id="button-addon1">
                                    <i className="bi bi-search"></i>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Form.Group controlId='name'>
                            <Form.Label>Nombre o Razón social</Form.Label>
                            <Form.Control disabled={disable} type='text' placeholder='Nombre' value={name} onChange={(e) => setName(e.target.value)} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Form.Group controlId='address'>
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control disabled={disable} type='text' placeholder='Dirección' value={address} onChange={(e) => setAddress(e.target.value)} required />
                    </Form.Group>
                </Row>
                <Row className='mt-3'>
                    <Form.Group controlId='reference'>
                        <Form.Label>Referencia</Form.Label>
                        <Form.Control type='text' placeholder='Referencia' value={reference} onChange={(e) => setReference(e.target.value)} />
                    </Form.Group>
                </Row>
                {user && user.role !== 'vendedor' &&
                    <Row className='mt-3'>
                        <div className="col">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="watched" defaultChecked={watched} onChange={(e) => setWatched(e.target.checked)} id="watched" />
                                <label className="form-check-label" htmlFor="watched">
                                    Observar cliente
                                </label>
                            </div>
                        </div>
                    </Row>
                }
                <div className="d-grid">
                    <Button className="mt-2" type="submit" variant="primary">Guardar</Button>
                </div>
            </form>
        </>
    )
}
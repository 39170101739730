import { useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { getDNIData, getRUCData } from '../../services/consulta_ruc_dni';

export default function CustomerModalForm({ show, toogle, submitFunction }: any) {
  const [disable, setDisable] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [reference, setReference] = useState('');

  function handleClose() {
    setDisable(false);
    setDocumentType('');
    setDocumentNumber('');
    setName('');
    setAddress('');
    toogle();
  }

  function handleDocumentType(e: any) {
    const value = e.target.value
    setDocumentType(value)
    if (value !== 'CEX') {
      if (value === '') setDisable(false)
      else setDisable(true)
    } else {
      setDisable(false)
    }
  }

  async function handleConsultaRUCDNI() {
    try {
      if (documentNumber === '') {
        alert('Ingresa un número de documento a buscar');
      } else {
        if (documentType === 'RUC') {
          const consulta = await getRUCData(documentNumber);
          setName(consulta.nombre)
          setAddress(consulta.direccion)
        } else {
          const consulta = await getDNIData(documentNumber);
          setName(consulta.nombre)
          setAddress(!consulta.direccion ? '-' : consulta.direccion)
        }
      }
    } catch (error: any) {
      toast.error(error)
    }
  }

  function handleSubmit(e: any) {
    e.stopPropagation();
    e.preventDefault();
    const customer = {
      name,
      documentType,
      documentNumber,
      address,
      reference,
      userId: localStorage['userId']
    }
    submitFunction(customer);
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Nuevo cliente</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId='documentType'>
                <Form.Label>Tipo de documento</Form.Label>
                <Form.Select value={documentType} onChange={handleDocumentType} required>
                  <option value="">Seleccionar uno</option>
                  <option value="RUC">RUC</option>
                  <option value="DNI">DNI</option>
                  <option value="CEX">Carnet de extranjeria</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='documentNumber'>
                <Form.Label>Número de documento</Form.Label>
                <InputGroup>
                  <Form.Control type='text' placeholder='Número' value={documentNumber} onChange={(e) => setDocumentNumber(e.target.value)} required />
                  <Button onClick={handleConsultaRUCDNI} disabled={!disable} variant="outline-secondary" id="button-addon1">
                    <i className="bi bi-search"></i>
                  </Button>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <Form.Group controlId='name'>
                <Form.Label>Nombre o Razón social</Form.Label>
                <Form.Control disabled={disable} type='text' placeholder='Nombre' value={name} onChange={(e) => setName(e.target.value)} required />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Form.Group controlId='address'>
              <Form.Label>Dirección</Form.Label>
              <Form.Control disabled={disable} type='text' placeholder='Dirección' value={address} onChange={(e) => setAddress(e.target.value)} required />
            </Form.Group>
          </Row>
          <Row className='mt-3'>
            <Form.Group controlId='reference'>
              <Form.Label>Referencia</Form.Label>
              <Form.Control type='text' placeholder='Referencia' value={reference} onChange={(e) => setReference(e.target.value)} />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

import { Button, Table } from 'react-bootstrap';
import CarrierForm from './CarrierForm';
import useCarriers from './useCarriers'

const TransportistasPage = () => {
  const { carriers, isError, isLoading, adding, editing, toggleAdding, toggleEditing, addCarrier, editCarrier, carrier, handleEdit } = useCarriers();

  if (isError) return <h3>Ocurrió un error en el servidor</h3>
  if (isLoading) return <h2>Cargando...</h2>
  return (
    <>
      {adding && !editing && <CarrierForm active={adding} cancel={toggleAdding} submitFunction={addCarrier} />}
      {editing && !adding && <CarrierForm active={editing} carrier={carrier} cancel={toggleEditing} submitFunction={editCarrier} />}
      {!adding && !editing &&
        <>
          <div className="d-flex">
            <button className='btn btn-success' onClick={toggleAdding}>Agregar trasportista</button>
          </div>
          <Table className='mt-2'>
            <thead>
              <tr>
                <th>Transportista</th>
                <th>Tipo de documento</th>
                <th>Número de documento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {carriers.carriers.map((c: any) => (
                <tr key={c.id}>
                  <td>{c.name}</td>
                  <td>{c.documentType}</td>
                  <td>{c.documentNumber}</td>
                  <td><Button className="sm" variant="warning" onClick={() => handleEdit(c)}>editar</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
          {carriers.carriers.length === 0 &&
            <h4>Aún no hay datos de transportistas</h4>
          }
        </>
      }
    </>
  )
}

export default TransportistasPage
import dayjs from "dayjs";
import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function ConfirmDatesModal({ show, close, handleFunction }: any) {
  const [desde, setDesde] = React.useState('');
  const [hasta, setHasta] = React.useState(dayjs().format('YYYY-MM-DD'));

  // funcion para devolver el rango de fechas con handleFunction
  const handleSubmit = () => {
    handleFunction(desde, hasta);
    close();  // cerrar modal
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Body>
        <h4>Selecciona las fechas</h4>
        <div className="row">
          <div className="col-md-6">
            <label>Desde</label>
            <input type="date" className="form-control" value={desde} onChange={(e) => setDesde(e.target.value)} required />
          </div>
          <div className="col-md-6">
            <label>Hasta</label>
            <input type="date" className="form-control" value={hasta} onChange={(e) => setHasta(e.target.value)} required />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => close()}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => handleSubmit()}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components';
import { axiosInstance } from '../../utils/axios';
registerLocale('es', es);

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Dato = styled.span`
  font-weight: 600;
`

function ResumenDeVentasPorMes() {
  const [startDate, setStartDate] = useState(new Date());
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);

  const getData = useCallback(() => {
    (async () => {
      try {
        const res = await axiosInstance.get('/sales/vendor-month-summary', {
          params: {
            date: startDate
          }
        });
        setData(res.data)
      } catch (e) {
        setError(e)
      }
    })()
  }, [startDate]);

  useEffect(() => {
    getData();
  }, [getData])

  return (
    <>
      <Header>
        <span>Mes: </span>
        <DatePicker
          selected={startDate}
          onChange={(date) => date && setStartDate(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          locale="es"
        />
      </Header>
      {error && alert(JSON.stringify(error, null, 2))}
      {data && data.amountOfSales === 0 &&
        <div style={{ color: 'red', fontWeight: 600 }}>No hay ventas en el mes seleccionado</div>
      }
      {data && data.amountOfSales > 0 &&
        <>
          <div>
            <span>Número de ventas: </span><Dato>{data.amountOfSales}</Dato>
          </div>
          <div>
            <span>Total vendido: </span><Dato>S/. {data.total}</Dato>
          </div>
          <div>
            <span>Comisión: </span><Dato>S/. {data.comision}</Dato>
          </div>
        </>
      }
    </>
  )
}

export default ResumenDeVentasPorMes
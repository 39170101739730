import React from 'react'
import toast from 'react-hot-toast'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { PageTitle } from '../../../components/styleds/common'
import routes from '../../../helpers/routes'
import { SellOrderContext } from '../../../providers/SellOrdersProvider'
import { updateSellOrder } from '../../../utils/api/sellOrders'
import OrderForm from '../orders/OrderForm'
import { useOrdersById } from '../orders/useOrders'

const Conatiner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

function EditSellOrder() {
  const { fetch } = React.useContext(SellOrderContext);
  let history = useHistory()
  const { id } = useParams<any>()
  const { order } = useOrdersById(id);

  const updateOrder = (orderForm: any) => {
    updateSellOrder(orderForm, id).then(() => {
      fetch();
      toast.success("Actualizada correctamente");
      history.push(routes.ventas.ver(id))
    })
  }

  if (!order) return <Conatiner>Cargando...</Conatiner>

  return (
    <Conatiner>
      <PageTitle>Editar orden de venta</PageTitle>
      <OrderForm order={order} submitFunction={updateOrder}></OrderForm>
    </Conatiner>
  )
}

export default EditSellOrder
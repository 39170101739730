import React from 'react'
import { BoxElement, Button, Text } from './styleds'
import { getDetailedOrdersDataReport } from './functions';
import useModal from '../../../hooks/useModal';
import ConfirmDatesModal from './ConfirmDatesModal';

function SalesData() {
  const [loading, setLoading] = React.useState(false);
  const datesModal = useModal();

  const handleExportSalesData = (desde: string, hasta: string) => {
    setLoading(true);
    getDetailedOrdersDataReport(desde, hasta).then(() => setLoading(false)).catch((e) => {
      alert(e.message);
      setLoading(false);
    });
  }

  return (
    <>
      <BoxElement>
        <Text>Exportar datos de ventas</Text>
        <Button onClick={datesModal.toogleModal} disabled={loading}>{!loading ? "Exportar" : 'Cargando...'}</Button>
      </BoxElement>
      <ConfirmDatesModal show={datesModal.isOpenModal} close={datesModal.toogleModal} handleFunction={handleExportSalesData} />
    </>
  )
}

export default SalesData
import React from 'react'
import { BoxElement, Button, Text } from './styleds'
import { getDetailedSoldProductsDataReport } from './functions';
import useModal from '../../../hooks/useModal';
import ConfirmDatesModal from './ConfirmDatesModal';

function SoldProductsData() {
  const [loading, setLoading] = React.useState(false);
  const datesModal = useModal();

  const handleExportProductsData = (desde: string, hasta: string) => {
    setLoading(true);
    getDetailedSoldProductsDataReport(desde, hasta).then(() => setLoading(false)).catch((e) => {
      alert(e.message);
      setLoading(false);
    });
  }
  return (
    <>
      <BoxElement>
        <Text>Exportar datos de productos vendidos</Text>
        <Button onClick={datesModal.toogleModal} disabled={loading}>{!loading ? "Exportar" : 'Cargando...'}</Button>
      </BoxElement>
      <ConfirmDatesModal show={datesModal.isOpenModal} close={datesModal.toogleModal} handleFunction={handleExportProductsData} />
    </>
  )
}

export default SoldProductsData
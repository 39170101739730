import React from 'react'
import styled from 'styled-components'
import InputPurchasesDashboard from '../../components/dashboards/InputPurchasesDashboard'
import StockOfInputs from '../../components/dashboards/StockOfInputs'
import SummarySells from '../../components/dashboards/SummarySells'
import routes from '../../helpers/routes'
import BoxLink from './BoxLink'

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-template-areas: "uno dos" "tres cuatro";
  overflow: auto;
`

const Uno = styled.div`
  grid-area: uno;
  display: flex;
  flex-direction: column;
`

const Dos = styled.div`
  grid-area: dos;
  border-left: 1px solid grey;
  display: flex;
  flex-direction: column;
`

const Tres = styled.div`
  grid-area: tres;
  border-top: 1px solid grey;
`

const Cuatro = styled.div`
  grid-area: cuatro;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  border-top: 1px solid grey;
  border-left: 1px solid grey;
`

function Admin() {
  return (
    <Container>
      <Uno>
        <InputPurchasesDashboard />
      </Uno>
      <Dos>
        <StockOfInputs />
      </Dos>
      <Tres>
        <SummarySells />
      </Tres>
      <Cuatro>
        <BoxLink to={routes.ventas.index} label="ventas" />
        <BoxLink to={routes.boxes} label="dinero" />
      </Cuatro>
    </Container>
  )
}

export default Admin
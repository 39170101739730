import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/PageLayout";
import TableList from "../../../components/TableList1";
import routes from "../../../helpers/routes";
import { useProductRecipe } from "../../../hooks/useRecipes";
import { EditAction, OrderViewActions } from "../../common/styled";

export default function ViewProductRecipe() {
  const { id: recipeId } = useParams<any>();
  const { recipe, isLoading } = useProductRecipe(recipeId);

  if (isLoading) {
    return <PageLayout>Cargando...</PageLayout>
  }

  const headers = ["Insumo", "Cantidad", "Unidad"]
  const body = recipe.items.map((i: any) => (
    <tr key={i.id}>
      <td>{i.input.name}</td>
      <td>{i.amount}</td>
      <td>{i.unit}</td>
    </tr>
  ))

  return (
    <PageLayout>
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: routes.production.productRecipes }} linkAs={Link}>
          Lista de recetas
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Detalle de receta</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <OrderViewActions>
          <h2>Receta {recipe.name}</h2>
          <EditAction as={Link} to={routes.production.editProductRecipe(recipe.id)}>
            <i className="bi bi-pencil"></i>
            Editar
          </EditAction>
        </OrderViewActions>
        <div>Producto final: {recipe.product.name}</div>
        <div>Cantidad de productos resultante: {recipe.quantity}</div>
      </div>
      <div className="mt-2">
        <h5>Lista de materiales</h5>
        <TableList headers={headers} body={body} />
      </div>
    </PageLayout>
  )
}
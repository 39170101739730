import React from 'react';
import { FullPageLayout } from '../../../components/FullPageLayout';
import styled from 'styled-components';
import { HeadingPage } from './HeadingPage';
import { BodyPage } from './BodyPage';

const StyledInputsStatePage = styled.div`
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
  box-sizing: border-box;
`;

const InputsStatePage: React.FC = () => {
  return (
    <FullPageLayout>
      <StyledInputsStatePage>
        <HeadingPage />
        <BodyPage />
      </StyledInputsStatePage>
    </FullPageLayout>
  );
};

export default InputsStatePage;
import { useCallback, useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { confirmSellOrder, deliverySellOrder, emitSellOrder, fetchAllSellOrders, fetchAllVendorOrders, fetchSellOrderById, paySellOrder } from "../../../utils/api/sellOrders"
import { axiosInstance } from "../../../utils/axios"

export function useOrders(vendor: boolean = false) {
    const [orders, setOrders] = useState<any>()

    const ordersForConfirm = useMemo(() => orders && orders.filter((o: any) => o.state === 'created'), [orders])
    const confirmedOrders = useMemo(() => orders && orders.filter((o: any) => !o.nulled && (o.state === 'confirmed' || o.state === 'emited')), [orders])
    const deliveredOrders = useMemo(() => orders && orders.filter((o: any) => o.state === 'delivered'), [orders])
    const finishedOrders = useMemo(() => orders && orders.filter((o: any) => o.state === 'finished' || o.nulled), [orders])

    const refresh = useCallback(
        () => {
            if (vendor) {
                const userId = localStorage['userId'];
                fetchAllVendorOrders(userId).then(setOrders)
            } else {
                fetchAllSellOrders().then(setOrders)
            }
        },
        [vendor],
    )

    useEffect(() => {
        refresh()
    }, [refresh])

    const deliveryOrder = async (order: any) => {
        try {
            await deliverySellOrder(order.id)
        } catch (error) {
            throw (error)
        }
    }

    const payOrder = async (data: any) => {
        const { order, amount, boxId, date } = data
        try {
            await paySellOrder(order.id, amount, boxId, date)
        } catch (error) {
            throw (error)
        }
    }

    const confirmOrder = async (data: any) => {
        const { orderType, deliveryDate, order } = data
        try {
            if (orderType === 'FACTURA' && order['customer'].documentType !== 'RUC') {
                throw Error('El cliente debe tener RUC para poder emitir una factura')
            }
            if (orderType === 'BOLETA' && order['customer'].documentType !== 'DNI') {
                throw Error('El cliente debe registrar DNI para poder emitir una boleta')
            }
            await confirmSellOrder(orderType, deliveryDate, order.id)
        } catch (error) {
            throw (error)
        }
    }

    const emitOrder = async (order: any) => {
        try {
            await emitSellOrder(order.id)
        } catch (error) {
            console.log(error)
            throw (error)
        }
    }

    return { finishedOrders, ordersForConfirm, confirmedOrders, deliveredOrders, confirmOrder, deliveryOrder, payOrder, emitOrder, refresh }
}

export function useOrdersById(id: string) {
    const [order, setOrder] = useState<any>()
    const [loading, setLoading] = useState(false);
    const [subTotal, setSubtotal] = useState(0);
    const [items, setItems] = useState<any>([]);

    function formatItems(items: any) {
        let res = []
        for (const item of items) {
            res.push({
                unitPrice: item.unitPrice,
                amount: item.amount,
                group: {
                    id: item.product_group.id,
                    code: item.product_group.code,
                    name: item.product_group.name
                }
            })
        }
        return res
    }

    async function unPayed(paymentId: number) {
        try {
            if (window.confirm('¿Seguro que desea deshacer el pago?')) {
                await axiosInstance.post(`/orders/un-pay-order/${id}/payment/${paymentId}`);
                refresh();
                toast.success('Se deshizo el pago correctamente');
            }
        } catch (error) {
            alert(JSON.stringify(error, null, 2));
        }
    }

    const refresh = useCallback(() => {
        setLoading(true);
        fetchSellOrderById(id).then(order => {
            setLoading(false);
            setOrder(order);
            setSubtotal(order.subTotal);
            setItems(formatItems(order.items));
        });
    }, [id])

    useEffect(() => {
        refresh();
    }, [refresh]);

    return { order, loading, items, setItems, subTotal, setSubtotal, unPayed }
}

import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { axiosInstance } from '../utils/axios'

export interface Purchase {
  id: number
  serialNumber: string
  documentNumber: string
  total: number
  date: string
  providerName: string
  supplierName: string
  purchaseNumber: string
  state: string
  toPay: number,
  currency: string
}

interface OutProps {
  purchases: Purchase[]
  isLoading: boolean
  isError: any
  mutate: any
}

export function usePurchases(purchaseType: string): OutProps {
  const { data, error, mutate } = useSWR(`/purchase/${purchaseType}`)

  return {
    purchases: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export function useMakroPurchases(): OutProps {
  const { data, error, mutate } = useSWR('/purchase/supplies/makro')

  return {
    purchases: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export function useAPurchase(id: number, purchaseType: string) {
  const history = useHistory();
  const { data, error, mutate } = useSWR(`/purchase/${purchaseType}/${id}`)

  const unConfirmPurchase = async () => {
    try {
      if (window.confirm('¿Seguro que desea deshacer la confirmación? La compra pasará a borrador y el movimiento de stock será restablecido')) {
        await axiosInstance.post(`/purchase/${purchaseType}/unConfirm/${id}`);
        mutate();
        toast.success('La compra regresó al estado borrador correctamente');
      }
    } catch (error) {
      alert(JSON.stringify(error, null, 2))
    }
  }

  const unPayed = async (paymentId: number) => {
    try {
      if (window.confirm('¿Seguro que desea deshacer el pago?')) {
        await axiosInstance.post(`/purchase/${purchaseType}/unPayed/${id}/payment/${paymentId}`);
        mutate();
        toast.success('Se deshizo el pago correctamente');
      }
    } catch (error) {
      alert(JSON.stringify(error, null, 2));
    }
  }

  const deletePurchase = async () => {
    try {
      if (window.confirm('¿Seguro que desea eliminar la compra?')) {
        await axiosInstance.delete(`/purchase/${purchaseType}/${id}`);
        toast.success('La compra fue eliminada correctamente');
        history.push(".");
      }
    } catch (error) {
      alert(JSON.stringify(error, null, 2));
    }
  }

  const markAsPayed = async () => {
    try {
      if (window.confirm('¿Seguro que desea marcar la compra como pagada?')) {
        await axiosInstance.post(`/purchase/${purchaseType}/markAsPayed/${id}`);
        mutate();
        toast.success('La compra fue editada como pagada correctamente');
      }
    } catch (error) {
      alert(JSON.stringify(error, null, 2))
    }
  }

  const registerPurchasePayment = async (formData: any) => {
    try {
      if (window.confirm('¿Confirma que los datos ingresados son correctos?')) {
        await axiosInstance.post(`/purchase/${purchaseType}/registerPayment/${id}`, formData);
        mutate();
        toast.success('El pago fue registrado satisfactoriamente');
      }
    } catch (error) {
      alert(JSON.stringify(error, null, 2))
    }
  }

  return {
    purchase: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    unConfirmPurchase,
    markAsPayed,
    registerPurchasePayment,
    unPayed,
    deletePurchase
  }
}
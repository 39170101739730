import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { addGroupCategory, fetchAllGroups, fetchGroup, getGroupsCategories, updateGroupCategory } from '../../utils/api/products'
import { Category } from './GroupsPage'

export interface ProductGroup {
  id: number
  name: string
  sellPrice: number
  code: string
}

export function useProductGroups() {
  const [groups, setGroups] = useState<ProductGroup[]>([])
  const [categories, setCategories] = useState<Category[]>([])

  const fetchGroups = useCallback(() => {
    (async () => {
      const fetchedGroups = await fetchAllGroups();
      setGroups(fetchedGroups);
    })()
  }, []);

  const fetchCategories = useCallback(() => {
    (async () => {
      const fetchedCategoires = await getGroupsCategories();
      setCategories(fetchedCategoires);
    })()
  }, []);

  async function addCategory(category: Category) {
    try {
      await addGroupCategory(category);
      toast.success('Categoría agregada');
      fetchCategories();
    } catch (error) {
      console.log(error)
    }
  }

  async function updateCategory(category: Category, categoryId: number) {
    try {
      await updateGroupCategory(category, categoryId);
      toast.success('Categoría actualizada');
      fetchCategories();
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchGroups();
    fetchCategories();
  }, [fetchGroups, fetchCategories])

  return { groups, categories, addCategory, updateCategory }
}

export function useProductGroup(id: number): any {
  const [group, setGroup] = useState()

  useEffect(() => {
    fetchGroup(id).then(setGroup)
  }, [id])

  return { group }
}

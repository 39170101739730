export interface Period {
  value: number
  text: string
}

export const periods = [
  {
    value: 1,
    text: 'Enero'
  },
  {
    value: 2,
    text: 'Febrero'
  },
  {
    value: 3,
    text: 'Marzo'
  },
  {
    value: 4,
    text: 'Abril'
  },
  {
    value: 5,
    text: 'Mayo'
  },
  {
    value: 6,
    text: 'Junio'
  },
  {
    value: 7,
    text: 'Julio'
  },
  {
    value: 8,
    text: 'Agosto'
  },
  {
    value: 9,
    text: 'Septiembre'
  },
  {
    value: 10,
    text: 'Octubre'
  },
  {
    value: 11,
    text: 'Noviembre'
  },
  {
    value: 12,
    text: 'Diciembre'
  },
]

export const filterPeriods = (month: number): Period[] => {
  return periods.filter(p => p.value >= month);
}
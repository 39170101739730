import { Input } from '../../components/Input'
import withEnhancedForm, { WraperEnhancedFormProps } from '../../hocs/withEnhancedForm'
import { InputModel } from './InputsPage'

function Form(props: WraperEnhancedFormProps<InputModel>) {
    const { record, handleChange, handleChangeCheckbox, handleSubmitForm, disabled = false, editing = true, toogle } = props

    return (
        <>
            {toogle &&
                <button className='btn btn-secondary' onClick={() => toogle()}>Regresar al listado</button>
            }
            <form onSubmit={handleSubmitForm}>
                <fieldset disabled={disabled}>
                    <div className="row mt-2">
                        <div className="col">
                            <Input
                                type="text"
                                name="name"
                                label="Nombre del insumo"
                                value={record.name}
                                handleChange={handleChange}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <Input
                                type="text"
                                name="internalCode"
                                label="Codigo de uso interno"
                                value={record.internalCode}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="col">
                            <Input
                                type="number"
                                name="stock"
                                label="Stock actual"
                                disabled={Object.keys(record).length > 0}
                                value={record.stock}
                                handleChange={handleChange}
                                required={true}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="unit" className='form-label'>Unidad de medida</label>
                            <select className="form-select" name="unit" id="unit" onChange={handleChange} value={record.unit} required>
                                <option value="">Selecciona la unidad</option>
                                <option value="kg">Kilogramos</option>
                                <option value="g">Gramos</option>
                                <option value="mg">Mili gramos</option>
                                <option value="gal">Galones</option>
                                <option value="l">Litros</option>
                                <option value="ml">Mili litro</option>
                                <option value="und">Unidad</option>
                            </select>
                        </div>
                        <div className="col">
                            <Input
                                type="number"
                                name="minimumStock"
                                label="Stock mínimo"
                                value={record.minimumStock}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <div className="form-check">
                                {record.isMaterialPrime === undefined && <input className="form-check-input" type="checkbox" defaultChecked={true} onChange={handleChangeCheckbox} id="isMaterialPrime" name="isMaterialPrime" />}
                                {record.isMaterialPrime !== undefined && <input className="form-check-input" type="checkbox" defaultChecked={record.isMaterialPrime} onChange={handleChangeCheckbox} id="isMaterialPrime" name="isMaterialPrime" />}
                                <label className="form-check-label" htmlFor="isMaterialPrime">
                                    ¿El insumo es una materia prima?
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <div className="form-check">
                                {record.storable === undefined && <input className="form-check-input" type="checkbox" defaultChecked={true} onChange={handleChangeCheckbox} id="storable" name="storable" />}
                                {record.storable !== undefined && <input className="form-check-input" type="checkbox" defaultChecked={record.storable} onChange={handleChangeCheckbox} id="storable" name="storable" />}
                                <label className="form-check-label" htmlFor="storable">
                                    ¿El insumo será almacenable?
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultChecked={record.manufactured} onChange={handleChangeCheckbox} id="manufactured" name="manufactured" />
                                <label className="form-check-label" htmlFor="manufactured">
                                    ¿El insumo se obtiene por producción?
                                </label>
                            </div>
                        </div>
                    </div>
                    {editing &&
                        <div className="d-grid">
                            <button className="btn btn-primary mt-2" type="submit">Guardar</button>
                        </div>
                    }
                </fieldset>
            </form>
        </>
    )
}

const InputForm = withEnhancedForm(Form)
export default InputForm

/* interface InputFormProps {
    disabled?: boolean
    editing?: boolean
    input?: Partial<InputModel>
    handleSubmit: (i: Partial<InputModel>) => void
}

export class InputForm extends React.Component<InputFormProps> {
    state = {
        disabled: false,
        input: {
            name: "",
            internalCode: "",
            stock: 0.00
        }
    }

    componentDidMount() {
        if (this.props.input) {
            this.setState({
                ...this.state,
                input: this.props.input
            })
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        this.setState({
            ...this.state,
            input: {
                ...this.state.input,
                [name]: value
            }
        })
    }

    handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        this.props.handleSubmit(this.state.input)
    }

    render() {
        const input = this.state.input
        const { disabled = false, editing = true } = this.props

        return (
            <form onSubmit={this.handleSubmit}>
                <fieldset disabled={disabled}>
                    <div className="row mt-2">
                        <div className="col">
                            <Input
                                type="text"
                                name="name"
                                label="Nombre del insumo"
                                value={input.name}
                                handleChange={this.handleChange}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <Input
                                type="text"
                                name="internalCode"
                                label="Codigo de uso interno"
                                value={input.internalCode}
                                handleChange={this.handleChange}
                            />
                        </div>
                        <div className="col">
                            <Input
                                type="number"
                                name="stock"
                                label="Stock actual"
                                value={input.stock}
                                handleChange={this.handleChange}
                                required={true}
                            />
                        </div>
                    </div>
                    {editing &&
                        <div className="d-grid">
                            <button className="btn btn-primary mt-2" type="submit">Guardar</button>
                        </div>
                    }
                </fieldset>
            </form>
        )
    }
} */

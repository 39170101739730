import React from 'react'
import { PageLayout } from '../../components/PageLayout'
import Main from './Main'
import Nav from './Nav'
import { SettingsPageContainer } from './styleds'
import useSettings from './useSettings'

const SettingsPage = () => {
  const { onUbigeo, onTransportista, toggle, role } = useSettings();
  return (
    <PageLayout>
      <SettingsPageContainer>
        <Nav
          onUbigeo={onUbigeo}
          onTransportista={onTransportista}
          toggle={toggle}
          role={role}
        />
        <Main onUbigeo={onUbigeo} onTransportista={onTransportista} />
      </SettingsPageContainer>
    </PageLayout>
  )
}

export default SettingsPage
import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import routes from "../../../helpers/routes";
import useBoxes from "../../../hooks/useBoxes";
import useModal from "../../../hooks/useModal";
import { registerMakroPayment } from "../../../utils/api/payments";
import { formatMoney } from "../../../utils/functions";
import { Box } from "../../money/BoxPage";

interface OrderItem {
  id: number
  date: string
  invoice: string
  total: number
  factory: number
  prontoPago: number
}

interface DiscountItem {
  id: number
  date: string
  invoice: string
  total: number
}

const useMakroPayments = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [orderItems, setOrdersItems] = useState<OrderItem[]>([]);
  const [discountItems, setDiscountItems] = useState<DiscountItem[]>([]);
  const ordersModal = useModal();
  const discountsModal = useModal();
  const { boxes } = useBoxes();
  const [selectedBox, setSelectedBox] = useState<Box[]>([]);
  const bankBoxes = boxes && boxes.filter(box => box.isCci === true);

  const equivalente = useMemo(() => {
    let sells = 0
    let discounts = 0
    for (const item of orderItems) {
      sells += item.total - item.factory
    }
    for (const disc of discountItems) {
      discounts += disc.total
    }
    return Math.round((sells - discounts) * 100) / 100
  }, [orderItems, discountItems]);

  const descuento = useMemo(() => {
    let prontos = 0
    for (const item of orderItems) {
      prontos += item.prontoPago
    }
    return Math.round(prontos * 100) / 100
  }, [orderItems]);

  const breadcrumbs = {
    links: [
      {
        to: routes.makro.cobros,
        text: "Facturas por cobrar"
      }
    ],
    active: "Registro de cobro"
  }

  const addOrderItem = (item: any) => {
    setOrdersItems([...orderItems, item])
  }

  const removeOrderItem = (item: any) => {
    setOrdersItems(orderItems.filter((i: any) => i !== item))
  }

  const addDiscountItem = (item: any) => {
    setDiscountItems([...discountItems, item])
  }

  const removeDiscountItem = (item: any) => {
    setDiscountItems(discountItems.filter((i: any) => i !== item))
  }

  const onSubmit = async (data: any) => {
    try {
      if (orderItems.length === 0 && discountItems.length === 0) {
        throw Error("Debe agregar facturas de ventas y/0 facturas de descuentos")
      }
      setLoading(true);
      const response = await registerMakroPayment({
        paymentDate: data.paymentDate,
        groupNumber: data.groupNumber,
        equivalentAmount: equivalente,
        discount: descuento,
        amountPaid: Number((equivalente - descuento).toFixed(2)),
        invoices: orderItems,
        discounts: discountItems,
        boxId: selectedBox[0].id
      });
      toast.success(response.message);
      history.push(".")
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.message) {
        toast.error(error.message)
      } else {
        toast.error(error.response.data)
      }
    }
  }

  const sellOrdersHeaders = ["Fecha", "Factura", "Total", "Factory", "Pronto pago", ""]
  const sellOrdersBody = orderItems.map((item: any, index: number) => (
    <tr key={index}>
      <td>{item.date}</td>
      <td>{item.invoice}</td>
      <td>{formatMoney(item.total)}</td>
      <td>{formatMoney(item.factory)}</td>
      <td>{formatMoney(item.prontoPago)}</td>
      <td>
        <div className="d-grid">
          <Button size="sm" variant="outline-danger" onClick={() => removeOrderItem(item)}>X</Button>
        </div>
      </td>
    </tr>
  ))

  const sellDiscountHeaders = ["Fecha", "Factura", "Total", ""]
  const sellDiscountBody = discountItems.map((item: any, index: number) => (
    <tr key={index}>
      <td>{item.date}</td>
      <td>{item.invoice}</td>
      <td>{formatMoney(item.total)}</td>
      <td>
        <div className="d-grid">
          <Button size="sm" variant="outline-danger" onClick={() => removeDiscountItem(item)}>X</Button>
        </div>
      </td>
    </tr>
  ))

  return {
    sellOrdersHeaders,
    sellOrdersBody,
    sellDiscountHeaders,
    sellDiscountBody,
    breadcrumbs,
    ordersModal,
    discountsModal,
    addOrderItem,
    addDiscountItem,
    equivalente,
    descuento,
    register,
    handleSubmit,
    onSubmit,
    loading,
    bankBoxes,
    selectedBox,
    setSelectedBox
  }
}

export default useMakroPayments;
import { FullPageLayout } from '../components/FullPageLayout';
import { axiosInstance } from '../utils/axios';

export default function Dashboard() {

    const handleReport = async () => {
        try {
            await axiosInstance.get('/reports/sales/vendors');
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <FullPageLayout>
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
                height: '100%',
            }}>
                <div style={{ gridColumn: '1 / 2', border: '.5px solid grey', display: 'flex', gap: '1em' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div>a</div>
                        <div style={{ flex: 1 }}>b</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}></div>
                </div>
                <div>
                    <button onClick={handleReport}>ventas de abril</button>
                </div>
            </div>
        </FullPageLayout>
    )
}
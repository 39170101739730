import { ReactNode } from 'react'
import Navigation from './Navigation'
import { Toaster } from 'react-hot-toast';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 1rem;
    width: 100vw;
    height: 100vh;
`

export function PageLayout(props: { children: ReactNode }) {
    return (
        <>
            <Container>
                <Toaster />
                <div style={{ gridRow: '1 / 2' }}>
                    <Navigation />
                </div>
                <div style={{ gridRow: '2 / 3', overflowY: 'scroll', padding: '.75rem .75rem', display: 'flex', flexDirection: 'column' }}>
                    {props.children}
                </div>
            </Container>
        </>
    )
}

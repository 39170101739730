import { useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom";
import routes from "../../../helpers/routes";
import useInputs, { Input, useManufacturedInputs } from "../../../hooks/useInputs";
import useModal from "../../../hooks/useModal";

export default function InputRecipeForm({ initialData, submitFunction, toogle }: any) {
  const history = useHistory();
  const addModal = useModal();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: initialData ? initialData.name : '',
      quantity: initialData ? initialData.quantity : '',
      unit: initialData ? initialData.unit : ''
    }
  });
  const [items, setItems] = useState<any>(initialData ? initialData.items : []);
  const { inputs } = useManufacturedInputs();
  const [selectedInput, setSelectedInput] = useState<Input[]>(initialData ? [initialData.input] : []);

  const addItem = (item: any) => {
    setItems([...items, item]);
  }

  const removeItem = (item: any) => {
    setItems(items.filter((i: any) => i !== item));
  }

  function handleClose() {
    if (toogle) {
      toogle()
    } else {
      history.push(routes.production.viewInputRecipe(initialData.id));
    }
  }

  function onSubmit(data: any) {
    if (items.length > 0) {
      submitFunction({
        inputId: selectedInput[0].id,
        name: data.name,
        quantity: data.quantity,
        unit: data.unit,
        items
      })
    } else {
      alert("La receta no contiene items")
    }
  }

  return (
    <>
      {!initialData ? <h2>Nueva receta</h2> : <h2>Editar receta</h2>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mt-3" controlId="name">
          <Form.Label>Nombre de la receta</Form.Label>
          <Form.Control type="text" {...register("name")} required />
        </Form.Group>
        <Form.Group className="mt-3" controlId="product">
          <Form.Label>Insumo</Form.Label>
          <Typeahead
            id="product-selected"
            labelKey="name"
            onChange={setSelectedInput}
            options={inputs}
            selected={selectedInput}
            placeholder="Buscar insumo"
            inputProps={{ required: true }}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mt-3 mb-3" controlId="quantity">
              <Form.Label>Cantidad producida por la receta</Form.Label>
              <Form.Control type="number" {...register("quantity")} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mt-3" controlId="unit">
              <Form.Label>Unidad</Form.Label>
              <select {...register("unit")} className="form-select" aria-label="unit" required>
                <option value="">Seleccione...</option>
                <option value="kg">Kilos</option>
                <option value="g">Gramos</option>
                <option value="mg">Mili gramos</option>
                <option value="l">Litros</option>
                <option value="ml">Mili litros</option>
              </select>
            </Form.Group>
          </Col>
        </Row>
        <h5>Componentes de la receta</h5>
        <Table bordered size="sm" className="mt-3">
          <thead>
            <tr>
              <th>Insumo</th>
              <th>Unidad</th>
              <th>Cantidad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.input.name}</td>
                <td>{item.unit}</td>
                <td>{item.amount}</td>
                <td>
                  <div className="d-grid">
                    <Button size="sm" variant="outline-danger" onClick={() => removeItem(item)}>X</Button>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <div className="d-grid">
                  <Button size="sm" variant="outline-primary" onClick={() => addModal.toogleModal()}>Agergar insumo</Button>
                </div>
              </td>
              <td colSpan={4}></td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex" style={{ gap: '10px' }}>
          <button className="btn btn-secondary" onClick={handleClose}>Cancelar</button>
          <input type="submit" className="btn btn-primary" value="Guardar" />
        </div>
      </form>
      <AddItemModal show={addModal.isOpenModal} toogle={addModal.toogleModal} addFunction={addItem} />
    </>
  )
}

function AddItemModal({ show, toogle, addFunction }: any) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      unit: 'kg',
      amount: '',
    }
  });
  const { inputs } = useInputs();
  const [selectedInput, setSelectedInput] = useState<Input[]>([]);

  const handleClose = () => {
    setSelectedInput([])
    reset();
    toogle()
  }

  const onSubmit = (data: any) => {
    const input = {
      input: {
        id: selectedInput[0].id,
        name: selectedInput[0].name
      },
      unit: data.unit,
      amount: data.amount
    }
    addFunction(input);
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar insumo</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mt-0" controlId="input">
                <Form.Label>Insumo</Form.Label>
                <Typeahead
                  id="input-selected"
                  labelKey={(option) => `${option.name}`}
                  onChange={setSelectedInput}
                  options={inputs}
                  selected={selectedInput}
                  placeholder="Buscar insumo"
                  inputProps={{ required: true }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mt-3" controlId="unit">
                <Form.Label>Unidad</Form.Label>
                <select {...register("unit")} className="form-select" aria-label="unit" required>
                  <option value="kg">Kilos</option>
                  <option value="g">Gramos</option>
                  <option value="mg">Mili gramos</option>
                  <option value="l">Litros</option>
                  <option value="ml">Mili litros</option>
                </select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mt-3" controlId="amount">
                <Form.Label>Cantidad</Form.Label>
                <Form.Control type="text" placeholder="Cantidad" {...register("amount")} autoComplete="off" required />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
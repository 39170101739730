import { useHistory } from "react-router-dom";
import routes from "../../helpers/routes";
import useModal from "../../hooks/useModal";
import { toLocalDateFormat } from "../../utils/functions";
import ConfirmOrderModal from "../sells/orders/ConfirmOrderModal";
import { OrderItem, OrderItemData, OrderItemDataBody, OrderItemDataHeader, OrderItemOptions, VendorBadge } from "./styleds";
import { useOrders } from "./useOrders";

function ForConfirmOrderItem({ order }: any) {
  const { mutate } = useOrders();
  let history = useHistory();
  const { isOpenModal, toogleModal } = useModal();

  return (
    <>
      <OrderItem>
        <OrderItemData>
          <OrderItemDataHeader>
            <div className="d-flex flex-column">
              <span style={{ fontWeight: 'bold' }}>Orden Nº{order.id}</span>
              <span style={{ fontWeight: 'bold', color: 'chocolate', fontStyle: 'italic' }}>{toLocalDateFormat(order.deliveryDate)}</span>
            </div>
            <div className="d-flex flex-column">
              <span style={{ fontWeight: 'bold', color: 'red' }}>ST: S/. {order.subTotal}</span>
              <span style={{ fontWeight: 'bold', color: 'grey' }}>T: S/. {order.total}</span>
            </div>
            <div><VendorBadge>{order.user.username}</VendorBadge></div>
          </OrderItemDataHeader>
          <OrderItemDataBody>{order.customer.name}</OrderItemDataBody>
        </OrderItemData>
        <OrderItemOptions>
          <button onClick={() => history.push(routes.viewOrder(order.id))}>Ver detalles</button>
          <button onClick={toogleModal}>Confirmar</button>
        </OrderItemOptions>
        <ConfirmOrderModal show={isOpenModal} close={toogleModal} order={order} refresh={mutate} />
      </OrderItem>
    </>
  );
}

export default ForConfirmOrderItem;
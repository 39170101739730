import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useUsers } from "./useUsers"

export default function AddUserModal({ show, close, refresh }: any) {
  const { addUser, error } = useUsers()
  const { register, handleSubmit, setValue } = useForm()

  const handleClose = () => {
    setValue("username", "")
    setValue("email", "")
    setValue("role", "")
    close()
  }

  const onSubmit = async (data: any) => {
    try {
      await addUser({
        username: data.username,
        email: data.email,
        role: data.role
      });
      toast.success("Usuario registrado");
      refresh()
      handleClose()
    } catch (error) {
      alert(error)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Nueva bóveda</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Nombre de usuario</Form.Label>
                <Form.Control type="text" placeholder="Nombre de usuario" {...register("username")} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Correo electrónico" {...register("email")} required />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="role">
                <Form.Label>Rol</Form.Label>
                <select {...register("role")} className="form-select" aria-label="Rol" required>
                  <option value="">Selecciona el rol</option>
                  <option value="vendedor">Vendedor(a)</option>
                  <option value="administrador">Administrador(a)</option>
                  <option value="admin">Admin</option>
                  <option value="almacenista">Almacén</option>
                  <option value="productor">Producción</option>
                </select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        {error && error}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Crear usuario
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
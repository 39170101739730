import { useMemo, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { formatMoney } from "../../../utils/functions"
import dayjs from "dayjs"
import { Box } from "../../common/interfaces"

interface Props {
  show: boolean;
  close: () => void;
  order: any;
  submitFunction: (data: any) => void;
  purchase?: boolean;
  boxes: Box[];
}

export default function PayOrderModal({ show, close, order, submitFunction, purchase, boxes }: Props) {
  const [partialPayment, setPartialPayment] = useState(false);
  const { register, handleSubmit, reset } = useForm()
  const [selectedBox, setSelectedBox] = useState("")
  const [changeCurrency, setChangeCurrency] = useState(false)

  function handleRadio(e: any) {
    if (e.target.value === "partial") {
      setPartialPayment(true);
    } else {
      setPartialPayment(false);
    }
  }

  const filteredBoxes = useMemo(() => {
    if (purchase) {
      return boxes
    }
    if (order && order.futureDocument !== 'PROFORMA') return boxes.filter((b: Box) => b.isOfficialBox && b.currency === 'soles')
    else return boxes.filter((b: Box) => !b.isOfficialBox)
  }, [boxes, order, purchase])

  function getBox(boxId: number) {
    return boxes.filter((b: Box) => b.id === boxId)[0]
  }

  function getCurrency(cur: string) {
    return cur === 'soles' ? 'pen' : 'usd'
  }

  const handleClose = () => {
    setPartialPayment(false);
    reset();
    close();
  }

  const handleBoxChange = (e: any) => {
    setSelectedBox(e.target.value);
    if (e.target.value !== "" && order.currency) {
      const box = getBox(Number(e.target.value));
      if (getCurrency(box.currency) !== order.currency) {
        setChangeCurrency(true)
      } else {
        setChangeCurrency(false)
      }
    } else {
      setChangeCurrency(false)
    }
  }

  const onSubmit = (data: any) => {
    if (partialPayment) {
      // se paga una parte
      submitFunction({
        amount: Number(data.partialAmount),
        boxId: selectedBox,
        date: data.payDate,
      });
    } else {
      submitFunction({
        amount: Number(order.toPay),
        boxId: selectedBox,
        date: data.payDate,
      });
    }
    handleClose();
  }

  if (!order) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Registrar pago [orden Nº {order.id}]</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-check">
            <input className='form-check-input' type="radio" value="total" {...register("radioTotal", { onChange: (e) => handleRadio(e) })} id="isPayingTotal" required />
            <label className='form-check-label' htmlFor="isPayingTotal">Se paga todo el saldo pendiente <span style={{ color: 'red' }}>({formatMoney(order.toPay, order.currency)})</span></label>
          </div>
          <div className="form-check">
            <input className='form-check-input' type="radio" value="partial" {...register("radioTotal", { onChange: (e) => handleRadio(e) })} id="isPayingPartial" required />
            <label className='form-check-label' htmlFor="isPayingPartial">Se paga una parte</label>
          </div>
          {partialPayment &&
            <div className='mt-2'>
              <label htmlFor="partialAmount" className='form-label'>Monto cancelado <span style={{ color: 'red' }}>({order.currency !== 'usd' ? 'soles' : null})</span></label>
              <input className='form-control' type="number" step="0.01" id="partialAmount" {...register("partialAmount", { validate: value => value <= Number(order.toPay) })} required placeholder="Ingresar el monto cancelado" />
            </div>
          }
          <div className="mt-2">
            <label htmlFor="payDate" className='form-label'>Fecha de pago</label>
            <input className='form-control' type="date" id="payDate" {...register("payDate")} defaultValue={dayjs().format("YYYY-MM-DD")} required />
          </div>
          <select value={selectedBox} onChange={handleBoxChange} className="form-select mt-2" aria-label="Caja de dinero" required>
            <option value="">Selecciona la caja de dinero</option>
            {filteredBoxes.map((box: any) => (
              <option key={box.id} value={box.id}>{box.name}</option>
            ))}
          </select>
          {changeCurrency &&
            <>
              <span>Tipo de cambio: </span>
              <input type='number' step="0.001" min="1" {...register("tipoDeCambio")} required />
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Registrar pago
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
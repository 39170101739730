import { useState } from "react"
import useAuth from "../../hooks/useAuth";

const useSettings = () => {
  const { user } = useAuth();
  const [onUbigeo, setOnUbigeo] = useState(false);
  const [onTransportista, setOnTransportista] = useState(false);

  const toggle = (option: string) => {
    if (option === 'ubigeo') {
      setOnUbigeo(true);
      setOnTransportista(false);
    }
    if (option === 'transportistas') {
      setOnUbigeo(false);
      setOnTransportista(true);
    }
  }

  return {
    onUbigeo,
    onTransportista,
    toggle,
    role: user?.role
  }
}

export default useSettings;
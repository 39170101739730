import React from 'react'
import { useForm } from 'react-hook-form';
import { Recipe } from '../interfaces';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import { axiosInstance } from '../../../utils/axios';
import useModal from '../../../hooks/useModal';
import AddRecipeItemModal from '../components/AddRecipeItemModal';
import routes from '../../../helpers/routes';
import HeaderSection from '../components/HeaderSection';

interface RecipeFormProps {
  initialData?: any
  submitFunction: any
  loading: boolean
}

export default function RecipeForm({ initialData, submitFunction, loading }: RecipeFormProps) {
  const addModal = useModal();
  const [basesList, setBasesList] = React.useState<any>([]);
  const [error, setError] = React.useState<any>(null);
  const [items, setItems] = React.useState<any>(initialData ? initialData.items : []);
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      name: initialData ? initialData.name : '',
      forBase: initialData ? initialData.forBase : false,
      forSale: initialData ? initialData.forSale : true,
      access: initialData ? initialData.access : 'private',
      inputBaseId: initialData ? initialData.inputBaseId : null
    }
  });

  React.useEffect(() => {
    // fetch
    (async () => {
      try {
        const response = await axiosInstance.get('/inputs/bases');
        setBasesList(response.data);
        if (initialData) {
          setValue("inputBaseId", initialData.inputBaseId);
        }
      } catch (error) {
        setError(error)
      }
    })()
  }, [setValue, initialData]);

  const forBase = watch("forBase");

  React.useEffect(() => {
    if (forBase) {
      setValue("forSale", false);
    } else if (!initialData || initialData.forSale === undefined) {
      setValue("forSale", true);
      setValue("inputBaseId", null);
    }
  }, [forBase, setValue, initialData]);

  const addItem = (item: any) => {
    setItems([...items, item]);
  }

  const removeItem = (item: any) => {
    setItems(items.filter((i: any) => i !== item));
  }

  const onSubmit = (data: Recipe) => {
    if (items.length > 0) {
      submitFunction({
        name: data.name,
        forBase: data.forBase,
        forSale: data.forSale,
        access: data.access,
        inputBaseId: data.inputBaseId,
        items
      })
    } else {
      alert("La receta no contiene items")
    }
  }

  return (
    <>
      <HeaderSection
        title={initialData ? 'Editar receta' : 'Nueva receta'}
        to={initialData ? routes.recipes.viewRecipe(initialData.id) : routes.recipes.index}
        linkString={initialData ? 'Cancelar edición' : 'Volver al listado'} />
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Form.Group className="mt-2" controlId="name">
          <Form.Label>Nombre de la receta</Form.Label>
          <Form.Control type="text" {...register("name")} required />
        </Form.Group>
        <Form.Check className="mt-2" type="switch" {...register("forBase")} label="¿Es para base?" />
        <Form.Check type="switch" {...register("forSale")} disabled={forBase} label="¿Es para venta?" />
        <Form.Group className="mt-2" controlId="access">
          <Form.Label>Acceso</Form.Label>
          <Form.Select {...register("access")} required>
            <option value="private">Privado</option>
            <option value="public">Público</option>
          </Form.Select>
        </Form.Group>
        {forBase && (
          <Form.Group className="mt-2" controlId="inputBaseId">
            <Form.Label>Base resulante</Form.Label>
            <Form.Select {...register("inputBaseId")} required>
              {error && <option value="">Error al cargar las bases</option>}
              {!error && <option value="">Selecciona una base</option>}
              {basesList.map((base: any) => (
                <option key={base.id} value={base.id}>{base.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
        <h5 style={{ marginTop: '1rem' }}>Componentes de la receta</h5>
        <Table bordered size="sm" className="mt-3">
          <thead>
            <tr>
              <th>Insumo</th>
              <th>Cantidad</th>
              <th>Unidad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.input.name}</td>
                <td>{item.amount}</td>
                <td>{item.unit}</td>
                <td>
                  <div className="d-grid">
                    <Button size="sm" variant="outline-danger" onClick={() => removeItem(item)}>X</Button>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <div className="d-grid">
                  <Button size="sm" variant="outline-primary" onClick={() => addModal.toogleModal()}>Agergar insumo</Button>
                </div>
              </td>
              <td colSpan={4}></td>
            </tr>
          </tbody>
        </Table>
        {!loading && <button className="mt-3 btn btn-primary" type="submit">Guardar</button>}
        {loading && <button className="mt-3 btn btn-primary" type="submit" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Guardando...
        </button>}
      </form>
      <AddRecipeItemModal show={addModal.isOpenModal} toogle={addModal.toogleModal} addFunction={addItem} />
    </>
  )
}
import React, { useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';
import { Product, useProducts } from '../../hooks/useProducts';

const ProductMovementModal = ({ show, toggle, submitFunction }: any) => {
  const { register, handleSubmit, reset } = useForm();
  const { products } = useProducts();
  const [selectedProduct, setSelectedProduct] = useState<Product[]>([]);

  function onSubmit(data: any) {
    submitFunction({
      ...data,
      productId: selectedProduct[0].id
    });
    close();
  }

  function close() {
    setSelectedProduct([])
    reset();
    toggle();
  }

  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Movimiento de productos</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId='input'>
                <Form.Label>Producto</Form.Label>
                <Typeahead
                  id="input-selected"
                  labelKey="name"
                  onChange={setSelectedProduct}
                  options={products}
                  selected={selectedProduct}
                  placeholder="Buscar producto"
                  inputProps={{ required: true }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='amount'>
                <Form.Label>Cantidad</Form.Label>
                <Form.Control placeholder="Cantidad de productos" min={1} step={1} type="number" {...register('amount')} required></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group controlId="movmenteType">
                <Form.Label>Tipo de movimiento</Form.Label>
                <Form.Select {...register("movementType")} required>
                  <option value="">Seleccionar uno</option>
                  <option value="ingreso">Ingreso</option>
                  <option value="salida">Salida</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='reason'>
                <Form.Label>Motivo</Form.Label>
                <Form.Control placeholder="Motivo del movimiento" {...register('reason')} required></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary' onClick={close}>Cancelar</button>
          <button type='submit' className='btn btn-primary'>Registrar movimiento</button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ProductMovementModal
import { ChangeEvent, useEffect, useState } from "react"
import { Form, Modal, Button, Container, Row, Col, Table } from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import { useHistory } from "react-router-dom"
import { InputFloating, SelectFloating } from "../../components/InputFloating"
import routes from "../../helpers/routes"
import useAuth from "../../hooks/useAuth"
import { Customer, IPriceList } from "../../utils/api/customers"
import { fetchAllGroups, IGroup } from "../../utils/api/products"

interface ICustomerFormProps {
  customer: Customer
  setCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>
  handleSubmit: (customer: Customer) => void
}

export default function CustomerForm(
  {
    customer = {} as Customer,
    setCustomer,
    handleSubmit
  }: ICustomerFormProps
) {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false)
  const toogleModal = () => setShowModal(!showModal)
  const { user } = useAuth()

  const addToItem = (item: IPriceList) => {
    setCustomer({
      ...customer,
      priceList: [...customer.priceList, item]
    })
  }

  const removeItem = (item: IPriceList) => {
    setCustomer({
      ...customer,
      priceList: customer.priceList.filter(i => i !== item)
    })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setCustomer({
      ...customer,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer({
      ...customer,
      [event.target.name]: event.target.checked
    })
  }

  const submitForm = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    handleSubmit(customer);
  }

  return (
    <div>
      <form onSubmit={submitForm}>
        <div className="row mb-3">
          <div className="col">
            <InputFloating
              type="text"
              label="Nombre o Razón social"
              name="name"
              value={customer.name}
              required={true}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <SelectFloating
              name="documentType"
              label="Tipo de documento"
              options={[{ value: "RUC", text: "RUC" }, { value: "DNI", text: "DNI" }, { value: "CEX", text: "Carnet de extranjería" }]}
              value={customer.documentType}
              handleChange={handleChange}
              required={true}
            />
          </div>
          <div className="col">
            <InputFloating
              type="text"
              label="Número de documento"
              name="documentNumber"
              value={customer.documentNumber}
              onChange={handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <InputFloating
              type="text"
              label="Dirección"
              name="address"
              value={customer.address}
              required={true}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <InputFloating
              type="text"
              label="Referencia"
              name="reference"
              value={customer.reference}
              required={false}
              onChange={handleChange}
            />
          </div>
        </div>
        {user && user.role !== 'vendedor' &&
          <div className="row mb-3">
            <div className="col">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" name="watched" defaultChecked={customer.watched} onChange={handleChangeCheckbox} id="watched" />
                <label className="form-check-label" htmlFor="watched">
                  Observar cliente
                </label>
              </div>
            </div>
          </div>
        }
        {user && user.role !== 'vendedor' &&
          <>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Precio</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customer.priceList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.groupName}</td>
                    <td>{item.price}</td>
                    <td>
                      <div className="d-grid">
                        <Button size="sm" variant="outline-danger" onClick={() => removeItem(item)}>X</Button>
                      </div>
                    </td>
                  </tr>
                )
                )}
                <tr>
                  <td>
                    <div className="d-grid">
                      <Button size="sm" variant="outline-primary" onClick={toogleModal}>Agergar producto</Button>
                    </div>
                  </td>
                  <td colSpan={2}></td>
                </tr>
              </tbody>
            </Table>
            <AddProductModal show={showModal} toogle={toogleModal} functionHandler={addToItem} />
          </>
        }
        <div className="d-flex gap-2">
          <button className="btn btn-secondary" onClick={() => history.push(routes.customer(customer.id))}>Cancelar</button>
          <button className="btn btn-primary" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </div>
  )
}

interface AddProductModalProps {
  show: boolean
  toogle: () => void
  functionHandler: (item: IPriceList) => void
}

function AddProductModal({ show, toogle, functionHandler }: AddProductModalProps) {
  const [groups, setGroups] = useState<IGroup[]>([])
  const [selectedGroup, setSelectedGroup] = useState<IGroup[]>([])
  const [sellPrice, setSellPrice] = useState("")

  useEffect(() => {
    fetchAllGroups().then(setGroups)
  }, [])

  const close = () => {
    setSelectedGroup([])
    setSellPrice("")
    toogle()
  }

  const addOrderItem = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    const item = {
      groupId: selectedGroup[0].id,
      groupName: selectedGroup[0].name,
      price: Number(sellPrice)
    }
    functionHandler(item)
    close()
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Producto</Modal.Title>
      </Modal.Header>
      <Form onSubmit={addOrderItem}>
        <Modal.Body>
          <Container>
            <Row className="mb-2">
              <Col>
                <Form.Group controlId="product">
                  <Form.Label>Producto</Form.Label>
                  <Typeahead
                    id="group-selection"
                    labelKey="name"
                    onChange={setSelectedGroup}
                    options={groups}
                    placeholder="Seleccionar producto..."
                    selected={selectedGroup}
                    inputProps={{ required: true }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <Form.Group controlId="unirPrice">
                  <Form.Label>Precio unitario</Form.Label>
                  <Form.Control type="number" placeholder="Precio unitario" value={sellPrice} onChange={(e) => setSellPrice(e.target.value)} required />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
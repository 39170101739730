import React from "react";
import { useForm } from "react-hook-form";
import useInputs, { Input } from "../../../hooks/useInputs";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

export default function AddRecipeItemModal({ show, toogle, addFunction }: any) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      unit: 'kg',
      amount: '',
    }
  });
  const { inputs } = useInputs();
  const [selectedInput, setSelectedInput] = React.useState<Input[]>([]);

  const handleClose = () => {
    setSelectedInput([])
    reset();
    toogle()
  }

  const onSubmit = (data: any) => {
    const input = {
      input: {
        id: selectedInput[0].id,
        name: selectedInput[0].name
      },
      inputId: selectedInput[0].id,
      amount: data.amount,
      unit: data.unit,
    }
    addFunction(input);
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar insumo</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mt-0" controlId="input">
                <Form.Label>Insumo</Form.Label>
                <Typeahead
                  id="input-selected"
                  labelKey="name"
                  onChange={setSelectedInput}
                  options={inputs}
                  selected={selectedInput}
                  placeholder="Buscar insumo"
                  inputProps={{ required: true }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mt-3" controlId="unit">
                <Form.Label>Unidad</Form.Label>
                <select {...register("unit")} className="form-select" aria-label="unit" required>
                  <option value="kg">Kilos</option>
                  <option value="g">Gramos</option>
                  <option value="mg">Mili gramos</option>
                  <option value="l">Litros</option>
                  <option value="ml">Mili litros</option>
                </select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mt-3" controlId="amount">
                <Form.Label>Cantidad</Form.Label>
                <Form.Control type="text" placeholder="Cantidad" {...register("amount")} autoComplete="off" required />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
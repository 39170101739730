import React from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { PageLayout } from '../../../components/PageLayout'
import routes from '../../../helpers/routes'
import { createSellOrder } from '../../../utils/api/sellOrders'
import OrderForm from './OrderForm'
import toast from 'react-hot-toast'
import useAuth from '../../../hooks/useAuth'

function NewOrder() {
  const { user } = useAuth();
  let history = useHistory<any>()

  const createOrder = (order: any) => {
    createSellOrder(order).then(() => {
      toast.success("Orden registrada");
      history.push(routes.adminOrders)
    }).catch((e: any) => toast.error(e.response.data));
  }

  return (
    <PageLayout>
      <Container>
        <Breadcrumb>
          {user && user.role === 'vendedor' ?
            <Breadcrumb.Item linkProps={{ to: routes.orders }} linkAs={Link}>
              Ordenes
            </Breadcrumb.Item> :
            <Breadcrumb.Item linkProps={{ to: routes.adminOrders }} linkAs={Link}>
              Ordenes
            </Breadcrumb.Item>
          }
          <Breadcrumb.Item active>Nueva Orden</Breadcrumb.Item>
        </Breadcrumb>
        <h1>Nueva Orden de venta</h1>
        <OrderForm submitFunction={createOrder} />
      </Container>
    </PageLayout>
  )
}

export default NewOrder

import React from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { RouteItem, RouteItemBody, RouteItemTitle, Pill, ClickPill } from '../../../../components/RouteItemComponent'
import routes from '../../../../helpers/routes'
import useModal from '../../../../hooks/useModal'
import { Order } from '../../../../providers/SellOrdersProvider'
import { formatMoney, toLocalDateFormat } from '../../../../utils/functions'
import ConfirmOrderModal from '../../orders/ConfirmOrderModal'

interface Props {
  order: Order
  refresh: () => void
}

function ForConfirmOrderItem({ order, refresh }: Props) {
  const history = useHistory();
  const modal = useModal();
  const infoModal = useModal();

  return (
    <>
      <RouteItem>
        <RouteItemTitle>
          <Pill>Nº {order.id}</Pill>
          <Pill>{order.user.username}</Pill>
          <Pill>{toLocalDateFormat(order.deliveryDate)}</Pill>
          <Pill>{formatMoney(order.total)}</Pill>
          <ClickPill onClick={modal.toogleModal}>Confirmar</ClickPill>
        </RouteItemTitle>
        <RouteItemBody onClick={infoModal.toogleModal}>
          {order.customer.name}
        </RouteItemBody>
      </RouteItem>
      <ConfirmOrderModal show={modal.isOpenModal} close={modal.toogleModal} order={order} refresh={refresh} />
      <Modal show={infoModal.isOpenModal} onHide={infoModal.toogleModal}>
        <Modal.Header closeButton>
          <div style={{ fontWeight: 600 }}>Orden Nº {order.id}</div>
        </Modal.Header>
        <Modal.Body>
          <div><b>Vendedor:</b> {order.user.username}</div>
          <div><b>Cliente:</b> {order.customer.name}</div>
          <div><b>Referencia:</b> {order.customer.reference}</div>
          <div><b>Total:</b> {formatMoney(order.total)}</div>
          <div>Detalle de la venta:</div>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid' }}>Producto</th>
                <th style={{ border: '1px solid' }}>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {order.items.map((item, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid' }}>{item.product_group.name}</td>
                  <td style={{ border: '1px solid' }}>{item.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-info' onClick={() => history.push(routes.ventas.ver(order.id))}>Ver Venta</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ForConfirmOrderItem
import React from 'react'
import styled from 'styled-components'
import useModal from '../../hooks/useModal'
import GroupCategoryModalForm from './GroupCategoryModalForm'
import { Category } from './GroupsPage'

type Props = {
  category: Category
  submitFunction: (c: Category, id: number) => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #466988eb;
  color: #fff;
  margin-top: .6em;
  border-radius: .8em;
  padding: .25em .75em;
`

const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1em;
  font-weight: 600;
`

const WrapperDescription = styled.div`
  font-size: .9em;
`

function CategoryItem({ category, submitFunction }: Props) {
  const updateModal = useModal();

  const handleUpdateCategory = (updatedCategory: Category) => {
    submitFunction(updatedCategory, category.id);
  }

  return (
    <Wrapper>
      <WrapperTitle>
        <div>
          {category.name}
        </div>
        <div style={{ color: 'yellow', cursor: 'pointer' }} onClick={updateModal.toogleModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
          </svg></div>
      </WrapperTitle>
      <WrapperDescription>Nombre corto: {category.slug}</WrapperDescription>
      <GroupCategoryModalForm show={updateModal.isOpenModal} close={updateModal.toogleModal} submitFunction={handleUpdateCategory} category={category} />
    </Wrapper>
  )
}

export default CategoryItem
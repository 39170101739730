import React from 'react';
import { Line } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
Chart.register(CategoryScale);

function LineChart({ chartData }: any) {
  return (
    <Line data={chartData} options={{
      scales: {
        y: {
          beginAtZero: true
        }
      },
      layout: {
        padding: 10
      }
    }} />
  )
}

export default LineChart
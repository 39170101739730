import { useState } from "react";
import styled from "styled-components";
import CuentasPorCobrar from "../../components/vendors/CuentasPorCobrar";
import ResumenDeVentasPorMes from "../../components/vendors/ResumenDeVentasPorMes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: .8em;
  width: 100%;
  heigth: 100%;
  padding: .5em .5em;
`

const Item = styled.div`
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25em 0;
  border: 1px solid grey;
  border-radius: 6px;
  &:hover {
    background-color: grey;
    color: white;
  }
`

export default function VendorDashboard({ user }: any) {
  const [choosed, setChoosed] = useState(false);
  const [chooseCuentas, setChooseCuentas] = useState(false);
  const [chooseVentas, setChooseVentas] = useState(false);

  function choose(item: string) {
    switch (item) {
      case 'cuentas':
        setChooseCuentas(true);
        setChooseVentas(false);
        break;
      case 'ventas':
        setChooseVentas(true);
        setChooseCuentas(false);
        break;

      default:
        break;
    }
    setChoosed(true);
  }
  return (
    <Container>
      {!choosed &&
        <>
          <Item onClick={() => choose('cuentas')}>Cuentas por cobrar</Item>
          <Item onClick={() => choose('ventas')}>Ventas del mes</Item>
        </>
      }
      {choosed &&
        <>
          <Item onClick={() => setChoosed(false)}>Regresar al menú</Item>
          {chooseCuentas &&
            <CuentasPorCobrar />
          }
          {chooseVentas &&
            <ResumenDeVentasPorMes />
          }
        </>
      }
    </Container>
  );
}
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function AddButton({ title, to }: { title: string, to: string }) {
  const history = useHistory();

  const handleLink = () => {
    history.push(to)
  }

  return <div>
    <Button variant='primary' onClick={handleLink}>{title}</Button>
  </div>;
}

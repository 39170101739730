import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import routes from "../helpers/routes";

export default function Navigation() {
    const { user, logout } = useAuth();

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" bg="dark">
            <Container>
                <Navbar.Brand as={NavLink} to={routes.home}>
                    CB Cleaner S.A.C.
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {user && (user.role === "admin") &&
                            <Nav.Link as={NavLink} to={routes.almacenes.productos}>
                                Almacenes
                            </Nav.Link>
                        }
                        {user && user.role === "admin" ? <NavDropdown id="compras" title="Compras">
                            <NavDropdown.Item as={NavLink} to={routes.providers}>
                                Proveedores
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.inputs}>
                                Insumos
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.suppliers}>
                                Suministradores
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.supplies}>
                                Suministros
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.purchase.inputs}>
                                Compra de insumos
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.purchase.supplies}>
                                Compra de suministros
                            </NavDropdown.Item>
                        </NavDropdown> : null}
                        {user && user.role === "admin" ? <NavDropdown id="production" title="Producción">
                            <NavDropdown.Item as={NavLink} to={routes.filling.index}>
                                Llenado
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.productions.index}>
                                Producciones
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.recipes.index}>
                                Recetas
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.production.productRecipes}>
                                Receta de productos
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.production.productProductions}>
                                Producción de productos
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.production.inputRecipes}>
                                Receta de insumos
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.production.inputProductions}>
                                Producción de insumos
                            </NavDropdown.Item>
                        </NavDropdown> : null}
                        {user && user.role === "admin" ? <NavDropdown id="ventas" title="Ventas">
                            <NavDropdown.Item as={NavLink} to={routes.customers}>
                                Clientes
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.products}>
                                Productos
                            </NavDropdown.Item>
                            {user.role === 'admin' && <NavDropdown.Item as={NavLink} to={routes.product_groups}>
                                Grupo de productos
                            </NavDropdown.Item>}
                            <NavDropdown.Item as={NavLink} to={routes.ventas.index}>
                                Ordenes de venta
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.remision}>
                                Guía de remisión
                            </NavDropdown.Item>
                        </NavDropdown> : null}
                        {user && user.role === "admin" ? <NavDropdown id="admin" title="Admin">
                            <NavDropdown.Item as={NavLink} to={routes.admin.users}>
                                Usuarios
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.vendors}>
                                Vendedores
                            </NavDropdown.Item>
                            {user && (user.role === "admin" || user.role === "administrador") &&
                                <NavDropdown.Item as={NavLink} to={routes.admin.config}>
                                    Configuraciones
                                </NavDropdown.Item>
                            }
                        </NavDropdown> : null}
                        {user && user.role === "admin" ? <NavDropdown id="cobros" title="Makro">
                            <NavDropdown.Item as={NavLink} to={routes.makro.cobros}>
                                Cobros
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.makro.profitReport}>
                                Reporte de utilidades
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to={routes.makro.utils}>
                                Herramientas
                            </NavDropdown.Item>
                        </NavDropdown> : null}
                        {user && (user.role === "admin" || user.role === "administrador") &&
                            <NavDropdown id="reportes" title="Reportes">
                                <NavDropdown.Item as={NavLink} to={routes.reports.productsByCustomers}>
                                    Productos
                                </NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.reports.kardex}>
                                    Kardex
                                </NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.reports.accounting}>
                                    Contabilidad
                                </NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.reports.various}>
                                    Reportes varios
                                </NavDropdown.Item>
                                {user.role === "admin" &&
                                    <NavDropdown.Item as={NavLink} to={routes.reports.financial}>
                                        Finanzas
                                    </NavDropdown.Item>
                                }
                                {user.role === "admin" &&
                                    <NavDropdown.Item as={NavLink} to={routes.reports.data}>
                                        Datos
                                    </NavDropdown.Item>
                                }
                            </NavDropdown>
                        }
                        {user && user.role === "admin" ? <Nav.Link as={NavLink} to={routes.boxes}>
                            Dinero
                        </Nav.Link> : null}
                        {user && user.role === "vendedor" ?
                            <>
                                <Nav.Link as={NavLink} to={routes.customers}>
                                    Clientes
                                </Nav.Link>
                                <Nav.Link as={NavLink} to={routes.orders}>
                                    Ordenes de venta
                                </Nav.Link>
                            </>
                            : null}
                    </Nav>
                    <Nav>
                        <Nav.Link as={NavLink} to={routes.account}>
                            Mi cuenta
                        </Nav.Link>
                        <Nav.Link onClick={logout}>
                            Cerrar Sesión
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

import { Breadcrumb } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useHistory, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/PageLayout";
import routes from "../../../helpers/routes";
import { useInputRecipe } from "../../../hooks/useRecipes";
import InputRecipeForm from "./Form";

export default function EditInputRecipe() {
  let history = useHistory();
  const { id: recipeId } = useParams<any>();
  const { recipe, isLoading, updateRecipe } = useInputRecipe(recipeId);

  const updateInputRecipe = async (recipe: any) => {
    try {
      await updateRecipe(recipe);
      toast.success("Actualizado correctamente");
      history.push(routes.production.viewInputRecipe(recipeId));
    } catch (e) {
      alert(JSON.stringify(e, null, 2))
    }
  }

  if (isLoading) {
    return <PageLayout>Cargando...</PageLayout>
  }

  return (
    <PageLayout>
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: routes.production.inputRecipes }} linkAs={Link}>
          Lista de recetas
        </Breadcrumb.Item>
        <Breadcrumb.Item linkProps={{ to: routes.production.viewInputRecipe(recipeId) }} linkAs={Link}>
          Ver Receta
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Edición</Breadcrumb.Item>
      </Breadcrumb>
      <InputRecipeForm initialData={recipe} submitFunction={updateInputRecipe} />
    </PageLayout>
  )
}
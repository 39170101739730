import React from 'react'
import { FullPageLayout } from '../../../components/FullPageLayout'
import styled from 'styled-components'
import SummarySellsByPeriod from './SummarySellsByPeriod'
import SummarySellsByPeriodAndByVendor from './SummarySellsByPeriodAndByVendor'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
`

const IndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  gap: 2rem;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

function HeaderBar({ backFunction, title }: { backFunction: () => void, title: string }) {
  return (
    <Header>
      <Title>{title}</Title>
      <button onClick={backFunction}>Volver</button>
    </Header>
  )
}

function MainPage() {
  const [onMainList, setOnMainList] = React.useState(true);
  const [onSummarySellsByPeriod, setOnSummarySellsByPeriod] = React.useState(false);
  const [onSummarySellsByPeriodAndByVendor, setOnSummarySellsByPeriodAndByVendor] = React.useState(false);

  const handleBackToIndex = () => {
    setOnMainList(true);
    setOnSummarySellsByPeriod(false);
    setOnSummarySellsByPeriodAndByVendor(false);
  }
  const handleGoToSummarySellsByPeriod = () => {
    setOnMainList(false);
    setOnSummarySellsByPeriodAndByVendor(false);
    setOnSummarySellsByPeriod(true);
  };

  const handleGoToSummarySellsByPeriodAndByVendor = () => {
    setOnMainList(false);
    setOnSummarySellsByPeriod(false);
    setOnSummarySellsByPeriodAndByVendor(true);
  };

  if (!onMainList && onSummarySellsByPeriod) return (
    <Container>
      <HeaderBar backFunction={handleBackToIndex} title={"Ventas y cobros del periodo"} />
      <SummarySellsByPeriod />
    </Container>
  )

  if (!onMainList && onSummarySellsByPeriodAndByVendor) return (
    <Container>
      <HeaderBar backFunction={handleBackToIndex} title={"Resumen de ventas por periodo y por vendedor"} />
      <SummarySellsByPeriodAndByVendor />
    </Container>
  )

  return (
    <IndexContainer>
      <h3>Reportes financieros</h3>
      <ul>
        <li onClick={handleGoToSummarySellsByPeriod}>Ventas y cobros por periodo</li>
      </ul>
      <ul>
        <li onClick={handleGoToSummarySellsByPeriodAndByVendor}>Resumen de ventas por periodo y por vendedor</li>
      </ul>
    </IndexContainer>
  )
}

function FinancialReportsIndex() {
  return (
    <FullPageLayout>
      <MainPage />
    </FullPageLayout>
  )
}

export default FinancialReportsIndex
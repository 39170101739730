import { axiosInstance } from "../axios";

export const fetchAllProviders = async () => {
    try {
        const res = await axiosInstance.get('/providers');
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const createProvider = async (body: object) => {
    try {
        return await axiosInstance.post('/providers', body)
    } catch (error) {
        throw (error)
    }
}
import ProductsList from "./ProductsList"
import { createProduct, fetchAllProducts, registerProductMovement } from "../../utils/api/products"
import { ProductForm } from "./ProductForm"
import withFecthData, { WraperPorps } from "../../hocs/withFetchData"
import { PageLayout } from "../../components/PageLayout"
import BasicPageTitle from "../../components/BasicPageTitle"
import { useState } from "react"
import { getTemplate } from "../../services/products"
import { axiosInstance } from "../../utils/axios"
import CargarDatos from "../../components/CargarDatos"
import useAuth from "../../hooks/useAuth"
import useModal from "../../hooks/useModal"
import ProductMovementModal from "./ProductMovementModal"
import toast from "react-hot-toast"

export interface ProductModel {
    id: number
    name: string,
    sellPrice: number,
    stock: number,
    recipeId: number,
    containerId: number,
    capId: number,
}

function Page(props: WraperPorps<ProductModel>) {
    const { items, addingNewItem, loading, error, toogleNewItemForm, addNewItem, mutate } = props
    const { user } = useAuth();
    const [upload, setUpload] = useState(false);
    const toggleUpload = () => setUpload(!upload);
    const productMovementModal = useModal();

    const registerMovement = (data: any) => {
        registerProductMovement(data).then(() => {
            toast.success("Movimiento del stock registrado");
            mutate();
        }).catch((err: any) => {
            toast.error(err.response.data);
        });
    }

    const massiveupload = async (data: any) => {
        try {
            await axiosInstance.post('/excel-uploads/products', data);
        } catch (e) {
            throw (e)
        }
    }

    return (
        <PageLayout>
            <BasicPageTitle title="Productos" subtitle="Administración de productos" />
            {!addingNewItem && !upload &&
                <>
                    {user?.role === 'admin' &&
                        <div className="d-flex justify-content-between mb-3">
                            <button className="btn btn-success" onClick={toogleNewItemForm}>Agregar producto</button>
                            <div>
                                <button className="btn btn-outline-success me-2" onClick={productMovementModal.toogleModal}>Ingresar movimiento</button>
                                <button className="btn btn-warning" onClick={toggleUpload}>Cargar datos</button>
                            </div>
                        </div>
                    }
                    <ProductsList products={items} isLoading={loading} isError={error} />
                </>
            }
            {addingNewItem &&
                <ProductForm handleSubmit={addNewItem} toogle={toogleNewItemForm} />
            }
            {upload &&
                <CargarDatos subject="productos" toogle={toggleUpload} getTemplateFunction={getTemplate} uploadFunction={massiveupload} />
            }
            <ProductMovementModal show={productMovementModal.isOpenModal} toggle={productMovementModal.toogleModal} submitFunction={registerMovement} />
        </PageLayout>
    )
}

const ProductPage = withFecthData(Page)(fetchAllProducts, createProduct);
export default ProductPage
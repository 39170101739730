import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

type Props = {
  title: string;
  to: string;
  linkString: string;
}


export default function HeaderSection({ title, to, linkString }: Props) {
  return (
    <Container>
      <h1>{title}</h1>
      <Link className={linkString.toLocaleLowerCase().includes('receta') ? 'btn btn-primary' : 'btn btn-secondary'} to={to}>{linkString}</Link>
    </Container>
  )
}

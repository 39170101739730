import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { FullPageLayout } from '../../components/FullPageLayout'
import useAuth from '../../hooks/useAuth'
import SellOrdersProvider from '../../providers/SellOrdersProvider'
import PrivateRoute from '../../routers/PrivateRoute'
import { ModuleContainer, NavItem, Sidebar } from '../common/modules.styles'
import CurrentSellOrders from './CurrentSellOrders'
import EditSellOrder from './EditSellOrder'
import HistorySellOrders from './HistorySellOrders'
import NewSellOrder from './NewSellOrder'
import SellOrdersPayable from './SellOrdersPayable'
import SalesReportPage from './Vendors/SalesReportPage'
import ViewSellOrder from './ViewSellOrder'

function SellsPage() {
  const { user } = useAuth();
  let { path, url } = useRouteMatch();

  return (
    <FullPageLayout>
      <ModuleContainer>
        <Sidebar>
          <h1 style={{ textAlign: 'center' }}>Ventas</h1>
          <NavItem to={url}>actuales</NavItem>
          <NavItem to={`${url}/por-cobrar`}>por cobrar</NavItem>
          <NavItem to={`${url}/historico`}>historico</NavItem>
          {user && user.role === 'admin' && <NavItem to={`${url}/vendedores`}>vendedores</NavItem>}
        </Sidebar>
        <React.Fragment>
          <SellOrdersProvider>
            <Switch>
              <PrivateRoute exact path={path} component={CurrentSellOrders} />
              <PrivateRoute path={`${path}/por-cobrar`} component={SellOrdersPayable} />
              <PrivateRoute path={`${path}/historico`} component={HistorySellOrders} />
              <PrivateRoute path={`${path}/nueva`} component={NewSellOrder} />
              <PrivateRoute path={`${path}/ver/:id`} component={ViewSellOrder} />
              <PrivateRoute path={`${path}/editar/:id`} component={EditSellOrder} />
              <PrivateRoute path={`${path}/vendedores`} component={SalesReportPage} />
            </Switch>
          </SellOrdersProvider>
        </React.Fragment>
      </ModuleContainer>
    </FullPageLayout>
  );
}

export default SellsPage
import React from 'react'
import { PageLayout } from '../components/PageLayout'
import EditVendor from './EditVendor';
import NewVendor from './NewVendor';
import useVendors from './useVendors';
import VendorsList from './VendorsList';

const VendorsComponent = () => {
  const { vendors, isLoading, isError, creating, createToggle, addVendor, vendor, setVendor, editing, editToggle, editVendor } = useVendors();
  return (
    <PageLayout>
      <VendorsList isLoading={isLoading} isError={isError} vendors={vendors} editing={editing} isCreating={creating} toggle={createToggle} setVendor={setVendor} editToggle={editToggle} />
      <NewVendor isCreating={creating} toggle={createToggle} submitFunction={addVendor} />
      <EditVendor editing={editing} vendor={vendor} editToggle={editToggle} submitFunction={editVendor} />
    </PageLayout>
  )
}

export default VendorsComponent
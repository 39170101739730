import React from 'react'
import styled from 'styled-components'
import { ActionsHeader } from '../../common/modules.styles'
import useProducts, { Product } from '../../../hooks/products.hook'

const Container = styled.div`
  padding: .5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  overflow-y: hidden;
`

const BodyPage = styled.div`
  margin-top: .5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: .8rem;
  overflow-y: hidden;
`

const RoundedBox = styled.div`
  border: 2px solid grey;
  border-radius: .4rem;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`

const ScrollList = styled.div`
  overflow-y: scroll;
  padding: .5rem;
`

const SearchInput = styled.input`
  margin: .25rem;
  border-radius: .3rem;
`

function ProductStockItem({ product }: { product: Product }) {
  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div>{product.name}</div>
    <div>{product.stock}</div>
  </div>
}

function ProductsWarehouse() {
  const { filteredProducts, searchValue, setSearchValue } = useProducts();
  return (
    <Container>
      <ActionsHeader>
        <button className='btn btn-success'>Nuevo producto</button>
        <button className='btn btn-success'>opciones</button>
      </ActionsHeader>
      <BodyPage>
        <RoundedBox>
          <h4 style={{ textAlign: 'center' }}>Stock de productos</h4>
          <SearchInput placeholder='Buscar producto...' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
          <ScrollList>
            {filteredProducts.map((p, i) => (
              <ProductStockItem key={i} product={p} />
            ))}
          </ScrollList>
        </RoundedBox>
        <RoundedBox>
          <h4 style={{ textAlign: 'center' }}>Últimos movimientos</h4>
        </RoundedBox>
      </BodyPage>
    </Container>
  )
}

export default ProductsWarehouse
import React, { useState } from 'react'
import { Form, Modal, Button, Row, Col } from 'react-bootstrap';

const VehicleModalForm = ({ show, toggle, submitFunction }: any) => {
  const [placa, setPlaca] = useState('');

  const close = () => {
    setPlaca('');
    toggle();
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    submitFunction({
      placa,
    });
    close();
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar vehículo</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Group controlId="placa">
                <Form.Label>Placa del vehículo</Form.Label>
                <Form.Control type="text" placeholder="Placa" value={placa} onChange={(e: any) => setPlaca(e.target.value)} required />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal >
  )
}

export default VehicleModalForm
import { Alert, Breadcrumb, Table } from 'react-bootstrap'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { PageLayout } from '../../../components/PageLayout'
import routes from '../../../helpers/routes'
import useAuth from '../../../hooks/useAuth'
import { formatMoney, toLocalDateFormat } from '../../../utils/functions'
import { EditAction, OrderGeneralDetails, OrderGeneralDetailsLine, OrderInfo, OrderInfoBold, OrderInfoLabel, OrderItemsView, OrderMoneyDetails, OrderView, OrderViewActions } from '../../common/styled'
import OptionsDropdown from './OptionsDropdown'
import { useOrdersById } from './useOrders'

const Nulled = ({ order }: any) => {
    if (!order.nulled) return null
    return (
        <Alert variant='danger'>
            <div className="d-flex justify-content-between align-items-center">
                <strong>El comprobante relacionado ha sido anulado</strong>
                <button className='btn btn-outline-danger btn-sm' onClick={() => window.open(order.nulledLink, "_blank")}>Revisar anulación</button>
            </div>
        </Alert>
    )
}
const NoteRelated = ({ order }: any) => {
    if (!order.creditNote) return null
    return (
        <Alert variant='info'>
            <strong>El comprobante ha sido anulado mediante nota de crédito</strong>
            <button className='btn btn-outline-info btn-sm' onClick={() => window.open(order.creditNoteLink, "_blank")}>Revisar nota de crédito</button>
        </Alert>
    )
}

function ViewOrder() {
    const { user } = useAuth();
    const { id } = useParams<any>();
    const { order } = useOrdersById(id);

    const writeVendor = (user: any) => {
        if (user.role !== 'vendedor') {
            return "Vendedor: Oficina"
        }
        return "Vendedor(a): " + user.username
    }

    const writeOrderStatus = (state: string) => {
        if (state === 'created') {
            return "Estado: Por confirmar"
        }
        if (state === 'confirmed') {
            return "Estado: Confirmada"
        }
    }

    if (!order) return <PageLayout>Cargando...</PageLayout>

    return (
        <PageLayout>
            <Breadcrumb>
                {user && user.role === 'vendedor' ?
                    <Breadcrumb.Item linkProps={{ to: routes.orders }} linkAs={Link}>
                        Ordenes
                    </Breadcrumb.Item> :
                    <Breadcrumb.Item linkProps={{ to: routes.adminOrders }} linkAs={Link}>
                        Ordenes
                    </Breadcrumb.Item>
                }
                <Breadcrumb.Item active>Detalle de orden</Breadcrumb.Item>
            </Breadcrumb>
            <Nulled order={order} />
            <NoteRelated order={order} />
            <OrderViewActions>
                <div className="">{writeVendor(order.user)}</div>
                <div className="">{writeOrderStatus(order.state)}</div>
                {order.futureDocument && <div>{order.futureDocument}</div>}
                {order.state !== 'created' && <OptionsDropdown order={order} />}
                {order.state === 'created' ?
                    <EditAction as={Link} to={routes.editOrder(id)}>
                        <i className="bi bi-pencil"></i>
                        Editar
                    </EditAction>
                    : null
                }
            </OrderViewActions>
            <OrderView>
                <OrderGeneralDetails>
                    <OrderGeneralDetailsLine>
                        <OrderInfoLabel>Cliente:</OrderInfoLabel>
                        <OrderInfo>{order.customer.name}</OrderInfo>
                    </OrderGeneralDetailsLine>
                    <OrderGeneralDetailsLine>
                        <OrderInfoLabel>{order.customer.documentType}:</OrderInfoLabel>
                        <OrderInfo>{order.customer.documentNumber}</OrderInfo>
                    </OrderGeneralDetailsLine>
                    <OrderGeneralDetailsLine>
                        <OrderInfoLabel>Dirección:</OrderInfoLabel>
                        <OrderInfo>{order.customer.address}</OrderInfo>
                    </OrderGeneralDetailsLine>
                    <OrderGeneralDetailsLine>
                        <OrderInfoLabel>Fecha de entrega:</OrderInfoLabel>
                        <OrderInfo color="navy">{toLocalDateFormat(order.deliveryDate)}</OrderInfo>
                    </OrderGeneralDetailsLine>
                    <OrderGeneralDetailsLine>
                        <OrderInfoLabel>Fecha de vencimiento:</OrderInfoLabel>
                        <OrderInfo color="crimson">{toLocalDateFormat(order.expirationDate)}</OrderInfo>
                    </OrderGeneralDetailsLine>
                </OrderGeneralDetails>
                <OrderMoneyDetails>
                    <OrderGeneralDetailsLine>
                        <OrderInfoLabel>Sub total:</OrderInfoLabel>
                        <OrderInfoBold>{formatMoney(order.subTotal)}</OrderInfoBold>
                    </OrderGeneralDetailsLine>
                    <OrderGeneralDetailsLine>
                        <OrderInfoLabel>IGV</OrderInfoLabel>
                        <OrderInfoBold>{formatMoney(order.igv)}</OrderInfoBold>
                    </OrderGeneralDetailsLine>
                    <OrderGeneralDetailsLine>
                        <OrderInfoLabel>Total:</OrderInfoLabel>
                        <OrderInfoBold>{formatMoney(order.total)}</OrderInfoBold>
                    </OrderGeneralDetailsLine>
                </OrderMoneyDetails>
                <OrderItemsView>
                    <Table bordered size="sm">
                        <thead>
                            <tr>
                                <th>Cantidad</th>
                                <th>Descripción</th>
                                <th>Precio Unitario</th>
                                <th>Sub Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.items.map((item: any) => (
                                <tr key={item.id}>
                                    <td>{item.amount}</td>
                                    <td>{item.product_group.name}</td>
                                    <td>{formatMoney(item.unitPrice)}</td>
                                    <td>{formatMoney(item.amount * item.unitPrice)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </OrderItemsView>
            </OrderView>
            {order.sell_payments &&
                <>
                    <h3>Cobros:</h3>
                    <Table bordered size="sm">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.sell_payments.map((item: any) => (
                                <tr key={item.id}>
                                    <td>{toLocalDateFormat(item.date)}</td>
                                    <td>{formatMoney(item.amount)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            }
        </PageLayout>
    )
}

export default ViewOrder

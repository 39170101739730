import React from 'react'
import { Table } from 'react-bootstrap'
import { Container } from './styleds'
import VehicleModalForm from './VehicleModalForm'

const Vehicles = ({ show, submitFunction, vehicles, removeFunction, toggle }: any) => {

  return (
    <>
      <Container>
        <h6 className='mb-0'>Vehículos</h6>
        {vehicles.length === 0 && null}
        {vehicles.length !== 0 &&
          <Table bordered size='sm'>
            <thead>
              <tr>
                <th>Placa</th>
                <th style={{ textAlign: 'center' }}>Quitar</th>
              </tr>
            </thead>
            <tbody>
              {vehicles.map((v: any, index: number) => (
                <tr key={index}>
                  <td>{v.placa}</td>
                  <td>
                    <div className="d-grid">
                      <button type="button" className='btn btn-sm btn-outline-danger' onClick={() => removeFunction(index)}>X</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
        <button type="button" className='btn btn-info btn-sm' onClick={() => toggle()}>Agregar vehículo</button>
      </Container>
      <VehicleModalForm show={show} toggle={toggle} submitFunction={submitFunction} />
    </>
  )
}

export default Vehicles
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

type Props = {
  children: React.ReactNode;
}


export default function PageHeader({ children }: Props) {
  return (
    <Container>
      {children}
    </Container>
  )
}

import React from "react";
import { axiosInstance } from "../utils/axios";

export interface Product {
  id: number
  name: string
  sellPrice: number
  code: string
  stock: number
  state: boolean
}

const useProducts = () => {
  const [products, setProducts] = React.useState<Product[]>([]);
  const [error, setError] = React.useState<any>();
  const [searchValue, setSearchValue] = React.useState("");

  const filteredProducts = React.useMemo(() => {
    return products.filter(product => product.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [products, searchValue])

  const fetchProducts = React.useCallback(() => {
    (async () => {
      try {
        const res = await axiosInstance.get('/products');
        setProducts(res.data);
      } catch (error) {
        setError(error)
      }
    })()
  }, []);

  React.useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return {
    filteredProducts,
    fetchProducts,
    searchValue,
    setSearchValue,
    isLoading: products.length === 0,
    isError: error,
  }
}

export default useProducts;
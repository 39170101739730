import styled from "styled-components";

export const TableCaption = styled.div`
display: flex;
justify-content: space-between;
padding: .25em .5em;
gap: .5em;
font-size: 1em;
font-weight: 600;
`

export const TableContainer = styled.div`
overflow-y: scroll;
font-size: .9em;
`

export const FlexCenterContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
`
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { PageLayout } from "../../../components/PageLayout";
import TableList from "../../../components/TableList";
import routes from "../../../helpers/routes";
import useAuth from "../../../hooks/useAuth";
import useModal from "../../../hooks/useModal";
import { Recipe, useRecipes } from "../../../hooks/useRecipes";
import { axiosInstance } from "../../../utils/axios";
import { ViewButton } from "../../common/styled";
import ProductRecipeForm from "./Form";
import writeXlsxFile from "write-excel-file";

export default function ProductRecipesPage() {
  const { user } = useAuth();
  const costModal = useModal();
  let history = useHistory();
  const [creating, setCreating] = useState(false);
  const { recipes, isLoading, mutate, addRecipe } = useRecipes('products');
  const [recipe, setRecipe] = useState<Recipe>();
  const [calculating, setCalculating] = useState(false);

  const getCostsReport = async () => {
    try {
      const res = await axiosInstance.get('/recipes/products/cost-report');
      const schema = [
        {
          column: "Producto",
          type: String,
          value: (item: any) => item.productName
        },
        {
          column: "Costo",
          type: Number,
          value: (item: any) => Number(item.cost)
        },
      ];
      return await writeXlsxFile(res.data, { schema, fileName: `Costo_de_productos_al_${dayjs().format("DDMMYYYY")}.xlsx` });
    } catch (e: any) {
      alert("Error: " + e.message);
    }
  }

  const reCalculateCosts = async () => {
    try {
      setCalculating(true);
      const res = await axiosInstance.get('/apisnet/tipo-de-cambio', { params: { fecha: new Date() } });
      await axiosInstance.post('/recipes/products/calculate-costs', { dolar: Number(res.data.venta) });
      mutate();
      setCalculating(false);
      toast.success("Costos calculados satisfactoriamente");
    } catch (e: any) {
      setCalculating(false);
      alert("Error: " + e.message);
    }
  }

  const handleOpenModal = (r: Recipe) => {
    setRecipe(r);
    costModal.toogleModal();
  }

  const handleCloseModal = () => {
    setRecipe(undefined);
    costModal.toogleModal();
  }

  const toogle = () => setCreating(!creating);

  const createFunction = async (recipe: any) => {
    try {
      await addRecipe(recipe);
      mutate();
      toogle();
      toast.success("Receta registrada");
    } catch (e) {
      alert(JSON.stringify(e, null, 2));
    }
  }

  const headers = ["Receta", "Producto", "Nro. de productos resultante", "Acciones"];
  const adminHeaders = ["Receta", "Producto", "Nro. de productos resultante", "Costo unitario (S/)", "Acciones"];

  if (isLoading) {
    return <PageLayout>Cargando...</PageLayout>
  }

  return (
    <PageLayout>
      {!creating &&
        <>
          <h2>Recetas de productos terminados</h2>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            <Button variant="primary" onClick={toogle}>Agregar receta</Button>
            <div>
              <button className="btn btn-outline-info me-2" onClick={getCostsReport}>Reporte de costos</button>
              <button className="btn btn-outline-warning" onClick={reCalculateCosts} disabled={calculating}>
                {!calculating && <span>Recalcular costos</span>}
                {calculating && <Spinner animation="border" size="sm" />}
              </button>
            </div>
          </div>
          <TableList headers={user && user.role === 'admin' ? adminHeaders : headers} items={recipes} searchParams={['name', 'productName']}>
            {(recipe) => (
              <>
                <td>{recipe.name}</td>
                <td>{recipe.productName}</td>
                <td>{recipe.quantity}</td>
                {user && user.role === 'admin' && <td>{recipe.lastCost}</td>}
                <td className="d-flex gap-1 justify-content-center">
                  <ViewButton title="Ver detalle" onClick={() => history.push(routes.production.viewProductRecipe(recipe.id))}><i className="bi bi-eye"></i></ViewButton>
                  {user && user.role === 'admin' && <button className="btn btn-warning btn-sm" onClick={() => handleOpenModal(recipe)}>Calcular costo</button>}
                </td>
              </>
            )}
          </TableList>
        </>
      }
      {creating &&
        <>
          <ProductRecipeForm submitFunction={createFunction} toogle={toogle} />
        </>
      }
      <CostModalCalculatorComponent show={costModal.isOpenModal} recipe={recipe} toggle={handleCloseModal} mutate={mutate} />
    </PageLayout>
  )
}

interface ModalProps {
  show: boolean
  recipe: Recipe | undefined
  toggle: () => void
  mutate: any
}

function CostModalCalculatorComponent({ show, recipe, toggle, mutate }: ModalProps) {
  const [dolar, setDolar] = useState<number>();
  const [cost, setCost] = useState();
  const [error, setError] = useState();
  const [calculated, setCalculated] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await axiosInstance.get('/apisnet/tipo-de-cambio', { params: { fecha: new Date() } });
      setDolar(Number(res.data.venta));
    })()
  }, []);

  function close() {
    setError(undefined);
    setCost(undefined);
    toggle();
  }

  async function getRecipeCost() {
    try {
      const res = await axiosInstance.get(`/recipes/products/recipe-cost/${recipe?.id}`, { params: { dolar } });
      setCost(res.data);
      setCalculated(true);
    } catch (e: any) {
      setError(e.response.data);
    }
  }

  async function handleSubmit(e: any) {
    try {
      e.preventDefault();
      await axiosInstance.post(`/recipes/products/recipe-cost/${recipe?.id}`, { cost });
      mutate();
      close();
      toast.success("¡Costo de receta actualizado!");
    } catch (e: any) {
      setError(e.response.data);
    }
  }

  return <Modal show={show} onHide={close}>
    <Modal.Header closeButton>
      <Modal.Title>Calcular costo por unidad de receta</Modal.Title>
    </Modal.Header>
    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <h4>{recipe?.name} {recipe?.quantity} {recipe?.unit}</h4>
        <Form.Group className="mt-1" as={Row} controlId="dolar">
          <Form.Label column sm="4">Precio del dólar (S/)</Form.Label>
          <Col sm="3">
            <Form.Control value={dolar} onChange={(e) => setDolar(Number(e.target.value))}></Form.Control>
          </Col>
          <Col sm="5">
            <button type="button" className="btn btn-outline-primary" onClick={getRecipeCost}>Calcular costo</button>
          </Col>
        </Form.Group>
        <Form.Group className="mt-1" as={Row} controlId="cost">
          <Form.Label column sm="4">Costo por {recipe?.unit} (S/)</Form.Label>
          <Col sm="3">
            <Form.Control disabled value={cost}></Form.Control>
          </Col>
        </Form.Group>
        <div className="mt-2" style={{ color: 'red' }}>{error && <span>{error}</span>}</div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={close}>Cancel</button>
        <button type="submit" className="btn btn-primary" disabled={!calculated}>Guardar Costo</button>
      </Modal.Footer>
    </Form>
  </Modal>
}
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./routers/AppRouter";
import AuthProvider from "./providers/AuthProvider";
import { SWRConfig } from 'swr'
import { axiosInstance } from "./utils/axios";

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              fetcher: url => axiosInstance.get(url).then(res => res.data)
            }}
          >
            <AppRouter />
          </SWRConfig>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;

import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const MyLink = styled(Link)`
  text-decoration: none;
  color: blue;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 700;
  border-radius: .4rem;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  padding: 1rem;
  transition: .3s;
  &:hover {
    text-decoration: none;
    font-size: 2.8rem;
  }
`

interface Props {
  to: string
  label: string
}

function BoxLink(props: Props) {
  return (
    <MyLink to={props.to}>{props.label}</MyLink>
  )
}

export default BoxLink
import React from 'react'
import { Breadcrumb } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { PageLayout } from '../../../components/PageLayout'
import { PageTitle } from '../../../components/styleds/common';
import routes from '../../../helpers/routes';
import { useOrdersById } from './useOrders';
import OrderForm from './OrderForm';
import { updateSellOrder } from '../../../utils/api/sellOrders';
import toast from 'react-hot-toast';

function EditOrder() {
    let history = useHistory<any>()
    const { id } = useParams<any>()
    const { order } = useOrdersById(id);

    const updateOrder = (orderForm: any) => {
        updateSellOrder(orderForm, id).then(() => {
            toast.success("Actualizada correctamente");
            history.push(routes.viewOrder(id))
        })
    }

    if (!order) return <PageLayout>Cargando...</PageLayout>

    return (
        <PageLayout>
            <Breadcrumb>
                <Breadcrumb.Item linkProps={{ to: routes.orders }} linkAs={Link}>
                    Ordenes
                </Breadcrumb.Item>
                <Breadcrumb.Item linkProps={{ to: routes.viewOrder(id) }} linkAs={Link}>
                    Ver Orden
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Edición de orden de venta</Breadcrumb.Item>
            </Breadcrumb>
            <PageTitle>Editar orden de venta</PageTitle>
            <OrderForm order={order} submitFunction={updateOrder}></OrderForm>
        </PageLayout>
    )
}

export default EditOrder

import React from 'react'
import { FullPageLayout } from '../../components/FullPageLayout'
import { Container } from '../common/modules.styles'
import { axiosInstance } from '../../utils/axios';
import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';
import styled from 'styled-components';
import { toLocalDateFormat } from '../../utils/functions';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { handleAxiosError } from '../../utils/functions/handleAxiosError';

const Production = styled.div`
  border: 1px solid #ccc;
  padding: .8rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

function useData() {
  const [productions, setProductions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = async () => {
    setLoading(true);
    // fetch
    try {
      const response = await axiosInstance('/productions/get-productions-for-be-filled')
      const data = await response.data;
      setProductions(data);
      setLoading(false);
    } catch (error) {
      handleAxiosError(error);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, [])

  return { productions, loading, fetchData }
}

function FillingPage() {
  const { productions, loading, fetchData } = useData();

  return (
    <FullPageLayout>
      <Container>
        <PageHeader>
          <PageTitle title='Llenado de productos' />
        </PageHeader>
        {loading && <p>Cargando...</p>}
        {productions.length === 0 && <p>No hay producciones por llenar</p>}
        <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          {productions.map((production: any) => (
            <ProductionFillable key={production.id} production={production} fetchFunction={fetchData} />
          ))}
        </div>
      </Container>
    </FullPageLayout>
  )
}

function ProductionFillable({ production, fetchFunction }: any) {
  const [showModal, setShowModal] = React.useState(false);

  const openModal = () => {
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  }

  return (
    <>
      <Production>
        <h3>{production.recipe.name}</h3>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Fecha de producción: {toLocalDateFormat(production.date)}</span>
          {production.state === 'inUse' && <span style={{ color: 'red', fontWeight: "bold" }}>En uso</span>}
          {production.state !== 'inUse' && <span style={{ color: 'blue', fontWeight: "bold" }}>Entera</span>}
        </div>
        <button className="btn btn-primary" onClick={openModal}>Llenar</button>
      </Production>
      {showModal && <FillProductionModal production={production} show={showModal} close={closeModal} fetchFunction={fetchFunction} />}
    </>
  )
}

function FillProductionModal({ production, show, close, fetchFunction }: { production: any, show: boolean, close: () => void, fetchFunction: () => Promise<any> }) {
  const [products, setProducts] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const { register, handleSubmit } = useForm();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [formData, setFormData] = React.useState<any>({});

  React.useEffect(() => {
    (async () => {
      try {
        const response = await axiosInstance.get(`/recipes/products/recipe/${production.recipe.id}`);
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        handleAxiosError(error);
        setLoading(false);
      }
    })();

  }, [production.recipe.id]);

  const productName = (productId: number): string => {
    return products.find(p => p.id === Number(productId))?.name;
  }

  const onSubmit = (data: any) => {
    setFormData(data);
    setShowConfirm(true);
  }

  const confirmSubmit = () => {
    axiosInstance.post('/productions/fill-production', { productionId: production.id, ...formData })
      .then(async () => {
        toast.success('Llenado registrado correctamente');
        setShowConfirm(false);
        close();
        await fetchFunction();
      })
      .catch(error => {
        handleAxiosError(error);
        setShowConfirm(false);
      });
  }

  return (
    <>
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Llenar producción</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {loading && <p>Cargando...</p>}
            {!loading &&
              <>
                <div>
                  <label htmlFor='product'>Producto</label>
                  <select {...register('product')} className="form-select" required>
                    <option value="">Selecciona un producto</option>
                    {products.map((product: any) => (
                      <option key={product.id} value={product.id}>{product.name}</option>
                    ))}
                  </select>
                </div>
                <div className='mt-2'>
                  <label htmlFor="quantity">Cantidad</label>
                  <input type="number" {...register('quantity')} className="form-control" required />
                </div>
                <div className="form-check form-switch mt-2">
                  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...register('stillProduction')} />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Quedó producción por llenar</label>
                </div>
              </>
            }
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className="btn btn-primary">Llenar</button>
            <button className="btn btn-secondary" onClick={close}>Cerrar</button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar llenado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Confirma el llenado de lo siguiente?</p>
          <p>Producto: {productName(formData?.product)}</p>
          <p>Cantidad: {formData?.quantity}</p>
          <p>Quedó producción por llenar: {formData?.stillProduction ? 'Sí' : 'No'}</p>
          {formData?.stillProduction && <p style={{ color: 'blue', fontWeight: 'bold' }}>Aún hay producción por llenar</p>}
          {!formData?.stillProduction && <p style={{ color: 'red', fontWeight: 'bold' }}>Ya no queda producción por ser llenada</p>}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={confirmSubmit}>Llenar</button>
          <button className="btn btn-secondary" onClick={() => setShowConfirm(false)}>Cancelar</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FillingPage
import React from 'react'
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../../../helpers/routes';
import { SellOrderContext } from '../../../providers/SellOrdersProvider';
import { createSellOrder } from '../../../utils/api/sellOrders';
import OrderForm from '../orders/OrderForm';

const Conatiner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: scroll;
`

function NewSellOrder() {
  const { fetch } = React.useContext(SellOrderContext);
  const history = useHistory();

  const createOrder = (order: any) => {
    createSellOrder(order).then(() => {
      fetch();
      toast.success("Orden registrada");
      history.push(routes.ventas.index);
    }).catch((e: any) => e.response ? toast.error(e.response.data) : console.log(e));
  }

  return (
    <Conatiner>
      <h1>Nueva Orden de venta</h1>
      <OrderForm submitFunction={createOrder} />
    </Conatiner>
  )
}

export default NewSellOrder
import styled from 'styled-components'

export const CurrentOrdersContainer = styled.div`
  padding: .25rem .75rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
`
export const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: .5rem;
`

export const MainSection = styled.div`
  display: flex;
  gap: 1rem;
  height: 80%;
`

export const OrdersContainer = styled.div`
  flex: 1;
  border: 1px solid rgb(123,12,31);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

export const Header = styled.div`
  padding: .25rem .5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Body = styled.div`
  padding: .25rem .5rem;
`
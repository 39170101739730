import React, { useState } from "react"
import { Col, Container, Form, Modal, Row, Button, Table } from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import { Link } from "react-router-dom"
import { InputFloating } from "../../components/InputFloating"
import routes from "../../helpers/routes"
import useInput from "../../hooks/useInput"
import useModal from "../../hooks/useModal"
import { getGroupsCategories, productsForSelect } from "../../utils/api/products"
import { Category } from "./GroupsPage"

interface Item {
  id: number
  name: string
}

interface GroupItem {
  id?: number
  quantity: number
  product: Item
}

interface AddProductModalProps {
  show: boolean
  toogle: () => void
  functionHandler: (item: GroupItem) => void
}

function AddProductModal({ show, toogle, functionHandler }: AddProductModalProps) {
  const [products, setProducts] = React.useState<Item[]>([])
  const [selectedProduct, setSelectedProduct] = React.useState<Item[]>([])
  const { value: quantity, bind: bindQuantity, reset: resetQuantity } = useInput('')

  React.useEffect(() => {
    productsForSelect().then(setProducts)
  }, [])

  const close = () => {
    setSelectedProduct([])
    resetQuantity()
    toogle()
  }

  const addOrderItem = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    functionHandler({
      quantity: Number(quantity),
      product: selectedProduct[0]
    })
    close()
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Producto</Modal.Title>
      </Modal.Header>
      <Form onSubmit={addOrderItem}>
        <Modal.Body>
          <Container>
            <Row className="mb-2">
              <Col>
                <Form.Group controlId="product">
                  <Form.Label>Producto</Form.Label>
                  <Typeahead
                    id="products"
                    onChange={setSelectedProduct}
                    labelKey="name"
                    options={products}
                    placeholder="Seleccione un producto..."
                    selected={selectedProduct}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <Form.Group controlId="unirPrice">
                  <Form.Label>Cantidad</Form.Label>
                  <Form.Control type="number" placeholder="Cantidad" {...bindQuantity} required />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export function GroupForm(props: any) {
  const [title, setTitle] = React.useState("Nuevo Grupo de Productos");
  const { value: name, bind: bindName, setValue: setName } = useInput('')
  const { value: sellPrice, bind: bindSellPrice, setValue: setSellPrice } = useInput('')
  const { value: listPrice, bind: bindListPrice, setValue: setListPrice } = useInput('')
  const { value: code, bind: bindCode, setValue: setCode } = useInput('')
  const { value: barCode, bind: bindBarCode, setValue: setBarCode } = useInput('')
  const [categories, setCategories] = useState<Category[]>([]);
  const [category, setCategory] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [webName, setWebName] = useState('');
  const [content, setContent] = useState('');
  const [webAvailable, setWebAvailable] = useState(false);
  const [state, setState] = useState(false);
  const [items, setItems] = React.useState<GroupItem[]>([])
  const modalAddProduct = useModal()

  React.useEffect(() => {
    (async () => {
      setCategories(await getGroupsCategories());
    })()
  }, []);

  React.useEffect(() => {
    if (props.group) {
      setTitle("Edición de grupo de productos")
      setName(props.group.name)
      setSellPrice(props.group.sellPrice)
      setListPrice(props.group.listPrice ? props.group.listPrice : '')
      setCode(props.group.code ? props.group.code : '')
      setBarCode(props.group.barCode ? props.group.barCode : '')
      setItems(props.group.items)
      setCategory(props.group.productGroupCategoryId ? props.group.productGroupCategoryId : '')
      setPhotoUrl(props.group.photoUrl ? props.group.photoUrl : '')
      setWebAvailable(props.group.webAvailable ? Boolean(props.group.webAvailable) : false)
      setState(props.group.state ? Boolean(props.group.state) : false)
      setWebName(props.group.webName ? props.group.webName : '')
      setContent(props.group.content ? props.group.content : '')
    }
  }, [props.group, setName, setSellPrice, setListPrice, setCode, setBarCode, setCategory, setPhotoUrl, setWebAvailable, setState, setWebName, setContent])

  const addItem = (item: GroupItem) => {
    setItems([...items, item])
  }

  const removeItem = (id: number) => {
    setItems(items.filter(item => item.product.id !== id))
  }

  const handleChecked = (e: any) => {
    setWebAvailable(e.target.checked)
  }

  const handleStateChecked = (e: any) => {
    setState(e.target.checked)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (items.length === 0) {
      alert("Falta añadir productos al grupo")
    } else {
      const group = {
        name,
        sellPrice: Number(sellPrice),
        listPrice: Number(listPrice),
        code: code === '' ? null : code,
        barCode: barCode === '' ? null : barCode,
        productGroupCategoryId: category === '' ? null : category,
        photoUrl,
        webAvailable,
        state,
        webName,
        content,
        items,
      }
      props.submitFunction(group)
    }
  }

  return (
    <div>
      <h1>{title}</h1>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col">
            <InputFloating
              type="text"
              name="name"
              label="Nombre del grupo"
              {...bindName}
              required={true}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <InputFloating
              type="number"
              name="sellPrice"
              label="Precio por mayor"
              {...bindSellPrice}
              required={true}
            />
          </div>
          <div className="col">
            <InputFloating
              type="number"
              name="listPrice"
              label="Precio de lista"
              {...bindListPrice}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <InputFloating
              type="text"
              name="code"
              label="Código interno"
              {...bindCode}
            />
          </div>
          <div className="col">
            <InputFloating
              type="text"
              name="barCode"
              label="Código de barras"
              {...bindBarCode}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <Form.Group controlId="categories">
              <Form.Label>Categoría</Form.Label>
              <Form.Select id="categories" value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="">Selecciona una categoría</option>
                {categories.map(c => (
                  <option key={c.id} value={c.id}>{c.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col">
            <Form.Group controlId="photo">
              <Form.Label>Url de la foto</Form.Label>
              <Form.Control type="text" value={photoUrl} onChange={(e) => setPhotoUrl(e.target.value)}></Form.Control>
            </Form.Group>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <Form.Group controlId="webName">
              <Form.Label>Nombre para la web</Form.Label>
              <Form.Control type="text" value={webName} onChange={(e) => setWebName(e.target.value)}></Form.Control>
            </Form.Group>
          </div>
          <div className="col">
            <Form.Group controlId="photo">
              <Form.Label>Contenido del producto</Form.Label>
              <Form.Control type="text" value={content} onChange={(e) => setContent(e.target.value)}></Form.Control>
            </Form.Group>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <Form.Check type="switch" id="webAvailable" label="¿Disponible para la web?" checked={webAvailable} onChange={handleChecked}></Form.Check>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <Form.Check type="switch" id="state" label="¿Disponible para la venta?" checked={state} onChange={handleStateChecked}></Form.Check>
          </div>
        </div>
        <Table bordered size="sm">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.product.id}>
                <td>{item.product.name}</td>
                <td>{item.quantity}</td>
                <td>
                  <div className="d-grid">
                    <Button size="sm" variant="outline-danger" onClick={() => removeItem(item.product.id)}>X</Button>
                  </div>
                </td>
              </tr>
            )
            )}
            <tr>
              <td>
                <div className="d-grid">
                  <Button size="sm" variant="outline-primary" onClick={modalAddProduct.toogleModal}>Agergar producto</Button>
                </div>
              </td>
              <td colSpan={2}></td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex gap-2">
          <Link to={!props.group ? routes.product_groups : routes.product_group(props.group.id)}>
            <button className="btn btn-secondary">Cancelar</button>
          </Link>
          <button className="btn btn-primary" type="submit">Guardar</button>
        </div>
        <AddProductModal show={modalAddProduct.isOpenModal} toogle={modalAddProduct.toogleModal} functionHandler={addItem} />
      </form>
    </div >
  )
}
import { axiosInstance } from "../utils/axios"

export const getSumaryMovements = async (type: string, range: string) => {
  const res = await axiosInstance.get('/boxes/sumary', {
    params: {
      type,
      range
    }
  });
  const { labels, data } = res.data;
  return {
    labels,
    data
  }
}
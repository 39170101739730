import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { updateAddress } from "../../../services/customers";

export function EmitModal({ show, toggle, order, refresh, handleFunction }: any) {
  const [address, setAddress] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState(true);
  const [isAccepted, setIsAccepted] = useState(0);
  const [edittingAddress, setEdittingAddress] = useState(false);
  const [edited, setEdited] = useState(false);

  const handleAcceptAddress = () => {
    if (isAccepted === 0) {
      setIsAccepted(1);
      setIsDisabled(false);
    } else {
      setIsAccepted(0);
      setIsDisabled(true);
    }
  }

  const handleEdditingAddress = () => {
    setAddress(order && order.customer.address);
    setEdittingAddress(true);
    setIsAccepted(0);
    setIsDisabled(true);
  }

  const handleUpdateCustomerAddress = async () => {
    try {
      if (order) {
        await updateAddress(order.customer.id, address);
        refresh();
        setEdited(true);
        setEdittingAddress(false);
      }
    } catch (e: any) {
      alert(JSON.stringify(e, null, 2))
    }
  }

  const handleCancelAddressEdition = () => {
    setEdittingAddress(false);
  }

  const handleEmit = () => {
    toggle()
    handleFunction()
  }

  return (
    <Modal size="lg" show={show} onHide={() => toggle()}>
      <Modal.Header>
        <Modal.Title>Emitir documento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!edittingAddress && !edited &&
          <Form.Group controlId="showedAddress">
            <Form.Label>Dirección</Form.Label>
            <Form.Control value={order && order.customer.address} disabled={true}></Form.Control>
          </Form.Group>
        }
        {(edittingAddress || edited) &&
          <Form.Group controlId="address">
            <Form.Label>Dirección</Form.Label>
            <Form.Control disabled={edited} value={address} onChange={(e) => setAddress(e.target.value)}></Form.Control>
          </Form.Group>
        }
        <div className="d-flex justify-content-between mt-1">
          <div>
            <Form.Check
              type="switch"
              value={isAccepted}
              onClick={handleAcceptAddress}
              id="igv-switch"
              label="Aceptar dirección"
              disabled={edittingAddress}
            />
          </div>
          <div>
            {!edittingAddress && !edited && <button className="btn btn-warning btn-sm" onClick={handleEdditingAddress}>Modificar</button>}
            {edittingAddress && !edited &&
              <>
                <button className="me-2 btn btn-primary btn-sm" onClick={handleUpdateCustomerAddress}>Actualizar dirección</button>
                <button className="btn btn-secondary btn-sm" onClick={handleCancelAddressEdition}>Cancelar actualización</button>
              </>
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => toggle()}>Cancelar</button>
        <button type="button" className="btn btn-primary" disabled={isDisabled} onClick={handleEmit}>Emitir</button>
      </Modal.Footer>
    </Modal>
  );
}
import { Tab, Tabs } from "react-bootstrap"
import { PageLayout } from "../../../../components/PageLayout"
import useAuth from "../../../../hooks/useAuth"
import { useOrders } from "../useOrders"
import ConfirmedTab from "./ConfirmedTab"
import DeliveredTab from "./DeliveredTab"
import FinishedTab from "./FinishedTab"
import ForConfirmTab from "./ForConfirmTab"
import useBoxes from "../../../../hooks/useBoxes"

export default function OrdersTabs() {
  const { boxes } = useBoxes();
  const { user } = useAuth();
  const { finishedOrders, ordersForConfirm, confirmedOrders, deliveredOrders, refresh } = useOrders(user?.role === 'vendedor');

  if (!ordersForConfirm) {
    return <PageLayout>Cargando...</PageLayout>
  }

  return (
    <Tabs
      defaultActiveKey="forConfirm"
      transition={false}
      id="orders-lists"
      className="mb-3"
    >
      <Tab eventKey="forConfirm" title="Por confirmar">
        <ForConfirmTab orders={ordersForConfirm} refresh={refresh} />
      </Tab>
      <Tab eventKey="confirmeds" title="Confirmadas">
        <ConfirmedTab orders={confirmedOrders} refresh={refresh} />
      </Tab>
      <Tab eventKey="delivereds" title="Despachadas">
        <DeliveredTab orders={deliveredOrders} refresh={refresh} boxes={boxes} />
      </Tab>
      {user?.role !== 'vendedor' && <Tab eventKey="finisheds" title="Terminadas">
        <FinishedTab orders={finishedOrders} />
      </Tab>}
    </Tabs>
  )
}

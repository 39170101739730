import { Breadcrumb } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useHistory, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/PageLayout";
import routes from "../../../helpers/routes";
import { useProductionOrder } from "../../../hooks/useProductions";
import ProductProductionOrderForm from "./Form";

export default function EditProductProductionOrder() {
  let history = useHistory();
  const { id: orderId } = useParams<any>();
  const { order, isLoading, updateProductionOrder } = useProductionOrder(orderId, 'products');

  const editFunction = async (order: any) => {
    try {
      const updated = await updateProductionOrder(order);
      toast.success("Actualizada correctamente");
      history.push(routes.production.viewProductProduction(updated.id));
    } catch (e) {
      alert(JSON.stringify(e, null, 2));
    }
  }

  if (isLoading) {
    return <PageLayout>Cargando...</PageLayout>
  }

  return (
    <PageLayout>
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: routes.production.productProductions }} linkAs={Link}>
          Lista de ordenes de producción
        </Breadcrumb.Item>
        <Breadcrumb.Item linkProps={{ to: routes.production.viewProductProduction(order.id) }} linkAs={Link}>
          Ver Orden
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Edición</Breadcrumb.Item>
      </Breadcrumb>
      <ProductProductionOrderForm initialData={order} submitFunction={editFunction} />
    </PageLayout>
  )
}
import React from 'react'
import { Table } from 'react-bootstrap'
import writeXlsxFile from 'write-excel-file';

function InputUserReport({ data }: { data: any }) {

  async function downloadExcel() {
    try {
      const schema = [
        {
          column: "Insumo",
          type: String,
          value: (item: any) => item.inputName
        },
        {
          column: "Unidad",
          type: String,
          value: (item: any) => item.unit
        },
        {
          column: "Cantidad Usada",
          type: Number,
          value: (item: any) => Number(item.amountUsed)
        },
        {
          column: "Uso Promedio",
          type: Number,
          value: (item: any) => Number(item.monthlyAverage)
        },
        {
          column: "Stock",
          type: Number,
          value: (item: any) => Number(item.stock)
        },
        {
          column: "Ultimo Costo",
          type: Number,
          value: (item: any) => item.lastCost ? Number(item.lastCost) : 0
        },
        {
          column: "Moneda",
          type: String,
          value: (item: any) => item.currency ? item.currency : '-'
        },
        {
          column: "Gato Estimado",
          type: Number,
          value: (item: any) => item.estimatedExpense ? Number(item.estimatedExpense) : 0
        },
      ];
      return await writeXlsxFile(data.data, { schema, fileName: `reporte_insumos_usados.xlsx` });
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>Los datos corresponden a {data.months} meses</span>
        <button className='btn btn-sm btn-success' onClick={downloadExcel}>Descargar reporte</button>
      </div>
      <div style={{ marginTop: '.5em' }}>
        <Table responsive size='sm'>
          <thead>
            <tr>
              <th>Insumo</th>
              <th>Unidad</th>
              <th>Cantidad Usada</th>
              <th>Uso Promedio</th>
              <th>Stock</th>
              <th>Ultimo Costo</th>
              <th>Moneda</th>
              <th>Gato Estimado</th>
            </tr>
          </thead>
          <tbody>
            {data.data.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.inputName}</td>
                <td>{item.unit}</td>
                <td>{item.amountUsed}</td>
                <td>{item.monthlyAverage}</td>
                <td>{item.stock}</td>
                <td>{item.lastCost}</td>
                <td>{item.currency}</td>
                <td>{item.estimatedExpense}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default InputUserReport
export function countDecimals(value: number) {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
}

export function formatToSelect(data: any): { id: number, name: string }[] {
    let formated = []
    for (const item of data) {
        formated.push({ id: item.id, name: item.name })
    }
    return formated
}

export const formatAmount = (amount: number) => {
    const parsed = parseFloat(amount.toString());
    return parsed % 1 === 0 ? parsed.toFixed(0) : parsed.toFixed(2);
}

export function setRefValue(el: HTMLElement, value: string) {
    el.setAttribute("value", value)
}

function formatCurrency(currency: string) {
    if ((currency === 'usd') || (currency !== 'pen')) return '$'
    return 'S/.'
}
const options = {
    minimumFractionDigits: 2
};

export const formatMoney = (number: number | string, currency: string | null = null): string => (`${currency ? formatCurrency(currency) : 'S/'} ${Number(number).toLocaleString('en', options)}`)

export const toLocalDateFormat = (date: string): string => {
    if (!date) return '----'
    const splited = date.split("-")
    return splited[2].concat("/", splited[1], "/", splited[0])
}

export const formatOrderState = (order: any): string => {
    if (order.nulled) return "Anulada"
    if (order.emited && order.state !== "finished") return "Emitida"

    switch (order.state) {
        case 'created':
            return 'Por confirmar'

        case 'confirmed':
            return 'Confirmada'

        case 'delivered':
            return 'Despachada'

        case 'emited':
            return 'Emitida'

        case 'payed':
            return 'Pagada'

        case 'finished':
            return 'Terminada'

        default:
            return 'Anulada'
    }
}

export function round(num: number) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Container = styled.div`
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
`

const modalRoot: HTMLElement | null = document.getElementById('modal-root');

function BottomModal({ children }: { children: JSX.Element }) {
  if (!modalRoot) {
    return null
  }

  return ReactDOM.createPortal(
    <Container>
      {children}
    </Container>,
    modalRoot
  );
};

export default BottomModal;
import dayjs from 'dayjs'
import React from 'react'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import writeXlsxFile from 'write-excel-file'
import useProducts from '../../../hooks/products.hook'
import { axiosInstance } from '../../../utils/axios'
import { AxiosError } from '../../../utils/interfaces'

const Container = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
`

const FormSection = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
`

const ResultSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: .5rem;
`

const ResultSectionHeader = styled.div`
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

function ProductKardex() {
  const { filteredProducts: products } = useProducts();
  const [selectedProduct, setSelectedProduct] = React.useState("");
  const [data, setData] = React.useState<any>(null);
  const [error, setError] = React.useState<string | null>(null);

  async function downloadExcel() {
    try {
      const schema = [
        {
          column: "Tipo de movimiento",
          type: String,
          value: (movement: any) => movement.type
        },
        {
          column: "Fecha",
          type: String,
          value: (movement: any) => movement.date,
        },
        {
          column: "Detalle",
          type: String,
          value: (movement: any) => movement.detail
        },
        {
          column: `Cantidad`,
          type: Number,
          value: (movement: any) => Number(movement.quantity)
        },
      ];
      return await writeXlsxFile(data.movements, { schema, fileName: `Kardex_${data.productName}_${dayjs().format("DDMMYYYY")}.xlsx` });
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.get('/reports/kardex/product', { params: { productId: selectedProduct } });
      setData(res.data);
      setError(null);
    } catch (err) {
      if (err instanceof Error) {
        if ((err as AxiosError).response) {
          setError(JSON.stringify((err as AxiosError).response.data, null, 2));
        } else {
          setError(err.message);
        }
      } else {
        setError('An unknown error occurred');
      }
    }
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <FormSection>
          <label className='form-label' htmlFor="product" style={{ gridColumn: '1 / 3', gridRow: '1 / 2' }}>Seleccione el producto a consultar</label>
          <select className='form-select' id="product" value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value.toString())} required style={{ gridColumn: '1 / 2', gridRow: '2 / 3' }}>
            <option value="">Escoge el producto</option>
            {products.map((product, index) => (
              <option key={index} value={product.id.toString()}>{product.name}</option>
            ))}
          </select>
          <button className='btn btn-primary ms-3' style={{ gridColumn: '2 / 3', gridRow: '2 / 3' }}>Buscar</button>
        </FormSection>
      </form>
      {error && <div className="error">ERROR: {error}</div>} {/* Muestra el error si existe */}
      {data &&
        <ResultSection>
          <ResultSectionHeader>
            <div style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>{data.productName} stock {data.stock} unidades</div>
            <button className='btn btn-success' onClick={downloadExcel}>Descargar reporte</button>
          </ResultSectionHeader>
          <div className='mt-3'>
            {data.movements.length === 0 && <h1 style={{ color: 'red' }}>No se registran movimientos para este producto</h1>}
            {data.movements.length > 0 &&
              <>
                <div>Detalle de movimientos</div>
                <Table responsive size="sm">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Tipo de movimiento</th>
                      <th>Detalle</th>
                      <th>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.movements.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.date}</td>
                        <td>{item.type}</td>
                        <td>{item.detail}</td>
                        <td>{item.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            }
          </div>
        </ResultSection>}
    </Container>
  )
}

export default ProductKardex
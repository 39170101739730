import { FullPageLayout } from "../components/FullPageLayout";
import useAuth from "../hooks/useAuth";
import AdministrationDashboard from "./dashboards/Administration";
import VendorDashboard from "./dashboards/Vendor";
import AdminHome from "./home/AdminHome";
import FactoryHome from "./home/FactoryHome";

export default function HomePage() {
    const { user } = useAuth();

    if (!user) {
        return <FullPageLayout>Cargando...</FullPageLayout>
    }

    return (
        <FullPageLayout>
            {user.role === 'vendedor' &&
                <VendorDashboard user={user} />
            }
            {user.role === 'administrador' &&
                <AdministrationDashboard />
            }
            {user.role === 'admin' &&
                <AdminHome />
            }
            {(user.role === 'almacenista' || user.role === 'productor') &&
                <FactoryHome />
            }
        </FullPageLayout>
    )
}

import { useHistory } from 'react-router';
import { Spinner, Table } from 'react-bootstrap'
import { fetchInput, updateInput } from '../../utils/api/inputs'
import { PageLayout } from '../../components/PageLayout';
import InputForm from './InputForm';
import { InputModel } from './InputsPage';
import withFetchOne, { WraperFetchOneProps } from '../../hocs/withFetchOne';
import useAuth from '../../hooks/useAuth';
import { deleteInput, setInputPrice } from '../../services/inputs';
import toast from 'react-hot-toast';
import { formatMoney } from '../../utils/functions';
import useModal from '../../hooks/useModal';
import SetInputCostModal from './SetInputCostModal';

interface PageProps {
    input: InputModel
    input_providers: { date: Date, provider: string, price: number, currency: string }[]
    products_info: { productId: number, productName: string, stock: number, weekAverage: number }[]
}

function Details(props: WraperFetchOneProps<PageProps>) {
    const { user } = useAuth();
    const modal = useModal();
    const history = useHistory();
    const { loading, disable, editing, fetchItem, handleSetPrice, handleEditing, handleSubmit } = props

    async function handleDeleteInput() {
        try {
            await deleteInput(fetchItem.input['id']);
            toast.success("Insumo eliminado");
            history.push(".");
        } catch (error: any) {
            if (error.response) {
                toast.error(error.response.data);
            } else {
                toast.error(error);
            }
        }
    }

    if (loading) return <PageLayout><Spinner animation="border" /></PageLayout>

    return (
        <PageLayout>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <h2 style={{ margin: 0 }}>Más detalles de {fetchItem.input['name']}</h2>
                    <button className="btn btn-secondary" onClick={() => history.push(".")}>Regresar</button>
                </div>
                {!editing ?
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        {user && user.role === 'admin' && <button className="btn btn-danger" onClick={() => {
                            if (window.confirm('¿Seguro que desea eliminar el insumo?')) {
                                handleDeleteInput()
                            }
                        }}>Eliminar</button>}
                        <button className="btn btn-warning" onClick={handleEditing}>Editar</button>
                        {fetchItem.input_providers.length === 0 &&
                            <button className='btn btn-outline-info' onClick={modal.toogleModal}>Cargar costo</button>
                        }
                    </div>
                    :
                    <button className="btn btn-secondary" onClick={handleEditing}>Cancelar</button>
                }
            </div>
            <InputForm input={fetchItem.input} disabled={disable} editing={editing} handleSubmit={handleSubmit} />
            {!editing && fetchItem.input_providers.length === 0 && !fetchItem.input['manufactured'] &&
                <div className="mt-2 text-info">
                    <h2>Aún no se han registrado compras de este insumo</h2>
                </div>
            }
            {!editing && fetchItem.input_providers.length > 0 &&
                <>
                    <h4 className='mt-3'>Lista de proveedores de este insumo:</h4>
                    <Table responsive size='sm'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Proveedor</th>
                                <th>Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchItem.input_providers.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.date}</td>
                                    <td>{item.provider}</td>
                                    <td>{formatMoney(item.price, item.currency)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            }
            {/* lista de productos que hacen uso del insumo, comentado porque no es de utilidad */}
            {/* {!editing && fetchItem.products_info.length === 0 &&
                <div className="mt-2 text-info">
                    <h2>No hay productos que usen este insumo</h2>
                </div>
            }
            {!editing && fetchItem.products_info.length > 0 &&
                <>
                    <h4 className='mt-3'>Productos que usan este insumo:</h4>
                    <Table responsive size='sm'>
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Stock</th>
                                <th>Promedio de ventas semanal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchItem.products_info.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.productName}</td>
                                    <td>{item.stock}</td>
                                    <td>{item.weekAverage}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            } */}
            <SetInputCostModal show={modal.isOpenModal} toggle={modal.toogleModal} submitFunction={handleSetPrice} />
        </PageLayout >
    )
}

const InputDetails = withFetchOne(Details)(fetchInput, updateInput, setInputPrice)
export default InputDetails
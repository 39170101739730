import styled from 'styled-components';

export const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  margin-top: 1rem;
`

export const BoxHeader = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BoxMain = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  padding-top: 3px;
`

export const BoxDescription = styled.div`
  font-size: 1.1rem;
  color: grey;
  align-self: flex-end;
`

export const BoxAddButton = styled.div`
  border: 1px solid blue;
  border-radius: 5px;
  color: blue;
  cursor: pointer;
  padding: 6px;
  font-size: 1rem;
  &:hover {
    background-color: blue;
    color: white;
  }
`
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { PageLayout } from '../../components/PageLayout'
import routes from '../../helpers/routes';
import { useProductGroup } from './useProductGroups';

function GroupDetails() {
    const { id } = useParams<any>()
    const { group } = useProductGroup(id)

    return (
        <PageLayout>
            {group &&
                <div className="">
                    <div className='d-flex justify-content-between'>
                        <Link to={routes.product_groups}>
                            <button className='btn btn-outline-secondary'>
                                <i className="bi bi-backspace me-1"></i><span>Regresar</span>
                            </button>
                        </Link>
                        <Link to={routes.updateProductGroup(id)}>
                            <button className='btn btn-outline-warning'>
                                <i className="bi bi-pencil me-1"></i><span>Editar</span>
                            </button>
                        </Link>
                    </div>
                    <div className="d-flex flex-column mt-3">
                        <div className="d-flex flex-row">
                            <div className="p-2 fw-bold">Grupo:</div>
                            <div className="p-2">{group.name}</div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="p-2 fw-bold">Precio por mayor:</div>
                            <div className="p-2">{group.sellPrice}</div>
                        </div>
                        <div className="d-flex flex-row mb-2">
                            <div className="p-2 fw-bold">Estado:</div>
                            <div className="p-2">{group.state ? "Activo" : "Inactivo"}</div>
                        </div>
                    </div>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {group.items.map((i: any) => (
                                <tr key={i.id}>
                                    <td>{i.product.name}</td>
                                    <td>{i.quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        </PageLayout>
    )
}

export default GroupDetails

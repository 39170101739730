import React from 'react'
import styled from 'styled-components'
import { SellOrderContext } from '../../../../providers/SellOrdersProvider'
import { formatMoney } from '../../../../utils/functions';
import PayableOrderItem from '../PayableOrderItem';
import { Box } from '../../../common/interfaces';

const Container = styled.div`
  overflow-y: scroll;
  grid-area: oficina;
  margin: .5rem;
  border: 1px solid red;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
`

function OficinaCustomers({ boxes }: { boxes: Box[] }) {
  const { oficinaSales, oficinaSumary } = React.useContext(SellOrderContext);

  return (
    <Container>
      <div className="header">Ventas de la oficina</div>
      <div className="sumary">
        <div>{oficinaSumary.numberOfSales} ventas</div>
        <div>{formatMoney(oficinaSumary.moneyTocollet)} por cobrar</div>
      </div>
      <div className="body">
        {oficinaSales.length === 0 && <h4>No hay ventas por cobrar</h4>}
        {oficinaSales.map(order => (
          <PayableOrderItem key={order.id} order={order} boxes={boxes}></PayableOrderItem>
        ))}
      </div>
    </Container>
  )
}

export default OficinaCustomers
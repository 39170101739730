import CustomersList from "./CustomersList"
import { createCustomer, fetchAllCustomers } from "../../utils/api/customers"
import withFecthData, { WraperPorps } from "../../hocs/withFetchData"
import BasicPageTitle from "../../components/BasicPageTitle"
import { useState } from "react"
import { getTemplate } from "../../services/customers"
import { axiosInstance } from "../../utils/axios"
import CargarDatos from "../../components/CargarDatos"
import useAuth from "../../hooks/useAuth"
import TopBar from "../../components/TopBar"
import NewCustomer from "./NewCustomer"
import { Container } from "../common/modules.styles"
import dayjs from "dayjs"
import writeXlsxFile from "write-excel-file"

export interface CustomerModel {
    id: number
    name: string
    documentType: string
    documentNumber: string
    address: string
    reference: string
    watched: boolean
    userId: string
    vendor: string
}

function Page(props: WraperPorps<CustomerModel>) {
    const { items, addingNewItem, loading, error, toogleNewItemForm, addNewItem } = props
    const { user } = useAuth();
    const [upload, setUpload] = useState(false);
    const toggleUpload = () => setUpload(!upload);

    const massiveupload = async (data: any) => {
        try {
            await axiosInstance.post('/excel-uploads/customers', data);
        } catch (e) {
            throw (e)
        }
    }

    const getCustomersUbigeoReport = async () => {
        try {
            const res = await axiosInstance.get('/customers/ubigeo-report');
            const schema = [
                {
                    column: "Vendedor",
                    type: String,
                    value: (item: any) => item.vendor
                },
                {
                    column: "Cliente",
                    type: String,
                    value: (item: any) => item.name
                },
                {
                    column: "Referencia",
                    type: String,
                    value: (item: any) => item.reference
                },
                {
                    column: "Distrito",
                    type: String,
                    value: (item: any) => item.district
                },
                {
                    column: "Contactos",
                    type: String,
                    value: (item: any) => item.phones
                },
                {
                    column: "Dirección",
                    type: String,
                    value: (item: any) => item.address
                },
            ];
            return await writeXlsxFile(res.data, { schema, fileName: `Clientes_al_${dayjs().format("DDMMYYYY")}.xlsx` });
        } catch (e: any) {
            alert("Error: " + e.message);
        }
    }

    return (
        <Container>
            <BasicPageTitle title="Clientes" subtitle="Administración de clientes" />
            {!addingNewItem && !upload &&
                <>
                    {user && <TopBar subject="cliente" toggle={toogleNewItemForm} toggleUpload={toggleUpload} downloadReport={getCustomersUbigeoReport} role={user.role} />}
                    <CustomersList customers={items} isLoading={loading} isError={error} />
                </>
            }
            {addingNewItem &&
                <NewCustomer submitFunction={addNewItem} toggle={toogleNewItemForm} />
            }
            {upload &&
                <CargarDatos subject="clientes" toogle={toggleUpload} getTemplateFunction={getTemplate} uploadFunction={massiveupload} />
            }
        </Container>
    )
}

const CustomersPage = withFecthData(Page)(fetchAllCustomers, createCustomer, true);
export default CustomersPage
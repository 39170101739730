import { Button, Card, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { CardsList } from "../../components/CardsList"
import routes from "../../helpers/routes"

interface Product {
    id: number
    name: string
    sellPrice: number
    stock: number
}

interface Props {
    products: Product[]
    isLoading: boolean
    isError: boolean
}

export default function ProductsList({ products, isLoading, isError }: Props) {
    if (isError) return <div>failed to load</div>

    if (isLoading) return <Spinner animation="border" />

    return (
        <CardsList items={products} searchParams={["name"]}>
            {(product) => (
                <>
                    <Card.Title>{product.name}</Card.Title>
                    <Card.Text>
                        <span>Precio de venta por mayor: {product.sellPrice}</span>
                        <br></br>
                        <span>Stock: {product.stock}</span>
                    </Card.Text>
                    <Link to={routes.product(product.id)}>
                        <Button variant="primary">Ver detalles</Button>
                    </Link>
                </>
            )}
        </CardsList>
    )
}
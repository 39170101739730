import { useState } from 'react'

const useModal = (initialValue = false) => {
  const [isOpenModal, setIsOpenModal] = useState(initialValue);

  const toogleModal = () => {
    setIsOpenModal(prev => !prev);
  }

  return { isOpenModal, toogleModal };
}

export default useModal
import { Button, Modal } from "react-bootstrap";
import SubmitButton from "./SubmitButton";

export default function ConfirmModal({ show, close, primaryText, secondaryText, handleFunction, submitButtonText = 'Confirmar', submitingButtonText = 'Confirmando...' }: any) {
  return (
    <Modal show={show} onHide={close}>
      <Modal.Body>
        <h2>{primaryText}</h2>
        <p>{secondaryText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => close()}>
          Cancelar
        </Button>
        <SubmitButton buttonText={submitButtonText} submittingText={submitingButtonText} onSubmit={handleFunction} />
      </Modal.Footer>
    </Modal>
  )
}
import { ReactNode } from 'react'
import Navigation from './Navigation'
import { Toaster } from 'react-hot-toast';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    height: 100vh;
`

export function FullPageLayout(props: { children: ReactNode }) {
    return (
        <>
            <Container>
                <Toaster />
                <Navigation />
                {props.children}
            </Container>
        </>
    )
}

import React from 'react'
import styled from 'styled-components'
import { getSumaryMovements } from '../../../services/money';
import { formatMoney } from '../../../utils/functions';
import DaysSectionComponent from './DaysSectionComponent';
import LineChart from './LineChart';

const RangeSelector = styled.div`flex: .6; display: flex; justify-content: end;`
const IngresosChart = styled.div`flex: 4;`
const Amount = styled.div`flex: .9; display: flex; align-items: center; justify-content: center; color: rgb(53, 162, 235); font-size: 2rem; font-weight: 700;`

interface Props {
  color: string
  movementType: string
  chartTitle: string
  borderColor: string
  backgroundColor: string
}


function MoneyChartComponet({ color, movementType, chartTitle, borderColor, backgroundColor }: Props) {
  const [chartData, setChartData] = React.useState<any>({});
  const [haveData, setHaveData] = React.useState(false);
  const [amount, setAmount] = React.useState<number>(0);
  const [error, setError] = React.useState<any>();
  const [range, setRange] = React.useState("semanal");

  function handleSelectRange(e: any) {
    setRange(e.target.value);
  }

  const fetchData = React.useCallback(() => {
    (async () => {
      try {
        const res = await getSumaryMovements(movementType, range);
        setAmount(res.data[res.data.length - 1])
        setChartData({
          labels: res.labels,
          datasets: [
            {
              label: chartTitle,
              data: res.data,
              borderColor,
              backgroundColor
            }
          ]
        });
        setHaveData(true);
      } catch (e) {
        setError(e)
      }
    })()
  }, [range, backgroundColor, borderColor, chartTitle, movementType]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (error) {
    <div style={{ border: `.5px solid ${color}`, display: 'flex', flexDirection: 'column', paddingBottom: '1rem' }}>
      <h1>Error al cargar los datos</h1>
    </div>
  }

  return (
    <div style={{ border: `.5px solid ${color}`, display: 'flex', flexDirection: 'column', paddingBottom: '1rem' }}>
      <RangeSelector>
        <select name="range" id="range" value={range} onChange={handleSelectRange}>
          <option value="diario">Diario</option>
          <option value="semanal">Semanal</option>
          <option value="mensual">Mensual</option>
        </select>
      </RangeSelector>
      <IngresosChart>
        {haveData && <LineChart
          chartData={chartData}
        />}
      </IngresosChart>
      {amount && <Amount>{formatMoney(amount)}</Amount>}
      <DaysSectionComponent />
    </div>
  )
}

export default MoneyChartComponet
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useBoxes from "../../hooks/useBoxes";

export default function AddBoxModal({ show, toogle, refresh }: any) {
  const { addBox } = useBoxes();
  const { register, handleSubmit, setValue } = useForm()

  const handleClose = () => {
    setValue("name", "")
    setValue("total", "")
    setValue("currency", "")
    setValue("isCci", false)
    setValue("isOfficialBox", false)
    toogle()
  }

  const onSubmit = async (data: any) => {
    try {
      await addBox({
        name: data.name,
        total: data.total,
        currency: data.currency,
        isCci: data.isCci,
        isOfficialBox: data.isOfficialBox,
      })
      refresh()
      handleClose()
    } catch (error) {
      alert(error)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Nueva bóveda</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Nombre de la bóveda</Form.Label>
                <Form.Control type="text" placeholder="Nombre" {...register("name")} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicTotal">
                <Form.Label>Dinero de apertura</Form.Label>
                <Form.Control type="number" step="0.01" placeholder="Dinero inicial" {...register("total")} required />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicCurrency">
                <Form.Label>Moneda</Form.Label>
                <select {...register("currency")} className="form-select" aria-label="Moneda" required>
                  <option value="">Selecciona la moneda</option>
                  <option value="soles">Soles</option>
                  <option value="dolares">Dólares</option>
                </select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicIsCci">
                <Form.Check type="checkbox" {...register("isCci")} label="¿Es cuenta corriente?" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicIsOfficialBox">
                <Form.Check type="checkbox" {...register("isOfficialBox")} label="¿Es caja oficial?" />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Crear bóveda
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
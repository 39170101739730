import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { Container, Form, Row, Col, Button, Table, Modal, InputGroup } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import toast from 'react-hot-toast'
import useModal from '../../../hooks/useModal'
import { AuthContext } from '../../../providers/AuthProvider'
import { createCustomer, Customer, fetchAllCustomers } from '../../../utils/api/customers'
import { fetchAllGroups, IGroup } from '../../../utils/api/products'
import { getVendors } from '../../../utils/api/vendor'
import { round } from '../../../utils/functions'
import CustomerModalForm from '../../customers/CustomerModalForm'

interface AddProductModalProps {
  show: boolean
  role: string | undefined
  toogle: () => void
  functionHandler: (item: Item) => void
  selectedCustomer: Customer[]
}

function AddProductModal({ show, role, toogle, functionHandler, selectedCustomer }: AddProductModalProps) {
  const [groups, setGroups] = React.useState<IGroup[]>([])
  const [selectedGroup, setSelectedGroup] = React.useState<IGroup[]>([])
  const [sellPrice, setSellPrice] = React.useState('')
  const [amount, setAmount] = React.useState('')

  React.useEffect(() => {
    fetchAllGroups().then((groups: IGroup[]) => {
      const customer = selectedCustomer[0]
      const fusion = groups.filter(g => g.state === true).map(group => {
        if (!customer.priceList) {
          return group
        }
        const found = customer.priceList.find(p => p.groupId === group.id)
        if (!found) {
          return group
        }
        return {
          ...group,
          sellPrice: found.price
        }
      })
      setGroups(fusion)
    }, (error: any) => console.log(error))
  }, [selectedCustomer])

  const close = () => {
    setSelectedGroup([])
    setSellPrice('')
    setAmount('')
    toogle()
  }

  const addOrderItem = (e: React.FormEvent<Element>) => {
    e.preventDefault()
    e.stopPropagation()
    const item = {
      group: {
        id: selectedGroup[0].id,
        name: selectedGroup[0].name,
      },
      unitPrice: Number(sellPrice),
      amount: Number(amount),
    }
    functionHandler(item)
    close()
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Producto</Modal.Title>
      </Modal.Header>
      <Form onSubmit={addOrderItem}>
        <Modal.Body>
          <Container>
            <Row className="mb-2">
              <Col>
                <Form.Group controlId="product">
                  <Form.Label>Producto</Form.Label>
                  <Typeahead
                    id="group-selection"
                    labelKey="name"
                    onChange={(s) => {
                      setSelectedGroup(s)
                      if (s.length !== 0) {
                        setSellPrice(s[0].sellPrice.toString())
                      } else {
                        setSellPrice('')
                      }
                    }}
                    options={groups}
                    placeholder="Seleccionar producto..."
                    selected={selectedGroup}
                    inputProps={{ required: true }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <Form.Group controlId="unirPrice">
                  <Form.Label>Precio unitario</Form.Label>
                  <Form.Control disabled={role === 'vendedor'} type="number" placeholder="Precio unitario" value={sellPrice} onChange={(e) => setSellPrice(e.target.value)} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="amount">
                  <Form.Label>Cantidad</Form.Label>
                  <Form.Control type="number" min="1" placeholder="Cantidad" value={amount} onChange={(e) => setAmount(e.target.value)} autoComplete="off" required />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

interface Item {
  group: {
    id: number
    name: string
  },
  unitPrice: number
  amount: number
}

function OrderForm({ order, submitFunction }: any) {
  const customerModal = useModal();
  const { user } = useContext(AuthContext)
  const [customers, setCustomers] = useState<Customer[]>([])
  const [selectedCustomer, setSelectedCustomer] = useState<Customer[]>(order ? [order.customer] : [])
  const [items, setItems] = useState<Item[]>(order ? formatItems(order) : [])
  const [showModal, setShowModal] = useState(false)

  const [saleDate, setSaleDate] = useState(order ? order.saleDate : dayjs().format("YYYY-MM-DD"));
  const [deliveryDate, setDeliveryDate] = useState(order ? order.deliveryDate : '');
  const [expirationRange, setExpirationRange] = useState(order ? order.expirationRange : '0day');

  const [observations, setObservations] = useState(order ? order.observations : '');
  const [ordenDeCompra, setOrdenDeCompra] = useState(order ? order.ordenDeCompra : '');

  const [subTotal, setSubTotal] = useState<number>(order ? order.subTotal : 0)
  const [igv, setIgv] = useState<number>(order ? order.igv : 0)
  const [total, setTotal] = useState<number>(order ? order.total : 0)
  const [vendor, setVendor] = useState(order ? order.userId : "")
  const [vendors, setVendors] = useState([])

  function formatItems(order: any) {
    let res = []
    for (const item of order.items) {
      res.push({
        unitPrice: item.unitPrice,
        amount: item.amount,
        group: {
          id: item.product_group.id,
          name: item.product_group.name
        }
      })
    }
    return res
  }

  useEffect(() => {
    (async () => {
      if (user) {
        setVendors(await getVendors(user.id))
        setCustomers(await fetchAllCustomers(user.id))
        setVendor(user.id)
      }
    })()
  }, [user])

  const toogleModal = () => setShowModal(!showModal)

  const addToItem = (item: Item) => {
    setItems([...items, item])
    const newSubtotal = Number(subTotal) + Number(item.amount * item.unitPrice)
    const newIgv = round(newSubtotal * 0.18)
    const newTotal = round(newSubtotal + newIgv)
    setSubTotal(newSubtotal)
    setIgv(newIgv)
    setTotal(newTotal)
  }

  const removeItem = (item: Item) => {
    setItems(items.filter(i => i !== item))
    const newSubtotal = Number(subTotal) - Number(item.amount * item.unitPrice)
    const newIgv = round(newSubtotal * 0.18)
    const newTotal = round(newSubtotal + newIgv)
    setSubTotal(newSubtotal)
    setIgv(newIgv)
    setTotal(newTotal)
  }

  const removeAllItems = () => {
    setItems([])
    setSubTotal(0)
  }

  const newCustomer = async (customer: any) => {
    try {
      const res = await createCustomer(customer);
      toast.success("Cliene agregado");
      setSelectedCustomer([res.data]);
    } catch (e: any) {
      toast.error(e.response.data);
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (items.length === 0) {
      alert("Falta añadir productos a la orden")
    } else {
      const orderForm = {
        customerId: selectedCustomer[0].id,
        saleDate,
        deliveryDate: deliveryDate === '' ? null : deliveryDate,
        expirationRange,
        observations,
        ordenDeCompra,
        vendor,
        subTotal,
        igv,
        total,
        items
      }
      submitFunction(orderForm)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="g-2 mb-3">
          <Col md>
            <Form.Group controlId="cliente">
              <Form.Label>Cliente</Form.Label>
              <InputGroup>
                <div style={{ flexGrow: 1 }}>
                  <Typeahead
                    id="customer-selection"
                    labelKey="name"
                    onChange={s => {
                      removeAllItems()
                      setSelectedCustomer(s)
                    }}
                    options={customers}
                    placeholder="Buscar cliente..."
                    selected={selectedCustomer}
                    inputProps={{ required: true }}
                  />
                </div>
                <Button variant="outline-warning" id="button-new-customer" onClick={customerModal.toogleModal}>
                  Nuevo
                </Button>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-2 mb-3">
          <Col md>
            <Form.Group controlId="delivery_date">
              <Form.Label>Fecha de venta</Form.Label>
              <Form.Control type="date" value={saleDate} onChange={(e) => setSaleDate(e.target.value)} required disabled />
            </Form.Group>
          </Col>
          <Col md>
            <Form.Group controlId="delivery_date">
              <Form.Label>Fecha de entrega</Form.Label>
              <Form.Control type="date" value={deliveryDate} onChange={(e) => setDeliveryDate(e.target.value)} />
            </Form.Group>
          </Col>
          <Col md>
            <Form.Group controlId="vendor">
              <Form.Label>Vendedor</Form.Label>
              {/* <Form.Control type="text" ref={vendor} disabled /> */}
              <Form.Select aria-label="Seleccion de vendedor" value={vendor} onChange={(e) => setVendor(e.target.value)} disabled={user?.role === 'vendedor' ? true : false} required>
                {vendors.map((v: { value: string, label: string }) => (
                  <option key={v.value} value={v.value}>{v.label}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md>
            <Form.Group controlId="expirationDate">
              <Form.Label>Condición de pago</Form.Label>
              <Form.Select aria-label="Selecciona un rango" value={expirationRange} onChange={(e) => setExpirationRange(e.target.value)} disabled={user?.role === 'vendedor' ? true : false} required>
                <option value="0day">Contado</option>
                <option value="30days">Treinta (30) días</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        {expirationRange === '30days' &&
          <Row className="g-2 mb-3">
            <Col>
              <Form.Group controlId='observations'>
                <Form.Label>Observaciones</Form.Label>
                <Form.Control value={observations} onChange={(e) => setObservations(e.target.value)} placeholder='Escriba obsevaciones opcionales' />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='orderDeCompra'>
                <Form.Label>Order de compra</Form.Label>
                <Form.Control value={ordenDeCompra} onChange={(e) => setOrdenDeCompra(e.target.value)} placeholder='Número de orden de compra' required />
              </Form.Group>
            </Col>
          </Row>
        }
        <Row className="mb-1">
          <Col>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Precio</th>
                  <th>Cantidad</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.group.name}</td>
                    <td>{item.unitPrice}</td>
                    <td>{item.amount}</td>
                    <td>
                      <div className="d-grid">
                        <Button size="sm" variant="outline-danger" onClick={() => removeItem(item)}>X</Button>
                      </div>
                    </td>
                  </tr>
                ))}
                {selectedCustomer.length > 0 ?
                  <tr>
                    <td>
                      <div className="d-grid">
                        <Button size="sm" variant="outline-primary" onClick={toogleModal}>Agergar producto</Button>
                      </div>
                    </td>
                    <td colSpan={3}></td>
                  </tr>
                  : null}
              </tbody>
            </Table>
          </Col>
        </Row>
        <div className="d-flex flex-row justify-content-between">
          <div className="">
            <button className="btn btn-primary" type="submit">Guardar</button>
          </div>
          <div className="d-flex flex-column p-3 border">
            <div className="d-flex gap-4 justify-content-between">
              <span className="fw-bold">Sub total</span><span>{subTotal}</span>
            </div>
            <div className="d-flex gap-4 justify-content-between">
              <span className="fw-bold">IGV</span><span>{igv}</span>
            </div>
            <div className="d-flex gap-4 justify-content-between">
              <span className="fw-bold">Total</span><span>{total}</span>
            </div>
          </div>
        </div>
      </Form>
      {selectedCustomer.length > 0 ?
        <AddProductModal role={user?.role} show={showModal} toogle={toogleModal} functionHandler={addToItem} selectedCustomer={selectedCustomer} />
        : null}
      <CustomerModalForm show={customerModal.isOpenModal} toogle={customerModal.toogleModal} submitFunction={newCustomer} />
    </>
  )
}

export default OrderForm

import React from 'react'
import { FullPageLayout } from '../../../components/FullPageLayout'
import { Link } from 'react-router-dom'
import routes from '../../../helpers/routes'
import { axiosInstance } from '../../../utils/axios'
import styled from 'styled-components'
import HeaderSection from '../components/HeaderSection'

function useRecipes() {
  const [recipes, setRecipes] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    // fetch
    (async () => {
      try {
        const response = await axiosInstance('/recipes/get-recipes')
        const data = await response.data;
        setRecipes(data)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  return { recipes, loading }

}

export default function RecipesPage() {
  const { recipes, loading } = useRecipes()
  return (
    <FullPageLayout>
      <Container>
        <HeaderSection title="Recetas" to={routes.recipes.newRecipe} linkString='Nueva Receta' />
        {loading && <p>Cargando...</p>}
        {recipes.map((recipe: any) => (
          <Recipe>
            <h5>{recipe.name}</h5>
            <Link className="btn btn-sm btn-warning" to={routes.recipes.viewRecipe(recipe.id)}>Ver Receta</Link>
          </Recipe>
        ))}
      </Container>
    </FullPageLayout>
  )
}

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
`
const Recipe = styled.div`
  border: 1px solid #ccc;
  padding: .8rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

import React from 'react'
import { FullPageLayout } from '../../components/FullPageLayout'
import PageHeader from '../../components/PageHeader'
import PageTitle from '../../components/PageTitle'
import { Link, useHistory, useParams } from 'react-router-dom'
import routes from '../../helpers/routes'
import { axiosInstance } from '../../utils/axios'
import { Button, Table } from 'react-bootstrap'
import useModal from '../../hooks/useModal'
import AddRecipeItemModal from '../recipes/components/AddRecipeItemModal'
import { Container } from '../common/modules.styles'
import { toLocalDateFormat } from '../../utils/functions'
import toast from 'react-hot-toast'

export default function CustomizeProduction() {
  const addModal = useModal();
  const history = useHistory();
  const { id: productionId } = useParams<{ id: string }>();
  const [production, setProduction] = React.useState<any>(null);
  const [items, setItems] = React.useState<any>([]);
  const [aceptChanges, setAceptChanges] = React.useState(0);

  const handleAceptChanges = () => {
    setAceptChanges(aceptChanges === 0 ? 1 : 0);
  }

  // traer la producción con el id productionId con useEffect y setearla en el estado production
  React.useEffect(() => {
    // fetch
    (async () => {
      try {
        const response = await axiosInstance(`/productions/get-production/${productionId}`)
        const data = await response.data;
        setProduction(data);
        setItems(data.recipe.items);
      } catch (error) {
        console.error(error)
      }
    })()
  }, [productionId])

  const addItem = (item: any) => {
    setItems([...items, item]);
  }

  const removeItem = (item: any) => {
    setItems(items.filter((i: any) => i !== item));
  }

  const handleSubmitChanges = () => {
    console.log(items);
    axiosInstance.post('/productions/customize-production', { productionId, items })
      .then(response => {
        console.log(response.data);
        toast.success('Producción completada');
        history.push("..");
      }).catch(error => {
        toast.error(error.response.data);
      })
  }


  return (
    <FullPageLayout>
      <Container>
        <PageHeader>
          <PageTitle title="Personalizar Producción" />
          <Link className="btn btn-secondary" to={routes.productions.index}>Volver</Link>
        </PageHeader>
        <div>
          {production &&
            <div>
              <h3>{production.recipe.name}</h3>
              <p>Fecha de producción: {toLocalDateFormat(production.date)}</p>
              <div className="mt-3" style={{ display: 'flex', justifyContent: "space-between", alignContent: "center" }}>
                <h3>Componentes de la receta</h3>
                <Button size="sm" variant="outline-primary" onClick={() => addModal.toogleModal()}>Agergar insumo</Button>
              </div>
              <Table bordered size="sm" className="mt-3">
                <thead>
                  <tr>
                    <th>Insumo</th>
                    <th>Cantidad</th>
                    <th>Unidad</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{item.input.name}</td>
                      <td>{item.amount}</td>
                      <td>{item.unit}</td>
                      <td>
                        <div className="d-grid">
                          <Button size="sm" variant="outline-danger" onClick={() => removeItem(item)}>X</Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <AddRecipeItemModal show={addModal.isOpenModal} toogle={addModal.toogleModal} addFunction={addItem} />
            </div>
          }
        </div>
        <div>
          {/* agregar switch para indicar que se realizaron los cambios, si se activa este switch recien se puede hacer click en el botón para guardar los caambios */}
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value={aceptChanges} onChange={handleAceptChanges} />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Cambios realizados</label>
          </div>
          <Button className="mt-1" variant="primary" disabled={!aceptChanges} onClick={handleSubmitChanges}>Guardar cambios</Button>
        </div>
      </Container>
    </FullPageLayout>
  )
}

import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { FullPageLayout } from "../../components/FullPageLayout";
import PrivateRoute from "../../routers/PrivateRoute";
import { ModuleContainer, NavItem, Sidebar } from "../common/modules.styles";
import BoxDetails from "./BoxDetails";
import BoxesPage from "./BoxesPage";
import BoxesSettings from "./BoxesSettings";
import BoxHistoryReport from "./BoxHistoryReport";
import MoneyProvider from "./money.provider";

function MoneyPage() {
    let { path, url } = useRouteMatch();

    return (
        <FullPageLayout>
            <ModuleContainer>
                <Sidebar>
                    <h1 style={{ textAlign: 'center' }}>Finanzas</h1>
                    <NavItem to={url}>Cajas</NavItem>
                    <NavItem to={`${url}/settings`}>Configuración</NavItem>
                    <NavItem to={`${url}/history`}>Histórico</NavItem>
                </Sidebar>
                <React.Fragment>
                    <MoneyProvider>
                        <Switch>
                            <PrivateRoute exact path={path} component={BoxesPage} />
                            <PrivateRoute path={`${path}/history`} component={BoxHistoryReport} />
                            <PrivateRoute path={`${path}/settings`} component={BoxesSettings} />
                            <PrivateRoute path={`${path}/:id`} component={BoxDetails} />
                        </Switch>
                    </MoneyProvider>
                </React.Fragment>
            </ModuleContainer>
        </FullPageLayout>
    )
}

export default MoneyPage;
import React from "react";
import { Table } from "react-bootstrap";
import { axiosInstance } from "../../utils/axios";
import { FlexCenterContainer, TableCaption, TableContainer } from "./styleds";

function useData() {
  const [inputs, setInputs] = React.useState<any>(null);

  async function getData() {
    try {
      const response = await axiosInstance.get('dashboards/get-inputs-stock');
      setInputs(response.data.inputs);
    } catch (error) {
      alert(error);
    }
  }

  React.useEffect(() => { getData() }, []);

  return { inputs }
}

export default function StockOfInputs() {
  const { inputs } = useData();

  if (!inputs) return <>
    <FlexCenterContainer>Cargando datos...</FlexCenterContainer>
  </>;

  return (
    <>
      <TableCaption>
        <div>Stock de insumos</div>
      </TableCaption>
      <TableContainer>
        <Table bordered size="sm" responsive>
          <thead>
            <tr>
              <th>Insumo</th>
              <th>Stock</th>
              <th>Gasto mensual</th>
            </tr>
          </thead>
          <tbody>
            {inputs && inputs.map((input: any, index: number) => (
              <tr key={index}>
                <td>{input.name}</td>
                <td>{input.stock}</td>
                <td>{input.minimumStock}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
}
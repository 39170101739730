import React from "react";
import { Form } from "react-bootstrap";

interface SelectElementProps {
  name: string;
  label: string;
  arrayData: { id: string, value: string }[];
  value: string;
  setData: React.Dispatch<React.SetStateAction<string>>
}

function SelectElementComponent({ name, label, arrayData, value, setData }: SelectElementProps): JSX.Element {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}:</Form.Label>
      <Form.Control as="select" value={value} onChange={(e) => setData(e.target.value)}>
        <option value="all">Todos</option>
        {arrayData.map(el => (
          <option key={el.id} value={el.id}>{el.value}</option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}

export default SelectElementComponent;

import React from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import routes from '../../../../helpers/routes';

export const Header = ({ id }: { id: string; }) => {
  const history = useHistory();

  const backToOrder = () => {
    history.push(routes.viewOrder(id));
  };

  return (
    <>
      <Alert variant='info' className='mb-0'>
        <div onClick={backToOrder} style={{ cursor: 'pointer' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
          </svg>
          Regresar a la orden de venta
        </div>
      </Alert>
      <div className="d-flex justify-content-center"><div className="fs-1">Emisión de nota de crédito</div></div>
    </>
  );

};

import React from 'react'
import { useForm } from 'react-hook-form';
import { Vendor } from './useVendors'

interface Props {
  vendor?: Vendor;
  toggle: () => void;
  submitFunction: (v: Vendor) => void;
}

const VendorForm = ({ vendor, toggle, submitFunction }: Props) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      firstName: vendor ? vendor.firstName : '',
      lastName: vendor ? vendor.lastName : '',
      documentNumber: vendor ? vendor.documentNumber : '',
      email: vendor ? vendor.email : '',
    }
  });

  const onSubmit = (data: any) => {
    const vendor = {
      firstName: data.firstName,
      lastName: data.lastName,
      documentNumber: data.documentNumber,
      email: data.email,
    }
    submitFunction(vendor);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-2">
        <label htmlFor="firstName" className='form-label'>Nombres</label>
        <input type="text" className='form-control' id="firstName" {...register('firstName')} required />
      </div>
      <div className="mt-2">
        <label htmlFor="lastName" className='form-label'>Apellidos</label>
        <input type="text" className='form-control' id="lastName" {...register('lastName')} required />
      </div>
      <div className="mt-2">
        <label htmlFor="documentNumber" className='form-label'>DNI</label>
        <input type="text" className='form-control' id="documentNumber" {...register('documentNumber')} required />
      </div>
      <div className="mt-2">
        <label htmlFor="email" className='form-label'>Correo electrónico</label>
        <input type="email" className='form-control' id="email" {...register('email')} required />
      </div>
      <div className="d-flex flex-wrap mt-2 gap-2">
        <button className='btn btn-secondary flex-grow-1' onClick={() => toggle()}>Cancelar</button>
        <button className='btn btn-primary flex-grow-1' type='submit'>{vendor ? 'Editar' : 'Añadir'}</button>
      </div>
    </form>
  )
}

export default VendorForm
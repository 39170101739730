import React from 'react'
import styled from "styled-components";
import EgresosComponent from './EgresosComponent';
import IngresosComponent from './IngresosComponent';

const Conatiner = styled.div`
  grid-area: dashboard;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
`

function DashboardComponent() {

  return (
    <Conatiner>
      <IngresosComponent />
      <EgresosComponent />
    </Conatiner>
  )
}
export default DashboardComponent
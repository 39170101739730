import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { axiosInstance } from '../../../../utils/axios';
import { BorderedSection, BorderedSectionBody, BorderedSectionBodyItem, BorderedSectionTitle } from './styleds';

function useCarriers() {
  const [carriers, setCarriers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    axiosInstance.get('/carriers').then(res => res.data)
      .then(data => {
        setCarriers(data.carriers)
        setLoading(false)
      }).catch(err => setError(err))
  }, []);

  return { carriers, loading, error }
}

export function TrasportistaSection(props: any) {
  const { carriers, loading, error } = useCarriers();

  const vehicles = useMemo(() => {
    const array = carriers.filter((t: any) => t.id.toString() === props.transportista);
    return array[0] ? array[0].vehicles : [];
  }, [carriers, props.transportista])

  const drivers = useMemo(() => {
    const array = carriers.filter((t: any) => t.id.toString() === props.transportista);
    return array[0] ? array[0].drivers : [];
  }, [carriers, props.transportista])

  const handleChange = (e: any) => {
    props.setTransportista(e.target.value);
    props.setVehiculo("");
    props.setConductor("");
  }

  if (loading) return <h5>Cargando datos...</h5>
  if (error) return <h5>Error al obtener los datos del servidor</h5>
  return (
    <BorderedSection>
      <BorderedSectionTitle>Datos del transportista</BorderedSectionTitle>
      <BorderedSectionBody>
        <BorderedSectionBodyItem>
          <Form.Group controlId='transportista'>
            <Form.Label>Transportista</Form.Label>
            <Form.Select value={props.transportista} onChange={handleChange} required>
              <option value="">Seleccionar uno</option>
              {carriers.map((item: any, index: number) => (
                <option key={index} value={item.id}>{item.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </BorderedSectionBodyItem>
        <BorderedSectionBodyItem>
          <Form.Group controlId='placa'>
            <Form.Label>Vehículo</Form.Label>
            <Form.Select value={props.vehiculo} onChange={(e: any) => props.setVehiculo(e.target.value)} required>
              <option value="">Seleccionar uno</option>
              {vehicles.map((item: any, index: number) => (
                <option key={index} value={item.id}>{item.placa}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </BorderedSectionBodyItem>
        <BorderedSectionBodyItem>
          <Form.Group controlId='conductor'>
            <Form.Label>Conductor</Form.Label>
            <Form.Select value={props.conductor} onChange={(e: any) => props.setConductor(e.target.value)} required>
              <option value="">Seleccionar uno</option>
              {drivers.map((item: any, index: number) => (
                <option key={index} value={item.id}>{item.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </BorderedSectionBodyItem>
      </BorderedSectionBody>
    </BorderedSection>
  );
}

import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { PageLayout } from '../../components/PageLayout'
import routes from '../../helpers/routes'
import { updateGroup as updateGroupApiFunction } from '../../utils/api/products'
import { GroupForm } from './GroupForm'
import { useProductGroup } from './useProductGroups'

function UpdateGroup() {
  let history = useHistory<any>()
  const { id } = useParams<any>()
  const { group } = useProductGroup(id)

  const updateGroup = (edited: any) => {
    updateGroupApiFunction(id, edited).then(() => {
      toast.success("Actualizado correctamente");
      history.push(routes.product_groups)
    }).catch((err) => {
      console.log(err)
    })
  }

  if (!group) {
    return <h1>Cargando</h1>
  }

  return (
    <PageLayout>
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: routes.product_groups }} linkAs={Link}>
          Grupos de Productos
        </Breadcrumb.Item>
        <Breadcrumb.Item linkProps={{ to: routes.product_group(id) }} linkAs={Link}>
          Detalle del grupo
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Edición</Breadcrumb.Item>
      </Breadcrumb>
      <GroupForm group={group} submitFunction={updateGroup} />

    </PageLayout>
  )
}

export default UpdateGroup

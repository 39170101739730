import React from "react";
import useSWR from "swr";
import SelectElementComponent from "./SelectElementComponent";
interface Props {
  value: string;
  selector: React.Dispatch<React.SetStateAction<string>>;
}

const useData = () => {
  const { data } = useSWR<{ id: string, value: string }[]>('/reports/history/vendors-array');
  return {
    vendorsList: data?.concat({ id: 'oficina', value: 'Oficina' })
  }
}

export function VendorSelection({ value, selector }: Props) {
  const { vendorsList } = useData();
  return (
    <>
      {vendorsList &&
        <SelectElementComponent
          name="vendor"
          label="Vendedor"
          arrayData={vendorsList}
          value={value}
          setData={selector}
        />
      }
    </>
  );
}

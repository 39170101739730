import { useState } from "react"
import { useHistory } from "react-router-dom"
import TableList from "../../../../components/TableList1"
import routes from "../../../../helpers/routes"
import useModal from "../../../../hooks/useModal"
import { formatOrderState, formatMoney, toLocalDateFormat } from "../../../../utils/functions"
import ConfirmOrderModal from "../ConfirmOrderModal"
import { ConfirmButton, ViewButton } from "../../../common/styled"

export default function ForConfirmTab({ orders, refresh }: any) {
  const [order, setOrder] = useState(null)
  const { isOpenModal, toogleModal } = useModal()

  const handleConfirm = (order: any) => {
    setOrder(order)
    toogleModal()
  }

  let history = useHistory<any>()
  const headers = ['Fecha', 'Cliente', 'Importe Total', 'Estado', ''];
  const body = orders?.map((order: any) => (
    <tr key={order.id}>
      <td>{toLocalDateFormat(order.saleDate)}</td>
      <td>{order.customer.name}</td>
      <td>{formatMoney(order.total)}</td>
      <td>{formatOrderState(order)}</td>
      <td className="d-flex gap-1">
        <ViewButton onClick={() => history.push(routes.viewOrder(order.id))}><i className="bi bi-eye"></i></ViewButton>
        <ConfirmButton onClick={() => handleConfirm(order)} title="Confirmar orden"><i className="bi bi-cart-check"></i></ConfirmButton>
      </td>
    </tr>
  ))

  return (
    <>
      <TableList headers={headers} body={body} />
      <ConfirmOrderModal show={isOpenModal} close={toogleModal} order={order} refresh={refresh} />
    </>
  )
}
import React from 'react';
import { Button, Table } from 'react-bootstrap';

export function InvoiceItems(props: any) {
  return (<div className='mt-2'>Detalle de la venta:
    <Table size="sm border">
      <thead>
        <tr>
          <th>Producto</th>
          <th>Precio</th>
          <th>Cantidad</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.items.map((item: any, index: any) => <tr key={index}>
          <td>{item.group.name}</td>
          <td>{item.unitPrice}</td>
          <td>{item.amount}</td>
          <td>
            <div className="d-grid">
              <Button size="sm" variant="outline-danger" onClick={() => props.removeItem(item)}>X</Button>
            </div>
          </td>
        </tr>)}
        <tr>
          <td>
            <div className="d-grid">
              <Button size="sm" variant="outline-primary" onClick={props.toogleModal}>Agergar producto</Button>
            </div>
          </td>
          <td colSpan={3}></td>
        </tr>
      </tbody>
    </Table>
  </div>);
}

import writeXlsxFile from "write-excel-file";
import { axiosInstance } from "../../../utils/axios";
import { detailedOrdersDataSchema, detailedSoldProductsDataSchema } from "./schemas";

export async function getDetailedOrdersDataReport(desde: string, hasta: string) {
  const res = await axiosInstance.get("/data/detailed-orders", { params: { since: desde, until: hasta } });
  return await writeXlsxFile(res.data, { schema: detailedOrdersDataSchema, fileName: "orders-data.xlsx" });
}

export async function getDetailedSoldProductsDataReport(desde: string, hasta: string) {
  const res = await axiosInstance.get("/data/detailed-sold-products", { params: { since: desde, until: hasta } });
  return await writeXlsxFile(res.data, { schema: detailedSoldProductsDataSchema, fileName: "sold-products-data.xlsx" });
}
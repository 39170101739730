import { useState } from 'react'
import useSWR from 'swr'
import { axiosInstance } from '../../../utils/axios';

const useCarriers = () => {
  const { data, error, mutate } = useSWR('/carriers');
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);
  const toggleAdding = () => setAdding(!adding);
  const toggleEditing = () => setEditing(!editing);
  const [carrier, setCarrier] = useState();

  const addCarrier = async (carrier: any) => {
    try {
      await axiosInstance.post('/carriers', carrier);
      mutate();
      toggleAdding();
    } catch (e) {
      console.log(e)
    }
  }

  const handleEdit = (carrier: any) => {
    setCarrier(carrier);
    toggleEditing();
  }

  const editCarrier = async (data: any) => {
    try {
      await axiosInstance.patch('/carriers', data);
      mutate();
      toggleEditing();
    } catch (e) {
      console.log(e)
    }
  }

  return {
    carriers: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    adding,
    editing,
    toggleAdding,
    toggleEditing,
    addCarrier,
    editCarrier,
    carrier,
    handleEdit
  }
}

export default useCarriers
import React from 'react'
import Layout from '../compartido/Layout'

function Clientes() {
  return (
    <Layout moduleName="clientes">
      hola
    </Layout>
  )
}

export default Clientes
import { AxiosResponse } from "axios";

function base64ToArrayBuffer(base64: string) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function getTicketLink(res: AxiosResponse<any>) {
  const u8 = new Uint8Array(res.data.buffer.data);
  const b64 = Buffer.from(u8).toString('base64');
  const sampleArr = base64ToArrayBuffer(b64);
  var blob = new Blob([sampleArr], { type: "application/pdf" });
  return window.URL.createObjectURL(blob);
}
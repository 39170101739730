import React from 'react'
import { axiosInstance } from '../../utils/axios';
import { FlexCenterContainer, TableCaption } from './styleds';
import SummaryItem from './SummaryItem';

function useData() {
  const [data, setData] = React.useState<any>(null);

  async function getData() {
    try {
      const response = await axiosInstance.get('dashboards/get-vendors-summary-sells');
      setData(response.data);
    } catch (error) {
      alert(error);
    }
  }

  React.useEffect(() => { getData() }, []);

  return { data }
}

function SummarySells() {
  const { data } = useData();

  if (!data) return <><FlexCenterContainer>Cargando datos...</FlexCenterContainer></>;

  return (
    <>
      <TableCaption>
        <div>Ventas del mes / ventas del mes pasado</div>
      </TableCaption>
      {data.map((obj: any, index: number) => (<SummaryItem key={index} item={obj} />))}
    </>
  );
}

export default SummarySells
import { useMemo, useState } from "react";
import useSWR from "swr";
import { confirmSellOrder, deliverySellOrder, emitSellOrder, paySellOrder } from "../../utils/api/sellOrders";

export const useOrders = () => {
  const { data, error, mutate } = useSWR('/orders');
  const [activeState, setactiveState] = useState("created");

  const states = useMemo(() => [
    { value: "created", text: "Por Confirmar" },
    { value: "confirmed", text: "Confirmadas" },
    { value: "delivered", text: "Entregadas" },
    { value: "finished", text: "Terminadas" }], []);

  const activeStateText = useMemo(() => {
    const obj = states.find(s => s.value === activeState);
    return obj ? obj.text : '';
  }, [states, activeState]);

  const confirmedOrders = useMemo(() => data && data.filter((o: { state: string; nulled: boolean; }) => o.state === activeState), [data, activeState]);

  const confirmOrder = async (data: any) => {
    const { orderType, deliveryDate, order } = data
    try {
      if (orderType === 'FACTURA' && order['customer'].documentType !== 'RUC') {
        throw Error('El cliente debe tener RUC para poder emitir una factura')
      }
      if (orderType === 'BOLETA' && order['customer'].documentType !== 'DNI') {
        throw Error('El cliente debe registrar DNI para poder emitir una boleta')
      }
      await confirmSellOrder(orderType, deliveryDate, order.id)
    } catch (error) {
      throw (error)
    }
  }

  const deliveryOrder = async (order: any) => {
    try {
      await deliverySellOrder(order.id)
    } catch (error) {
      throw (error)
    }
  }

  const payOrder = async (data: any) => {
    const { order, amount, boxId, date } = data
    try {
      await paySellOrder(order.id, amount, boxId, date)
    } catch (error) {
      throw (error)
    }
  }

  const emitOrder = async (order: any) => {
    try {
      await emitSellOrder(order.id)
    } catch (error) {
      console.log(error)
      throw (error)
    }
  }

  return {
    orders: data,
    isLoading: !error && !data,
    isError: error,
    states,
    setactiveState,
    activeStateText,
    confirmedOrders,
    confirmOrder,
    mutate,
    deliveryOrder,
    payOrder,
    emitOrder
  }
}
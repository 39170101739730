import React from 'react';
import styled from 'styled-components';
import { Container } from '../../common/modules.styles';

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
`

interface Props {
  moduleName: String
  children: React.ReactChild
}

function Layout(props: Props) {
  return (
    <Container>
      <Title>Administración de {props.moduleName}</Title>
      {props.children}
    </Container>
  );
}

export default Layout;
import React, { useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';
import useInputs, { Input } from '../../hooks/useInputs';

const InputMovementModal = ({ type, show, toggle, submitFunction }: any) => {
  const { register, handleSubmit, reset } = useForm();
  const { inputs } = useInputs();
  const [selectedInput, setSelectedInput] = useState<Input[]>([]);

  function onSubmit(data: any) {
    submitFunction({
      ...data,
      inputId: selectedInput[0].id
    });
    close();
  }

  function close() {
    setSelectedInput([])
    reset();
    toggle();
  }

  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{type} de insumo</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId='input'>
                <Form.Label>Insumo</Form.Label>
                <Typeahead
                  id="input-selected"
                  labelKey="name"
                  onChange={setSelectedInput}
                  options={inputs}
                  selected={selectedInput}
                  placeholder="Buscar insumo"
                  inputProps={{ required: true }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='amount'>
                <Form.Label>Cantidad</Form.Label>
                <Form.Control type="number" min={0.01} step={0.01} {...register('amount')} required></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='unit'>
                <Form.Label>Unidades</Form.Label>
                <Form.Select {...register('unit')} required>
                  <option value="">Selecciona la unidad</option>
                  <option value="kg">Kilogramos</option>
                  <option value="g">Gramos</option>
                  <option value="mg">Mili gramos</option>
                  <option value="gal">Galones</option>
                  <option value="l">Litros</option>
                  <option value="ml">Mili litro</option>
                  <option value="und">Unidad</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Form.Group controlId='reason'>
              <Form.Label>Motivo</Form.Label>
              <Form.Control {...register('reason')} required></Form.Control>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary' onClick={close}>Cancelar</button>
          <button type='submit' className='btn btn-primary'>Registrar movimiento</button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default InputMovementModal
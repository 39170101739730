import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import { PageLayout } from "../../../components/PageLayout";
import TableList from "../../../components/TableList";
import routes from "../../../helpers/routes";
import { useProductions } from "../../../hooks/useProductions";
import { toLocalDateFormat } from "../../../utils/functions";
import ProductProductionOrderForm from "./Form";

export default function ProductProductions() {
  let history = useHistory();
  const [creating, setCreating] = useState(false);
  const [showInCourse, setShowInCourse] = useState(false);
  const { orders, isLoading, addProductionOrder } = useProductions('products');

  const filteredOrders = useMemo(() => {
    if (showInCourse) return orders.filter(order => order.state === 'draft');
    else return orders;
  }, [orders, showInCourse]);

  const toogle = () => setCreating(!creating);

  const createFunction = async (order: any) => {
    try {
      const newOrder = await addProductionOrder(order);
      toast.success("Orden registrada");
      history.push(routes.production.viewProductProduction(newOrder.id));
    } catch (e) {
      alert(JSON.stringify(e, null, 2));
    }
  }

  const headers = ["ID", "Fecha", "Receta", "Producción", "Estado", "Acciones"];

  if (isLoading) {
    return <PageLayout>Cargando...</PageLayout>
  }

  return (
    <PageLayout>
      {!creating &&
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>Ordenes de producción de productos</h2>
            <Button variant="primary" onClick={toogle}>Agregar orden de producción</Button>
          </div>
          <div>
            <input type="checkbox" name="filter" id="filter" className="form-check-input" checked={showInCourse} onChange={(e) => setShowInCourse(e.target.checked)} />
            <label className="form-check-label ms-1" htmlFor="filter">
              Mostrar órdenes en curso
            </label>
          </div>
          <TableList headers={headers} items={filteredOrders} searchParams={["date", "recipeName"]}>
            {(order) => (
              <>
                <td>{order.id}</td>
                <td>{toLocalDateFormat(order.date)}</td>
                <td>{order.recipeName}</td>
                <td>{order.quantity} unidades</td>
                <td>{order.state}</td>
                <td className="d-flex gap-1 justify-content-center">
                  <Link to={routes.production.viewProductProduction(order.id)}>
                    <button className="btn btn-primary btn-sm">Ver detalle</button>
                  </Link>
                </td>
              </>
            )}
          </TableList>
        </>
      }
      {creating &&
        <>
          <ProductProductionOrderForm submitFunction={createFunction} toogle={toogle} />
        </>
      }
    </PageLayout>
  )
}
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { ClickPill, Pill, RouteItem, RouteItemBody, RouteItemTitle } from '../../../../components/RouteItemComponent'
import useModal from '../../../../hooks/useModal'
import { deliverySellOrder, emitSellOrder } from '../../../../utils/api/sellOrders'
import { formatMoney, toLocalDateFormat } from '../../../../utils/functions'
import { EmitModal } from '../../orders/EmitModal'
import ConfirmModal from '../../../../components/ConfirmModal'
import { getInvoice, getTicket, getTicketForInvoice } from '../../../../services/sell_order'
import { Order } from '../../../../providers/SellOrdersProvider'
import { useHistory } from 'react-router-dom'
import routes from '../../../../helpers/routes'
import useAuth from '../../../../hooks/useAuth'

export const CPill = styled.div`
    background: #F5EBD7;
    color: black;
    font-weight: 600;
    padding: .10em .5em;
    border-radius: 1em;
    font-size: .8rem;
`
interface Props {
  order: Order
  refresh: () => void
}

function ConfirmedOrderItem({ order, refresh }: Props) {
  const { user } = useAuth();
  const history = useHistory();
  const infoModal = useModal();
  const [loading, setLoading] = useState(false);
  const deliveryModal = useModal();
  const emitModal = useModal();

  const deliveryOrder = async () => {
    try {
      await deliverySellOrder(order.id.toString())
      toast.success("Orden entregada");
      refresh()
      deliveryModal.toogleModal()
    } catch (error: any) {
      deliveryModal.toogleModal();
      toast.error(error.response.data);
    }
  }
  const _emitSellOrder = async () => {
    try {
      setLoading(true)
      await emitSellOrder(order.id.toString());
      toast.success("Documento electronico emitido");
      refresh()
      setLoading(false)
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data);
    }
  }

  const print = async () => {
    try {
      let link: string = order.futureDocument !== 'PROFORMA' ? await getInvoice(order.id.toString()) : await getTicket(order.id);
      window.open(link, "_blank");
    } catch (error) {
      alert(JSON.stringify(error, null, 2))
    }
  }

  const printTicket = async () => {
    try {
      let link: string = await getTicketForInvoice(order.id);
      window.open(link, "_blank");
    } catch (error) {
      alert(JSON.stringify(error, null, 2))
    }
  }

  return (
    <>
      {loading && <h3>Emitiendo documento...</h3>}
      {!loading &&
        <>
          <RouteItem>
            <RouteItemTitle>
              <Pill>Nº {order.id}</Pill>
              <Pill>{order.documentNumber}</Pill>
              <Pill>{order.user.username}</Pill>
              <Pill>{toLocalDateFormat(order.deliveryDate)}</Pill>
              <Pill>{formatMoney(order.total)}</Pill>
              {order.futureDocument === 'PROFORMA' && user && user.role === 'admin' &&
                <ClickPill onClick={deliveryModal.toogleModal}>Despachar</ClickPill>
              }
              {order.futureDocument !== 'PROFORMA' && !order.emited &&
                <ClickPill onClick={emitModal.toogleModal}>{order.futureDocument === 'FACTURA' ? 'Emitir Factura' : 'Emitir Boleta'}</ClickPill>
              }
              {order.futureDocument !== 'PROFORMA' && order.emited && user && user.role === 'admin' &&
                <ClickPill onClick={deliveryModal.toogleModal}>Despachar</ClickPill>
              }
            </RouteItemTitle>
            <RouteItemBody onClick={infoModal.toogleModal}>
              {order.customer.name}
            </RouteItemBody>
          </RouteItem>
          <ConfirmModal
            show={deliveryModal.isOpenModal}
            close={deliveryModal.toogleModal}
            primaryText="Despacho de orden de venta"
            secondaryText="¿Confirma que se despachó la orden de venta? Los productos serán descontados del stock."
            submitButtonText="Despachar"
            submitingButtonText="Despachando..."
            handleFunction={deliveryOrder}
          />
          <EmitModal
            show={emitModal.isOpenModal}
            toggle={emitModal.toogleModal}
            order={order}
            refresh={refresh}
            handleFunction={_emitSellOrder}
          />
          <Modal show={infoModal.isOpenModal} onHide={infoModal.toogleModal}>
            <Modal.Header closeButton>
              <div style={{ fontWeight: 600 }}>Orden Nº {order.id}</div>
            </Modal.Header>
            <Modal.Body>
              <div>{order.futureDocument}</div>
              <div><b>Vendedor:</b> {order.user.username}</div>
              <div><b>Cliente:</b> {order.customer.name}</div>
              <div><b>Referencia:</b> {order.customer.reference}</div>
              <div><b>Total:</b> {formatMoney(order.total)}</div>
              <div>Detalle de la venta:</div>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid' }}>Producto</th>
                    <th style={{ border: '1px solid' }}>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items.map((item, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid' }}>{item.product_group.name}</td>
                      <td style={{ border: '1px solid' }}>{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              {!order.payed &&
                <button className='btn btn-info' onClick={() => history.push(routes.ventas.ver(order.id))}>Ver Venta</button>
              }
              {
                order.futureDocument !== 'PROFORMA' && order.emited &&
                <button className='btn btn-primary' onClick={printTicket}>Imprimir ticket</button>
              }
              {((order.futureDocument === 'PROFORMA') || (order.futureDocument !== 'PROFORMA' && order.emited)) &&
                <button className='btn btn-primary' onClick={print}>Imprimir documento</button>
              }
            </Modal.Footer>
          </Modal>
        </>
      }
    </>
  )
}

export default ConfirmedOrderItem
import React, { useMemo } from 'react'
import useSWR from 'swr';
import { formatMoney, toLocalDateFormat } from '../../utils/functions';
import RouteItemComponent from '../RouteItemComponent';

const useData = () => {
  const { data, error } = useSWR('/dashboards/vendor-data');
  const deuda: number = useMemo(() => {
    return data && data.reduce((prev: any, curr: any) => {
      if (typeof prev === 'number') {
        return prev + Number(curr.toPay)
      } else {
        return Number(Number(prev.toPay) + Number(curr.toPay))
      }
    })
  }, [data]);
  return {
    data,
    deuda,
    isLoading: !data && !error,
    isError: error
  }
}

function CuentasPorCobrar() {
  const { data, deuda, isLoading, isError } = useData();
  if (isError) {
    return <h1>Error al intentrar traer datos del servidor</h1>
  }
  if (isLoading) {
    return <h1>Cargando datos...</h1>
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', color: 'darkblue', fontWeight: 600, justifyContent: 'center', textTransform: 'uppercase' }}>cuentas por cobrar</div>
      <div style={{ border: '1px solid red', padding: '.25em .5em', borderRadius: '1em' }}>
        <div style={{ display: 'flex', fontWeight: 600, justifyContent: 'space-between', alignItems: 'center', padding: '0 1em' }}>
          <span>{data.length} ventas</span>
          <span>S/. {deuda.toFixed(2)}</span>
        </div>
        {data.map((order: any, index: number) => (
          <RouteItemComponent
            key={index}
            titles={[`Nº ${order.id}`, order.documentNumber, toLocalDateFormat(order.saleDate), formatMoney(order.toPay)]}
            bodyText={order.customerName}
          />
        ))}
      </div>
    </>
  );
}

export default CuentasPorCobrar
import React from 'react'
import { Table } from 'react-bootstrap'
import DriverModalForm from './DriverModalForm'
import { Container } from './styleds'

const Drivers = ({ show, submitFunction, drivers, removeFunction, toggle }: any) => {

  return (
    <>
      <Container>
        <h6 className='mb-0'>Conductores</h6>
        {drivers.length === 0 && null}
        {drivers.length !== 0 &&
          <Table bordered size='sm'>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Documento</th>
                <th>Número de documento</th>
                <th style={{ textAlign: 'center' }}>Quitar</th>
              </tr>
            </thead>
            <tbody>
              {drivers.map((d: any, index: number) => (
                <tr key={index}>
                  <td>{d.name}</td>
                  <td>{d.documentType}</td>
                  <td>{d.documentNumber}</td>
                  <td>
                    <div className="d-grid">
                      <button type="button" className='btn btn-sm btn-outline-danger' onClick={() => removeFunction(index)}>X</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
        <button type="button" className='btn btn-info btn-sm' onClick={() => toggle()}>Agregar conductor</button>
      </Container>
      <DriverModalForm show={show} toggle={toggle} submitFunction={submitFunction} />
    </>
  )
}

export default Drivers
import useSWR from "swr"
import { axiosInstance } from "../utils/axios";

export interface ProductionOrder {
  id: number
  date: string
  recipeName: string
  recipeQuantity: number
  recipeUnit: string
  quantity: number
  state: string
}

interface OrderParams {
  order: {
    recipeId: number
    quantity: number
  }
}

interface UseProductionsProps {
  orders: ProductionOrder[]
  isLoading: boolean
  isError: any
  mutate: any
  addProductionOrder: (order: OrderParams) => any
}

export const useProductions = (productionType: string): UseProductionsProps => {
  const { data, error, mutate } = useSWR(`/productions/${productionType}`);

  async function addProductionOrder(order: OrderParams) {
    try {
      const res = await axiosInstance.post(`/productions/${productionType}`, order)
      return res.data;
    } catch (e) {
      throw (e)
    }
  }

  return {
    orders: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    addProductionOrder
  }
}

export const useProductionHelpers = () => {
  async function checkInputAvailability(items: any) {
    try {
      const res = await axiosInstance.post('/productions/checkAvailability', items)
      return res.data;
    } catch (e) {
      throw (e)
    }
  }

  async function requestProduction(orderId: number, productionType: string) {
    try {
      await axiosInstance.post(`/productions/${productionType}/produce/${orderId}`);
    } catch (e) {
      throw (e)
    }
  }

  return { checkInputAvailability, requestProduction }
}

export const useProductionOrder = (orderId: number, productionType: string) => {
  const { data, error, mutate } = useSWR(`/productions/${productionType}/${orderId}`);

  async function updateProductionOrder(order: any) {
    try {
      const res = await axiosInstance.patch(`/productions/${productionType}/${orderId}`, order);
      return res.data;
    } catch (e) {
      throw (e)
    }
  }

  return {
    order: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    updateProductionOrder
  }
}
import ConfirmedOrderItem from "./ConfirmedOrderItem"
import DeliveredOrderItem from "./DeliveredOrderItem"
import FinishedOrderItem from "./FinishedOrderItem"
import ForConfirmOrderItem from "./ForConfirmOrderItem"

function OrdersList({ orders, setLoading, boxes }: any) {

  return (
    <div className='d-flex flex-column mt-2'>
      {orders.map((o: any, i: number) => {
        if (o.state === 'created') {
          return <ForConfirmOrderItem order={o} key={i} />
        }
        if (o.state === 'confirmed') {
          return <ConfirmedOrderItem order={o} setLoading={setLoading} key={i} />
        }
        if (o.state === 'delivered') {
          return <DeliveredOrderItem order={o} key={i} boxes={boxes} />
        }
        if (o.state === 'finished') {
          return <FinishedOrderItem order={o} key={i} />
        }
        return null
      })}
    </div>
  )
}

export default OrdersList
import React from 'react'
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { getSummaryDataFromPeriodAndByVendor } from './functions';
import { SelectWithOutLabel } from '../../../components/SelectWithOutLabel';
import { periods } from './common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const FormDiv = styled.div`
  display: flex;
  gap: 1rem;
`

const INIT_YEAR = 2022;

type SearchFormProps = {
  setFunction: (data: SummarySellsByPeriodAndByVendorProps) => void;
}
function SearchForm({ setFunction }: SearchFormProps) {
  const { handleSubmit, register } = useForm();
  const [years, setYears] = React.useState<{ value: string, label: string }[]>([]);

  React.useEffect(() => {
    let year = dayjs().year();
    let arrayOfYears = [];
    while (year >= INIT_YEAR) {
      arrayOfYears.push({
        value: year.toString(),
        label: year.toString(),
      });
      year--;
    }
    setYears(arrayOfYears);
  }, []);

  async function onSubmit(data: any) {
    try {
      const response = await getSummaryDataFromPeriodAndByVendor({
        vendor: data.vendor,
        month: data.month,
        year: data.year,
      });
      console.log(response);
      /* function({
        summaryBlack: response.summaryBlack,
        summaryWhite: response.summaryWhite,
        blackAmount: response.blackAmount,
        whiteAmount: response.whiteAmount,
      }); */
    } catch (error: any) {
      alert(error.message);
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormDiv>
          <SelectWithOutLabel
            name='month'
            text='Selecciona el mes'
            options={periods}
            register={register}
          />
          <SelectWithOutLabel
            name='year'
            text='Selecciona el año'
            options={years}
            register={register}
          />
          <button type="submit">Buscar ventas</button>
        </FormDiv>
      </form>
    </div>
  )
}

type Summary = {
  teoricAmount: number;
  totalSells: number;
  totalPayed: number;
}

export type SummarySellsByPeriodAndByVendorProps = {
  summaryBlack: Summary;
  summaryWhite: Summary;
  blackAmount: number;
  whiteAmount: number;
}

function SummarySellsByPeriodAndByVendor() {
  const [response, setResponse] = React.useState<SummarySellsByPeriodAndByVendorProps>();

  function setFetchedData(data: SummarySellsByPeriodAndByVendorProps) {
    setResponse(data);
  }
  return (
    <Container>
      <SearchForm setFunction={setFetchedData} />
      <div>SummarySellsByPeriodAndByVendor</div>
      <div>{response?.summaryBlack}</div>
      {/* Luego de usar el formulario se mostrará lo siguiente */}
      {/* De las ventas blancas se mostrará la cantidad de ventas, el monto vendido y el monto cobrado de esas ventas */}
      {/* De las ventas negras se mostrará la cantidad de ventas, el monto vendido y el monto cobrado de esas ventas */}
      {/* Se mostrará el monto cobrado durante el mes de ventas blancas y negras */}
      {{/* Por último se mostrara el total cobrado durante el mes */ }}
    </Container>
  )
}

export default SummarySellsByPeriodAndByVendor
import React from "react";
import { useForm } from "react-hook-form";
import { axiosInstance } from "../../utils/axios";
import { Form } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

export default function ProductionForm({ submitFunction }: { submitFunction: any }) {
  // obtener el usuario actual
  const { user } = useAuth();
  // Obtener la fecha de hoy en formato YYYY-MM-DD
  const today = new Date().toISOString().split('T')[0];
  const { register, handleSubmit } = useForm({
    defaultValues: {
      recipeId: null,
      productionDate: today,
    }
  });

  const [recipes, setRecipes] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    // fetch
    (async () => {
      try {
        const response = await axiosInstance('/recipes/get-recipes')
        const data = await response.data;
        const userRecipes = data.filter((recipe: any) => {
          if (user && user.role === 'productor')
            return recipe.access === 'public'
          else
            return true
        })
        setRecipes(userRecipes);
      } catch (error) {
        console.error(error)
      }
    })()
  }, [user]);

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true); // Iniciar carga
      await submitFunction(data); // Enviar datos al servidor
      setIsLoading(false); // Finalizar carga
    } catch (error) {
      console.error(error);
      setIsLoading(false); // Finalizar carga
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId='productionDate'>
          <Form.Label>Fecha de producción</Form.Label>
          <Form.Control type="date" {...register('productionDate')} />
        </Form.Group>
        <Form.Group controlId='recipeId' className='mt-2'>
          <Form.Label>Receta</Form.Label>
          <Form.Control as="select" {...register('recipeId')} required>
            <option value="">Selecciona una receta</option>
            {recipes && recipes.map((recipe: any) => (
              <option key={recipe.id} value={recipe.id}>{recipe.name}</option>
            ))}
          </Form.Control>
          {/* boton para registrar la producción */}
          <button type="submit" className="btn btn-primary mt-3" disabled={isLoading}>
            {isLoading ? 'Registrando...' : 'Registrar Producción'}
          </button>
        </Form.Group>
      </form>
    </>
  )
}
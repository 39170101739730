import dayjs from 'dayjs';
import React from 'react'
import { useForm } from 'react-hook-form';
import styled from 'styled-components'
import { periods } from '../../purchase/common';
import { getSummaryDataFromPeriod } from './functions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const FormDiv = styled.div`
  display: flex;
  gap: 1rem;
`

const INIT_YEAR = 2022;

function SearchForm({ setFunction }: {
  setFunction: React.Dispatch<React.SetStateAction<SummarySellsByPeriodProps | undefined>>
}) {
  const { handleSubmit, register } = useForm();
  const [years, setYears] = React.useState<{ value: number, text: string }[]>([]);

  React.useEffect(() => {
    let year = dayjs().year();
    let arrayOfYears = [];
    while (year >= INIT_YEAR) {
      arrayOfYears.push({
        value: year,
        text: year.toString(),
      });
      year--;
    }
    setYears(arrayOfYears);
  }, []);

  async function onSubmit(data: any) {
    try {
      const response = await getSummaryDataFromPeriod(data);
      setFunction({
        summaryBlack: response.black,
        summaryWhite: response.white,
        blackAmount: response.blackAmount,
        whiteAmount: response.whiteAmount,
        makroPayment: response.makroPayment,
      });
    } catch (error: any) {
      alert(error.message);
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormDiv>
          <select id="month" {...register('month')} required>
            <option value="">Selecciona el mes</option>
            {periods.map((p, i) => (
              <option key={i} value={p.value}>{p.text}</option>
            ))}
          </select>
          <select id="year" {...register('year')} required>
            <option value="">Selecciona el año</option>
            {years.map((y, i) => (
              <option key={i} value={y.value}>{y.text}</option>
            ))}
          </select>
          <button type="submit">Buscar ventas</button>
        </FormDiv>
      </form>
    </div>
  )
}

type SummaryBlack = {
  teoricAmount: number;
  totalSells: number;
  totalPayed: number;
}

type SummaryWhite = {
  teoricAmount: number;
  totalSells: number;
  totalPayed: number;
}

type SummarySellsByPeriodProps = {
  summaryBlack: SummaryBlack;
  summaryWhite: SummaryWhite;
  blackAmount: number;
  whiteAmount: number;
  makroPayment: number;
}

function SummarySellsByPeriod() {
  const [response, setResponse] = React.useState<SummarySellsByPeriodProps>();

  return (
    <Container>
      <SearchForm setFunction={setResponse} />
      {response && response.summaryBlack.totalSells > 0 &&
        <div>
          <h3>Negras</h3>
          <p>Total de ventas: {response?.summaryBlack.totalSells}</p>
          <p>Total teórico: S/ {response?.summaryBlack.teoricAmount}</p>
          <p>Monto cobrado de estas ventas: S/ {response?.summaryBlack.totalPayed}</p>
        </div>
      }
      {response && response.summaryBlack.totalSells === 0 &&
        <p>No hay ventas negras en el periodo seleccionado</p>
      }
      {response && response.summaryWhite.totalSells > 0 &&
        <div>
          <h3>Blancas</h3>
          <p>Total de ventas: {response?.summaryWhite.totalSells}</p>
          <p>Total teórico: S/ {response?.summaryWhite.teoricAmount}</p>
          <p>Monto cobrado de estas ventas: S/ {response?.summaryWhite.totalPayed}</p>
        </div>
      }
      {response && response.summaryWhite.totalSells === 0 &&
        <p>No hay ventas blancas en el periodo seleccionado</p>
      }
      {response && response.blackAmount > 0 &&
        <div>
          <h3>Monto total recaudado de ventas negras: S/ {response?.blackAmount}</h3>
        </div>
      }
      {response && response.whiteAmount > 0 &&
        <div>
          <h3>Monto total recaudado de ventas blancas: S/ {response?.whiteAmount + response?.makroPayment}</h3>
        </div>
      }
    </Container>
  )
}

export default SummarySellsByPeriod
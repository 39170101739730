import React from 'react'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import writeXlsxFile from 'write-excel-file'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`
const Header = styled.div`
  display: flex;
  align-items: center;
`

function Resultados(props: any) {
  async function downloadExcel() {
    try {
      const schema = [
        {
          column: "Fecha",
          type: String,
          value: (item: any) => item.date
        },
        {
          column: "Producto",
          type: String,
          value: (item: any) => item.product
        },
        {
          column: "Cliente",
          type: String,
          value: (item: any) => item.customer
        },
        {
          column: "Cantidad",
          type: Number,
          format: '#',
          value: (item: any) => Number(item.amount)
        }
      ];
      return await writeXlsxFile(props.data, { schema, fileName: `reporte_productos_por_clientes.xlsx` });
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Wrapper>
      {!props.data &&
        <h3>Use el formulario para buscar</h3>}
      {props.data && props.data.length === 0 &&
        <h3 style={{ color: 'red' }}>No resultados que coincidan con los parámetros buscados</h3>}
      {props.data && props.data.length > 0 &&
        <>
          <Header><button className='btn btn-success' onClick={downloadExcel}>Exportar reporte</button></Header>
          <Table responsive size='sm'>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Producto</th>
                <th>Cliente</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {props.data.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item.date}</td>
                  <td>{item.product}</td>
                  <td>{item.customer}</td>
                  <td>{item.amount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      }
    </Wrapper>
  )
}

export default Resultados
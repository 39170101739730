import useSWR from "swr"

export const useIncomeMovements = () => {
  const { data, error, mutate } = useSWR('/boxes/incomes-movements');

  return {
    incomes: data,
    isLoading: !error && !data,
    isError: error,
    mutateIncomes: mutate,
  }

}
export const useWithdrawalMovements = () => {
  const { data, error, mutate } = useSWR('/boxes/withdrawals-movements');

  return {
    withdrawals: data,
    isLoading: !error && !data,
    isError: error,
    mutateWithdrawals: mutate,
  }
}
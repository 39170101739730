import dayjs from 'dayjs';
import React from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import writeXlsxFile from 'write-excel-file';
import useModal from '../../../../hooks/useModal';
import { formatMoney, formatOrderState, toLocalDateFormat } from '../../../../utils/functions';
import OrderDetailsModal from './OrderDetailsModal';

const Container = styled.div`
  margin-top: .5rem;
  overflow-y: hidden;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const CustomerRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: grey;
    font-size: 1.01rem;
  }
`

export function Results(props: any) {
  const infoModal = useModal();
  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleSelected = (item: any) => {
    setSelectedItem(item);
    infoModal.toogleModal();
  }

  const handleCloseModal = () => {
    setSelectedItem(null);
    infoModal.toogleModal();
  }

  async function downloadExcel() {
    try {
      const schema = [
        {
          column: "Documento",
          type: String,
          value: (order: any) => order.futureDocument
        },
        {
          column: "Fecha",
          type: String,
          value: (order: any) => toLocalDateFormat(order.saleDate)
        },
        {
          column: "Cliente",
          type: String,
          value: (order: any) => order.customer.name
        },
        {
          column: "Total",
          type: Number,
          format: '#,##0.00',
          value: (order: any) => Number(order.total)
        },
        {
          column: "Por Pagar",
          type: Number,
          format: '#,##0.00',
          value: (order: any) => Number(order.toPay)
        },
        {
          column: "Vendedor",
          type: String,
          value: (order: any) => order.user.role === 'vendedor' ? order.user.username : 'Oficina'
        },
        {
          column: "Estado",
          type: String,
          value: (order: any) => formatOrderState(order)
        }
      ];
      return await writeXlsxFile(props.data, { schema, fileName: `reporte_ventas_${dayjs().format('DD-MM-YYYY')}.xlsx` });
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Container>
      {!props.data &&
        <h3>Use el formulario para buscar ventas</h3>}
      {props.data && props.data.length === 0 &&
        <h3 style={{ color: 'red' }}>No hay ventas que coincidan con los parámetros buscados</h3>}
      {props.data && props.data.length > 0 &&
        <>
          <Header><button className='btn btn-success' onClick={downloadExcel}>Exportar reporte</button></Header>
          <Table responsive size='sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Documento</th>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Total</th>
                <th>Por Cobrar</th>
                <th>Vendedor</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {props.data.map((item: any) => (
                <CustomerRow style={{ color: item.nulled ? 'red' : 'inherit', textDecoration: item.nulled ? 'line-through' : 'none' }} key={item.id} onClick={() => handleSelected(item)}>
                  <td>{item.id}</td>
                  <td>{item.futureDocument}</td>
                  <td>{dayjs(item.saleDate).format("DD/MM/YYYY")}</td>
                  <td>{item.customer.name}</td>
                  <td>{formatMoney(item.total)}</td>
                  <td style={{ color: item.toPay > 0 ? 'green' : 'inherit' }}><span>{formatMoney(item.toPay)}</span></td>
                  <td>{item.user.role === 'vendedor' ? item.user.username : 'Oficina'}</td>
                  <td>{formatOrderState(item)}</td>
                </CustomerRow>
              ))}
            </tbody>
          </Table>
          {selectedItem && <OrderDetailsModal show={infoModal.isOpenModal} close={handleCloseModal} order={selectedItem} />}
        </>
      }
    </Container>
  );
}

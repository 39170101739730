import React, { useState } from 'react'
import { SellOrderContext } from '../../../../providers/SellOrdersProvider'
import { Body, Header, OrdersContainer } from '../../styles'
import ForConfirmOrderItem from './ForConfirmOrderItem'

function ForConfirmOrders() {
  const { porConfirmar: orders, fetch, vendorsArray: vendors, vendorsIdArray: vendorIds } = React.useContext(SellOrderContext);
  const [selectedVendor, setSelectedVendor] = useState('all');

  const filterOrders = React.useMemo(() => {
    if (selectedVendor === 'all') {
      return orders;
    } else if (selectedVendor === 'oficina') {
      return orders.filter(order => !vendorIds.includes(order.user.id))
    } else {
      return orders.filter(order => order.user.id === selectedVendor)
    }
  }, [selectedVendor, orders, vendorIds])

  const handleSelectVendor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedVendor(e.target.value);
  }

  return (
    <OrdersContainer>
      <Header>
        <div style={{ fontWeight: 600 }}>Ventas por confirmar</div>
        <select name="vendors" id="vendors" value={selectedVendor} onChange={handleSelectVendor}>
          <option value="all">Todos</option>
          <option value="oficina">Oficina</option>
          {vendors.map(v => (
            <option key={v.value} value={v.value}>{v.label}</option>
          ))}
        </select>
      </Header>
      <Body>
        {filterOrders.length === 0 && <h3>No hay ventas por confirmar</h3>}
        {filterOrders.map((o, i) => (
          <ForConfirmOrderItem
            key={i}
            order={o}
            refresh={fetch}
          />
        ))}
      </Body>
    </OrdersContainer>
  )
}

export default ForConfirmOrders
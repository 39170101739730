import { useEffect, useState } from "react";
import { fetchAllInputs, fetchAllManufacturedInputs, fetchAllNoManufacturedInputs } from "../utils/api/inputs";

export interface Input {
    id: number
    name: string
    internalCode: string
}

export default function useInputs() {
    const [inputs, setInputs] = useState<Input[]>([]);

    useEffect(() => {
        fetchAllInputs().then(setInputs);
    }, []);

    return { inputs }
}

export function useManufacturedInputs() {
    const [inputs, setInputs] = useState<Input[]>([]);

    useEffect(() => {
        fetchAllManufacturedInputs().then(setInputs);
    }, []);

    return { inputs }
}

export function useNoManufacturedInputs() {
    const [inputs, setInputs] = useState<Input[]>([]);

    useEffect(() => {
        fetchAllNoManufacturedInputs().then(setInputs);
    }, []);

    return { inputs }
}
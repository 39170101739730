import { axiosInstance } from "../../../utils/axios";

export async function getSummaryDataFromPeriod({ month, year }: { month: number, year: number }) {
  try {
    const resBlack = await axiosInstance.get('financial/black', { params: { month, year } });
    const resWhite = await axiosInstance.get('financial/white', { params: { month, year } });
    // la siguiente función devuelve dos montos en un objeto
    const totalSalesPaid = await axiosInstance.get('financial/sales-paid-on-period', { params: { month, year } });
    const { blackAmount, whiteAmount } = totalSalesPaid.data;
    const makroPaidOnPeriod = await axiosInstance.get('financial/makro-paid-on-period', { params: { month, year } });
    const makroPayment = makroPaidOnPeriod.data;
    return { black: resBlack.data, white: resWhite.data, blackAmount, whiteAmount, makroPayment: makroPayment.total };
  } catch (error: any) {
    throw Error(error);
  }
}

export async function getSummaryDataFromPeriodAndByVendor({ vendor, month, year }: { vendor: string, month: number, year: number }) {
  return null;
}
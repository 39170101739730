import React from 'react'
import { Alert, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components'
import routes from '../../../helpers/routes';
import useAuth from '../../../hooks/useAuth';
import { SellOrderContext } from '../../../providers/SellOrdersProvider';
import { formatMoney, toLocalDateFormat } from '../../../utils/functions';
import { EditAction, OrderGeneralDetails, OrderGeneralDetailsLine, OrderInfo, OrderInfoBold, OrderInfoLabel, OrderItemsView, OrderMoneyDetails, OrderView, OrderViewActions } from '../../common/styled';
import OptionsDropdown from '../orders/OptionsDropdown';
import { useOrdersById } from '../orders/useOrders';
const Conatiner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const Nulled = ({ order }: any) => {
  if (!order.nulled) return null
  return (
    <Alert variant='danger'>
      <div className="d-flex justify-content-between align-items-center">
        <strong>El comprobante relacionado ha sido anulado</strong>
        <button className='btn btn-outline-danger btn-sm' onClick={() => window.open(order.nulledLink, "_blank")}>Revisar anulación</button>
      </div>
    </Alert>
  )
}
const NoteRelated = ({ order }: any) => {
  if (!order.creditNote) return null
  return (
    <Alert variant='info'>
      <strong>El comprobante cuenta con nota de crédito</strong>
    </Alert>
  )
}

function ViewSellOrder() {
  const { user } = useAuth();
  const [editingPayments, setEditingPayments] = React.useState(false);
  const { fetch } = React.useContext(SellOrderContext);
  const { id } = useParams<any>();
  const { order, unPayed } = useOrdersById(id);

  const toggleEditingPayments = () => setEditingPayments(!editingPayments);

  const writeVendor = (user: any) => {
    if (user.role !== 'vendedor') {
      return "Vendedor: Oficina"
    }
    return "Vendedor(a): " + user.username
  }

  const writeOrderStatus = (state: string) => {
    if (state === 'created') {
      return "Estado: Por confirmar"
    }
    if (state === 'confirmed') {
      return "Estado: Confirmada"
    }
  }

  if (!order) return <Conatiner>Cargando...</Conatiner>

  return (
    <Conatiner>
      <Nulled order={order} />
      <NoteRelated order={order} />
      <OrderViewActions>
        <div className="">{writeVendor(order.user)}</div>
        <div className="">{writeOrderStatus(order.state)}</div>
        {order.futureDocument && <div>{order.futureDocument}</div>}
        {order.state !== 'created' && <OptionsDropdown order={order} refresh={fetch} />}
        {order.state === 'created' ?
          <EditAction as={Link} to={routes.ventas.editar(id)}>
            <i className="bi bi-pencil"></i>
            Editar
          </EditAction>
          : null
        }
      </OrderViewActions>
      <OrderView>
        <OrderGeneralDetails>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Cliente:</OrderInfoLabel>
            <OrderInfo>{order.customer.name}</OrderInfo>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>{order.customer.documentType}:</OrderInfoLabel>
            <OrderInfo>{order.customer.documentNumber}</OrderInfo>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Dirección:</OrderInfoLabel>
            <OrderInfo>{order.customer.address}</OrderInfo>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Fecha de entrega:</OrderInfoLabel>
            <OrderInfo color="navy">{toLocalDateFormat(order.deliveryDate)}</OrderInfo>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Fecha de vencimiento:</OrderInfoLabel>
            <OrderInfo color="crimson">{toLocalDateFormat(order.expirationDate)}</OrderInfo>
          </OrderGeneralDetailsLine>
        </OrderGeneralDetails>
        <OrderMoneyDetails>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Sub total:</OrderInfoLabel>
            <OrderInfoBold>{formatMoney(order.subTotal)}</OrderInfoBold>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>IGV</OrderInfoLabel>
            <OrderInfoBold>{formatMoney(order.igv)}</OrderInfoBold>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Total:</OrderInfoLabel>
            <OrderInfoBold>{formatMoney(order.total)}</OrderInfoBold>
          </OrderGeneralDetailsLine>
        </OrderMoneyDetails>
        <OrderItemsView>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>Cantidad</th>
                <th>Descripción</th>
                <th>Precio Unitario</th>
                <th>Sub Total</th>
              </tr>
            </thead>
            <tbody>
              {order.items.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.amount}</td>
                  <td>{item.product_group.name}</td>
                  <td>{formatMoney(item.unitPrice)}</td>
                  <td>{formatMoney(item.amount * item.unitPrice)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </OrderItemsView>
      </OrderView>
      {order.sell_payments &&
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h3>Cobros:</h3>
            {user && user.role === 'admin' && order.sell_payments.length > 0 &&
              <>
                {!editingPayments && <button className="btn btn-danger" onClick={toggleEditingPayments}>Editar</button>}
                {editingPayments && <button className="btn btn-outline-danger" onClick={toggleEditingPayments}>X</button>}
              </>
            }
          </div>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Monto</th>
                <th>Caja</th>
                {editingPayments && <th>Eliminar</th>}
              </tr>
            </thead>
            <tbody>
              {order.sell_payments.map((item: any) => (
                <tr key={item.id}>
                  <td>{toLocalDateFormat(item.date)}</td>
                  <td>{formatMoney(item.amount)}</td>
                  <td>{item.box.name}</td>
                  {editingPayments && <td style={{ textAlign: 'center', color: 'red', fontSize: '1.4em', padding: '0' }}><i style={{ cursor: 'pointer' }} className="bi bi-x-circle" title="Eliminar pago" onClick={() => unPayed(item.id)}></i></td>}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      }
    </Conatiner>
  )
}

export default ViewSellOrder
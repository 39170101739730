import { useEffect, useState } from "react";
import styled from "styled-components";
import { DataSection } from "./DataSection";
import { axiosInstance } from "../../../utils/axios";

const BodyPart = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  overflow: hidden;
`;

export interface InputsStateSinceLastPurchase {
  id: string;
  name: string;
  stock: number;
  unit: string;
  lastPurchase: string;
  lastPurchaseId: number;
  lastPurchaseAmount: number;
}

const useData = () => {
  const [data, setData] = useState<InputsStateSinceLastPurchase[]>([]);

  // Aquí iría la lógica para obtener los datos de la API
  // y guardarlos en el estado data
  useEffect(() => {
    axiosInstance.get<InputsStateSinceLastPurchase[]>("/dashboards/get-inputs-state").then((res) => {
      setData(res.data);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return data;
}

export function BodyPage() {
  const inputsList = useData();
  const [filter, setFilter] = useState("");

  let filteredData = inputsList.filter((item) => {
    return item.name.toLowerCase().includes(filter.toLowerCase());
  });

  return (
    <BodyPart>
      <input
        type="text"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Buscar un insumo..."
      />
      <DataSection listData={filteredData} />
    </BodyPart>
  );
}
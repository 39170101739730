import { axiosInstance } from "../axios";

export const fetchAllBoxes = async () => {
  try {
    const res = await axiosInstance.get('/boxes');
    return res.data
  } catch (error) {
    throw (error)
  }
}

export const addIncomeReason = async (data: any) => {
  try {
    await axiosInstance.post('/boxes/add-income-reason', data);
  } catch (error) {
    throw (error)
  }
}

export const addWithdrawalReason = async (data: any) => {
  try {
    await axiosInstance.post('/boxes/add-withdrawal-reason', data);
  } catch (error) {
    throw (error)
  }
}
import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import routes from '../../../helpers/routes'
import ConfirmedOrders from './ConfirmedOrders'
import ForConfirmOrders from './ForConfirmOrders/index'
import { CurrentOrdersContainer, MainSection, TopSection } from '../styles'
import { SellOrderContext } from '../../../providers/SellOrdersProvider'

function CurrentSellOrders() {
  const { isLoading, isError } = React.useContext(SellOrderContext);
  const history = useHistory();

  if (isLoading) return <CurrentOrdersContainer>Cargando...</CurrentOrdersContainer>

  if (isError) return <CurrentOrdersContainer>Hubo un error en la carga de datos</CurrentOrdersContainer>

  return (
    <CurrentOrdersContainer>
      <TopSection>
        <Button variant="success" onClick={() => history.push(routes.ventas.nueva)}>Nueva venta</Button>
      </TopSection>
      <MainSection>
        <ForConfirmOrders></ForConfirmOrders>
        <ConfirmedOrders></ConfirmedOrders>
      </MainSection>
    </CurrentOrdersContainer>
  )
}

export default CurrentSellOrders
import React, { useEffect, useState } from 'react'
import TableList from '../../../../components/TableList1';
import { getReferralGuides } from '../../../../services/sell_order';
import { axiosInstance } from '../../../../utils/axios';

const GuiasList = ({ toggleAdding }: any) => {
  const [referrals, setReferrals] = useState([]);
  const [errors, setErrors] = useState();

  const getReferralGuide = async (referralId: number) => {
    try {
      const res = await axiosInstance.get(`/orders/referral-guide/${referralId}`);
      if (res.data.success) {
        window.open(res.data.link, "_blank");
      } else {
        alert("Aún no hay PDF disponible para esta guía");
      }
    } catch (e) {
      console.log(e)
    }
  }

  const headers = ['Serie', 'Número', 'Cliente', 'Ver guía'];
  const body = referrals.map((r: any) => (
    <tr key={r.id}>
      <td>{r.serie}</td>
      <td>{r.numero}</td>
      <td>{r.customerName}</td>
      <td style={{ textAlign: 'center' }}>
        <button onClick={() => getReferralGuide(r.id)} className='btn btn-secondary btn-sm'>enlace</button>
      </td>
    </tr>
  ))

  useEffect(() => {
    getReferralGuides().then(setReferrals).catch(setErrors);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h1>Lista de Guias</h1>
        <button className='btn btn-outline-primary' onClick={() => toggleAdding()}>Registrar guía de remisión</button>
      </div>
      {errors ? <h4>Error al traer los datos</h4> : <TableList headers={headers} body={body} actions={false} />}
    </>
  )
}

export default GuiasList
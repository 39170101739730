import React, { useState } from "react";
import { Alert, Button, Container, Form, Row } from "react-bootstrap";
import useAuth from "../hooks/useAuth";

export default function LoginPage(props: any) {
    const { login, error } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    /* useEffect(() => {
        login({username, password}, location.state?.from);
    }, [username, password, location, login]); */

    const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        login({ username, password }, props.location.state?.from);
    }

    return (
        <>
            <Container style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <Form onSubmit={handleLogin}>
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre de usuario</Form.Label>
                            <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Ingresa tu usuario"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Ingresa tu contraseña"></Form.Control>
                        </Form.Group>
                        {error &&
                            <Alert variant="danger">
                                {error}
                            </Alert>
                        }
                    </Row>
                    <Row className="px-2">
                        <Button size="lg" variant="primary" type="submit">Login</Button>
                    </Row>
                </Form>
            </Container>
        </>
    )
}
import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap'
import { fetchProduct, updateProduct } from '../../utils/api/products'
import { PageLayout } from '../../components/PageLayout';
import { ProductForm } from './ProductForm';
import { ProductModel } from './ProductsPage';
import withFecthOne, { WraperFetchOneProps } from '../../hocs/withFetchOne';
import { deleteProduct } from '../../services/products';
import routes from '../../helpers/routes';
import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';

function Details(props: WraperFetchOneProps<ProductModel>) {
    const history = useHistory();
    const { loading, disable, editing, fetchItem, handleEditing, handleSubmit, handleCancel } = props

    const handleDelete = async (productId: number) => {
        try {
            await deleteProduct(productId);
            alert('Producto Eliminado');
            history.push(routes.products);
        } catch (e) {
            alert(JSON.stringify(e, null, 2))
        }
    }

    if (loading) return <PageLayout><Spinner animation="border" /></PageLayout>

    return (
        <PageLayout>
            <PageHeader>
                <PageTitle title={fetchItem.name} />
                <button className="btn btn-secondary" onClick={() => history.push(".")}>Regresar</button>
            </PageHeader>
            <ProductForm product={fetchItem} disabled={disable} editing={editing} handleSubmit={handleSubmit} />
            {!editing ?
                <div className="mt-3" style={{ display: 'flex', alignContent: 'center', justifyContent: 'end' }}>
                    <button className="btn btn-warning" onClick={handleEditing}>Editar</button>
                    <button className="btn btn-outline-danger ms-2" onClick={() => {
                        if (window.confirm('¿Seguro que desea eliminar el producto?')) {
                            handleDelete(fetchItem.id)
                        }
                    }}>Eliminar</button>
                </div>
                :
                <div className="d-grid mt-2">
                    <button className="btn btn-secondary" onClick={handleCancel}>Cancelar</button>
                </div>
            }
        </PageLayout>
    )
}

const ProductDetails = withFecthOne(Details)(fetchProduct, updateProduct);
export default ProductDetails
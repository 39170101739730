import React from 'react'
import styled from 'styled-components'
import { SellOrderContext } from '../../../../providers/SellOrdersProvider'
import { formatMoney } from '../../../../utils/functions';
import PayableOrderItem from '../PayableOrderItem';
import { Box } from '../../../common/interfaces';

const Conatiner = styled.div`
  overflow-y: scroll;
  grid-area: vendors;
  margin: .5rem;
  border: 1px solid green;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
`

function VendorsCustomers({ boxes }: { boxes: Box[] }) {
  const searchEl = React.useRef<HTMLInputElement>(null);
  const { q, setQ, filteredVenorsSalesBySearch, vendorsSumary, vendorsArray, selectedVendor, setSelectedVendor } = React.useContext(SellOrderContext);

  function handleKeyDown(event: KeyboardEvent) {
    if (event.altKey && event.key === 'm') {
      searchEl.current?.focus();
    }
  }

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <Conatiner>
      <div className="header">
        <span>Ventas de vendedores</span>
        <select name="vendor" id="vendor-select" value={selectedVendor} onChange={(e) => setSelectedVendor(e.target.value)}>
          <option value="all">Todos</option>
          {vendorsArray.map((v, i) => (
            <option key={i} value={v.value}>{v.label}</option>
          ))}
        </select>
      </div>
      <div className='searchBox'>
        <input ref={searchEl} type="text" placeholder='Buscar orden de venta' value={q} onChange={(e) => setQ(e.target.value)} />
      </div>
      <div className="sumary">
        <div>{vendorsSumary.numberOfSales} ventas</div>
        <div>{formatMoney(vendorsSumary.moneyTocollet)} por cobrar</div>
      </div>
      <div className="body">
        {filteredVenorsSalesBySearch.length === 0 && <h4>No hay ventas por cobrar</h4>}
        {filteredVenorsSalesBySearch.map(order => (
          <PayableOrderItem key={order.id} order={order} boxes={boxes}></PayableOrderItem>
        ))}
      </div>
    </Conatiner>
  )
}

export default VendorsCustomers
import React from 'react'
import { FullPageLayout } from '../../../components/FullPageLayout'
import { Link, useParams } from 'react-router-dom'
import { Container } from './RecipesPage'
import routes from '../../../helpers/routes'
import HeaderSection from '../components/HeaderSection'
import { useRecipe } from '../utils'

export default function ViewRecipePage() {
  const { id: recipeId } = useParams<{ id: string }>();
  const { recipe, loading } = useRecipe(Number(recipeId));

  if (loading) {
    return <FullPageLayout>
      <Container>
        <p>Cargando...</p>
      </Container>
    </FullPageLayout>
  }

  return (
    <FullPageLayout>
      <Container>
        <HeaderSection title="Detalle de la receta" to={routes.recipes.index} linkString='Regresar al listado' />
        <hr style={{ margin: '0' }} />
        {recipe && (
          <div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '1rem'
            }}>
              <h2>{recipe.name}</h2>
              <Link className='btn btn-warning' to={routes.recipes.editRecipe(recipe.id)}>Editar</Link>
            </div>
            <p>{recipe.description}</p>
            <h3>Ingredientes</h3>
            <ul>
              {recipe.items.map((ingredient: any) => (
                <li key={ingredient.id}>{ingredient.input.name} <b>{ingredient.amount} {ingredient.unit}</b></li>
              ))}
            </ul>
          </div>
        )}

      </Container>
    </FullPageLayout>
  )
}
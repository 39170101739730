import styled from "styled-components";
import { InputsStateSinceLastPurchase } from "./BodyPage";

const DataPart = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const CustomCard = styled.div`
  padding: .5rem;
  border: 1px solid;
  border-radius: .5rem;
  gap: 0.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-sizing: border-box;
  width: 18rem;
  height: 7rem;
`;

const Bar = styled.div<{ ratio: number }>`
  width: 100%;
  height: 14px;
  position: relative;
  border-radius: 5px;
  border: 1px solid;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ ratio }) => `${ratio * 100}%`};
    height: 100%;
    background-color: green;
  }
`;

interface DataSectionProps {
  listData: InputsStateSinceLastPurchase[];
}

export function DataSection({ listData }: DataSectionProps) {
  return (
    <DataPart>
      {listData.length === 0 ? (
        <h3>Cargando datos...</h3>
      ) : (
        listData.map(item => {
          const fontSize = Math.min(20, 400 / item.name.length);
          return (
            <CustomCard key={item.id} style={{ backgroundColor: item.stock / item.lastPurchaseAmount > 1 ? "lightcoral" : "" }}>
              <h3 style={{ fontSize: `${fontSize}px` }}>{item.name}</h3>
              <Bar ratio={item.stock / item.lastPurchaseAmount} />
              <span style={{ alignSelf: "flex-end", fontWeight: "bold" }}>{item.stock} / {item.lastPurchaseAmount} {item.unit}</span>
            </CustomCard>
          );
        })
      )}
    </DataPart >
  );
}
import React, { useState } from 'react';
import { Button } from "react-bootstrap";

interface SubmitButtonProps {
  buttonText: string;
  submittingText: string;
  onSubmit: () => Promise<void>;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ buttonText, submittingText, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Button variant="primary" onClick={handleClick} disabled={isSubmitting}>
      {isSubmitting ? submittingText : buttonText}
    </Button>
  );
};

export default SubmitButton;
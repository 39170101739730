import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../../helpers/routes';
import useAuth from '../../hooks/useAuth';

const Container = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 1rem;

  button {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    border: none;
    border-radius: 1rem;
    background-color: #f1f1f1;
    cursor: pointer;
    flex-basis: 45%;
    height: 100px;

    &:hover {
      background-color: #e1e1e1;
    }
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

const FactoryHome: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const goToFillingPage = () => {
    history.push(routes.filling.index);
  };

  const goToProductionPage = () => {
    history.push(routes.productions.index);
  };

  return (
    <Container>
      <button onClick={goToFillingPage}>Llenado</button>
      {user && user.role === 'productor' && <button onClick={goToProductionPage}>Producciones</button>}
    </Container>
  );
};

export default FactoryHome;
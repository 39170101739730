import useSWR from 'swr'

interface Data {
  vendorsArray: { value: string, label: string }[]
  vendorsIdArray: string[]
}

function useVendors() {
  const { data, error } = useSWR<Data>('/vendors/data');

  return {
    vendorsArray: data && data.vendorsArray,
    vendorsIdArray: data && data.vendorsIdArray,
    isLoading: !error && !data,
    isError: error,
  }
}

export default useVendors
import React from 'react'

interface Props {
    name: string
    label: string
    required?: boolean
    disabled?: boolean
}

interface InputProps extends Props {
    value: string | number
    type: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface SelectProps extends Props {
    value?: string
    options: { value: string, text: string }[]
    handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export function InputFloating({
    type,
    name,
    value = "",
    label,
    required = false,
    disabled = false,
    onChange
}: InputProps) {

    return (
        <div className="form-floating">
            <input
                type={type}
                className="form-control"
                id={name}
                name={name}
                value={value}
                placeholder={label}
                disabled={disabled}
                required={required}
                onChange={onChange}
            />
            <label htmlFor={name}>{label}</label>
        </div>
    )
}

export function SelectFloating({
    name,
    value = "",
    label,
    required = false,
    disabled = false,
    options = [],
    handleChange
}: SelectProps) {
    return (
        <div className="form-floating">
            <select className="form-select" id={name} name={name} aria-label={name} value={value} onChange={handleChange} required={required} disabled={disabled}>
                <option value="">Selecciona uno</option>
                {options.map((o, i) => <option key={i} value={o.value}>{o.text}</option>)}
            </select>
            <label htmlFor={name}>{label}</label>
        </div>
    )
}


import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { PageLayout } from "../../components/PageLayout";
import useSupplies, { Supply } from "../../hooks/useSupplies";
import SupplyForm from "./SupplyForm";

export default function SupplyPage() {
  const [q, setQ] = useState("");
  const [supply, setSupply] = useState<Supply>()
  const [addFlag, setAddFlag] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const { supplies, addSupply, refresh, patchSupply } = useSupplies();

  const toogleAdd = () => setAddFlag(!addFlag);
  const toogleEdit = () => setEditFlag(!editFlag);

  const searchParams: Array<keyof Supply> = ["internalCode", "name"]
  const filteredItems = supplies.filter((item) => {
    return searchParams.some((newItem) => {
      const value = item[newItem]
      if (typeof value === "string" || typeof value === "number") {
        return value.toString().toLowerCase().includes(q.toLowerCase())
      }
      return false;
    });
  });

  const handleCreation = async (supply: any) => {
    try {
      await addSupply(supply);
      toast.success("Suministro registrado");
      refresh()
      setAddFlag(false)
    } catch (error) {
      throw (error)
    }
  }

  const handleUpdate = async (editedSupply: any) => {
    try {
      if (supply) {
        await patchSupply(supply.id, editedSupply)
        refresh()
        setEditFlag(false)
      }
    } catch (error) {
      throw (error)
    }
  }

  const handleEditClick = (supply: any) => {
    setSupply(supply)
    setEditFlag(true)
  }

  if (!supplies) return <PageLayout>Cargando...</PageLayout>

  return (
    <PageLayout>
      {(addFlag === editFlag) &&
        <h2>Administración de suministros</h2>
      }

      {(!addFlag && !editFlag) && <Button variant="success" onClick={() => setAddFlag(!addFlag)}>Nuevo Suministro</Button>}
      {addFlag && <SupplyForm submitFunction={handleCreation} toogle={toogleAdd}></SupplyForm>}
      {editFlag && <SupplyForm initialSupply={supply} submitFunction={handleUpdate} toogle={toogleEdit}></SupplyForm>}

      {(addFlag === editFlag) &&
        <>
          <div className="mt-3">
            <input type="text" className="form-control" placeholder="Buscar suministro" value={q} onChange={(e) => setQ(e.target.value)} />
          </div>
          <Table>
            <thead>
              <tr>
                <th>Código</th>
                <th>Nombre del suministro</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((s: any) => (
                <tr key={s.id}>
                  <td>{s.internalCode}</td>
                  <td>{s.name}</td>
                  <td><Button className="sm" variant="warning" onClick={() => handleEditClick(s)}>editar</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      }
    </PageLayout>
  )
}
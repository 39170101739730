import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import TableList from "../../components/TableList";
import routes from "../../helpers/routes";

interface ProviderModel {
    id: number
    name: string
    documentNumber: string
    address: string
}

interface Props {
    providers: ProviderModel[],
    isLoading: boolean,
    isError: boolean
}

export default function ProvidersList({ providers, isLoading, isError }: Props) {
    const headers = ["Nombre", "Número de documento", "Dirección", ""]

    if (isError) return <div>failed to load</div>

    if (isLoading) return <Spinner animation="border" />

    return (
        <>
            <TableList items={providers} headers={headers} searchParams={["name", "documentNumber"]}>
                {(provider) => (
                    <>
                        <td>{provider.name}</td>
                        <td>{provider.documentNumber}</td>
                        <td>{provider.address}</td>
                        <td className="d-flex justify-content-center"><Link to={routes.provider(provider.id)}>
                            <Button variant="primary" size="sm">Ver detalles</Button>
                        </Link></td>
                    </>
                )}
            </TableList>
        </>
    )
}
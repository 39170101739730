import { Breadcrumb } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useHistory, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/PageLayout";
import routes from "../../../helpers/routes";
import { useProductRecipe } from "../../../hooks/useRecipes";
import ProductRecipeForm from "./Form";

export default function EditProductRecipe() {
  let history = useHistory();
  const { id: recipeId } = useParams<any>();
  const { recipe, isLoading, updateRecipe } = useProductRecipe(recipeId);

  const updateProductRecipe = async (recipe: any) => {
    try {
      await updateRecipe(recipe);
      toast.success("Actualizado correctamente")
      history.push(routes.production.viewProductRecipe(recipeId));
    } catch (e) {
      alert(JSON.stringify(e, null, 2))
    }
  }

  if (isLoading) {
    return <PageLayout>Cargando...</PageLayout>
  }

  return (
    <PageLayout>
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: routes.production.productRecipes }} linkAs={Link}>
          Lista de recetas
        </Breadcrumb.Item>
        <Breadcrumb.Item linkProps={{ to: routes.production.viewProductRecipe(recipeId) }} linkAs={Link}>
          Ver Receta
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Edición</Breadcrumb.Item>
      </Breadcrumb>
      <ProductRecipeForm initialData={recipe} submitFunction={updateProductRecipe} />
    </PageLayout>
  )
}
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import EntityForm from "../../components/EntityForm";
import { PageLayout } from "../../components/PageLayout";
import useSuppliers, { Supplier } from "../../hooks/useSuppliers";
import SupplierForm from "./SupplierForm";

export default function SupplierPage() {
  const [q, setQ] = useState("");
  const [supplier, setSupplier] = useState<Supplier>()
  const [addFlag, setAddFlag] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const { suppliers, addSupplier, refresh, patchSupplier } = useSuppliers();

  const toogleAdd = () => setAddFlag(!addFlag);
  const toogleEdit = () => setEditFlag(!editFlag);

  const searchParams: Array<keyof Supplier> = ["name", "documentNumber"]
  const filteredItems = suppliers.filter((item) => {
    return searchParams.some((newItem) => {
      const value = item[newItem]
      if (typeof value === "string" || typeof value === "number") {
        return value.toString().toLowerCase().includes(q.toLowerCase())
      }
      return false;
    });
  });

  const handleCreation = async (supplier: any) => {
    try {
      await addSupplier(supplier);
      toast.success("Suministrador registrado");
      setAddFlag(false);
      refresh();
    } catch (error) {
      throw (error)
    }
  }

  const handleUpdate = async (editedSupply: any) => {
    try {
      if (supplier) {
        await patchSupplier(supplier.id, editedSupply)
        setEditFlag(false)
        refresh()
      }
    } catch (error) {
      throw (error)
    }
  }

  const handleEditClick = (supplier: any) => {
    setSupplier(supplier)
    setEditFlag(true)
  }

  if (!suppliers) return <PageLayout>Cargando...</PageLayout>

  return (
    <PageLayout>
      {(addFlag === editFlag) &&
        <h2>Administración de suministradores</h2>
      }

      {(!addFlag && !editFlag) && <Button variant="success" onClick={() => setAddFlag(!addFlag)}>Nuevo Suministrador</Button>}
      {addFlag && <EntityForm subject="Suministrador" submitFunction={handleCreation} toggle={toogleAdd}></EntityForm>}
      {editFlag && <SupplierForm initialSupplier={supplier} submitFunction={handleUpdate} toogle={toogleEdit}></SupplierForm>}

      {(addFlag === editFlag) &&
        <>
          <div className="mt-3">
            <input type="text" className="form-control" placeholder="Buscar suministro" value={q} onChange={(e) => setQ(e.target.value)} />
          </div>
          <Table>
            <thead>
              <tr>
                <th>Documento</th>
                <th>Nombre del suministrador</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((s: any) => (
                <tr key={s.id}>
                  <td>{s.documentNumber}</td>
                  <td>{s.name}</td>
                  <td><Button className="sm" variant="warning" onClick={() => handleEditClick(s)}>editar</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      }
    </PageLayout>
  )
}
import React, { useState, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import useAuth from '../../../../hooks/useAuth'
import { emitReferralGuide } from '../../../../services/sell_order';
import { Customer, fetchAllCustomers } from '../../../../utils/api/customers';
import DestinoSection from './DestinoSection';
import ItemsSection from './ItemsSection';
import PartidaSection from './PartidaSection';
import { TrasportistaSection } from './TrasportistaSection';

const GuiaRemisionForm = ({ invoice, cancel }: any) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      motivo: '01',
      pesoBrutoTotal: '',
      numeroDeBultos: '',
      tipoDeTransporte: '',
      fechaDeTraslado: '',
    }
  });
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([])
  const [selectedCustomer, setSelectedCustomer] = useState<Customer[]>([])
  const [error, setError] = useState();
  const [transportista, setTransportista] = useState('')
  const [vehiculo, setVehiculo] = useState('')
  const [conductor, setConductor] = useState('')
  const [partidaUbigeo, setPartidaUbigeo] = useState('')
  const [partidaDireccion, setPartidaDireccion] = useState('')
  const [destinoUbigeo, setDestinoUbigeo] = useState('')
  const [destinoDireccion, setDestinoDireccion] = useState('')
  const [items, setItems] = React.useState<any>([]);

  useEffect(() => {
    if (invoice) {
      setSelectedCustomer([invoice.customer]);
      setItems(invoice.items);
    }
  }, [invoice])

  useEffect(() => {
    (async () => {
      try {
        if (user) setCustomers(await fetchAllCustomers(user.id));
      } catch (e: any) {
        setError(e);
      }
    })()
  }, [user]);

  const handleSelectCustomer = (customer: Customer[]) => {
    setSelectedCustomer(customer)
  }

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      await emitReferralGuide({
        ...data,
        customer: selectedCustomer[0],
        transportista,
        vehiculo,
        conductor,
        partidaUbigeo,
        partidaDireccion,
        destinoUbigeo,
        destinoDireccion,
        items
      });
      toast.success("Guía de remisión registrada y emitida");
      cancel();
    } catch (e: any) {
      toast.error(e.response.data);
    } finally {
      setIsSubmitting(false);
    }
  }

  if (error) return <h4>Ocurrió un error con el servidor</h4>
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId='customer'>
          <Form.Label>Cliente</Form.Label>
          <Typeahead
            id="customer-selection"
            labelKey={(option) => `${option.documentNumber} | ${option.name}`}
            onChange={handleSelectCustomer}
            options={customers}
            placeholder="Buscar cliente..."
            selected={selectedCustomer}
            inputProps={{ required: true }}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId='motivo'>
              <Form.Label>Motivo del traslado</Form.Label>
              <Form.Select {...register('motivo')} required>
                <option value="">Selecciona el motivo</option>
                <option value="01">VENTA</option>
                <option value="14">VENTA SUJETA A CONFIRMACION DEL COMPRADOR</option>
                <option value="02">COMPRA</option>
                <option value="04">TRASLADO ENTRE ESTABLECIMIENTOS DE LA MISMA EMPRESA</option>
                <option value="18">TRASLADO EMISOR ITINERANTE CP</option>
                <option value="08">IMPORTACION</option>
                <option value="09">EXPORTACION</option>
                <option value="19">TRASLADO A ZONA PRIMARIA</option>
                <option value="13">"OTROS</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='pesoBrutoTotal'>
              <Form.Label>Peso bruto total</Form.Label>
              <Form.Control type="number" {...register('pesoBrutoTotal')} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='numeroDeBultos'>
              <Form.Label>Número de bultos</Form.Label>
              <Form.Control type="number" {...register('numeroDeBultos')} required />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId='tipoDeTransporte'>
              <Form.Label>Tipo de transporte</Form.Label>
              <Form.Select {...register('tipoDeTransporte')} required>
                <option value="">Selecciona el tipo</option>
                <option value="01">TRANSPORTE PÚBLICO</option>
                <option value="02">TRANSPORTE PRIVADO</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='fechaDeTraslado'>
              <Form.Label>Fecha de traslado</Form.Label>
              <Form.Control type="date" {...register('fechaDeTraslado')} />
            </Form.Group>
          </Col>
        </Row>
        <TrasportistaSection
          transportista={transportista}
          setTransportista={setTransportista}
          vehiculo={vehiculo}
          setVehiculo={setVehiculo}
          conductor={conductor}
          setConductor={setConductor}
        />
        <PartidaSection
          partidaUbigeo={partidaUbigeo}
          setPartidaUbigeo={setPartidaUbigeo}
          partidaDireccion={partidaDireccion}
          setPartidaDireccion={setPartidaDireccion}
        />
        <DestinoSection
          destinoUbigeo={destinoUbigeo}
          setDestinoUbigeo={setDestinoUbigeo}
          destinoDireccion={destinoDireccion}
          setDestinoDireccion={setDestinoDireccion}
        />
        <ItemsSection items={items} setItems={setItems} />
        <div className="d-flex gap-2 mt-3">
          <button type='button' className='btn btn-secondary' onClick={() => cancel()}>Cancelar</button>
          <button type='submit' className='btn btn-primary'>{isSubmitting ? "Generando..." : "Generar guía de remisión"}</button>
        </div>
      </Form>
    </>
  )

}

export default GuiaRemisionForm
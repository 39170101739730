import { axiosInstance } from "../utils/axios"
import useAuth from "./useAuth"

export default function usePurchase(purchaseType: string) {
    const { user } = useAuth();
    const addPurchase = async (purchase: any) => {
        try {
            return await axiosInstance.post(`/purchase/${purchaseType}`, purchase)
        } catch (error) {
            throw (error)
        }
    }

    const updatePurchase = async (purchaseId: number, purchase: any) => {
        try {
            return await axiosInstance.patch(`/purchase/${purchaseType}/${purchaseId}`, purchase)
        } catch (error) {
            throw (error)
        }
    }

    const confirmPurchase = async (purchaseId: number) => {
        try {
            return await axiosInstance.post(`/purchase/${purchaseType}/confirm/${purchaseId}`)
        } catch (error) {
            throw (error)
        }
    }

    const payPurchase = async (purchaseId: number, data: any) => {
        try {
            return await axiosInstance.post(`/purchase/${purchaseType}/pay/${purchaseId}`, data);
        } catch (error) {
            throw (error)
        }
    }

    const markPurchaseAsPayed = async (purchaseId: number) => {
        try {
            return await axiosInstance.post(`/purchase/${purchaseType}/markAsPayed/${purchaseId}`);
        } catch (error) {
            throw (error)
        }
    }

    return { addPurchase, updatePurchase, confirmPurchase, payPurchase, role: user?.role, markPurchaseAsPayed }
}
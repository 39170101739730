import React from 'react'
import { Title } from './styleds'
import { Vendor } from './useVendors';
import VendorForm from './VendorForm';

interface Props {
  isCreating: boolean;
  toggle: () => void;
  submitFunction: (v: Vendor) => void
}

const NewVendor = ({ isCreating, toggle, submitFunction }: Props) => {
  if (!isCreating) return null

  return (
    <>
      <Title>Nuevo Vendedor</Title>
      <VendorForm toggle={toggle} submitFunction={submitFunction}></VendorForm>
    </>
  )
}

export default NewVendor
import { useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { PageLayout } from '../../components/PageLayout'
import routes from '../../helpers/routes'
import { Customer, updateCustomer } from '../../utils/api/customers'
import CustomerForm from './CustomerForm'
import { useCustomerById } from './useCustomer'

function CustomerUpdate() {
  const history = useHistory()
  const { id: customerId } = useParams<any>()
  const { customer, setCustomer } = useCustomerById(customerId)
  const [error, setError] = useState(null)

  const handleSubmit = async (customer: Customer) => {
    try {
      await updateCustomer(customerId, customer)
      history.push(routes.customer(customerId))
    } catch (error: any) {
      setError(error)
    }
  }

  if (error) {
    return (
      <h1>
        {JSON.stringify(error, null, 2)}
      </h1>
    )
  }

  return (
    <PageLayout>
      {customer &&
        <div>
          <Breadcrumb>
            <Breadcrumb.Item linkProps={{ to: routes.customers }} linkAs={Link}>
              Clientes
            </Breadcrumb.Item>
            <Breadcrumb.Item linkProps={{ to: routes.customer(customer.id) }} linkAs={Link}>
              {customer.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Actualización de datos</Breadcrumb.Item>
          </Breadcrumb>
          <CustomerForm customer={customer} setCustomer={setCustomer} handleSubmit={handleSubmit} />
        </div>
      }
    </PageLayout>
  )
}

export default CustomerUpdate

import { axiosInstance } from "../utils/axios";
import { getTicketLink } from "../utils/functions/files";

export async function deleteOrder(orderId: number) {
  try {
    await axiosInstance.delete(`/orders/delete-order/${orderId}`);
  } catch (e) {
    throw (e)
  }
}

export async function unConfirmOrder(orderId: number) {
  try {
    await axiosInstance.post(`/orders/un-confirm-order/${orderId}`);
  } catch (e) {
    throw (e)
  }
}

export async function unDeliveredOrder(orderId: number) {
  try {
    await axiosInstance.post(`/orders/un-delivered-order/${orderId}`);
  } catch (e) {
    throw (e)
  }
}

export async function getTicket(orderId: number) {
  try {
    const res = await axiosInstance.get(`/orders/print-ticket/${orderId}`);
    return getTicketLink(res);
  } catch (e) {
    throw (e)
  }
}

export async function getTicketForInvoice(orderId: number) {
  try {
    const res = await axiosInstance.get(`/orders/print-invoice-ticket/${orderId}`);
    return getTicketLink(res);
  } catch (e) {
    throw (e)
  }
}

export async function getInvoice(orderId: string) {
  try {
    const res = await axiosInstance.get(`/orders/get-invoice/${orderId}`);
    return res.data
  } catch (e) {
    throw (e)
  }
}

export async function getXml(orderId: string) {
  try {
    const res = await axiosInstance.get(`/orders/get-xml/${orderId}`);
    return res.data
  } catch (e) {
    throw (e)
  }
}

export async function emitCreditNote(orderId: string, body: any) {
  try {
    const res = await axiosInstance.post(`/orders/credit-note/${orderId}`, body);
    return res.data
  } catch (e) {
    throw (e)
  }
}

export async function emitDeditNote(orderId: string, body: any) {
  try {
    const res = await axiosInstance.post(`/orders/debit-note/${orderId}`, body);
    return res.data
  } catch (e) {
    throw (e)
  }
}

export async function emitReferralGuide(data: any) {
  try {
    await axiosInstance.post('/orders/referral-guide', data);
  } catch (e) {
    throw (e)
  }
}

export async function getReferralGuides() {
  try {
    const res = await axiosInstance.get('/orders/referral-guides');
    return res.data;
  } catch (e) {
    throw (e)
  }
}
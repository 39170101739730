import React from 'react'
import styled from 'styled-components'
import FiltersForm from './FiltersForm'
import { Results } from './Results'

const HistorySellsOrdersPage = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  overflow-y: hidden;
`

function HistorySellOrders() {
  const [results, setResults] = React.useState(null);

  return (
    <>
      <HistorySellsOrdersPage>
        <FiltersForm setData={setResults}></FiltersForm>
        <Results data={results}></Results>
      </HistorySellsOrdersPage>
    </>
  )
}

export default HistorySellOrders
import writeXlsxFile from 'write-excel-file'
import { axiosInstance } from '../utils/axios';

export async function getTemplate() {
  try {
    const objects = [
      {
        name: 'equis de srl',
        documentNumber: '32846971542',
        documentType: 'RUC',
        address: 'Ejemplo cliente con RUC. Por favor reemplazar esta fila por un cliente real'
      },
      {
        name: 'pepe inga',
        documentNumber: '87451285',
        documentType: 'DNI',
        address: 'Ejemplo cliente con DNI. Por favor reemplazar esta fila por un cliente real'
      }
    ]

    const schema = [
      // Column #1
      {
        column: 'Nombre',
        type: String,
        value: (customer: any) => customer.name
      },
      // Column #2
      {
        column: 'Número de documento',
        type: String,
        value: (customer: any) => customer.documentNumber
      },
      // Column #3
      {
        column: 'Tipo de documento',
        type: String,
        value: (customer: any) => customer.documentType
      },
      // Column #4
      {
        column: 'Dirección',
        type: String,
        value: (customer: any) => customer.address
      }
    ]

    return await writeXlsxFile(objects, { schema, fileName: 'plantilla-clientes.xlsx' });
  } catch (error) {
    throw (error)
  }
}

export async function updateAddress(customerId: number, address: string) {
  try {
    await axiosInstance.post(`/customers/update-address/${customerId}`, { address });
  } catch (e) {
    throw (e)
  }
}
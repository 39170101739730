import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import writeXlsxFile from 'write-excel-file'
import { FullPageLayout } from '../../../components/FullPageLayout'
import { axiosInstance } from '../../../utils/axios'

const Wrapper = styled.div`
  padding: 1em 2em;
  overflow-y: hidden;
`

const Results = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1em;
  overflor-y: scroll;
`

function MakroProfitReportPage() {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      sinceDate: '',
      untilDate: dayjs().format('YYYY-MM-DD')
    }
  });
  const [results, setResults] = useState<any>(null);
  const [theoreticalTotalSumatory, setTheoreticalTotalSumatory] = useState<any>(null);
  const [realTotalSumatory, setRealTotalSumary] = useState<any>(null);
  const [totalInvoices, setTotalInvoices] = useState<any>(null);
  const [totalDiscount, setTotalDiscount] = useState<any>(null);
  const [error, setError] = useState(null);

  const onSubmit = async (data: any) => {
    try {
      setError(null);
      setResults(null);
      const response = await axiosInstance.get('/reports/makro/profit-report', {
        params: {
          since: data.sinceDate,
          until: data.untilDate
        }
      });
      setTheoreticalTotalSumatory(response.data.theoreticalTotalSumatory);
      setRealTotalSumary(response.data.realTotalSumatory);
      setTotalInvoices(response.data.totalInvoices);
      setTotalDiscount(Number(response.data.theoreticalTotalSumatory) - Number(response.data.realTotalSumatory));
      setResults(response.data.data);
    } catch (e: any) {
      setError(e)
    }
  }

  //TODO: corregir este reporte con la nueva data enviada desde la API
  // eslint-disable-next-line
  async function downloadExcel() {
    try {
      const schema = [
        {
          column: "Fecha",
          type: String,
          value: (sell: any) => dayjs(sell.sellDate).format("DD-MM-YYYY")
        },
        {
          column: "Factura",
          type: String,
          value: (sell: any) => sell.factura
        },
        {
          column: "Total",
          type: Number,
          format: '#,##0.00',
          value: (sell: any) => sell.total
        },
        {
          column: "Fecha de pago",
          type: String,
          value: (sell: any) => dayjs(sell.paymentDate).format("DD-MM-YYYY")
        },
        {
          column: "Número de grupo",
          type: String,
          value: (sell: any) => sell.grupo
        },
        {
          column: "Monto pagado",
          type: Number,
          format: '#,##0.00',
          value: (sell: any) => sell.amountPayed
        },
        {
          column: "Descuento total",
          type: Number,
          format: '#,##0.00',
          value: (sell: any) => sell.difference
        },
      ];
      return await writeXlsxFile(results, { schema, fileName: `pagos_makro_${dayjs().format('DD-MM-YYYY')}.xlsx` });
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FullPageLayout>
      <Wrapper>
        <div>
          <h3 style={{ textDecoration: 'underline' }}>Reporte de utilidades</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col">
                <label className='form-label' htmlFor="since">Desde</label>
                <input className='form-control' type="date" id="since" {...register('sinceDate')} />
              </div>
              <div className="col">
                <label className='form-label' htmlFor="until">Hasta</label>
                <input className='form-control' type="date" id="until" {...register('untilDate')} />
              </div>
              <div className="col" style={{ display: 'flex' }}>
                <button style={{ alignSelf: 'flex-end', width: '100%' }} className='btn btn-primary' type='submit'>Buscar</button>
              </div>
            </div>
          </form>
        </div>
        <Results>
          {error && <><h2 style={{ color: 'red' }}>Error en el servidor</h2></>}
          {results && results.length === 0 && <><h2 style={{ color: 'red' }}>No hay registros en el periodo buscado</h2></>}
          {results && results.length > 0 &&
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3>Total de facturas cobradas: {totalInvoices}</h3>
                <h3>Monto total teórico: S/. {theoreticalTotalSumatory}</h3>
                <h3>Monto real cobrado: S/. {realTotalSumatory}</h3>
                <h3>Descuentos totales: S/. {totalDiscount}</h3>
                {/* <button className='btn btn-success' onClick={downloadExcel}>Descargar reporte</button> */}
              </div>
            </>
          }
        </Results>
      </Wrapper>
    </FullPageLayout>
  )
}

export default MakroProfitReportPage
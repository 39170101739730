import React, { useState } from 'react';
import { FullPageLayout } from '../../../components/FullPageLayout';
import { Link, Switch, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components';
import PrivateRoute from '../../../routers/PrivateRoute';
import OrderForm from './OrderForm';
import Settings from './Settings';

const ContainerPage = styled.div`
  padding: 1rem;
  display: flex;
  gap: .6rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Menu = styled.div`
  border: 1px solid #ccc;
  padding: .5rem;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #ccc;
  display: flex;
  flex-direction: column;
  gap: .4rem;
`;

const ExpandButton = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const ResponsiveMenu = styled.div<{ isMenuExpanded: boolean }>`
  display: ${props => (props.isMenuExpanded ? 'flex' : 'none')};
  flex-direction: column;
  gap: .4rem;

  @media (min-width: 769px) {
    display: flex;
    flex-direction: column;
  }
`;

const DisplaySection = styled.div`
  flex: 1;
  padding: .5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #ccc;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
  padding: .5rem;
  border-radius: 5px;
  transition: background-color .3s;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const MakroUtilitiesPage: React.FC = () => {
  const [isMenuExpanded, setMenuExpanded] = useState(false);
  let { path, url } = useRouteMatch();

  return (
    <FullPageLayout>
      <ContainerPage>
        <Menu>
          <ExpandButton onClick={() => setMenuExpanded(!isMenuExpanded)}>
            {isMenuExpanded ? 'Contraer Menú' : 'Desplegar Menú'}
          </ExpandButton>
          <ResponsiveMenu isMenuExpanded={isMenuExpanded}>
            <StyledLink to={url}>Generar documentos</StyledLink>
            <StyledLink to={`${url}/configuraciones`}>Configuraciones</StyledLink>
          </ResponsiveMenu>
        </Menu>
        <DisplaySection>
          <Switch>
            <PrivateRoute exact path={path} component={OrderForm} />
            <PrivateRoute path={`${path}/configuraciones`} component={Settings} />
          </Switch>
        </DisplaySection>
      </ContainerPage>
    </FullPageLayout>
  );
};

export default MakroUtilitiesPage;
import React from "react"
import { Card, Col, Row } from "react-bootstrap";

interface Props<T> {
    children: (item: T) => React.ReactNode
    items: T[]
    searchParams: Array<keyof T>
}

interface IdObj {
    id: string | number
}

export const CardsList = <T extends IdObj>({
    children,
    items,
    searchParams
}: Props<T>) => {
    const [q, setQ] = React.useState("");
    let filterItems = items?.filter((item) => {
        return searchParams.some((newItem) => {
            const value = item[newItem]
            if (typeof value === "string" || typeof value === "number") {
                return value.toString().toLowerCase().includes(q.toLowerCase())
            }
            return false;
        });
    });

    return (
        <>
            <input
                type="text"
                className="form-control mb-2"
                name="search"
                value={q}
                onChange={(e) => setQ(e.target.value)}
                placeholder="Escriba aquí para buscar"
            />
            <Row xs={1} md={3} lg={4} className="g-4">
                {filterItems.map((item) => (
                    <Col key={item.id}>
                        <Card>
                            <Card.Body>
                                {children(item)}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    )
}
import { axiosInstance } from "../utils/axios"

export const deleteInput = async (inputId: number) => {
  try {
    await axiosInstance.delete(`/inputs/${inputId}`);
  } catch (e) {
    throw (e)
  }
}

export const setInputPrice = async (inputId: number, obj: { providerId: number, price: number, currency: string }) => {
  try {
    await axiosInstance.post(`/inputs/setPrice/${inputId}`, obj);
  } catch (e: any) {
    throw Error(e)
  }
}
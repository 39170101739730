import React from 'react'
import { MainSettingsSection } from './styleds'
import TransportistasPage from './transportistas/TransportistasPage'
import UbigeoSettings from './ubigeo/UbigeoSettings'

const Main = (props: any) => {
  return (
    <MainSettingsSection>
      {props.onUbigeo && !props.onTransportista &&
        <UbigeoSettings />
      }
      {props.onTransportista && !props.onUbigeo &&
        <TransportistasPage />
      }
    </MainSettingsSection>
  )
}

export default Main
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import TableList from '../../../../components/TableList';
import { axiosInstance } from '../../../../utils/axios';
import { CustomerModel } from '../../../customers/CustomersPage';

interface Invoice {
  id: number
  serie: string
  numero: string
  customerName: string
  customer: CustomerModel
}

const SearchForInvoice = ({ show, toggle, setInvoice }: any) => {
  const [invoices, setInvoices] = useState();
  const [errors, setErrors] = useState();

  const headers = ["Serie", "Número de documento", "Cliente", ""]

  useEffect(() => {
    axiosInstance.get('/orders/invoices-for-referral-guide')
      .then((res: any) => {
        return res.data;
      })
      .then((data: any) => setInvoices(data))
      .catch((err: any) => setErrors(err));
  }, []);

  function close() {
    toggle();
  }

  function handleSelect(invoice: Invoice) {
    setInvoice(invoice);
    close();
  }

  if (!invoices || errors) return null;
  return (
    <Modal size="lg" show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Buscar factura</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableList items={invoices} headers={headers} searchParams={["numero", "customerName"]}>
          {(invoice: Invoice) => (
            <>
              <td>{invoice.serie}</td>
              <td>{invoice.numero}</td>
              <td>{invoice.customerName}</td>
              <td style={{ textAlign: 'center' }}><button onClick={() => handleSelect(invoice)} type='button' className='btn btn-outline-success btn-sm'>Seleccionar</button></td>
            </>
          )}
        </TableList>
      </Modal.Body>
    </Modal>
  )
}

export default SearchForInvoice
import writeXlsxFile from 'write-excel-file'
import { axiosInstance } from '../utils/axios';

export async function getTemplate() {
  try {
    const objects = [
      {
        name: 'silicona para cueros x 250ml',
        sellPrice: 5.00,
        stock: 0,
        code: 'SC-250 - Por favor reemplazar esta fila por un producto real'
      },
      {
        name: 'Limpia melamine x 500ml',
        sellPrice: 5.50,
        stock: 100,
        code: 'LM-500 - Por favor reemplazar esta fila por un producto real'
      }
    ]

    const schema = [
      // Column #1
      {
        column: 'Nombre',
        type: String,
        value: (product: any) => product.name
      },
      // Column #2
      {
        column: 'Precio por mayor',
        type: Number,
        format: '0.00',
        value: (product: any) => product.sellPrice
      },
      // Column #3
      {
        column: 'Stock',
        type: Number,
        value: (product: any) => product.stock
      },
      // Column #4
      {
        column: 'Código',
        type: String,
        value: (product: any) => product.code
      }
    ]

    return await writeXlsxFile(objects, { schema, fileName: 'plantilla-productos.xlsx' });
  } catch (error) {
    throw (error)
  }
}

export async function deleteProduct(productId: number) {
  try {
    await axiosInstance.delete(`/products/${productId}`);
  } catch (e) {
    throw (e)
  }
}
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export default function SupplierForm({ initialSupplier, submitFunction, toogle }: any) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: initialSupplier ? initialSupplier.name : '',
      documentNumber: initialSupplier ? initialSupplier.documentNumber : '',
      documentType: initialSupplier ? initialSupplier.documentType : '',
      address: initialSupplier ? initialSupplier.address : '',
    }
  })

  const onSubmit = (data: any) => {
    const supplier = {
      name: data.name,
      documentNumber: data.documentNumber,
      documentType: data.documentType,
      address: data.address,
    }
    submitFunction(supplier)
  }

  return (
    <>
      {!initialSupplier ? <h2>Nuevo suministrador</h2> : <h2>Editar suministrador</h2>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Nombre del suministrador</Form.Label>
          <Form.Control type="text" placeholder="Nombre" {...register("name")} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="documentType">
          <Form.Label>Tipo de documento</Form.Label>
          <select className="form-select" aria-label="doctType" {...register("documentType")} required>
            <option value="">Selecciona el tipo de documento</option>
            <option value="DNI">DNI</option>
            <option value="RUC">RUC</option>
            <option value="CEX">Carnet de extranjería</option>
          </select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="documentNumber">
          <Form.Label>Número de documento</Form.Label>
          <Form.Control type="text" placeholder="# de identidad" {...register("documentNumber")} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="address">
          <Form.Label>Dirección</Form.Label>
          <Form.Control type="text" placeholder="Dirección fiscal" {...register("address")} required />
        </Form.Group>
        <div className="d-flex" style={{ gap: '10px' }}>
          <Button className="mr-3" variant="secondary" onClick={() => toogle()}>Cancelar</Button>
          <Button variant="primary" type="submit">
            {initialSupplier ? "Actualizar" : "Guardar"}
          </Button>
        </div>
      </form>
    </>
  )
}
import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useBoxes from '../../../hooks/useBoxes';

function PayPurchaseModal({ purchase, show, close, submitFunction }: any) {
  const { boxes } = useBoxes();
  const [partialPayment, setPartialPayment] = React.useState(false);
  const [differentCurrency, setDifferentCurrency] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = React.useState("");
  const { register, handleSubmit, reset } = useForm();

  function handleClose() {
    setPartialPayment(false);
    setDifferentCurrency(false);
    setSelectedCurrency("");
    reset();
    close();
  }

  function handleRadio(e: any) {
    if (e.target.value === "partial") {
      setPartialPayment(true);
    } else {
      setPartialPayment(false);
    }
  }

  function getCurrency(value: string | undefined): string {
    if (value === "soles") return "pen";
    else return "usd";
  }

  function handleSelectBox(e: any) {
    const boxId = e.target.value;
    const selectedBox = boxes.find(box => box.id === Number(boxId));
    const boxCurrency = getCurrency(selectedBox?.currency);
    setSelectedCurrency(boxCurrency);
    if (boxCurrency !== purchase.currency) setDifferentCurrency(true);
    else setDifferentCurrency(false);
  }

  function onSubmit(data: any) {
    submitFunction({
      partialPayment,
      differentCurrency,
      selectedBox: data.boxSelected,
      partialAmount: data.partialAmount,
      ussedAmount: data.ussedAmount,
      payDate: data.payDate,
    });
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Registro de pago [{purchase.serialNumber}-{purchase.documentNumber}]</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-check">
            <input className='form-check-input' type="radio" value="total" {...register("radioTotal", { onChange: (e) => handleRadio(e) })} id="isPayingTotal" required />
            <label className='form-check-label' htmlFor="isPayingTotal">Se paga todo el saldo pendiente <span style={{ color: 'red' }}>({purchase.toPay} {purchase.currency})</span></label>
          </div>
          <div className="form-check">
            <input className='form-check-input' type="radio" value="partial" {...register("radioTotal", { onChange: (e) => handleRadio(e) })} id="isPayingPartial" required />
            <label className='form-check-label' htmlFor="isPayingPartial">Se paga una parte</label>
          </div>
          {partialPayment &&
            <div className='mt-2'>
              <label htmlFor="partialAmount" className='form-label'>Monto cancelado <span style={{ color: 'red' }}>({(purchase.currency).toUpperCase()})</span></label>
              <input className='form-control' type="number" step="0.01" id="partialAmount" {...register("partialAmount", { validate: value => value <= Number(purchase.toPay) })} required placeholder="Ingresar el monto cancelado" />
            </div>
          }
          <div className='mt-2'>
            <label htmlFor='pay-date' className='form-label'>Fecha de pago</label>
            <input type="date" className='form-control' id='pay-date' {...register("payDate")} required />
          </div>
          <div className="mt-2">
            <label htmlFor="boxSelected" className='form-label'>Cuenta origen</label>
            <select className='form-select' id='boxSelected' {...register("boxSelected", { onChange: (e) => handleSelectBox(e) })} required>
              <option value="">Selecione la cuenta origen</option>
              {boxes.map((box: any) => (
                <option key={box.id} value={box.id}>{box.name}</option>
              ))}
            </select>
          </div>
          {differentCurrency &&
            <div className='mt-2'>
              <label htmlFor="ussedAmount" className="form-label">Monto usado para pagar <span style={{ color: 'red' }}>({(selectedCurrency).toUpperCase()})</span></label>
              <input className='form-control' type="number" step="0.01" id="ussedAmount" {...register("ussedAmount")} required placeholder="Ingrese el monto usado" />
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Registrar pago
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default PayPurchaseModal
import React from 'react'
import styled from "styled-components";
import { FullPageLayout } from '../../../components/FullPageLayout'
import { axiosInstance } from '../../../utils/axios';
import InputUserReport from './InputUserReport';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: .8em;
  overflow-y: hidden;
`

const SelectReportPart = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: .8em;
`

const ReportItem = styled.div`
  padding: .25em .5em;
  display: flex;
  align-items: center;
  column-gap: .5em;
  border: 1px solid;
  border-color: red;
  border-radius: 8px;
`

const DisplayReport = styled.div`
  margin-top: 1em;
  flex: 1;
  border: 1px solid green;
  overflow-y: scroll;
  padding: .8em;
`

function VariosReportPage() {
  const [reportData, setReportData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleInputUsedReport = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get('inputs/used-info');
      setReportData(res.data)
      setLoading(false);
    } catch (e) {
      alert(e)
    }
  }

  return (
    <FullPageLayout>
      <Container>
        <SelectReportPart>
          <ReportItem>
            <div>Reporte de uso de insumos</div>
            <button className="btn btn-sm btn-primary" onClick={handleInputUsedReport}>Ejecutar reporte</button>
          </ReportItem>
        </SelectReportPart>
        <DisplayReport>
          {loading && <h5>Cargando datos...</h5>}
          {reportData && <InputUserReport data={reportData} />}
        </DisplayReport>
      </Container>
    </FullPageLayout>
  )
}

export default VariosReportPage
import React, { useEffect, useState, createContext } from "react"
import { useHistory } from "react-router-dom"
import routes from "../helpers/routes"
import { callLogin, callLogout, getCurrentUser } from "../utils/api/auth"
import { ICredentials, IUser } from "../utils/interfaces"

interface IContextProps {
    user: IUser | null,
    login: (credentials: ICredentials, from: Location) => void,
    logout: () => void
    isLogged: () => boolean,
    hasRole: (role: string) => boolean
    error: string
    isVendor: () => boolean
}

export const AuthContext = createContext({} as IContextProps)

const AuthProvider = ({ children }: any) => {
    let history = useHistory()
    const [user, setUser] = useState<IUser | null>(null)
    const [error, setError] = useState('')

    const login = async (userCredentials: ICredentials, fromLocation: Location) => {
        callLogin(userCredentials)
            .then(data => {
                const { user } = data.payload
                setUser(user);
                localStorage.setItem('userId', user.id);
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);
                if (fromLocation) {
                    history.push(fromLocation.pathname);
                } else {
                    history.push(routes.home);
                }
            })
            .catch(err => setError(err.message))
    }

    const logout = () => {
        callLogout()
            .then(() => {
                setUser(null);
                setError('');
                localStorage.clear();
                history.push(routes.login);
            })
    }

    const isLogged = () => {
        const userId = localStorage['userId'];
        if (userId) {
            return true;
        }
        return false;
    }

    const hasRole = (role: string) => {
        return user?.role === role
    };

    const isVendor = () => user?.role === 'vendedor'

    useEffect(() => {
        (async () => {
            const userId = localStorage['userId'];
            if (userId) {
                const userFromServer = await getCurrentUser()
                setUser(userFromServer)
            } else {
                setUser(null);
            }
        })()
    }, []);

    const contextValue = {
        user,
        login,
        logout,
        isLogged,
        hasRole,
        error,
        isVendor
    }

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default AuthProvider;
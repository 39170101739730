import { axiosInstance } from "../axios";

export const fetchAllSupplies = async () => {
  try {
    const res = await axiosInstance.get('/supplies');
    return res.data
  } catch (error) {
    throw (error)
  }
}

export const fetchSupply = async (id: number) => {
  try {
    const res = await axiosInstance.get(`/supplies/${id}`);
    return res.data
  } catch (error) {
    throw (error)
  }
}

export const createSupply = async (body: {}) => {
  try {
    return await axiosInstance.post('/supplies', body)
  } catch (error) {
    throw (error)
  }
}

export const updateSupply = async (id: number, body: {}) => {
  try {
    return await axiosInstance.patch(`/supplies/${id}`, body)
  } catch (error) {
    throw (error)
  }
}
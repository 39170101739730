import MoneyChartComponet from './MoneyChartComponet'

function EgresosComponent() {
  return (
    <MoneyChartComponet
      color="red"
      movementType="salida"
      chartTitle='Egresos (S/.)'
      borderColor='rgb(255, 99, 132)'
      backgroundColor='rgba(255, 99, 132, 0.5)'
    />
  )
}

export default EgresosComponent
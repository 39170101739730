const routes = {
    home: '/',
    dashboards: '/dashboards',
    login: '/login',
    account: '/cuenta',
    vendors: '/vendedores',
    remision: '/guia-de-remision',
    ventas: {
        index: '/ventas',
        nueva: '/ventas/nueva',
        ver: (id?: number) => (id ? `/ventas/ver/${id}` : '/ventas/ver/:id'),
        editar: (id?: number) => (id ? `/ventas/editar/${id}` : '/ventas/editar/:id'),
    },
    orders: '/ordenes-de-venta',
    adminOrders: '/admin/ordenes-de-venta',
    newOrder: '/nueva/orden-de-venta',
    creditNote: (id?: string) => (id ? `/notas-de-credito/order/${id}` : '/notas-de-credito/order/:id'),
    viewOrder: (id?: string) => (id ? `/ordenes-de-venta/${id}` : '/ordenes-de-venta/:id'),
    editOrder: (id?: string) => (id ? `/ordenes-de-venta/editar/${id}` : '/ordenes-de-venta/editar/:id'),
    customers: '/clientes',
    customer: (id?: number) => (id ? `/clientes/${id}` : '/clientes/:id'),
    updateCustomer: (id?: number) => (id ? `/clientes/actualizar/${id}` : '/clientes/actualizar/:id'),
    providers: '/proveedores',
    provider: (id?: number) => (id ? `/proveedores/${id}` : '/proveedores/:id'),
    supplies: '/suministros',
    suppliers: '/suministradores',
    inputs: '/insumos',
    inputsState: '/insumos/estado',
    input: (id?: number) => (id ? `/insumos/${id}` : '/insumos/:id'),
    products: '/productos',
    product: (id?: number) => (id ? `/productos/${id}` : '/productos/:id'),
    product_groups: '/grupo-de-productos',
    product_group: (id?: number) => (id ? `/grupo-de-productos/${id}` : '/grupo-de-productos/:id'),
    newProductGroup: '/nuevo/grupo-de-productos',
    updateProductGroup: (id?: number) => (id ? `/editar/grupo-de-productos/${id}` : '/editar/grupo-de-productos/:id'),
    purchase: {
        inputs: '/compra/insumos',
        viewInput: (id?: number) => (id ? `/compra/insumos/${id}` : '/compra/insumos/:id'),
        editInput: (id?: number) => (id ? `/compra/insumos/editar/${id}` : '/compra/insumos/editar/:id'),
        supplies: '/compra/suministros',
        viewSupply: (id?: number) => (id ? `/compra/suministros/${id}` : '/compra/suministros/:id'),
        editSupply: (id?: number) => (id ? `/compra/suministros/editar/${id}` : '/compra/suministros/editar/:id'),
    },
    production: {
        productRecipes: '/recetas/productos',
        viewProductRecipe: (id?: number) => (id ? `/recetas/productos/${id}` : '/recetas/productos/:id'),
        editProductRecipe: (id?: number) => (id ? `/recetas/productos/editar/${id}` : '/recetas/productos/editar/:id'),
        inputRecipes: '/recetas/insumos',
        viewInputRecipe: (id?: number) => (id ? `/recetas/insumos/${id}` : '/recetas/insumos/:id'),
        editInputRecipe: (id?: number) => (id ? `/recetas/insumos/editar/${id}` : '/recetas/insumos/editar/:id'),
        inputProductions: '/produccion/insumos',
        viewInputProduction: (id?: number) => (id ? `/produccion/insumos/${id}` : '/produccion/insumos/:id'),
        editInputProduction: (id?: number) => (id ? `/produccion/insumos/editar/${id}` : '/produccion/insumos/editar/:id'),
        productProductions: '/produccion/productos',
        viewProductProduction: (id?: number) => (id ? `/produccion/productos/${id}` : '/produccion/productos/:id'),
        editProductProduction: (id?: number) => (id ? `/produccion/productos/editar/${id}` : '/produccion/productos/editar/:id')
    },
    reports: {
        sales: '/reportes/ventas',
        productsByCustomers: '/reportes/productos-por-clientes',
        kardex: '/reportes/kardex',
        accounting: '/reportes/contabilidad',
        various: '/reportes/reportes-varios',
        financial: '/reportes/financieros',
        data: '/reportes/datos',
    },
    productions: {
        index: '/producciones',
        newProduction: '/producciones/nueva',
        viewProduction: (id?: number) => (id ? `/producciones/ver/${id}` : '/producciones/ver/:id'),
        editProduction: (id?: number) => (id ? `/producciones/editar/${id}` : '/producciones/editar/:id'),
        customizeProduction: (id?: number) => (id ? `/producciones/personalizar/${id}` : '/producciones/personalizar/:id'),
    },
    filling: {
        index: '/llenado',
        makewfilling: (id?: number) => (id ? `/llenado/hacer/${id}` : '/llenado/hacer/:id'),
    },
    recipes: {
        index: '/recipes',
        newRecipe: '/recipes/new',
        viewRecipe: (id?: number) => (id ? `/recipes/view/${id}` : '/recipes/view/:id'),
        editRecipe: (id?: number) => (id ? `/recipes/edit/${id}` : '/recipes/edit/:id'),
    },
    boxes: '/money',
    box: (id?: string) => (id ? `/money/${id}` : '/money/:id'),
    admin: {
        users: '/admin/usuarios',
        config: '/admin/config',
    },
    makro: {
        cobros: '/makro/cobros',
        addMakroPayment: '/makro/cobros/registro-de-cobro',
        profitReport: '/makro/reporte/lucro',
        utils: '/makro/utilidades',
    },
    almacenes: {
        productos: '/almacen',
        insumos: '/almacen/insumos',
        kardex: '/almacen/kardex'
    },
    dashboard: {
        insumos: '/dashboard/insumos',
        productos: '/dashboard/productos',
    },
    maestros: {
        index: '/maestros',
    }
};

export default routes;
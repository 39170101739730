import { Button } from 'react-bootstrap'
import { PageLayout } from '../../../components/PageLayout'
import OrdersTabs from './tabs/OrdersTabs'
import { useHistory } from 'react-router-dom'
import routes from '../../../helpers/routes'

function OrdersPage() {
  let history = useHistory<any>()

  return (
    <PageLayout>
      <>
        <Button variant="success" className="mb-3" onClick={() => history.push(routes.newOrder)}>Nueva orden de venta</Button>
        <OrdersTabs />
      </>
    </PageLayout>
  )
}

export default OrdersPage

import React from 'react'
import toast from 'react-hot-toast'

interface IdObj {
  id: string | number
}

interface State<T> {
  items: T[],
  addingNew: boolean
  isLoading: boolean
  isError: boolean
}

function withFecthData<T extends IdObj>(Component: React.ElementType) {
  return (fecthFunction: any, addFunction: any, useUserId: boolean = false) => class extends React.Component {
    state: State<T> = {
      items: [],
      addingNew: false,
      isLoading: true,
      isError: false
    }

    componentDidMount() {
      this.fetcher()
    }

    fetcher = async () => {
      try {
        let items
        if (useUserId) {
          const userId = localStorage['userId']
          items = await fecthFunction(userId)
        } else {
          items = await fecthFunction()
        }
        this.setState({
          ...this.state,
          items,
          isLoading: false
        })
      } catch (e) {
        this.setState({ ...this.state, isError: true })
      }
    }

    toogleNewItem = () => {
      this.setState({
        ...this.state,
        addingNew: !this.state.addingNew
      })
    }

    addItem = (item: Partial<T>) => {
      addFunction(item).then(() => {
        this.fetcher()
        this.toogleNewItem()
        toast.success('¡Creado correctamente!.')
      }).catch((e: any) => toast.error(e.response.data))
    }

    render() {
      return (
        <Component
          items={this.state.items}
          addingNewItem={this.state.addingNew}
          loading={this.state.isLoading}
          error={this.state.isError}
          toogleNewItemForm={this.toogleNewItem}
          addNewItem={this.addItem}
          mutate={this.fetcher}
        />
      )
    }
  }
}

export interface WraperPorps<T> {
  items: T[]
  addingNewItem: boolean
  loading: boolean
  error: boolean
  toogleNewItemForm: () => void
  addNewItem: (item: Partial<T>) => void
  mutate: () => void
}

export default withFecthData
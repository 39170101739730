import React from 'react'
import styled from 'styled-components'
const DaysSection = styled.div`
  flex: .7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`

const Day = styled.div`
  font-size: 1.6rem;
  font-weight: 800;
`

const weekDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];

function DaysSectionComponent() {
  const [dayNumber, setDayNumber] = React.useState<number>(0);

  React.useEffect(() => {
    const day = new Date();
    setDayNumber(day.getDay());
  }, []);

  return (
    <DaysSection>
      {weekDays.map((day, i) => (
        <Day style={{ color: dayNumber - 1 === i ? 'orangered' : 'black' }} key={i}>{day}</Day>
      ))}
    </DaysSection>
  )
}

export default DaysSectionComponent
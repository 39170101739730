import { Button, Modal } from "react-bootstrap"

export default function ModalForm(props: any) {
    const { title, show, close, submitFunction } = props

    return (
        /* <Modal backdrop="static" keyboard={false} show={show} onHide={close} centered> */
        <Modal size="lg" backdrop="static" keyboard={false} show={show} onHide={close}>
            <Modal.Header>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={submitFunction}>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary">Guardar</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
import { axiosInstance } from "../utils/axios"

export async function getRUCData(ruc: string) {
  try {
    const res = await axiosInstance.get(`/apisnet/consulta-ruc/${ruc}`)
    return res.data
  } catch (e: any) {
    throw (e.response.data)
  }
}

export async function getDNIData(dni: string) {
  try {
    const res = await axiosInstance.get(`/apisnet/consulta-dni/${dni}`)
    return res.data
  } catch (e: any) {
    throw (e.response.data)
  }
}
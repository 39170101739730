import useSWR from 'swr'

export interface Product {
    id: number
    name: string
}

interface ReturnType {
    products: Product[]
    isLoading: boolean
    isError: any
    mutate: any
}

export function useProducts(): ReturnType {
    const { data, error, mutate } = useSWR('/products')

    return {
        products: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    }
}

export function useProduct(id: number) {
    const { data, error } = useSWR(`/products/${id}`)

    return {
        product: data,
        isLoading: !error && !data,
        isError: error
    }
}
import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Purchase, useMakroPurchases } from '../../../hooks/usePurchases';

export default function AddDiscountModal({ show, toogle, addFunction }: any) {
  const { purchases } = useMakroPurchases();
  const [selectedOrder, setSelectedOrder] = useState<Purchase[]>([]);

  const handleClose = () => {
    setSelectedOrder([]);
    toogle();
  }

  const handleSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    const item = {
      id: selectedOrder[0].id,
      date: selectedOrder[0].date,
      invoice: selectedOrder[0].serialNumber + "-" + selectedOrder[0].documentNumber,
      total: Number(selectedOrder[0].total)
    }
    addFunction(item);
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar factura de venta</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {/* {isError && <div>Error al cargar los datos del servidor</div>}
          {isLoading && <div>Cargando datos...</div>} */}
          <Row>
            <Form.Group className="mt-0" controlId="order">
              <Form.Label>Facturas de descuentos</Form.Label>
              <Typeahead
                id="order-selected"
                labelKey={(option) => `${option.serialNumber}-${option.documentNumber}`}
                onChange={setSelectedOrder}
                options={purchases}
                selected={selectedOrder}
                placeholder="Buscar factura"
                inputProps={{ required: true }}
              />
            </Form.Group>
          </Row>
          {selectedOrder[0] &&
            <Row>
              <Col>
                <div>Monto total de la factura: {selectedOrder[0].total}</div>
                <div>Fecha de emisión: {selectedOrder[0].date}</div>
              </Col>
            </Row>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

import { useHistory } from "react-router-dom";
import TableList from "../../../../components/TableList1";
import routes from "../../../../helpers/routes";
import { formatOrderState, formatMoney } from "../../../../utils/functions";
import { ViewButton } from "../../../common/styled";

export default function FinishedTab({ orders }: any) {
  let history = useHistory<any>()

  const headers = ['Vendedor', 'Cliente', 'Importe Total', 'Tipo', 'Estado', ''];
  const body = orders?.map((order: any) => (
    <tr key={order.id}>
      <td>{order.user.username}</td>
      <td>{order.customer.name}</td>
      <td>{formatMoney(order.total)}</td>
      <td>{order.futureDocument}</td>
      <td>{formatOrderState(order)}</td>
      <td className="d-flex gap-1">
        <ViewButton title="Ver detalle" onClick={() => history.push(routes.viewOrder(order.id))}><i className="bi bi-eye"></i></ViewButton>
      </td>
    </tr>
  ))

  return (
    <TableList headers={headers} body={body} />
  )
}
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import routes from "../../../helpers/routes";
import useModal from "../../../hooks/useModal";
import useSuppliers, { Supplier } from "../../../hooks/useSuppliers";
import useSupplies, { Supply } from "../../../hooks/useSupplies";
import { countDecimals } from "../../../utils/functions";
import { filterPeriods, Period } from "../common";

export default function SupplyManualPurchaseForm({ initialData, submitFunction, toogle }: any) {
    const history = useHistory()
    const [periodos, setPeriodos] = useState<Period[]>([]);
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            purchaseDate: initialData ? initialData.date : "",
            purchasePeriod: initialData ? initialData.period : "",
            serialNumber: initialData ? initialData.serialNumber : "",
            documentNumber: initialData ? initialData.documentNumber : "",
            currency: initialData ? initialData.currency : "",
        }
    });
    const addModal = useModal();
    const [items, setItems] = useState(initialData ? initialData.items : []);
    const { suppliers } = useSuppliers();
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier[]>(initialData ? [initialData.supplier] : []);
    const [subTotal, setSubTotal] = useState<number>(initialData ? Number(initialData.subTotal) : 0)
    const [igv, setIgv] = useState<number>(initialData ? Number(initialData.igv) : 0)
    const [total, setTotal] = useState<number>(initialData ? Number(initialData.total) : 0)

    useEffect(() => {
        const day = new Date();
        const periodosFiltrados = filterPeriods(day.getMonth() + 1)
        setPeriodos(periodosFiltrados);
        setValue('purchasePeriod', periodosFiltrados[0].value.toString())
    }, [setValue]);

    const addItem = (item: any) => {
        setItems([...items, item])
        const newSubTotal = Number(Number(subTotal + (item.amount * item.unitPrice)).toFixed(2))
        const newIgv = Number((newSubTotal * 0.18).toFixed(2))
        const newTotal = Number((newSubTotal + newIgv).toFixed(2))
        setSubTotal(newSubTotal)
        setIgv(newIgv)
        setTotal(newTotal)
    }

    const removeItem = (item: any) => {
        setItems(items.filter((i: any) => i !== item))
        const newSubTotal = Number(Number(subTotal - (item.amount * item.unitPrice)).toFixed(2))
        const newIgv = Number((newSubTotal * 0.18).toFixed(2))
        const newTotal = Number((newSubTotal + newIgv).toFixed(2))
        setSubTotal(newSubTotal)
        setIgv(newIgv)
        setTotal(newTotal)
    }

    const handleCancel = () => {
        if (initialData) {
            history.push(routes.purchase.viewSupply(initialData.id))
        } else {
            toogle();
        }
    }

    const onSubmit = (data: any) => {
        if (items.length > 0) {
            const purchase = {
                supplierId: selectedSupplier[0].id,
                date: data.purchaseDate,
                serialNumber: data.serialNumber,
                documentNumber: data.documentNumber,
                period: data.purchasePeriod,
                documentType: 'FACTURA',
                currency: data.currency,
                total,
                subTotal,
                igv,
                items
            }
            submitFunction(purchase)
        } else {
            alert("La compra no contiene items")
        }
    }

    return (
        <>
            {initialData ? <h2>Editar Compra Manual</h2> : <h2>Nueva Compra Manual</h2>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col>
                        <Form.Group className="mt-3" controlId="supplier">
                            <Form.Label>Suministrador</Form.Label>
                            <Typeahead
                                id="supplier-selected"
                                labelKey="name"
                                onChange={setSelectedSupplier}
                                options={suppliers}
                                selected={selectedSupplier}
                                placeholder="Buscar suministrador"
                                inputProps={{ required: true }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mt-3" controlId="purchaseDate">
                            <Form.Label>Fecha de compra</Form.Label>
                            <Form.Control type="date" placeholder="Selecciona fecha" {...register("purchaseDate")} required></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mt-3" controlId="purchasePeriod">
                            <Form.Label>Periodo</Form.Label>
                            <select {...register("purchasePeriod")} className="form-select" aria-label="period" required>
                                {periodos.map((p, index) => {
                                    return <option key={index} value={p.value}>{p.text}</option>
                                })}
                            </select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mt-3" controlId="serialNumber">
                            <Form.Label>Serie del comprobante</Form.Label>
                            <Form.Control type="text" placeholder="Ingresar la serie" {...register("serialNumber")} required></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mt-3" controlId="documentNumber">
                            <Form.Label>Número del comprobante</Form.Label>
                            <Form.Control type="text" placeholder="Ingresar el número" {...register("documentNumber")} required></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mt-3" controlId="currency">
                            <Form.Label>Moneda</Form.Label>
                            <select {...register("currency")} className="form-select" aria-label="currency" required>
                                <option value="">Selecciona una la moneda</option>
                                <option value="pen">Soles</option>
                                <option value="usd">Dólares americanos</option>
                            </select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table bordered size="sm" className="mt-3">
                            <thead>
                                <tr>
                                    <th>Insumo</th>
                                    <th>Cantidad</th>
                                    <th>Precio unitario</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item: any, index: number) => (
                                    <tr key={index}>
                                        <td>{item.supply.name}</td>
                                        <td>{item.amount}</td>
                                        <td>{item.unitPrice}</td>
                                        <td>
                                            <div className="d-grid">
                                                <Button size="sm" variant="outline-danger" onClick={() => removeItem(item)}>X</Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>
                                        <div className="d-grid">
                                            <Button size="sm" variant="outline-primary" onClick={() => addModal.toogleModal()}>Agergar suministro</Button>
                                        </div>
                                    </td>
                                    <td colSpan={4}></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <div className="d-flex mt-3 flex-row justify-content-between">
                    <div className="d-flex gap-2" style={{ height: '2.5rem' }}>
                        <button className="btn btn-secondary" onClick={handleCancel}>Cancelar</button>
                        <button className="btn btn-primary" type="submit">Guardar</button>
                    </div>
                    <div className="d-flex flex-column p-3 border">
                        <div className="d-flex gap-4 justify-content-between">
                            <span className="fw-bold">Sub total</span>
                            <input type="number" step="0.01" value={subTotal} onChange={(e) => setSubTotal(Number(e.target.value))} />
                        </div>
                        <div className="d-flex gap-4 justify-content-between">
                            <span className="fw-bold">IGV</span>
                            <input type="number" step="0.01" value={igv} onChange={(e) => setIgv(Number(e.target.value))} />
                        </div>
                        <div className="d-flex gap-4 justify-content-between">
                            <span className="fw-bold">Total</span>
                            <input type="number" step="0.01" value={total} onChange={(e) => setTotal(Number(e.target.value))} />
                        </div>
                    </div>
                </div>
            </form>
            <AddItemModal show={addModal.isOpenModal} toogle={addModal.toogleModal} addFunction={addItem} />
        </>
    )
}

function AddItemModal({ show, toogle, addFunction }: any) {
    const { register, handleSubmit, setValue } = useForm();
    const { supplies } = useSupplies();
    const [selectedSupply, setSelectedSupply] = useState<Supply[]>([]);
    const [withIGV, setWithIGV] = useState(0);

    const toggleIGV = () => setWithIGV((v: number) => v === 0 ? 1 : 0);

    const handleClose = () => {
        setSelectedSupply([])
        setValue("amount", "")
        setValue("unitPrice", "")
        setWithIGV(0)
        toogle()
    }

    const onSubmit = (data: { amount: number, unitPrice: number }) => {
        const price = withIGV ? Number((data.unitPrice / 1.18).toFixed(countDecimals(data.unitPrice))) : data.unitPrice;
        const supply = {
            supply: {
                id: selectedSupply[0].id,
                name: selectedSupply[0].name
            },
            amount: data.amount,
            unitPrice: price,
        }
        addFunction(supply);
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar suministro</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mt-0" controlId="supply">
                                <Form.Label>Insumo</Form.Label>
                                <Typeahead
                                    id="supply-selected"
                                    labelKey="name"
                                    onChange={setSelectedSupply}
                                    options={supplies}
                                    selected={selectedSupply}
                                    placeholder="Buscar suministro"
                                    inputProps={{ required: true }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mt-3" controlId="amount">
                                <Form.Label>Cantidad</Form.Label>
                                <Form.Control type="text" placeholder="Cantidad" {...register("amount")} autoComplete="off" required />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mt-3" controlId="price">
                                <Form.Label>Precio</Form.Label>
                                <Form.Control type="text" placeholder="P.U" {...register("unitPrice")} autoComplete="off" required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Form.Check
                                type="switch"
                                value={withIGV}
                                onClick={toggleIGV}
                                id="igv-switch"
                                label="Incluye IGV"
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" type="submit">
                        Agregar
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
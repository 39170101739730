import React from 'react'
import { FullPageLayout } from '../../../components/FullPageLayout'
import { useHistory, useParams } from 'react-router-dom';
import { useRecipe } from '../utils';
import { Container } from './RecipesPage';
import RecipeForm from './RecipeForm';
import { axiosInstance } from '../../../utils/axios';
import toast from 'react-hot-toast';

export default function EditRecipePage() {
  let history = useHistory();
  const { id: recipeId } = useParams<{ id: string }>();
  const { recipe, loading } = useRecipe(Number(recipeId));
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleSubmit = (editedRecipe: any) => {
    setSubmitting(true)
    axiosInstance.patch(`/recipes/update-recipe/${recipeId}`, editedRecipe).then(() => {
      setSubmitting(false)
      toast.success('Receta actualizada')
      history.push(`/recipes/view/${recipeId}`)
    }).catch((error) => {
      setSubmitting(false)
      toast.error('Error actualizando receta')
    })
  }

  if (loading) {
    return <FullPageLayout>
      <Container>
        <p>Cargando...</p>
      </Container>
    </FullPageLayout>
  }

  return (
    <FullPageLayout>
      <Container>
        {recipe && <RecipeForm initialData={recipe} submitFunction={handleSubmit} loading={submitting} />}
      </Container>
    </FullPageLayout>
  )
}

import React from 'react';
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import useModal from '../../../hooks/useModal';
import { axiosInstance } from '../../../utils/axios';
import styled from 'styled-components';
import BasicPageTitle from '../../../components/BasicPageTitle';
import { formatMoney, toLocalDateFormat } from '../../../utils/functions';
import ModalForm from '../../../components/ModalForm';

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`

const MyRow = styled(Row)`
  overflow-y: scroll;
`

export interface BoxMovement {
  id: string,
  date: Date,
  byOrTo: string,
  amount: number,
  detail: string,
  box_movement_reason: MovementReason
}

interface MovementReason {
  id: number,
  name: string,
  type: string
}

export interface Box {
  id: number
  name: string
  total: number
  isCci: boolean
  isOfficialBox: boolean
  currency: string
  box_movements: BoxMovement[]
}

export default function BoxDetails() {
  const { id: boxId } = useParams<{ id: string }>();
  const [box, setBox] = React.useState<Box>();
  const [inputs, setInputs] = React.useState<BoxMovement[]>([]);
  const [outputs, setOuputs] = React.useState<BoxMovement[]>([]);
  const inputModal = useModal();
  const outputModal = useModal();
  const [inputReasons, setInputReasons] = React.useState<MovementReason[]>();
  const [outputReasons, setOutputReasons] = React.useState<MovementReason[]>();

  const theadsInputs = ['Fecha', 'Persona que entrega', 'Cantidad de dinero', 'Motivo', 'Referencia'];
  const theadsOutputs = ['Fecha', 'Persona quien recibe', 'Cantidad de dinero', 'Motivo', 'Referencia'];

  function getBox(id: string) {
    axiosInstance.get(`/boxes/${id}`)
      .then(res => res.data)
      .then((data: Box) => {
        setInputs(data.box_movements.filter(m => m.box_movement_reason['type'] === 'ingreso'));
        setOuputs(data.box_movements.filter(m => m.box_movement_reason['type'] === 'salida'));
        setBox(data);
      });
  }

  React.useEffect(() => {
    getBox(boxId);
    axiosInstance.get('/boxes/movement-reasons/')
      .then(res => res.data)
      .then((data: MovementReason[]) => {
        setInputReasons(data.filter(r => r.type === 'ingreso'));
        setOutputReasons(data.filter(r => r.type === 'salida'));
      })
      .catch(err => console.log(err))
  }, [boxId]);

  let name = box?.name
  let total = box?.total
  return (
    <Container>
      <BasicPageTitle title={name} subtitle={`${total && formatMoney(total)}`} />
      <MyRow className="mt-1">
        <Tabs defaultActiveKey="inputs" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="inputs" title="Ingresos">
            <MovementsTable headers={theadsInputs} body={inputs} toogleModal={inputModal.toogleModal} type="ingreso" />
          </Tab>
          <Tab eventKey="outputs" title="Salidas">
            <MovementsTable headers={theadsOutputs} body={outputs} toogleModal={outputModal.toogleModal} type="salida" />
          </Tab>
        </Tabs>
      </MyRow>
      <AddInputMovement refresh={getBox} boxId={boxId} isOpen={inputModal.isOpenModal} closeModal={inputModal.toogleModal} reasons={inputReasons} />
      <AddOutputMovement refresh={getBox} boxId={boxId} isOpen={outputModal.isOpenModal} closeModal={outputModal.toogleModal} reasons={outputReasons} />
    </Container>
  )
}

function MovementsTable(props: any) {
  const { headers, body, toogleModal, type } = props;
  return (
    <>
      <button className="btn btn-primary mb-3" onClick={toogleModal}>Agregar movimiento</button>
      {body.length === 0
        ?
        <h2>{`No hay ningún ${type} registrado`}</h2>
        :
        <Table bordered hover size="sm">
          <thead>
            <tr>
              {headers.map((thead: string) => <th key={thead}>{thead}</th>)}
            </tr>
          </thead>
          <tbody>
            {body.map((i: BoxMovement) =>
              <tr key={i.id}>
                <td>{toLocalDateFormat(i.date.toString())}</td>
                <td>{i.byOrTo}</td>
                <td>{i.amount}</td>
                <td>{i.box_movement_reason['name']}</td>
                <td>{i.detail}</td>
              </tr>
            )}
          </tbody>
        </Table>
      }
    </>
  );
}

function AddInputMovement(props: any) {
  const { refresh, boxId, isOpen, closeModal, reasons } = props;
  return (
    <AddMovementForm refresh={refresh} boxId={boxId} showModal={isOpen} closeModal={closeModal} type="Ingreso" reasons={reasons} />
  )
}

function AddOutputMovement(props: any) {
  const { refresh, boxId, isOpen, closeModal, reasons } = props;
  return (
    <AddMovementForm refresh={refresh} boxId={boxId} showModal={isOpen} closeModal={closeModal} type="Salida" reasons={reasons} />
  )
}

function AddMovementForm(props: any) {
  const { refresh, boxId, showModal, closeModal, type, reasons } = props;

  function addMovement(e: React.FormEvent<EventTarget>) {
    e.preventDefault();
    axiosInstance.post(`/boxes/movements/${boxId}`, {
      date: (document.getElementById('date') as HTMLInputElement).value,
      byOrTo: (document.getElementById('byorto') as HTMLInputElement).value,
      amount: Number((document.getElementById('amount') as HTMLInputElement).value),
      detail: (document.getElementById('details') as HTMLInputElement).value,
      boxId: boxId,
      boxMovementReasonId: (document.getElementById('reason') as HTMLInputElement).value
    })
      .then(() => {
        toast.success("Movimiento de dinero registrado");
        refresh(boxId)
      });
    closeModal();
  }

  return (
    <ModalForm title={`Registar ${type}`} show={showModal} close={closeModal} submitFunction={addMovement}>
      <Row>
        <Col>
          <div className="form-floating">
            <input type="date" className="form-control" id="date" placeholder="fecha" />
            <label htmlFor="date">Fecha</label>
          </div>
        </Col>
        <Col>
          <div className="form-floating">
            <input type="text" className="form-control" id="byorto" placeholder="entrega" required />
            <label htmlFor="byorto">Persona {type === 'Ingreso' ? 'que entrega' : 'quien recibe'}</label>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <div className="form-floating">
            <input type="number" className="form-control" id="amount" placeholder="1.0" step="0.01" min="0" required />
            <label htmlFor="amount">Monto de dinero</label>
          </div>
        </Col>
        <Col>
          <div className="form-floating">
            <select className="form-select" id="reason" aria-label="motivo" required>
              <option defaultValue="">Selecciona uno</option>
              {reasons?.map((reason: { id: number, name: string }) =>
                <option key={reason.id} value={reason.id}>{reason.name}</option>
              )}
            </select>
            <label htmlFor="reason">Motivo o Concepto</label>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <div className="form-floating">
          <input type="text" className="form-control" id="details" placeholder="detalle" />
          <label htmlFor="details" style={{ paddingLeft: "1.5rem" }}>Referencia o detalle del movimiento</label>
        </div>
      </Row>
    </ModalForm>
  );
}

import { useState } from "react";
import { Table } from "react-bootstrap";
import readXlsxFile, { Row } from "read-excel-file";

const CargarDatos = ({ subject, toogle, getTemplateFunction, uploadFunction }: any) => {
  const [data, setData] = useState<Row[]>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = () => {
    const input = (document.getElementById('input') as HTMLInputElement)
    if (input.files) {
      readXlsxFile(input.files[0]).then((rows) => {
        setData(rows)
      })
    }
  }

  const handleGetTemplate = async () => {
    try {
      await getTemplateFunction()
    } catch (error) {
      alert(JSON.stringify(error, null, 2))
    }
  }

  const handleUpload = async () => {
    try {
      setLoading(true);
      await uploadFunction({ data: data?.slice(1) });
      setSuccess(true);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      alert(JSON.stringify(error.response.data.message, null, 2))
    }
  }

  return <div>
    {toogle && <button className="btn btn-secondary mb-3" onClick={() => toogle()}>Regresar</button>}
    <h4>Carga masiva de {subject}</h4>
    <div className="d-flex justify-content-between">
      <input onChange={handleChange} type="file" id="input" /><br />
      {getTemplateFunction && <button className="btn btn-warning" onClick={handleGetTemplate}>Obtener plantilla</button>}
    </div>
    {data && !success &&
      <div className="alert alert-warning mt-2" role="alert">
        {!loading &&
          <div>
            <div className="d-flex justify-content-center">
              <span className="fs-4">Por favor, revisar los datos y si todo es correcto, confimar al final de la tabla</span>
            </div>
            <Table className="mt-2">
              <thead>
                <tr>
                  {data[0].map((str, index) => (
                    <th key={index}>{str}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.slice(1).map((obj, index) => (
                  <tr key={index}>
                    {obj.map((str, index) => (
                      <td key={index}>{str}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center">
              <button className="btn btn-outline-dark" onClick={handleUpload}>Subir datos</button>
            </div>
          </div>
        }
        {loading &&
          <div className="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        }
      </div>
    }
    {success &&
      <div className="alert alert-success mt-2">
        <span className="fs-4">¡Felicidades, los datos han sido subidos satisfactoriamente!</span>
      </div>
    }
  </div>
}

export default CargarDatos;
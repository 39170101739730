import styled from "styled-components"

export const OrderItem = styled.div`
  border-bottom: 1px solid grey;
  display: grid;
  grid-template-columns: 1fr 4rem;
  column-gap: .5rem;
  padding: .25rem .20rem;
  @media (min-width: 780px) {
    grid-template-columns: 1fr 5rem;
  }
`

export const OrderItemData = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  flex-direction: column;
  gap: .25rem;
`

export const OrderItemOptions = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .20rem;
  font-size: .6rem;
  @media (min-width: 780px) {
    font-size: .7rem;
  }
`

export const OrderItemDataHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .7rem;
  @media (min-width: 780px) {
    font-size: .8rem;
  }
`

export const OrderItemDataBody = styled.div`
  font-size: .8rem;
  @media (min-width: 780px) {
    font-size: .9rem;
  }
`

export const VendorBadge = styled.div`
  border-radius: .3rem;
  background: royalblue;
  color: white;
  padding: .15rem;
`
import { useEffect, useState } from "react";
import { Customer, fetchCustomer } from "../../utils/api/customers";

export function useCustomerById(id: string) {
  const [customer, setCustomer] = useState<Customer>()

  useEffect(() => {
    fetchCustomer(id).then(setCustomer)
  }, [id])

  return { customer, setCustomer }
}
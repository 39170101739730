import React from 'react'
import { Dropdown, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import routes from '../helpers/routes';
import { HeaderPage, Title } from './styleds'
import { Vendor } from './useVendors'

interface Props {
  vendors: Vendor[];
  editing: boolean;
  isCreating: boolean;
  toggle: () => void;
  setVendor: React.Dispatch<React.SetStateAction<Vendor | undefined>>
  editToggle: () => void;
  isLoading: boolean;
  isError: boolean;
}

const VendorsList = ({ isLoading, isError, vendors, editing, isCreating, toggle, editToggle, setVendor }: Props) => {
  const handleEdit = (vendor: Vendor) => {
    setVendor(vendor);
    editToggle();
  }

  if (isLoading) return <div>Cargando...</div>

  if (isError) return <div>Error al cargar los datos</div>

  if (isCreating || editing) return null

  return (
    <>
      <HeaderPage>
        <Title>Lista de vendedores</Title>
        <button className='btn btn-info' onClick={() => toggle()}>+ Agregar vendedor</button>
      </HeaderPage>
      <Table className='mt-3'>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>DNI</th>
            <th>Correo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {vendors.map((v, index) => (
            <tr key={index}>
              <td>{v.firstName + " " + v.lastName}</td>
              <td>{v.documentNumber}</td>
              <td>{v.email}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                    Opciones
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEdit(v)}>Editar datos</Dropdown.Item>
                    <Dropdown.Item as={Link} to={routes.admin.users} >Ver usuario</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {vendors.length === 0 &&
        <div>Aún no hay vendedores cargados</div>
      }
    </>
  )
}

export default VendorsList
import AddButton from "../../../components/AddButton";
import { PageLayout } from "../../../components/PageLayout";
import { TopActionsBar } from "../../../components/styleds/common";
import routes from "../../../helpers/routes";
import FacturasPorCobrarTable from "./FacturasPorCobrarTable";

export default function MakroPaymentsPage() {
  return (
    <PageLayout>
      <h3>Facturas por cobrar de Makro</h3>
      <TopActionsBar>
        <AddButton title="Registrar cobro" to={routes.makro.addMakroPayment} />
      </TopActionsBar>
      <FacturasPorCobrarTable />
    </PageLayout>
  )
}
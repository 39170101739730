import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import writeXlsxFile from 'write-excel-file'
import { fetchAllBoxes } from '../../../utils/api/boxes'
import { axiosInstance } from '../../../utils/axios'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 1em;
  padding: 1em;
  overflow-y: hidden;
`

const FormSection = styled.div``
const ViewSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow-y: scroll;
`

function BoxHistoryReport() {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      box: 'all',
      movementType: 'all',
      sinceDate: '',
      untilDate: dayjs().format('YYYY-MM-DD')
    }
  });
  const [boxes, setBoxes] = useState([]);
  const [results, setResults] = useState<any>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setBoxes(await fetchAllBoxes())
      } catch (e: any) {
        setError(e)
      }
    })()
  }, []);

  const onSubmit = async (data: any) => {
    try {
      const response = await axiosInstance.get('/reports/money/movements-history', {
        params: {
          selectedBox: data.box,
          selectedMovementType: data.movementType,
          since: data.sinceDate,
          until: data.untilDate
        }
      });
      setResults(response.data);
    } catch (e: any) {
      setError(e)
    }
  }

  async function downloadExcel() {
    try {
      const schema = [
        {
          column: "Categoría",
          type: String,
          value: (movement: any) => movement.category
        },
        {
          column: "Fecha",
          type: String,
          value: (movement: any) => dayjs(movement.date).format("DD-MM-YYYY")
        },
        {
          column: "Tipo",
          type: String,
          value: (movement: any) => movement.type
        },
        {
          column: "Caja",
          type: String,
          value: (movement: any) => movement.box
        },
        {
          column: "Moneda",
          type: String,
          value: (movement: any) => movement.currency
        },
        {
          column: "Monto",
          type: Number,
          format: '#,##0.00',
          value: (movement: any) => movement.amount
        },
        {
          column: "De o Para",
          type: String,
          value: (movement: any) => movement.byOrTo
        },
        {
          column: "Detalle",
          type: String,
          value: (movement: any) => movement.detail
        },
      ];
      return await writeXlsxFile(results, { schema, fileName: `reporte_de_movimientos_${dayjs().format('DD-MM-YYYY')}.xlsx` });
    } catch (error) {
      console.error(error)
    }
  }

  if (error !== null) {
    return <h3>Error en el servidor</h3>
  }

  return (
    <Wrapper>
      <FormSection>
        <h3 style={{ textDecoration: 'underline' }}>Reporte de movimientos</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            <div className="col">
              <label className='form-label' htmlFor="boxId">Caja</label>
              <select className='form-select' {...register('box')} id="boxId">
                <option value="all">Todas</option>
                {boxes.map((b: any, i: number) => (
                  <option key={i} value={b.id}>{b.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label className='form-label' htmlFor="type">Tipo de Movimiento</label>
              <select className='form-select' {...register('movementType')} id="type">
                <option value="all">Todos</option>
                <option value="ingreso">Ingresos</option>
                <option value="salida">Salidas</option>
              </select>
            </div>
            <div className="col">
              <label className='form-label' htmlFor="since">Desde</label>
              <input className='form-control' type="date" id="since" {...register('sinceDate')} />
            </div>
            <div className="col">
              <label className='form-label' htmlFor="until">Hasta</label>
              <input className='form-control' type="date" id="until" {...register('untilDate')} />
            </div>
            <div className="col" style={{ display: 'flex' }}>
              <button style={{ alignSelf: 'flex-end', width: '100%' }} className='btn btn-primary' type='submit'>Buscar</button>
            </div>
          </div>
        </form>
      </FormSection>
      <ViewSection>
        {results && results.length === 0 && <h2 style={{ color: 'red' }}>No hay movimientos que coincidan con la búsqueda</h2>}
        {results && results.length > 0 &&
          <>
            <div>
              <button className='btn btn-success' onClick={downloadExcel}>Descargar reporte</button>
            </div>
            <Table size='sm' bordered>
              <thead>
                <tr>
                  <th>Categoría</th>
                  <th>Fecha</th>
                  <th>Tipo</th>
                  <th>Caja</th>
                  <th>Moneda</th>
                  <th>Monto</th>
                  <th>De o Para</th>
                  <th>Detalle</th>
                </tr>
              </thead>
              <tbody>
                {results.map((item: any, index: number) => (
                  <tr key={index} style={{ color: item.type === 'ingreso' ? 'blue' : 'red' }}>
                    <td>{item.category}</td>
                    <td>{item.date}</td>
                    <td>{item.type}</td>
                    <td>{item.box}</td>
                    <td>{item.currency}</td>
                    <td>{item.amount}</td>
                    <td>{item.byOrTo}</td>
                    <td>{item.detail}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        }
      </ViewSection>
    </Wrapper>
  )
}

export default BoxHistoryReport
import React from 'react'
import styled from 'styled-components'
import BoxesComponent from './BoxesComponent'
import DashboardComponent from './DashboardComponent'
const Conatiner = styled.div`
  padding: 1rem;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .8fr 1fr;
  grid-template-areas:
                      "boxes"
                      "dashboard";
`

function BoxesPage() {
  return (
    <Conatiner>
      <BoxesComponent />
      <DashboardComponent />
    </Conatiner>
  )
}

export default BoxesPage
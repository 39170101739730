import styled from 'styled-components';

export const RouteItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: .5rem;
`

export const RouteItemTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .35em .5em;
    background: #94A89E;
    border-top-left-radius: 1em;
`

export const RouteItemBody = styled.div`
    padding: .35em .5em;
    font-size: .9rem;
    background: #F2F6F4;
    border-bottom-left-radius: 1em;
    cursor: pointer;
`

export const Pill = styled.div`
    background: #F5EBD7;
    color: black;
    font-weight: 600;
    padding: .10em .5em;
    border-radius: 1em;
    font-size: .8rem;
`

export const ClickPill = styled.div`
    cursor: pointer;
    background: #A5CBD6;
    color: black;
    font-weight: 600;
    padding: .10em .5em;
    border-radius: 1em;
    font-size: .8rem;
`

interface Props {
  titles: string[]
  bodyText: string
}

function RouteItemComponent(props: Props) {
  return (
    <RouteItem>
      <RouteItemTitle>
        {props.titles.map((t, index) => (
          <Pill key={index}>{t}</Pill>
        ))}
      </RouteItemTitle>
      <RouteItemBody>
        {props.bodyText}
      </RouteItemBody>
    </RouteItem>
  )
}

export default RouteItemComponent
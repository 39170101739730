import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { PageLayout } from '../../components/PageLayout'
import FilterOrdersModal from './FilterOrdersModal'
import routes from '../../helpers/routes'
import { useOrders } from './useOrders'
import OrdersList from './OrdersList'
import useBoxes from '../../hooks/useBoxes'

function OrdersPage() {
  const { boxes } = useBoxes();
  let history = useHistory<any>()
  const { isLoading, isError, states, setactiveState, activeStateText, confirmedOrders } = useOrders();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  if (isError) return null
  if (isLoading) return <h3>Cargando...</h3>

  return (
    <PageLayout>
      <>
        <div className="d-flex justify-content-between align-items-center">
          <div style={{ cursor: 'pointer' }} onClick={() => setModal(true)}>
            {activeStateText}<i className="bi bi-caret-down-fill ms-1"></i>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => history.push(routes.newOrder)}>
            <i className="bi bi-plus-square"></i>
          </div>
        </div>
        {!loading && <OrdersList orders={confirmedOrders} setLoading={setLoading} boxes={boxes} />}
        {loading &&
          <div style={{ marginTop: '10rem' }} className="d-flex justify-content-center align-items-center">
            <div className="spinner-border" style={{ width: "3rem", height: "3rem" }} role="status" />
          </div>
        }
        <FilterOrdersModal setter={setactiveState} states={states} show={modal} close={() => setModal(false)} />
      </>
    </PageLayout>
  )
}

export default OrdersPage

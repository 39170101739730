import { Link } from "react-router-dom";
import routes from "../../../helpers/routes";
import styled from "styled-components";

const HeadingPart = styled.div`
  /* Quiero un flex en horizontal con un espaciado de 0.5rem */
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  /* Quiero que los elementos estén centrados */
  justify-content: space-between;
  align-items: center;
`;


export function HeadingPage() {
  return (<HeadingPart>
    <div>
      <h1>Consumo de insumos</h1>
      <p style={{
        marginBottom: '0'
      }}>Se muestra lo que queda de cada insumo en relación a la última compra.</p>
    </div>
    <div><Link to={routes.inputs} className='btn btn-secondary'>Regresar al listado</Link></div>
  </HeadingPart>);
}

import axios, { AxiosError } from 'axios';
import toast from 'react-hot-toast';

export function handleAxiosError(error: AxiosError | unknown): void {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // El servidor respondió con un código de estado fuera del rango 2xx
      console.error('Error en la respuesta del servidor:', error.response.status);
      console.error('Datos del error:', error.response.data);
      toast.error(error.response.data);
    } else if (error.request) {
      // La petición fue hecha pero no se recibió respuesta
      console.error('No se recibió respuesta del servidor:', error.request);
    } else {
      // Algo ocurrió al configurar la petición
      console.error('Error al configurar la petición:', error.message);
    }
  } else {
    // Error inesperado que no es de Axios
    console.error('Error inesperado:', error);
  }
}
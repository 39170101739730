import React from 'react'
import { Input } from '../../components/Input'
import { ProductModel } from './ProductsPage'
import { axiosInstance } from '../../utils/axios'

interface ProductFormProps {
    disabled?: boolean
    editing?: boolean
    product?: Partial<ProductModel>
    handleSubmit: (i: Partial<ProductModel>) => void
    toogle?: any
}

export class ProductForm extends React.Component<ProductFormProps> {
    state = {
        product: {
            name: "",
            sellPrice: 0.00,
            stock: 0.00,
            recipeId: 0,
            containerId: 0,
            capId: 0,
        },
        recipeOptions: [],
        containerOptions: [],
        capOptions: []
    }

    componentDidMount() {
        if (this.props.product) {
            this.setState({
                ...this.state,
                product: this.props.product
            })
        }

        // traer las recetas de productos para el select
        axiosInstance.get('/recipes/get-recipes-for-productos').then((res) => {
            this.setState({
                ...this.state,
                recipeOptions: res.data
            })
        }).catch((e) => console.log(e));
        // traer los contenedores para el select
        // traer las tapas para el select
        axiosInstance.get('/inputs/no-materialPrime').then((res) => {
            this.setState({
                ...this.state,
                containerOptions: res.data.filter((i: any) => i.name.toLowerCase().includes('envase')),
                capOptions: res.data.filter((i: any) => i.name.toLowerCase().includes('tapa'))
            })
        }).catch((e) => console.log(e));
    }

    componentDidUpdate(prevProps: ProductFormProps) {
        if (prevProps.product !== this.props.product) {
            this.setState({
                ...this.state,
                product: this.props.product
            })
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                [name]: value
            }
        })
    }

    handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const name = event.target.name
        const value = parseInt(event.target.value)
        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                [name]: value
            }
        })
    }

    handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        this.props.handleSubmit(this.state.product)
    }

    render() {
        const product = this.state.product
        const { disabled = false, editing = true } = this.props
        const { recipeOptions, containerOptions, capOptions } = this.state

        return (
            <div>
                {!this.props.product &&
                    <button className='btn btn-secondary' onClick={() => this.props.toogle()}>Regresar al listado</button>
                }
                <form onSubmit={this.handleSubmit}>
                    <fieldset disabled={disabled}>
                        <div className="row mt-2">
                            <div className="col">
                                <Input
                                    type="text"
                                    name="name"
                                    label="Nombre del producto"
                                    value={product.name}
                                    handleChange={this.handleChange}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <Input
                                    type="number"
                                    name="sellPrice"
                                    label="Precio por mayor"
                                    value={product.sellPrice}
                                    handleChange={this.handleChange}
                                    required={true}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    type="number"
                                    name="stock"
                                    label="Stock actual"
                                    value={product.stock}
                                    disabled={this.props.product && true}
                                    handleChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', marginTop: '.5rem' }} id='recipecontainercap'>
                            <div style={{ flexGrow: 1 }}>
                                <label className='form-label'>Receta</label>
                                <select className='form-select' name='recipeId' value={product.recipeId} onChange={this.handleSelectChange} required>
                                    <option value="">Seleccionar receta</option>
                                    {recipeOptions.map((r: any) => <option key={r.id} value={r.id}>{r.name}</option>)}
                                </select>
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <label className='form-label'>Envase y tapa</label>
                                <select className='form-select' name='containerId' value={product.containerId} onChange={this.handleSelectChange} required>
                                    <option value="">Seleccionar envase</option>
                                    {containerOptions.map((c: any) => <option key={c.id} value={c.id}>{c.name}</option>)}
                                </select>
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <label className='form-label'>Tapa</label>
                                <select className='form-select' name='capId' value={product.capId} onChange={this.handleSelectChange}>
                                    <option value="">Seleccionar tapa</option>
                                    {capOptions.map((c: any) => <option key={c.id} value={c.id}>{c.name}</option>)}
                                </select>
                            </div>
                        </div>
                        {editing &&
                            <div className="d-grid">
                                <button className="btn btn-primary mt-2" type="submit">Guardar</button>
                            </div>
                        }
                    </fieldset>
                </form>
            </div>
        )
    }
}
import React from 'react'
import { PageLayout } from '../../components/PageLayout'
import CustomersPage from './CustomersPage'

function CustomerMainPage() {
  return (
    <PageLayout>
      <CustomersPage></CustomersPage>
    </PageLayout>
  )
}

export default CustomerMainPage
import React from 'react'
import styled from 'styled-components';
import { FullPageLayout } from '../../../components/FullPageLayout'
import AccountingPurchasesReport from './AccountingPurchasesReport';
import AccountingSalesReport from './AccountingSalesReport';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

export const SelectReportSection = styled.div`
  height: 4rem;
  display: flex;
  gap: 1rem;
`

export const SelectReportSectionItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  border-radius: .4rem;
  cursor: pointer;
  font-size: 2rem;
`

export const ReportSection = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`

function AccountingReportPage() {
  const [sales, setSales] = React.useState(false);
  const [purchases, setPurchases] = React.useState(false);

  const styleSales = { border: sales ? '2px solid black' : '1px solid blue', color: sales ? 'white' : 'black', background: sales ? 'blue' : 'white' }
  const stylePurchases = { border: purchases ? '2px solid black' : '1px solid green', color: purchases ? 'white' : 'black', background: purchases ? 'green' : 'white' }

  const toggleSales = () => {
    setSales(true);
    setPurchases(false);
  }
  const togglePurchases = () => {
    setSales(false);
    setPurchases(true);
  }

  return (
    <FullPageLayout>
      <Container>
        <SelectReportSection>
          <SelectReportSectionItem style={styleSales} onClick={toggleSales}>Ventas</SelectReportSectionItem>
          <SelectReportSectionItem style={stylePurchases} onClick={togglePurchases}>Compras</SelectReportSectionItem>
        </SelectReportSection>
        <ReportSection>
          {sales &&
            <AccountingSalesReport />
          }
          {purchases &&
            <AccountingPurchasesReport />
          }
        </ReportSection>
      </Container>
    </FullPageLayout>
  )
}

export default AccountingReportPage
import React from 'react'
import Pagination from './Pagination'

interface Props<T> {
    children: (item: T) => React.ReactNode
    headers: string[]
    items: T[]
    searchParams: Array<keyof T>
    actions?: boolean
    numberPerPage?: number
}

interface IdObj {
    id: string | number
}

export default function TableList<T extends IdObj>({ children, headers, items, searchParams, actions = true, numberPerPage = 5 }: Props<T>) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage] = React.useState(numberPerPage);

    const [q, setQ] = React.useState("");
    let filterItems = items?.filter((item) => {
        return searchParams.some((newItem) => {
            const value = item[newItem]
            if (typeof value === "string" || typeof value === "number") {
                return value.toString().toLowerCase().includes(q.toLowerCase())
            }
            return false;
        });
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filterItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    return (
        <div className='mt-3'>
            <input
                type="text"
                className="form-control mb-2"
                name="search"
                value={q}
                onChange={(e) => setQ(e.target.value)}
                placeholder="Escriba aquí para buscar"
            />
            <div className="table-responsive">
                <table className="table table-bordered table-sm">
                    <thead className="table-light">
                        <tr>
                            {headers.map((item, index) => (
                                index !== headers.length - 1 ? <th key={index}>{item}</th> : null
                            ))}
                            <th style={actions ? { textAlign: 'center' } : {}}>{headers[headers.length - 1]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item) => (
                            <tr key={item.id}>
                                {children(item)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={items.length}
                paginate={paginate}
            />
        </div>
    )
}

import React from 'react'
import { FullPageLayout } from '../../../components/FullPageLayout'
import SalesData from './SalesData'
import SoldProductsData from './SoldProductsData'
import { Container } from './styleds'

function DataExportMainPage() {
  return (
    <FullPageLayout>
      <Container>
        <h1>Exportar datos</h1>
        <SalesData />
        <SoldProductsData />
      </Container>
    </FullPageLayout>
  )
}

export default DataExportMainPage
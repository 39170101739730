import { Redirect, Route } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import routes from "../helpers/routes";

export default function PublicRoute(props: any) {
    const { isLogged } = useAuth();

    if (isLogged()) return <Redirect to={routes.home} />;

    return <Route {...props} />;
}
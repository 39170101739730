import { useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { PageLayout } from "../../../components/PageLayout";
import TableList from "../../../components/TableList1";
import routes from "../../../helpers/routes";
import useAuth from "../../../hooks/useAuth";
import useModal from "../../../hooks/useModal";
import { useAPurchase } from "../../../hooks/usePurchases";
import { formatMoney, toLocalDateFormat } from "../../../utils/functions";
import { EditAction, OrderGeneralDetails, OrderGeneralDetailsLine, OrderInfo, OrderInfoBold, OrderInfoLabel, OrderItemsView, OrderMoneyDetails, OrderView, OrderViewActions } from "../../common/styled";
import PayPurchaseModal from "../common/PayPurchaseModal";

const JustifyBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default function ViewPurchase() {
  const payModal = useModal();
  const { user } = useAuth();
  const [editingPayments, setEditingPayments] = useState(false);
  const { id: purchaseId } = useParams<any>();
  const { purchase, isLoading, unConfirmPurchase, markAsPayed, registerPurchasePayment, unPayed, deletePurchase } = useAPurchase(purchaseId, 'inputs');
  const toggleEditingPayments = () => setEditingPayments(!editingPayments);

  if (isLoading) {
    return <PageLayout>Cargando...</PageLayout>
  }

  const itemsHeaders = ['Insumo', 'Cantidad', 'Unidad', 'PrecioUnitario']
  const itemsBody = purchase.items.map((item: any) => (
    <tr key={item.id}>
      <td>{item.input.name}</td>
      <td>{item.amount}</td>
      <td>{item.unit}</td>
      <td style={{ textAlign: 'center' }}>{formatMoney(item.unitPrice, purchase.currency)}</td>
    </tr>
  ));

  const paymentHseaders = !editingPayments ? ['Fecha', 'Monto', 'Caja'] : ['Fecha', 'Monto', 'Caja', 'Eliminar'];
  const paymentsBody = purchase.payments.map((payment: any) => (
    <tr key={payment.id}>
      <td>{toLocalDateFormat(payment.date)}</td>
      <td style={{ textAlign: 'center' }}>{formatMoney(payment.amount, purchase.currency)}</td>
      <td style={{ textAlign: 'center' }}>{payment.box.name}</td>
      {editingPayments && <td style={{ textAlign: 'center', color: 'red', fontSize: '1.4em', padding: '0' }}><i style={{ cursor: 'pointer' }} className="bi bi-x-circle" title="Eliminar pago" onClick={() => unPayed(payment.id)}></i></td>}
    </tr>
  ));

  return (
    <PageLayout>
      <JustifyBetween>
        <Breadcrumb>
          <Breadcrumb.Item linkProps={{ to: routes.purchase.inputs }} linkAs={Link}>
            Lista de compras
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Detalle de compra</Breadcrumb.Item>
        </Breadcrumb>
        {user && user.role === 'admin' && purchase.state === 'confirmed' &&
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">Opciones</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={markAsPayed}>Marcar como pagado</Dropdown.Item>
              <Dropdown.Item onClick={unConfirmPurchase}>Deshacer confirmación</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      </JustifyBetween>
      <OrderViewActions>
        <div className="">{purchase.serialNumber}-{purchase.documentNumber}</div>
        <div className="">{purchase.state}</div>
        {purchase.state === 'draft' ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <EditAction as={Link} to={routes.purchase.editInput(purchase.id)}>
              <i className="bi bi-pencil"></i>
              Editar
            </EditAction>
            {user && user.role === 'admin' && <button className="ms-2 btn btn-danger btn-sm" onClick={deletePurchase}><i className="bi bi-trash"></i><span className="ms-2">Eliminar</span></button>}
          </div>
          : null
        }
      </OrderViewActions>
      <OrderView>
        <OrderGeneralDetails>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Proveedor:</OrderInfoLabel>
            <OrderInfo>{purchase.provider.name}</OrderInfo>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>FACTURA:</OrderInfoLabel>
            <OrderInfo>{purchase.serialNumber}-{purchase.documentNumber}</OrderInfo>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Fecha de compra:</OrderInfoLabel>
            <OrderInfo color="navy">{toLocalDateFormat(purchase.date)}</OrderInfo>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Fecha de vencimiento:</OrderInfoLabel>
            <OrderInfo color="crimson">{toLocalDateFormat(purchase.date)}</OrderInfo>
          </OrderGeneralDetailsLine>
        </OrderGeneralDetails>
        <OrderMoneyDetails>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Sub total:</OrderInfoLabel>
            <OrderInfoBold>{formatMoney(purchase.subTotal, purchase.currency)}</OrderInfoBold>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>IGV</OrderInfoLabel>
            <OrderInfoBold>{formatMoney(purchase.igv, purchase.currency)}</OrderInfoBold>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Total:</OrderInfoLabel>
            <OrderInfoBold>{formatMoney(purchase.total, purchase.currency)}</OrderInfoBold>
          </OrderGeneralDetailsLine>
          <OrderGeneralDetailsLine>
            <OrderInfoLabel>Moneda:</OrderInfoLabel>
            <OrderInfo>{purchase.currency}</OrderInfo>
          </OrderGeneralDetailsLine>
        </OrderMoneyDetails>
        <OrderItemsView>
          <TableList headers={itemsHeaders} body={itemsBody} />
        </OrderItemsView>
      </OrderView>
      {purchase.payments &&
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h4>Pagos:</h4>
            {user && user.role === 'admin' && purchase.payments.length > 0 &&
              <>
                {!editingPayments && <button className="btn btn-danger" onClick={toggleEditingPayments}>Editar</button>}
                {editingPayments && <button className="btn btn-outline-danger" onClick={toggleEditingPayments}>X</button>}
              </>
            }
            {user && user.role === 'admin' && (purchase.state !== 'payed' && purchase.state !== 'draft') && <button className="btn btn-warning btn-xs" onClick={payModal.toogleModal}>Registrar Pago</button>}
          </div>
          <TableList headers={paymentHseaders} body={paymentsBody} />
        </>
      }
      <PayPurchaseModal purchase={purchase} show={payModal.isOpenModal} close={payModal.toogleModal} submitFunction={registerPurchasePayment} />
    </PageLayout>
  )
}
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 16fr;
  margin-bottom: 2rem;
`

export const Header = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  border: 1px solid green;
  display: flex;
  justify-content: flex-end;
  padding: .25rem .5rem;
`

export const Main = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  border: 1px solid grey;
  padding: .5rem .75rem;
  border-top: none;
`

export const BorderedSection = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 0.4rem;
  margin-top: 1rem;
`

export const BorderedSectionTitle = styled.div`
  font-size: 0.8rem;
`

export const BorderedSectionBody = styled.div`
  display: flex;
  gap: 1rem;
  padding: .25rem .5rem;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`

export const BorderedSectionBodyItem = styled.div`
  flex-grow: 1;
`

export const BorderedSectionBodyFooter = styled.div`
padding: .25rem .5rem;
`
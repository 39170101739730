import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import routes from "../../../helpers/routes";
import { useRecipes, Recipe } from "../../../hooks/useRecipes";
import dayjs from "dayjs";

export default function ProductProductionOrderForm({ initialData, submitFunction, toogle }: any) {
  const history = useHistory();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      quantity: initialData ? initialData.quantity : "",
      complete: initialData ? initialData.complete : 0,
      date: initialData ? initialData.date : dayjs().format('YYYY-MM-DD')
    }
  });
  const { recipes } = useRecipes('products');
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe[]>(initialData ? [initialData.recipe] : []);
  //const [complete, setComplete] = useState(0);

  //const toggleComplete = () => setComplete((last) => last === 0 ? 1 : 0);

  function handleCancel() {
    if (initialData) {
      history.push(routes.production.viewProductProduction(initialData.id));
    } else {
      toogle();
    }
  }

  function onSubmit(data: any) {
    submitFunction({
      recipeId: selectedRecipe[0].id,
      quantity: data.quantity,
      complete: data.complete,
      date: data.date
    })
  }

  return (
    <>
      {!initialData ? <h2>Nueva orden de producción</h2> : <h2>Editar orden de producción</h2>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group className="mt-3" controlId="product">
              <Form.Label>Receta</Form.Label>
              <Typeahead
                id="product-selected"
                labelKey="name"
                onChange={setSelectedRecipe}
                options={recipes}
                selected={selectedRecipe}
                placeholder="Buscar receta"
                inputProps={{ required: true }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mt-3 mb-3" controlId="quantity">
              <Form.Label>Cantidad de productos a producir</Form.Label>
              <Form.Control type="number" {...register("quantity")} autoComplete="off" required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mt-3 mb-3" controlId="date">
              <Form.Label>Fecha de producción</Form.Label>
              <Form.Control type="date" {...register("date")} autoComplete="off" required />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              type="switch"
              {...register("complete")}
              id="igv-switch"
              label="¿Producción completa?"
            />
          </Col>
        </Row>
        <div className="mt-2 d-flex gap-2">
          <button className="btn btn-secondary" onClick={handleCancel}>Cancelar</button>
          <input type="submit" className="btn btn-primary" value="Guardar" />
        </div>
      </form>
    </>
  )
}
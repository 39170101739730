import React from 'react'
import styled from 'styled-components'
import { FullPageLayout } from '../../../components/FullPageLayout'
import Form from './Form'
import Resultados from './Resultados'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`

function ProductByCustomerReportPage() {
  const [results, setResults] = React.useState(null);

  return (
    <FullPageLayout>
      <Wrapper>
        <Form setData={setResults}></Form>
        <Resultados data={results}></Resultados>
      </Wrapper>
    </FullPageLayout>
  )
}

export default ProductByCustomerReportPage
import React from 'react'
import styled from 'styled-components'
import OficinaCustomers from './OficinaCustomers'
import "./styles.css"
import VendorsCustomers from './VendorsCustomers'
import WhatchedCustomers from './WhatchedCustomers'
import useBoxes from '../../../hooks/useBoxes'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "vendors oficina"
    "vendors watcheds";
  overflow-y: hidden;
`

function SellOrdersPayable() {
  const { boxes } = useBoxes();

  return (
    <Container>
      <VendorsCustomers boxes={boxes}></VendorsCustomers>
      <WhatchedCustomers></WhatchedCustomers>
      <OficinaCustomers boxes={boxes}></OficinaCustomers>
    </Container>
  )
}

export default SellOrdersPayable
import React, { useMemo, useState } from 'react'

const MostrarUbigeos = ({ isEmpty, departamentos, provincias, ubigeos }: any) => {
  const [departamentoSelectValue, setDepartamentoSelectValue] = useState("");
  const [provinciaSelectValue, setProvinciaSelectValue] = useState("");
  const [ubigeoSelectValue, setUbigeoSelectValue] = useState("");

  const filterProvincias = useMemo(() => {
    return provincias.filter((p: any) => p.departamentoId.toString() === departamentoSelectValue)
  }, [provincias, departamentoSelectValue])

  const filterUbigeos = useMemo(() => {
    return ubigeos.filter((u: any) => u.provinciaId.toString() === provinciaSelectValue)
  }, [ubigeos, provinciaSelectValue])

  const handleDepartamentos = (e: any) => {
    setDepartamentoSelectValue(e.target.value)
  }
  const handleProvincias = (e: any) => {
    setProvinciaSelectValue(e.target.value)
  }
  const handleUbigeos = (e: any) => {
    setUbigeoSelectValue(e.target.value)
  }

  if (isEmpty) return null;
  return (
    <div className="d-flex mt-3 gap-2">
      <div className="form-group mt-2">
        <label className='mb-1' style={{ fontWeight: 'bold' }} htmlFor="departamentos">Departamento</label>
        <select className='form-control' name="departamentos" id="departamentos" value={departamentoSelectValue} onChange={handleDepartamentos}>
          <option value="">Selecciona uno</option>
          {departamentos.map((d: any) => (
            <option key={d.id} value={d.id}>{d.descripcion}</option>
          ))}
        </select>
      </div>
      <div className="form-group mt-2">
        <label className='mb-1' style={{ fontWeight: 'bold' }} htmlFor="provincias">Provincia</label>
        <select className='form-control' name="provincias" id="provincias" value={provinciaSelectValue} onChange={handleProvincias}>
          <option value="">Selecciona uno</option>
          {filterProvincias.map((p: any) => (
            <option key={p.id} value={p.id}>{p.descripcion}</option>
          ))}
        </select>
      </div>
      <div className="form-group mt-2">
        <label className='mb-1' style={{ fontWeight: 'bold' }} htmlFor="ubigeos">Distrito</label>
        <select className='form-control' name="ubigeos" id="ubigeos" value={ubigeoSelectValue} onChange={handleUbigeos}>
          <option value="">Selecciona uno</option>
          {filterUbigeos.map((u: any) => (
            <option key={u.id} value={u.id}>{u.descripcion}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default MostrarUbigeos
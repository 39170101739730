import React from 'react'
import { FullPageLayout } from '../../../components/FullPageLayout'
import { Container, ReportSection, SelectReportSection, SelectReportSectionItem } from '../contabilidad/AccountingReportPage'
import InputKardex from './InputKardex'
import ProductKardex from './ProductKardex';

function KardexReportPage() {
  const [inputs, setInputs] = React.useState(false);
  const [products, setProducts] = React.useState(false);
  const styleInputs = { border: inputs ? '2px solid black' : '1px solid blue', color: inputs ? 'white' : 'black', background: inputs ? 'blue' : 'white' }
  const styleProducts = { border: products ? '2px solid black' : '1px solid green', color: products ? 'white' : 'black', background: products ? 'green' : 'white' }
  const toggleInputs = () => {
    setInputs(true);
    setProducts(false);
  }
  const toggleProducts = () => {
    setProducts(true);
    setInputs(false);
  }
  return (
    <FullPageLayout>
      <Container>
        <SelectReportSection>
          <SelectReportSectionItem style={styleInputs} onClick={toggleInputs}>Kardex de insumos</SelectReportSectionItem>
          <SelectReportSectionItem style={styleProducts} onClick={toggleProducts}>Kardex de productos</SelectReportSectionItem>
        </SelectReportSection>
        <ReportSection>
          {inputs && <InputKardex />}
          {products && <ProductKardex />}
        </ReportSection>
      </Container>
    </FullPageLayout>
  )
}

export default KardexReportPage
import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom';
import { FullPageLayout } from '../../components/FullPageLayout'
import PrivateRoute from '../../routers/PrivateRoute';
import { ModuleContainer, NavItem, Sidebar } from '../common/modules.styles'
import InputsWarehouse from './inputs/InputsWarehouse';
import Kardex from './kardex/Kardex';
import ProductsWarehouse from './products/ProductsWarehouse';

function WarehousePage() {
  let { path, url } = useRouteMatch();

  return (
    <FullPageLayout>
      <ModuleContainer>
        <Sidebar>
          <h1 style={{ textAlign: 'center' }}>Almacenes</h1>
          <NavItem to={url}>productos</NavItem>
          <NavItem to={`${url}/insumos`}>insumos</NavItem>
          <NavItem to={`${url}/kardex`}>kardex</NavItem>
        </Sidebar>
        <React.Fragment>
          <Switch>
            <PrivateRoute exact path={path} component={ProductsWarehouse} />
            <PrivateRoute path={`${path}/insumos`} component={InputsWarehouse} />
            <PrivateRoute path={`${path}/kardex`} component={Kardex} />
          </Switch>
        </React.Fragment>
      </ModuleContainer>
    </FullPageLayout>
  )
}

export default WarehousePage
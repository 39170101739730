import { Spinner } from "react-bootstrap"
import { Link } from "react-router-dom";
import routes from "../../helpers/routes";
import TableList from "../../components/TableList";

interface Input {
    id: number
    name: string
    internalCode: string
    stock: number
    minimumStock: number
    unit: string
}

interface Props {
    inputs: Input[],
    isLoading: boolean,
    isError: boolean
}

export default function InputsList({ inputs, isLoading, isError }: Props) {
    const headers = ["Codigo", "Nombre", "Unidad", "Stock", "Stock Mínimo", "Opciones"];

    function styleStock(input: Input) {
        return {
            color: input.stock <= input.minimumStock ? 'red' : 'blue'
        }
    }

    if (isError) return <div>failed to load</div>

    if (isLoading) return <Spinner animation="border" />

    return (
        <TableList headers={headers} items={inputs} searchParams={["internalCode", "name"]} numberPerPage={8}>
            {(input) => (
                <>
                    <td>{input.internalCode}</td>
                    <td>{input.name}</td>
                    <td>{input.unit}</td>
                    <td style={styleStock(input)}>{input.stock}</td>
                    <td style={{ color: 'green' }}>{input.minimumStock}</td>
                    <td className='d-flex justify-content-center'>
                        <Link to={routes.input(input.id)}>
                            <button className='btn btn-primary btn-sm'>Ver detalle</button>
                        </Link>
                    </td>
                </>
            )}
        </TableList>
    )
}
import { useCallback, useEffect, useState } from "react";
import { createSupplier, fetchAllSuppliers, updateSupplier } from "../utils/api/suppliers";

export interface Supplier {
  id: number
  name: string
  documentNumber: string
  documentType: string
  address: string
}

export default function useSuppliers() {
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const refresh = useCallback(() => {
    fetchAllSuppliers().then(setSuppliers)
  }, [])

  useEffect(() => {
    refresh()
  }, [refresh])

  const addSupplier = async (supplier: Partial<Supplier>) => {
    try {
      await createSupplier(supplier)
    } catch (error) {
      throw (error)
    }
  }

  const patchSupplier = async (id: number, supplier: Partial<Supplier>) => {
    try {
      await updateSupplier(id, supplier)
    } catch (error) {
      throw (error)
    }
  }

  return { suppliers, refresh, addSupplier, patchSupplier }
}
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'

type Inputs = {
  name: string
  slug: string
}

function GroupCategoryModalForm({ show, close, category, submitFunction }: any) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: category ? category.name : '',
      slug: category ? category.slug : ''
    }
  });

  const onSubmit: SubmitHandler<Inputs> = data => {
    submitFunction({
      name: data.name,
      slug: data.slug
    });
    handleClose();
  }

  const handleClose = () => {
    reset();
    close();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{category ? 'Editar ' : 'Nueva '} Categoría</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group controlId='name'>
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" placeholder='Nombre de la categoría' {...register('name')} required />
          </Form.Group>
          <Form.Group controlId='slug' className='mt-1'>
            <Form.Label>Nombre corto</Form.Label>
            <Form.Control type="text" placeholder='Slug' {...register('slug')} required />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" variant="primary">{category ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default GroupCategoryModalForm
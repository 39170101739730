import { Link } from "react-router-dom"
import styled from "styled-components"

export const ModuleContainer = styled.div`
    display: grid;
    grid-template-columns: 16rem 1fr;
    overflow-y: hidden;
`

export const Sidebar = styled.div`
  background: rgb(110,90,60);
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap: 1rem;
`

export const NavItem = styled(Link)`
  background: rgb(12, 23, 56);
  border-radius: 4rem;
  padding: .25rem .5rem;
  height: 3rem;
  margin: 0 .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
`

export const Container = styled.div`
  padding: .5rem;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  /* cuando este en un dispositivo pequeño no hacen overflow en y */
  @media (max-width: 768px) {
    overflow-y: auto;
  }
`

export const ActionsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ModuleTitle = styled.h1`
  text-align: center;
`
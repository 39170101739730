import { axiosInstance } from "../axios";
import { formatToSelect } from "../functions";

export interface IPriceList {
    id?: number
    groupId: number
    groupName: string
    price: number
}

export interface Customer {
    id: number
    name: string
    documentNumber: string
    documentType: string
    address: string
    reference: string
    watched: boolean
    userId: string
    priceList: IPriceList[]
}

export const fetchAllCustomers = async (userId: string | null | undefined) => {
    try {
        const res = await axiosInstance.get(`/customers/user/${userId}`);
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const fetchCustomer = async (id: string) => {
    try {
        const res = await axiosInstance.get(`/customers/${id}`);
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const createCustomer = async (body: object) => {
    try {
        return await axiosInstance.post('/customers', body)
    } catch (error) {
        throw (error)
    }
}

export const updateCustomer = async (id: number, body: {}) => {
    try {
        return await axiosInstance.patch(`/customers/${id}`, body)
    } catch (error) {
        throw error
    }
}

export const customersForSelect = async () => {
    try {
        const res = await axiosInstance.get('/customers');
        return formatToSelect(res.data)
    } catch (error) {
        throw (error)
    }
}
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Modal, Form, Row, Col } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import { Provider, useProvidersData } from '../../hooks/useProviders';

interface Props {
  show: boolean
  toggle: () => void
  submitFunction: (obj: { providerId: number, price: number, currency: string }) => void
}

function SetInputCostModal({ show, toggle, submitFunction }: Props) {
  const { register, handleSubmit, reset } = useForm();
  const { providers } = useProvidersData();
  const [selectedProvider, setSelectedProvider] = useState<Provider[]>([]);

  function onSubmit(data: any) {
    submitFunction({ providerId: selectedProvider![0].id, price: Number(data.lastPrice), currency: data.currency });
    close();
  }

  function close() {
    setSelectedProvider([]);
    reset();
    toggle();
  }

  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Precio del insumo</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId='provider'>
                <Form.Label>Proveedor</Form.Label>
                <Typeahead
                  id="provider-selected"
                  labelKey="name"
                  onChange={setSelectedProvider}
                  options={providers}
                  selected={selectedProvider}
                  placeholder="Buscar proveedor"
                  inputProps={{ required: true }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group controlId='lastPrice'>
                <Form.Label>Último precio de compra</Form.Label>
                <Form.Control type="number" min={0.01} step={0.01} {...register('lastPrice')} required></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="currency">
                <Form.Label>Moneda</Form.Label>
                <select {...register("currency")} className="form-select" aria-label="currency" required>
                  <option value="">Selecciona una la moneda</option>
                  <option value="pen">Soles</option>
                  <option value="usd">Dólares americanos</option>
                </select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary' onClick={close}>Cancelar</button>
          <button type='submit' className='btn btn-primary'>Registrar precio</button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default SetInputCostModal
import { useMemo, useState } from 'react'
import { Alert, } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { PageLayout } from '../../../../components/PageLayout'
import { useOrdersById } from '../useOrders'
import AddProductModal from './AddProductModal'
import { GeneralData } from './GeneralData'
import { Header } from './Header'
import { InvoiceItems } from './InvoiceItems'
import { LargeSubmit } from '../../../../components/LargeSubmit'
import { useNubeDocByOrderId } from './useNubeFact'
import { emitCreditNote } from '../../../../services/sell_order'
import toast from 'react-hot-toast'

function SelectTipoDeNotaDeCredito(props: any) {
  return (<select className="form-select" aria-label="nota-credito" value={props.creditNoteType} onChange={props.handleSelect} required>
    <option value="">Selecciona el tipo de nota de crédito a crear</option>
    <option value="1">ANULACIÓN DE LA OPERACIÓN</option>
    {/* <option value="2">ANULACIÓN POR ERROR EN EL RUC</option> */}
    {/* <option value="3">CORRECCIÓN POR ERROR EN LA DESCRIPCIÓN</option> */}
    {/* <option value="4">DESCUENTO GLOBAL</option>
    <option value="5">DESCUENTO POR ÍTEM</option> */}
    <option value="6">DEVOLUCIÓN TOTAL</option>
    <option value="7">DEVOLUCIÓN POR ÍTEM</option>
    {/* <option value="8">BONIFICACIÓN</option>
    <option value="9">DISMINUCIÓN EN EL VALOR</option>
    <option value="10">OTROS CONCEPTOS</option>
    <option value="11">AJUSTES AFECTOS AL IVAP</option>
    <option value="12">AJUSTES DE OPERACIONES DE EXPORTACIÓN</option>
    <option value="13">AJUSTES - MONTOS Y/O FECHAS DE PAGO</option> */}
  </select>);
}

function Notes(props: any) {
  return (<div className="mb-2">
    Observaciones:
    <textarea className="form-control" rows={1} value={props.notes} onChange={props.handleChange} required></textarea>
  </div>);
}

const CreditNote = () => {
  const { id } = useParams<{ id: string }>();
  const { order, loading, items, setItems, subTotal, setSubtotal } = useOrdersById(id);
  const { nubeDoc, isLoading, isError } = useNubeDocByOrderId(id);
  const [showModal, setShowModal] = useState(false)
  const toogleModal = () => setShowModal(!showModal)
  const [creditNoteType, setCreditNoteType] = useState("");
  const [notes, setNotes] = useState("");
  const [emitLoading, setEmitLoading] = useState(false);

  const [noteCreated, setNoteCreated] = useState(false);
  const [link, setLink] = useState("");

  const igv = useMemo(() => Math.round((subTotal * 0.18) * 100) / 100, [subTotal])
  const total = useMemo(() => Math.round((Number(subTotal) + igv) * 100) / 100, [subTotal, igv])

  const addToItem = (item: any) => {
    setItems([...items, item])
    setSubtotal((st: number) => Number((st + (item.amount * item.unitPrice)).toFixed(2)))
  }

  const removeItem = (item: any) => {
    setItems(items.filter((i: any) => i !== item))
    setSubtotal((st: number) => Number((st - (item.amount * item.unitPrice)).toFixed(2)))
  }

  const handleSelect = (e: any) => {
    setCreditNoteType(e.target.value);
  }

  const handleChange = (e: any) => {
    setNotes(e.target.value);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setEmitLoading(true);
      const res = await emitCreditNote(id, {
        creditNoteType,
        notes,
        subTotal,
        igv,
        total,
        items
      });
      toast.success("Nota de crédito registrada y emitida");
      setEmitLoading(false);
      setNoteCreated(true);
      setLink(res.enlace_del_pdf);
    } catch (e: any) {
      toast.error(e.response.data);
    }
  }

  const viewNote = () => {
    window.open(link, "_blank");
  }


  if (loading) return <PageLayout>Cargando...</PageLayout>
  if (!order) return <PageLayout><Alert variant='danger'>Error al cargar los datos</Alert></PageLayout>

  if (isLoading) return <PageLayout>Cargando...</PageLayout>
  if (isError) return <PageLayout><Alert variant='danger'>Error al cargar los datos</Alert></PageLayout>

  return (
    <PageLayout>
      <Header id={id} />
      {!noteCreated &&
        <>
          <GeneralData subTotal={Number(subTotal)} igv={Number(igv)} total={Number(total)} customer={order.customer.name} nubeDoc={nubeDoc}></GeneralData>
          Tipo de nota de crédito:
          <form onSubmit={handleSubmit}>
            <SelectTipoDeNotaDeCredito creditNoteType={creditNoteType} handleSelect={handleSelect}></SelectTipoDeNotaDeCredito>
            <Notes notes={notes} handleChange={handleChange}></Notes>
            <InvoiceItems items={items} toogleModal={toogleModal} removeItem={removeItem}></InvoiceItems>
            {!emitLoading ?
              <LargeSubmit text="Crear documento" />
              :
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            }
          </form>
          <AddProductModal show={showModal} toogle={toogleModal} functionHandler={addToItem} />
        </>
      }
      {noteCreated &&
        <>
          <Alert variant='success'>
            <div className="d-flex justify-content-between">
              <div>
                <i className="bi bi-bandaid me-2"></i><strong>Nota de crédito creada satisfactoriamente</strong>
              </div>
              <div style={{ cursor: 'pointer' }}>
                <span onClick={viewNote}>Ver nota de crédito</span>
              </div>
            </div>
          </Alert>
        </>
      }
    </PageLayout>
  )
}

export default CreditNote
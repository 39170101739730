import { Breadcrumb } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useHistory, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/PageLayout";
import routes from "../../../helpers/routes";
import usePurchase from "../../../hooks/usePurchase";
import { useAPurchase } from "../../../hooks/usePurchases";
import InputManualPurchaseForm from "./PurchaseManualForm";

export default function EditPurchase() {
  let history = useHistory<any>();
  const { updatePurchase: updateInputPurchase } = usePurchase('inputs');
  const { id: purchaseId } = useParams<any>();
  const { purchase, isLoading } = useAPurchase(purchaseId, 'inputs');

  const updatePurchase = async (edited: any) => {
    try {
      //TODO llamar al servicio de actualización
      await updateInputPurchase(purchaseId, edited);
      toast.success("Actualizado correctamente");
      history.push(routes.purchase.viewInput(purchaseId));
    } catch (error) {
      alert(JSON.stringify(error, null, 2))
    }
  }

  if (isLoading) {
    return <PageLayout>Cargando...</PageLayout>
  }

  return (
    <PageLayout>
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: routes.purchase.inputs }} linkAs={Link}>
          Compras de insumos
        </Breadcrumb.Item>
        <Breadcrumb.Item linkProps={{ to: routes.purchase.viewInput(purchase.id) }} linkAs={Link}>
          Ver Compra
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Edición de compra</Breadcrumb.Item>
      </Breadcrumb>
      <InputManualPurchaseForm initialData={purchase} submitFunction={updatePurchase} />
    </PageLayout>
  )
}
import { axiosInstance } from "../axios";

export const fetchAllSuppliers = async () => {
  try {
    const res = await axiosInstance.get('/suppliers');
    return res.data
  } catch (error) {
    throw (error)
  }
}

export const fetchSupplier = async (id: number) => {
  try {
    const res = await axiosInstance.get(`/suppliers/${id}`);
    return res.data
  } catch (error) {
    throw (error)
  }
}

export const createSupplier = async (body: {}) => {
  try {
    return await axiosInstance.post('/suppliers', body)
  } catch (error) {
    throw (error)
  }
}

export const updateSupplier = async (id: number, body: {}) => {
  try {
    return await axiosInstance.patch(`/suppliers/${id}`, body)
  } catch (error) {
    throw (error)
  }
}
import useSWR from 'swr';
import TableList from '../../../components/TableList1';

export interface Order {
  id: number
  date: string
  documentNumber: string
  link: string
  total: number
}

interface OutProps {
  orders: Order[]
  isLoading: boolean
  isError: any
  mutate: any
}

export const useFacturasPorCobrarMakro = (): OutProps => {
  const { data, error, mutate } = useSWR('/collect/por-cobrar/makro');

  return {
    orders: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export default function FacturasPorCobrarTable() {
  const { orders, isLoading, isError } = useFacturasPorCobrarMakro();

  function viewDocument(link: string) {
    window.open(link, "_blank");
  }

  const headers = ["Fecha de factura", "Numero de factura", "Monto total", "Acciones"];
  const body = orders && orders.map((o) => (
    <tr key={o.id}>
      <td>{o.date}</td>
      <td>{o.documentNumber}</td>
      <td>{o.total}</td>
      <td><span style={{
        cursor: 'pointer',
        borderRadius: '1rem',
        color: '#FFFFFF',
        backgroundColor: 'rgb(12,42,17,0.6)',
        padding: '.25rem .5rem'
      }} onClick={() => viewDocument(o.link)}>Ver factura</span></td>
    </tr>
  ));

  if (isError) {
    return <div>Error al cargar los datos</div>
  }

  if (isLoading) {
    return <div>
      Cargando datos...
    </div>
  }

  return <div>
    <TableList headers={headers} body={body} />
    {orders.length === 0 && <h5>Aún no se registran datos</h5>}
  </div>;
}

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { axiosInstance } from "../../utils/axios";

export default function EditUserDetailsModal({ show, close, user, refresh }: any) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      first_name: user ? user.first_name : '',
      last_name: user ? user.last_name : '',
      username: user ? user.username : '',
      email: user ? user.email : '',
    }
  })

  const handleClose = () => {
    close()
  }

  const onSubmit = async (data: any) => {
    try {
      await axiosInstance.patch(`/users/${user.id}`, {
        first_name: data.first_name,
        last_name: data.last_name,
        username: data.username,
        email: data.email,
      });
      toast.success("Datos actualizados");
      refresh()
      handleClose()
    } catch (error) {
      alert(error)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar datos personales</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Col>
            <Row>
              <Form.Group className="mb-3" controlId="first_name">
                <Form.Label>Nombres</Form.Label>
                <Form.Control type="text" placeholder="Nombres" {...register("first_name")} required />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="last_name">
                <Form.Label>Apellidos</Form.Label>
                <Form.Control type="text" placeholder="Apellidos" {...register("last_name")} required />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Nombre de usuario</Form.Label>
                <Form.Control type="text" placeholder="Nombre de usuario" {...register("username")} required />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control type="email" placeholder="Email" {...register("email")} required />
              </Form.Group>
            </Row>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Actualizar datos
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
import React from "react"
import { axiosInstance } from "../../utils/axios"

export interface Box {
  id: string
  name: string
  total: number
  currency: string
  isCci: boolean
  isOfficialBox: boolean
}

interface IMoneyContext {
  boxes: Box[]
  fetch: () => void
  isLoading: boolean
  isError: any
  boxesSumary: { name: string, total: number }
}

export const MoneyContext = React.createContext({} as IMoneyContext);

function MoneyProvider({ children }: { children: JSX.Element }) {
  const [boxes, setBoxes] = React.useState<Box[]>([]);
  const [error, setError] = React.useState<any>();

  const boxesSumary = React.useMemo(() => {
    let sum = 0;
    for (let i = 0; i < boxes.length; i++) {
      const element = boxes[i];
      sum += Number(element.total)
    }
    return {
      name: 'Resumen de fondos',
      total: sum
    }
  }, [boxes]);

  const fetch = React.useCallback(() => {
    (async () => {
      try {
        const res = await axiosInstance.get('/boxes');
        setBoxes(res.data);
      } catch (error) {
        setError(error)
      }
    })()
  }, []);

  React.useEffect(() => {
    fetch()
  }, [fetch]);

  const contextValue = {
    boxes,
    fetch,
    isLoading: boxes.length === 0,
    isError: error,
    boxesSumary
  }
  return <MoneyContext.Provider value={contextValue}>{children}</MoneyContext.Provider>
}

export default MoneyProvider;
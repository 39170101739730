import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export default function SupplyForm({ initialSupply, submitFunction, toogle }: any) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      internalCode: initialSupply ? initialSupply.internalCode : '',
      name: initialSupply ? initialSupply.name : '',
    }
  })

  const onSubmit = (data: any) => {
    const supply = {
      internalCode: data.internalCode,
      name: data.name
    }
    submitFunction(supply)
  }

  return (
    <>
      {!initialSupply ? <h2>Nuevo suministro</h2> : <h2>Editar suministro</h2>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="internalCode">
          <Form.Label>Código del suministro</Form.Label>
          <Form.Control type="text" placeholder="Código" {...register("internalCode")} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="first_name">
          <Form.Label>Nombre del suministro</Form.Label>
          <Form.Control type="text" placeholder="Nombre" {...register("name")} required />
        </Form.Group>
        <div className="d-flex" style={{ gap: '10px' }}>
          <Button className="mr-3" variant="secondary" onClick={() => toogle()}>Cancelar</Button>
          <Button variant="primary" type="submit">
            {initialSupply ? "Actualizar" : "Guardar"}
          </Button>
        </div>
      </form>
    </>
  )
}
import styled from "styled-components";

export const SettingsPageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  column-gap: 1rem;
  height: auto;
`;

export const NavSettings = styled.div`
  grid-column: 1 / 2;
  border: 1px solid darkslategray;
  border-radius: .6rem;
  padding: .5rem;
`;

export const MainSettingsSection = styled.div`
  grid-column: 2 / 3;
  padding: .5rem;
`;

interface MyProps {
  active: boolean;
}

export const NavSettingsItem = styled.div`
  border-bottom: ${(props: MyProps) => props.active ? "1px solid red" : "1px solid green"};
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
`;
import { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import { getMakroOrdersForPayment } from "../../../utils/api/sellOrders";

interface ForPay {
  id: number
  serie: string
  numero: string
  sellOrderId: number
  total: number
  date: string
}

const useNubeDocuments = () => {
  const [orders, setOrders] = useState<ForPay[]>([]);

  useEffect(() => {
    getMakroOrdersForPayment().then(setOrders);
  }, []);

  return { orders }
}

const AddOrderInvoiceModal = ({ show, toogle, addFunction }: any) => {
  const { register, handleSubmit, setValue } = useForm();
  const { orders } = useNubeDocuments();
  const [selectedOrder, setSelectedOrder] = useState<ForPay[]>([]);
  const factory = useMemo(() => selectedOrder[0] && Math.round(selectedOrder[0].total * 0.03 * 100) / 100, [selectedOrder]);

  const handleClose = () => {
    setSelectedOrder([]);
    toogle();
  }

  const handleChange = (s: any) => {
    setSelectedOrder(s);
    const localFactory = Math.round((s[0].total * 0.97) * 100) / 100
    setValue("prontoPago", Math.round((localFactory * 0.025) * 100) / 100)
  }

  const onSubmit = (data: any) => {
    const item = {
      id: selectedOrder[0].id,
      date: selectedOrder[0].date,
      sellOrderId: selectedOrder[0].sellOrderId,
      invoice: selectedOrder[0].serie + "-" + selectedOrder[0].numero,
      total: Number(selectedOrder[0].total),
      factory,
      prontoPago: Number(data.prontoPago)
    }
    addFunction(item);
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar factura de venta</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {/* {isError && <div>Error al cargar los datos del servidor</div>}
          {isLoading && <div>Cargando datos...</div>} */}
          <Row>
            <Form.Group className="mt-0" controlId="order">
              <Form.Label>Facturas por cobrar</Form.Label>
              <Typeahead
                id="order-selected"
                labelKey={(option) => `${option.serie}-${option.numero}`}
                onChange={(s) => handleChange(s)}
                options={orders}
                selected={selectedOrder}
                placeholder="Buscar factura"
                inputProps={{ required: true }}
              />
            </Form.Group>
          </Row>
          {selectedOrder[0] &&
            <div>
              <Row>
                <Col>
                  <div>Monto total de la factura: {selectedOrder[0].total}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mt-3" controlId="prontoPago">
                    <Form.Label>Pronto pago</Form.Label>
                    <Form.Control type="number" step="0.01" placeholder="Pronto pago" {...register("prontoPago")} required />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default AddOrderInvoiceModal;
import React, { useState } from 'react'
import { PageLayout } from '../../../../components/PageLayout'
import GuiasList from './GuiasList';
import NuevaGuiaRemision from './NuevaGuiaRemision'

const GuiaRemisionPage = () => {
  const [adding, setAdding] = useState(false);
  const toggleAdding = () => setAdding(!adding);

  return (
    <PageLayout>
      {!adding && <GuiasList toggleAdding={toggleAdding} />}
      {adding && <NuevaGuiaRemision toggleAdding={toggleAdding} />}
    </PageLayout>
  )
}

export default GuiaRemisionPage
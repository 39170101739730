import React from 'react'

interface Props {
    type: string
    name: string
    value: string | number | undefined
    label: string
    disabled?: boolean
    required?: boolean
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export class Input extends React.Component<Props> {
    render() {
        return (
            <React.Fragment>
                <label htmlFor={this.props.name} className="form-label">{this.props.label}</label>
                <input
                    type={this.props.type}
                    className="form-control"
                    id={this.props.name}
                    name={this.props.name}
                    disabled={this.props.disabled && this.props.disabled}
                    value={this.props.value}
                    onChange={this.props.handleChange}
                    required={this.props.required}
                />
            </React.Fragment>
        )
    }
}
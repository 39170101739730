import React from 'react';
import { UseFormRegister } from 'react-hook-form';

type SelectProps = {
  name: string;
  text: string;
  options: { value: string; label: string }[];
  register: UseFormRegister<any>;
};

export function SelectWithOutLabel({ name, text, options, register }: SelectProps) {
  return (
    <>
      <select id={name} {...register(name)} required>
        <option value="">{text}</option>
        {options.map((o, i) => (
          <option key={i} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </>
  )
}
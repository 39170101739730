import React from 'react'

interface Props {
    headers: string[],
    body: React.Component | JSX.Element[]
    actions?: boolean
    noMargin?: boolean
}

export default function TableList({ headers, body, actions = true, noMargin = false }: Props) {
    const tableClass = noMargin ? "tbale-responsive mt-0" : "tbale-responsive mt-3"

    return (
        <div className={tableClass}>
            <table className="table table-bordered table-sm">
                <thead className="table-light">
                    <tr>
                        {headers.map((item, index) => (
                            index !== headers.length - 1 ? <th key={index}>{item}</th> : null
                        ))}
                        <th style={actions ? { textAlign: 'center' } : {}}>{headers[headers.length - 1]}</th>
                    </tr>
                </thead>
                <tbody>
                    {body}
                </tbody>
            </table>
        </div>
    )
}

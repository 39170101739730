import { useState } from "react"
import toast from "react-hot-toast"
import { useHistory } from "react-router-dom"
import ConfirmModal from "../../../../components/ConfirmModal"
import TableList from "../../../../components/TableList1"
import routes from "../../../../helpers/routes"
import useAuth from "../../../../hooks/useAuth"
import useModal from "../../../../hooks/useModal"
import { formatOrderState, formatMoney, toLocalDateFormat } from "../../../../utils/functions"
import { DeliveryButton, EmitButtom, ViewButton } from "../../../common/styled"
import { EmitModal } from "../EmitModal"
import { useOrders } from "../useOrders"

export default function ConfirmedTab({ orders, refresh }: any) {
  let history = useHistory<any>()
  const { user } = useAuth()
  const { emitOrder, deliveryOrder } = useOrders(user?.role === 'vendedor')
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState(null)
  const { isOpenModal, toogleModal } = useModal()
  const emitModal = useModal()

  const handleDelivery = (order: any) => {
    setOrder(order)
    toogleModal()
  }

  const handleEmitSellOrder = async (order: any) => {
    setOrder(order)
    emitModal.toogleModal();
  }

  const _emitSellOrder = async () => {
    try {
      setLoading(true)
      await emitOrder(order);
      toast.success("Documento electronico emitido");
      refresh()
      setLoading(false)
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data);
    }
  }

  const deliverySellOrder = async () => {
    try {
      await deliveryOrder(order);
      toast.success("Orden entregada");
      refresh()
      toogleModal()
    } catch (error: any) {
      toogleModal();
      toast.error(error.response.data);
    }
  }

  const headers = (user?.role !== 'vendedor') ? ['Fecha', 'Venedor', 'Cliente', 'Importe Total', 'Tipo', 'Estado', ''] : ['Fecha', 'Cliente', 'Importe Total', 'Estado', ''];
  const body = orders?.map((order: any) => (
    <tr key={order.id}>
      <td>{toLocalDateFormat(order.deliveryDate)}</td>
      {user?.role !== 'vendedor' && <td>{order.user.username}</td>}
      <td>{order.customer.name}</td>
      <td>{formatMoney(order.total)}</td>
      <td>{order.futureDocument}</td>
      <td>{formatOrderState(order)}</td>
      <td className="d-flex gap-1">
        <ViewButton title="Ver detalle" onClick={() => history.push(routes.viewOrder(order.id))}><i className="bi bi-eye"></i></ViewButton>
        {user?.role !== 'vendedor' && (order.futureDocument === 'PROFORMA' || order.emited) && !order.delivered &&
          <DeliveryButton title="Despachar orden" onClick={() => handleDelivery(order)}><i className="bi bi-truck"></i></DeliveryButton>
        }
        {user?.role !== 'vendedor' && !order.emited && order.futureDocument !== 'PROFORMA' &&
          <EmitButtom title="Emitir documento" onClick={() => handleEmitSellOrder(order)}><i className="bi bi-files"></i></EmitButtom>
        }
      </td>
    </tr>
  ))

  if (loading) {
    return <h1>Cargando...</h1>
  }

  return (
    <>
      <TableList headers={headers} body={body} />
      <ConfirmModal
        show={isOpenModal}
        close={toogleModal}
        primaryText="Despacho de orden de venta"
        secondaryText="¿Confirma que se despachó la orden de venta? Los productos serán descontados del stock."
        handleFunction={deliverySellOrder}
      />
      <EmitModal
        show={emitModal.isOpenModal}
        toggle={emitModal.toogleModal}
        order={order}
        refresh={refresh}
        handleFunction={_emitSellOrder}
      />
    </>
  )
}

import { axiosInstance } from "../axios";

export const getMakroOrdersForPayment = async () => {
    try {
        const res = await axiosInstance.get('/orders/makro-orders-for-payment');
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const fetchAllSellOrders = async () => {
    try {
        const res = await axiosInstance.get('/orders');
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const fetchSellOrderById = async (orderId: string) => {
    try {
        const res = await axiosInstance.get(`/orders/${orderId}`);
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const fetchAllVendorOrders = async (userId: string) => {
    try {
        const res = await axiosInstance.get(`/orders/vendor/${userId}`);
        return res.data
    } catch (error) {
        throw (error)
    }
}

export const createSellOrder = async (order: any) => {
    try {
        return await axiosInstance.post('/orders', order)
    } catch (error) {
        throw (error)
    }
}

export const updateSellOrder = async (order: any, orderId: string) => {
    try {
        return await axiosInstance.patch(`orders/${orderId}`, order)
    } catch (error) {
        throw (error)
    }
}

export const confirmSellOrder = async (orderType: string, deliveryDate: string, orderId: string) => {
    try {
        return await axiosInstance.post(`/orders/confirm/${orderId}`, { orderType, deliveryDate })
    } catch (error) {
        throw (error)
    }
}

export const deliverySellOrder = async (orderId: string) => {
    try {
        return await axiosInstance.post(`/orders/delivery/${orderId}`)
    } catch (error) {
        throw (error)
    }
}

export const paySellOrder = async (orderId: number, amount: number, boxId: string, date: string) => {
    try {
        return await axiosInstance.post(`/orders/pay/${orderId}`, { amount, boxId, date })
    } catch (error) {
        throw (error)
    }
}

export const emitSellOrder = async (orderId: string) => {
    try {
        const res = await axiosInstance.post(`/orders/emit/${orderId}`)
        return res.data
    } catch (error: any) {
        if (!error.response) {
            throw (error)
        }
        throw (error.response.data.errors)
    }
}

// Para pasar a producción nubefact se tiene que generar lo siguiente:
//
// Factura Electrónica en Soles
// Factura Electrónica en Dólares
// Factura Electrónica para operaciones Exoneradas o Inafectas
export const facturaExonerada = async () => {
    try {
        const resp = await axiosInstance.post('nubefact/factura/exonerada')
        return resp.data
    } catch (e) {
        throw (e)
    }
}
// Factura Electrónica para Exportación
export const facturaParaExportacion = async () => {
    try {
        const resp = await axiosInstance.post('nubefact/factura/exportacion')
        return resp.data
    } catch (e) {
        throw (e)
    }
}
// Nota de Crédito Electrónica modificando o anulando una Factura Electrónica
// Nota de Débito Electrónica modificando o modificando una Factura Electrónica
// Factura Electrónica combinada con operaciones Gravadas, Inafectas o Exoneradas
export const facturaMixta = async () => {
    try {
        const resp = await axiosInstance.post('nubefact/factura/mixta')
        return resp.data
    } catch (e) {
        throw (e)
    }
}
// Consulta de Estado en la Sunat de la Factura Electrónica
// Boleta de Venta Electrónica en Soles
// Boleta de Venta Electrónica en Dólares
// Boleta de Venta Electrónica para operaciones Exoneradas o Inafectas
export const boletaExonerada = async () => {
    try {
        const resp = await axiosInstance.post('nubefact/boleta/exonerada')
        return resp.data
    } catch (e) {
        throw (e)
    }
}
// Boleta de Venta Electrónica para Exportación
export const boletaParaExportacion = async () => {
    try {
        const resp = await axiosInstance.post('nubefact/boleta/exportacion')
        return resp.data
    } catch (e) {
        throw (e)
    }
}
// Boleta de Venta Electrónica combinada con operaciones Gravadas, Inafectas o Exoneradas
export const boletaMixta = async () => {
    try {
        const resp = await axiosInstance.post('nubefact/boleta/mixta')
        return resp.data
    } catch (e) {
        throw (e)
    }
}
// Nota de Crédito Electrónica modificando o anulando una Boleta de Venta Electrónica
// Nota de Débito Electrónica modificando o modificando una Boleta de Venta Electrónica
// Consulta de Estado en la Sunat de la Boleta de Venta Electrónica
// Comunicación de Baja de Factura Electrónica
// Comunicación de Baja de Boleta de Venta Electrónica
// Consulta de Estado de Comunicación de Baja
import React from "react";
import { axiosInstance } from "../utils/axios";

export interface Input {
  id: Number
  name: String
  stock: Number
  unit: String
  internalCode: String
  manufactured: Boolean
  storable: Boolean
}

const useInputs = () => {
  const [inputs, setInputs] = React.useState<Input[]>([]);
  const [error, setError] = React.useState<any>();
  const [searchValue, setSearchValue] = React.useState("");

  const filteredInputs = React.useMemo(() => {
    return inputs.filter(input => input.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [inputs, searchValue])

  const fetchInputs = React.useCallback(() => {
    (async () => {
      try {
        const res = await axiosInstance.get('/inputs');
        setInputs(res.data);
      } catch (error) {
        setError(error)
      }
    })()
  }, []);

  React.useEffect(() => {
    fetchInputs();
  }, [fetchInputs]);

  return {
    filteredInputs,
    fetchInputs,
    searchValue,
    setSearchValue,
    isLoading: inputs.length === 0,
    isError: error,
  }
}

export default useInputs;
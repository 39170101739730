export const detailedOrdersDataSchema = [
  {
    column: "Número de orden",
    type: Number,
    value: (data: any) => data.numero_de_orden
  },
  {
    column: "Fecha",
    type: String,
    value: (data: any) => data.fecha
  },
  {
    column: "Producto",
    type: String,
    value: (data: any) => data.nombre_de_producto
  },
  {
    column: "Cantidad",
    type: Number,
    value: (data: any) => Number(data.cantidad)
  },
  {
    column: "Precio",
    type: Number,
    value: (data: any) => Number(data.precio)
  },
  {
    column: "Cliente",
    type: String,
    value: (data: any) => data.cliente
  },
];

export const detailedSoldProductsDataSchema = [
  {
    column: "Número de orden",
    type: Number,
    value: (data: any) => data.numero_de_orden
  },
  {
    column: "Fecha",
    type: String,
    value: (data: any) => data.fecha
  },
  {
    column: "Producto",
    type: String,
    value: (data: any) => data.nombre_de_producto
  },
  {
    column: "Cantidad",
    type: Number,
    value: (data: any) => Number(data.cantidad)
  },
];
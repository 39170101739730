import MoneyChartComponet from './MoneyChartComponet';

function IngresosComponent() {
  return (
    <MoneyChartComponet
      color="green"
      movementType="ingreso"
      chartTitle="Ingresos (S/.)"
      borderColor='rgb(53, 162, 235)'
      backgroundColor='rgba(53, 162, 235, 0.5)'
    />
  )
}

export default IngresosComponent
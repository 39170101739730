import { Breadcrumb, Table, Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { PageLayout } from '../../components/PageLayout'
import routes from '../../helpers/routes'
import { useCustomerById } from './useCustomer'

function CustomerDetails() {
  const { id: customerId } = useParams<any>()
  const { customer } = useCustomerById(customerId)

  return (
    <PageLayout>
      {customer &&
        <div>
          <Breadcrumb>
            <Breadcrumb.Item linkProps={{ to: routes.customers }} linkAs={Link}>
              Clientes
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{customer.name}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between">
            <h1>{customer.name}</h1>
            <Link to={routes.updateCustomer(customer.id)}>
              <Button variant="outline-warning">Editar</Button>
            </Link>
          </div>
          <p>{customer.documentType}: {customer.documentNumber}</p>
          <p>{customer.address}</p>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>Producto</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              {customer.priceList.map((item, index) => (
                <tr key={index}>
                  <td>{item.groupName}</td>
                  <td>{item.price}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      }
    </PageLayout>
  )
}

export default CustomerDetails;
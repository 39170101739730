import React, { useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { addIncomeReason, addWithdrawalReason } from '../../utils/api/boxes'
import ReasonForm from './ReasonForm'
import { useIncomeMovements, useWithdrawalMovements } from './useMovementReasons'

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }
`

const Group = styled.div`
  border: 1px solid grey;
  border-radius: .4rem;
  display: flex;
  flex-direction: column;
  padding: .5rem;
`

const GroupHeader = styled.div`
  color: red;
`

const GroupItems = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: .3rem;
`

const GroupItem = styled.div`
  padding: .25rem .5rem;
  border-bottom: 1px solid grey;
`

const MovmentReasonsForm = () => {
  const { incomes, isError: incomesError, mutateIncomes } = useIncomeMovements();
  const { withdrawals, isError: withdrawalsError, mutateWithdrawals } = useWithdrawalMovements();
  const [incomesFormErrors, setIncomesFormErrors] = useState('');
  const [withdrawalsFormErros, setWithdrawalsFormErros] = useState('');

  function addIncome(data: any) {
    addIncomeReason(data).then(() => {
      toast.success("Motivo registrado");
      mutateIncomes();
    }).catch(setIncomesFormErrors);
  }

  function addWithdrawal(data: any) {
    addWithdrawalReason(data).then(() => {
      toast.success("Motivo registrado");
      mutateWithdrawals();
    }).catch(setWithdrawalsFormErros);
  }

  if (incomesError || withdrawalsError) return null
  return (
    <>
      <Container>
        <Group>
          <GroupHeader>Motivos para ingresos de dinero</GroupHeader>
          <ReasonForm errors={incomesFormErrors} submitFunction={addIncome} />
          <GroupItems>
            {incomes?.map((i: any) => (
              <GroupItem key={i.id}>{i.name}</GroupItem>
            ))}
          </GroupItems>
        </Group>
        <Group>
          <GroupHeader>Motivos para salidas de dinero</GroupHeader>
          <ReasonForm errors={withdrawalsFormErros} submitFunction={addWithdrawal} />
          <GroupItems>
            {withdrawals?.map((w: any) => (
              <GroupItem key={w.id}>{w.name}</GroupItem>
            ))}
          </GroupItems>
        </Group>
      </Container>
    </>
  )
}

export default MovmentReasonsForm
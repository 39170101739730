import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap';
import { axiosInstance } from '../../../../utils/axios';
import { BorderedSection, BorderedSectionBody, BorderedSectionBodyItem, BorderedSectionTitle, BorderedSectionBodyFooter } from './styleds';

export function useUbigeos() {
  const [departamentos, setDepartamenos] = useState<any>([]);
  const [provincias, setProvincias] = useState<any>([]);
  const [distritos, setDistritos] = useState<any>([]);
  const [error, setError] = useState();

  useEffect(() => {
    axiosInstance.get('/ubigeos').then(res => res.data)
      .then(data => {
        setDepartamenos(data.departamentos);
        setProvincias(data.provincias);
        setDistritos(data.ubigeos);
      }).catch(setError)
  }, []);

  return {
    departamentos,
    provincias,
    distritos,
    isLoading: !error && !departamentos,
    isError: error,
  }
}

const PartidaSection = (props: any) => {
  const { departamentos, provincias, distritos, isLoading, isError } = useUbigeos();
  const [departamentoSelectValue, setDepartamentoSelectValue] = useState("");
  const [provinciaSelectValue, setProvinciaSelectValue] = useState("");

  const filterProvincias = useMemo(() => {
    return provincias.filter((p: any) => p.departamentoId.toString() === departamentoSelectValue)
  }, [provincias, departamentoSelectValue])

  const filterUbigeos = useMemo(() => {
    return distritos.filter((d: any) => d.provinciaId.toString() === provinciaSelectValue)
  }, [distritos, provinciaSelectValue])

  const handleChangeDepartamento = (e: any) => {
    setDepartamentoSelectValue(e.target.value);
    setProvinciaSelectValue("");
    props.setPartidaUbigeo("");
  }

  const handleChangeProvincia = (e: any) => {
    setProvinciaSelectValue(e.target.value);
    props.setPartidaUbigeo("");
  }

  if (isLoading) return <h5>Cargando datos...</h5>
  if (isError) return <h5>Error al obtener los datos del servidor</h5>
  return (
    <BorderedSection>
      <BorderedSectionTitle>Partida</BorderedSectionTitle>
      <BorderedSectionBody>
        <BorderedSectionBodyItem>
          <Form.Group controlId='departamentoDePartida'>
            <Form.Label>Departamento</Form.Label>
            <Form.Select value={departamentoSelectValue} onChange={handleChangeDepartamento} required>
              <option value="">Seleccionar</option>
              {departamentos.map((d: any) => (
                <option key={d.id} value={d.id}>{d.descripcion}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </BorderedSectionBodyItem>
        <BorderedSectionBodyItem>
          <Form.Group controlId='provinciaDePartida'>
            <Form.Label>Provincia</Form.Label>
            <Form.Select value={provinciaSelectValue} onChange={handleChangeProvincia} required>
              <option value="">Seleccionar</option>
              {filterProvincias.map((p: any) => (
                <option key={p.id} value={p.id}>{p.descripcion}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </BorderedSectionBodyItem>
        <BorderedSectionBodyItem>
          <Form.Group controlId='distritoDePartida'>
            <Form.Label>Distito</Form.Label>
            <Form.Select value={props.partidaUbigeo} onChange={(e: any) => props.setPartidaUbigeo(e.target.value)} required>
              <option value="">Seleccionar</option>
              {filterUbigeos.map((u: any) => (
                <option key={u.id} value={u.ubigeo}>{u.descripcion}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </BorderedSectionBodyItem>
      </BorderedSectionBody>
      <BorderedSectionBodyFooter>
        <Form.Group controlId='direccionDePartida'>
          <Form.Label>Dirección</Form.Label>
          <Form.Control type='text' value={props.partidaDireccion} onChange={(e: any) => props.setPartidaDireccion(e.target.value)} required />
        </Form.Group>
      </BorderedSectionBodyFooter>
    </BorderedSection>
  )
}

export default PartidaSection
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(3em, 1fr));
  grid-template-rows: 1.5em 1.5em;
  margin-top: .5em;
`

const VendorName = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SellsCount = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SellsAmount = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Separator = styled.div`
  border-top: 1px solid grey;
  width: 50%
`

function SummaryItem({ item }: any) {
  return (
    <Container>
      <VendorName>{item.user}</VendorName>
      <SellsCount>
        <div>{item.countOfCurrentMonth}</div>
        <Separator />
        <div>{item.countOfLastMonth}</div>
      </SellsCount>
      <SellsAmount>
        <div>S/ {item.sumOfCurrentMonth}</div>
        <Separator />
        <div>S/ {item.sumOfLastMonth}</div>
      </SellsAmount>
    </Container>
  )
}

export default SummaryItem
import React from "react";

interface State<T> {
  record: Partial<T>
  initialRecord: Partial<T>
}

interface Props<T> {
  disabled?: boolean
  editing?: boolean
  input?: Partial<T>
  handleSubmit: (i: Partial<T>) => void
  toogle?: () => void
}

function withEnhancedForm<T>(Component: React.ElementType) {
  return class extends React.Component<Props<T>, State<T>> {
    state: State<T> = {
      record: {},
      initialRecord: {}
    }

    componentDidMount() {
      if (this.props.input) {
        this.setState({
          ...this.state,
          record: this.props.input,
          initialRecord: this.props.input,
        })
      }
    }

    componentDidUpdate(prevProps: any) {
      if (this.props.editing !== prevProps.editing) {
        this.setState({
          ...this.state,
          record: this.state.initialRecord
        })
      }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const name = event.target.name
      const value = event.target.value
      this.setState({
        ...this.state,
        record: {
          ...this.state.record,
          [name]: value
        }
      })
    }

    handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name
      const value = event.target.checked
      this.setState({
        ...this.state,
        record: {
          ...this.state.record,
          [name]: value
        }
      })
    }

    handleSubmit = (event: React.FormEvent) => {
      event.preventDefault()
      this.props.handleSubmit(this.state.record)
    }

    render() {
      return (
        <Component
          record={this.state.record}
          handleChange={this.handleChange}
          handleChangeCheckbox={this.handleChangeCheckbox}
          handleSubmitForm={this.handleSubmit}
          {...this.props}
        />
      )
    }
  }
}

export interface WraperEnhancedFormProps<T> {
  record: Partial<T>
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSubmitForm: (event: React.FormEvent<Element>) => void
  disabled?: boolean
  editing?: boolean
  input?: Partial<T>
  toogle?: () => void
  handleSubmit: (i: Partial<T>) => void
}

export default withEnhancedForm
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import ConfirmModal from "../../components/ConfirmModal";
import routes from "../../helpers/routes";
import useAuth from "../../hooks/useAuth";
import useModal from "../../hooks/useModal";
import { toLocalDateFormat } from "../../utils/functions";
import { EmitModal } from "../sells/orders/EmitModal";
import { OrderItem, OrderItemData, OrderItemDataBody, OrderItemDataHeader, OrderItemOptions, VendorBadge } from "./styleds";
import { useOrders } from "./useOrders";

function ConfirmedOrderItem({ order, setLoading }: any) {
  const { mutate, emitOrder, deliveryOrder } = useOrders();
  const { user } = useAuth()
  let history = useHistory();
  const emitModal = useModal();
  const deliveryModal = useModal();

  const emitSellOrder = async () => {
    try {
      setLoading(true);
      await emitOrder(order);
      setLoading(false);
      toast.success("Documento electronico emitido");
      mutate()
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
  }

  const deliverySellOrder = async () => {
    try {
      setLoading(true);
      await deliveryOrder(order);
      setLoading(false);
      mutate();
      deliveryModal.toogleModal();
      toast.success("Orden entregada");
    } catch (error: any) {
      deliveryModal.toogleModal();
      setLoading(false);
      toast.error(error.response.data);
    }
  }

  return (
    <>
      <OrderItem>
        <OrderItemData>
          <OrderItemDataHeader>
            <div>
              <span style={{ fontWeight: 'bold' }}>Orden Nº{order.id}</span>
              <span style={{ fontWeight: 'bold', color: 'red', marginLeft: '.5rem' }}>S/. {order.total}</span>
              <span style={{ fontWeight: 'bold', color: 'chocolate', marginLeft: '.5rem', fontStyle: 'italic' }}>{toLocalDateFormat(order.deliveryDate)}</span>
            </div>
            <div><VendorBadge>{order.user.username}</VendorBadge></div>
          </OrderItemDataHeader>
          <OrderItemDataBody>{order.customer.name}</OrderItemDataBody>
        </OrderItemData>
        <OrderItemOptions>
          <button onClick={() => history.push(routes.viewOrder(order.id))}>Ver detalles</button>
          {user && user.role !== 'vendedor' && (order.futureDocument === 'PROFORMA' || order.emited) && !order.delivered &&
            <button onClick={deliveryModal.toogleModal}>Despachar</button>
          }
          {user && user.role !== 'vendedor' && !order.emited && order.futureDocument !== 'PROFORMA' &&
            <button onClick={emitModal.toogleModal}>Emitir</button>
          }
        </OrderItemOptions>
        <ConfirmModal
          show={deliveryModal.isOpenModal}
          close={deliveryModal.toogleModal}
          primaryText="Despacho de orden de venta"
          secondaryText="¿Confirma que se despachó la orden de venta? Los productos serán descontados del stock."
          handleFunction={deliverySellOrder}
        />
        <EmitModal
          show={emitModal.isOpenModal}
          toggle={emitModal.toogleModal}
          order={order}
          refresh={mutate}
          handleFunction={emitSellOrder}
        />
      </OrderItem>
    </>
  );
}

export default ConfirmedOrderItem;
import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { Link, useHistory } from 'react-router-dom'
import { PageLayout } from '../../components/PageLayout'
import routes from '../../helpers/routes'
import { createGroup } from '../../utils/api/products'
import { GroupForm } from './GroupForm'

function NewGroup() {
  let history = useHistory<any>()

  const addGroup = (group: any) => {
    createGroup(group).then(() => {
      toast.success("Grupo registrado");
      history.push(routes.product_groups)
    })
  }

  return (
    <PageLayout>
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: routes.product_groups }} linkAs={Link}>
          Grupos de Productos
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Nuevo grupo de productos</Breadcrumb.Item>
      </Breadcrumb>
      <GroupForm submitFunction={addGroup} />
    </PageLayout>
  )
}

export default NewGroup

import React from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { fetchAllGroups, IGroup } from '../../../../utils/api/products'

interface Item {
  group: {
    id: number
    name: string
  },
  unitPrice: number
  amount: number
}

interface AddProductModalProps {
  show: boolean
  toogle: () => void
  functionHandler: (item: Item) => void
}

export default function AddProductModal({ show, toogle, functionHandler }: AddProductModalProps) {
  const [groups, setGroups] = React.useState<IGroup[]>([])
  const [selectedGroup, setSelectedGroup] = React.useState<IGroup[]>([])
  const [sellPrice, setSellPrice] = React.useState(0)
  const [amount, setAmount] = React.useState(1)

  React.useEffect(() => {
    fetchAllGroups().then(setGroups)
  }, [])

  const close = () => {
    setSelectedGroup([])
    setSellPrice(0)
    setAmount(1)
    toogle()
  }

  const addOrderItem = (e: React.FormEvent<Element>) => {
    e.preventDefault()
    e.stopPropagation()
    const item = {
      group: {
        id: selectedGroup[0].id,
        code: selectedGroup[0].code,
        name: selectedGroup[0].name,
      },
      unitPrice: sellPrice,
      amount: amount,
    }
    functionHandler(item)
    close()
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Producto</Modal.Title>
      </Modal.Header>
      <Form onSubmit={addOrderItem}>
        <Modal.Body>
          <Container>
            <Row className="mb-2">
              <Col>
                <Form.Group controlId="product">
                  <Form.Label>Producto</Form.Label>
                  <Typeahead
                    id="group-selection"
                    labelKey="name"
                    onChange={(s) => {
                      setSelectedGroup(s)
                      if (s.length !== 0) {
                        setSellPrice(s[0].sellPrice)
                      } else {
                        setSellPrice(0)
                      }
                    }}
                    options={groups}
                    placeholder="Seleccionar producto..."
                    selected={selectedGroup}
                    inputProps={{ required: true }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <Form.Group controlId="unirPrice">
                  <Form.Label>Precio unitario</Form.Label>
                  <Form.Control type="number" placeholder="Precio unitario" value={sellPrice} onChange={(e) => setSellPrice(Number(e.target.value))} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="amount">
                  <Form.Label>Cantidad</Form.Label>
                  <Form.Control type="number" min="1" placeholder="Cantidad" value={amount} onChange={(e) => setAmount(Number(e.target.value))} required />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
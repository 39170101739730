import { Button, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom";
import routes from "../../helpers/routes";
import { CustomerModel } from "./CustomersPage";
import TableList from "../../components/TableList";

interface Props {
    customers: CustomerModel[],
    isLoading: boolean,
    isError: boolean
}

export default function CustomersList({ customers, isLoading, isError }: Props) {
    const headers = ["Nombre", "Número de documento", "Dirección", "Vendedor", ""]

    if (isError) return <div>failed to load</div>

    if (isLoading) return <Spinner animation="border" />

    return (
        <>
            <TableList items={customers} headers={headers} searchParams={["name", "documentNumber"]}>
                {(customer) => (
                    <>
                        <td>{customer.name}</td>
                        <td>{customer.documentNumber}</td>
                        <td>{customer.address}</td>
                        <td>{customer.vendor}</td>
                        <td className="d-flex justify-content-center"><Link to={routes.customer(customer.id)}>
                            <Button variant="primary" size="sm">Ver detalles</Button>
                        </Link></td>
                    </>
                )}
            </TableList>
        </>
    )
}
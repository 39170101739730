import Breadcrumbs from '../../../components/Breadcrumbs';
import { PageLayout } from '../../../components/PageLayout';
import BoxAggregableTable from '../../../components/BoxAggregableTable';
import useMakroPayments from './useMakroPayment';
import AddOrderInvoiceModal from './AddOrderInvoiceModal';
import AddDiscountModal from './AddDiscountModal';
import styled from 'styled-components';
import { formatMoney } from '../../../utils/functions';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router-dom';

const GeneralDataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2rem 2rem 2rem 2rem 2rem;
  border: solid #6f6f86 1px;
  padding: 5px;
`
const LabelColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  padding: 5px;
`
const DataColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: .9rem;
  font-weight: bold;
  align-items: center;
`

export default function AddMakroPayment() {
  const history = useHistory();
  const {
    sellOrdersHeaders,
    sellOrdersBody,
    sellDiscountHeaders,
    sellDiscountBody,
    breadcrumbs,
    ordersModal,
    discountsModal,
    equivalente,
    descuento,
    addOrderItem,
    addDiscountItem,
    register,
    handleSubmit,
    onSubmit,
    loading,
    bankBoxes,
    setSelectedBox,
    selectedBox
  } = useMakroPayments();

  return (
    <PageLayout>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralDataContainer className='col-xs-6'>
          <LabelColumn>Fecha de abono</LabelColumn>
          <DataColumn><input className="form-control form-control-sm" type="date" {...register('paymentDate')} required /></DataColumn>
          <LabelColumn>Número de grupo</LabelColumn>
          <DataColumn><input className="form-control form-control-sm" type="text" {...register('groupNumber')} required /></DataColumn>
          <LabelColumn>Importe equivalente</LabelColumn>
          <DataColumn>{formatMoney(equivalente)}</DataColumn>
          <LabelColumn>Descuento</LabelColumn>
          <DataColumn>{formatMoney(descuento)}</DataColumn>
          <LabelColumn>Importe abonado</LabelColumn>
          <DataColumn>{formatMoney(Number((equivalente - descuento).toFixed(2)))}</DataColumn>
          <LabelColumn>Cuenta destino</LabelColumn>
          <DataColumn>
            <div style={{ flexGrow: 1 }}>
              <Typeahead
                id="box-selected"
                labelKey="name"
                onChange={setSelectedBox}
                options={bankBoxes}
                selected={selectedBox}
                placeholder="Buscar cuenta de fondos"
                size="small"
                inputProps={{ required: true }}
              />
            </div>
          </DataColumn>
        </GeneralDataContainer>
        <BoxAggregableTable
          description="Facturas a cobrar"
          buttonText="Agregar factura"
          headers={sellOrdersHeaders}
          body={sellOrdersBody}
          toogle={ordersModal.toogleModal}
        />
        <BoxAggregableTable
          description="Descuentos de makro"
          buttonText="Agregar descuento"
          headers={sellDiscountHeaders}
          body={sellDiscountBody}
          toogle={discountsModal.toogleModal}
        />
        <div>
          <button type='button' className='btn btn-secondary' onClick={() => history.push('.')}>Cancelar</button>
          <button className='btn btn-primary m-2' type="submit" disabled={loading}>{!loading ? 'Guardar' : 'Registrando pago...'}</button>
        </div>
      </form>
      <AddOrderInvoiceModal
        show={ordersModal.isOpenModal}
        toogle={ordersModal.toogleModal}
        addFunction={addOrderItem} />
      <AddDiscountModal
        show={discountsModal.isOpenModal}
        toogle={discountsModal.toogleModal}
        addFunction={addDiscountItem} />
    </PageLayout>
  );
}

import { Route, Switch } from "react-router-dom";
import routes from "../helpers/routes";
import AccountPage from "../pages/users/AccountPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import MoneyPage from "../pages/money/MoneyPage";
import NotFoundPage from "../pages/NotFoundPage";
import UsersPage from "../pages/users/UsersPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ProvidersPage from "../pages/providers/ProvidersPage";
import InputsPage from "../pages/inputs/InputsPage";
import ProductsPage from "../pages/products/ProductsPage";
import OrdersPage from "../pages/orders/OrdersPage";
import { default as AdminOrderPage } from "../pages/sells/orders/OrdersPage";
import NewOrder from "../pages/sells/orders/NewOrder";
import CustomerDetails from "../pages/customers/CustomerDetails";
import InputDetails from "../pages/inputs/InputDetails";
import Dashboard from "../pages/Dashboard";
import ProductDetails from "../pages/products/ProductDetails";
import GroupsPage from "../pages/product-groups/GroupsPage";
import GroupDetails from "../pages/product-groups/GroupDetails";
import CustomerUpdate from "../pages/customers/CustomerUpdate";
import NewGroup from "../pages/product-groups/NewGroup";
import UpdateGroup from "../pages/product-groups/UpdateGroup";
import ViewOrder from "../pages/sells/orders/ViewOrder";
import EditOrder from "../pages/sells/orders/EditOrder";
import SupplyPage from "../pages/supply/SupplyPage";
import SupplierPage from "../pages/supplier/SupplierPage";
import PurchasePage from "../pages/purchase/inputs/PurchasePage";
import ViewProvider from "../pages/providers/ViewProvider";
import ViewPurchase from "../pages/purchase/inputs/ViewPurchase";
import EditPurchase from "../pages/purchase/inputs/EditPurchase";
import ProductRecipesPage from "../pages/recipes/products/Index";
import ProductProductions from "../pages/production/products/Index";
import ProductProductionOrder from "../pages/production/products/View";
import EditProductProductionOrder from "../pages/production/products/Edit";
import ViewProductRecipe from "../pages/recipes/products/View";
import EditProductRecipe from "../pages/recipes/products/Edit";
import InputRecipesPage from "../pages/recipes/inputs/Index";
import ViewInputRecipe from "../pages/recipes/inputs/View";
import EditInputRecipe from "../pages/recipes/inputs/Edit";
import InputProductionOrder from "../pages/production/inputs/View";
import InputProductions from "../pages/production/inputs/Index";
import EditInputProductionOrder from "../pages/production/inputs/Edit";
import SuppliesPurchasePage from "../pages/purchase/supplies/PurchasePage";
import ViewSupplyPurchase from "../pages/purchase/supplies/ViewPurchase";
import EditSupplyPurchase from "../pages/purchase/supplies/EditPurchase";
import roles from "../helpers/roles";
import CreditNote from "../pages/sells/orders/credit-note/CreditNote";
import VendorsComponent from "../vendors/VendorsComponent";
import SettingsPage from "../pages/settings/SettingsPage";
import GuiaRemisionPage from "../pages/sells/orders/guia-remision/GuiaRemisionPage";
import SellsPage from "../pages/sells/SellsPage";
import WarehousePage from "../pages/warehouse/WarehousePage";
import MaestrosIndex from "../pages/maestros/MaestrosIndex";
import CustomerMainPage from "../pages/customers/CustomerMainPage";
import KardexReportPage from "../pages/reports/kardex/KardexReportPage";
import AccountingReportPage from "../pages/reports/contabilidad/AccountingReportPage";
import ProductByCustomerReportPage from "../pages/reports/products/ProductByCustomerReportPage";
import MakroProfitReportPage from "../pages/makro/profitReport/MakroProfitReportPage";
import MakroPaymentsPage from "../pages/makro/payments/Index";
import AddMakroPayment from "../pages/makro/payments/AddMakroPayment";
import VariosReportPage from "../pages/reports/varios";
import Dashboards from "../pages/dashboards/Dashboards";
import FinancialReportsIndex from "../pages/reports/finanzas";
import DataExportMainPage from "../pages/reports/data";
import InputsStatePage from "../pages/inputs/inputs-state/InputsStatePage";
import MakroUtilitiesPage from "../pages/makro/utilities/MakroUtilitiesPage";
import RecipesPage from "../pages/recipes/general/RecipesPage";
import NewRecipePage from "../pages/recipes/general/NewRecipePage";
import ViewRecipePage from "../pages/recipes/general/ViewRecipePage";
import EditRecipePage from "../pages/recipes/general/EditRecipePage";
import ProductionsPage from "../pages/productions/ProductionsPage";
import NewProductionPage from "../pages/productions/NewProductionPage";
import CustomizeProduction from "../pages/productions/CustomizeProduction";
import FillingPage from "../pages/filling/FillingPage";

export default function AppRouter() {
    return (
        <Switch>
            <Route path="/maqueta" component={Dashboard} />
            <PrivateRoute exact path={routes.home} component={HomePage} />
            <PublicRoute exact path={routes.login} component={LoginPage} />
            <PrivateRoute exact path={routes.account} component={AccountPage} />
            <PrivateRoute exact path={routes.admin.users} hasRole={roles.admin} component={UsersPage} />
            {/* Configuraciones (Settings) */}
            <PrivateRoute exact path={routes.admin.config} vendorAdmit={false} component={SettingsPage} />
            {/* Vendedores */}
            <PrivateRoute exact path={routes.vendors} hasRole={roles.admin} component={VendorsComponent} />
            {/* Clientes */}
            <PrivateRoute exact path={routes.customers} component={CustomerMainPage} />
            <PrivateRoute exact path={routes.customer()} component={CustomerDetails} />
            <PrivateRoute exact path={routes.updateCustomer()} component={CustomerUpdate} />
            {/* Insumos, suministros y proveedores */}
            <PrivateRoute exact path={routes.inputs} vendorAdmit={false} component={InputsPage} />
            <PrivateRoute exact path={routes.inputsState} vendorAdmit={false} component={InputsStatePage} />
            <PrivateRoute exact path={routes.input()} vendorAdmit={false} component={InputDetails} />
            <PrivateRoute exact path={routes.supplies} vendorAdmit={false} component={SupplyPage} />
            <PrivateRoute exact path={routes.suppliers} vendorAdmit={false} component={SupplierPage} />
            <PrivateRoute exact path={routes.providers} vendorAdmit={false} component={ProvidersPage} />
            <PrivateRoute exact path={routes.provider()} vendorAdmit={false} component={ViewProvider} />
            {/* Productos */}
            <PrivateRoute exact path={routes.products} vendorAdmit={false} component={ProductsPage} />
            <PrivateRoute exact path={routes.product()} vendorAdmit={false} component={ProductDetails} />
            {/* Grupo de productos */}
            <PrivateRoute exact path={routes.product_groups} hasRole={roles.admin} component={GroupsPage} />
            <PrivateRoute exact path={routes.newProductGroup} hasRole={roles.admin} component={NewGroup} />
            <PrivateRoute exact path={routes.product_group()} hasRole={roles.admin} component={GroupDetails} />
            <PrivateRoute exact path={routes.updateProductGroup()} hasRole={roles.admin} component={UpdateGroup} />
            {/* Ordenes de venta */}
            <PrivateRoute path={routes.ventas.index} vendorAdmit={false} component={SellsPage} />
            <PrivateRoute exact path={routes.remision} component={GuiaRemisionPage} />
            <PrivateRoute exact path={routes.orders} component={OrdersPage} />
            <PrivateRoute exact path={routes.adminOrders} vendorAdmit={false} component={AdminOrderPage} />
            <PrivateRoute exact path={routes.newOrder} component={NewOrder} />
            <PrivateRoute exact path={routes.viewOrder()} component={ViewOrder} />
            <PrivateRoute exact path={routes.editOrder()} component={EditOrder} />
            {/* Notas de credito y debito */}
            <PrivateRoute exact path={routes.creditNote()} component={CreditNote} />
            {/* Dinero */}
            <PrivateRoute path={routes.boxes} hasRole={roles.admin} component={MoneyPage} />
            {/* Compra de insumos */}
            <PrivateRoute exact path={routes.purchase.inputs} vendorAdmit={false} component={PurchasePage} />
            <PrivateRoute exact path={routes.purchase.viewInput()} vendorAdmit={false} component={ViewPurchase} />
            <PrivateRoute exact path={routes.purchase.editInput()} vendorAdmit={false} component={EditPurchase} />
            {/* Compra de suministros */}
            <PrivateRoute exact path={routes.purchase.supplies} vendorAdmit={false} component={SuppliesPurchasePage} />
            <PrivateRoute exact path={routes.purchase.viewSupply()} vendorAdmit={false} component={ViewSupplyPurchase} />
            <PrivateRoute exact path={routes.purchase.editSupply()} vendorAdmit={false} component={EditSupplyPurchase} />
            {/* Recetas generales */}
            <PrivateRoute exact path={routes.recipes.index} hasRole={roles.admin} component={RecipesPage} />
            <PrivateRoute exact path={routes.recipes.newRecipe} hasRole={roles.admin} component={NewRecipePage} />
            <PrivateRoute exact path={routes.recipes.viewRecipe()} hasRole={roles.admin} component={ViewRecipePage} />
            <PrivateRoute exact path={routes.recipes.editRecipe()} hasRole={roles.admin} component={EditRecipePage} />
            {/* Producciones generales */}
            <PrivateRoute exact path={routes.productions.index} vendorAdmit={false} component={ProductionsPage} />
            <PrivateRoute exact path={routes.productions.newProduction} vendorAdmit={false} component={NewProductionPage} />
            <PrivateRoute exact path={routes.productions.customizeProduction()} hasRole={roles.admin} component={CustomizeProduction} />
            <PrivateRoute exact path={routes.filling.index} vendorAdmit={false} component={FillingPage} />
            {/* Recetas de productos */}
            <PrivateRoute exact path={routes.production.viewProductRecipe()} hasRole={roles.admin} component={ViewProductRecipe} />
            <PrivateRoute exact path={routes.production.productRecipes} hasRole={roles.admin} component={ProductRecipesPage} />
            <PrivateRoute exact path={routes.production.editProductRecipe()} hasRole={roles.admin} component={EditProductRecipe} />
            {/* Recetas de insumos */}
            <PrivateRoute exact path={routes.production.viewInputRecipe()} hasRole={roles.admin} component={ViewInputRecipe} />
            <PrivateRoute exact path={routes.production.inputRecipes} hasRole={roles.admin} component={InputRecipesPage} />
            <PrivateRoute exact path={routes.production.editInputRecipe()} hasRole={roles.admin} component={EditInputRecipe} />
            {/* Producción de productos */}
            <PrivateRoute exact path={routes.production.viewProductProduction()} vendorAdmit={false} component={ProductProductionOrder} />
            <PrivateRoute exact path={routes.production.productProductions} vendorAdmit={false} component={ProductProductions} />
            <PrivateRoute exact path={routes.production.editProductProduction()} vendorAdmit={false} component={EditProductProductionOrder} />
            {/* Producción de insumos */}
            <PrivateRoute exact path={routes.production.viewInputProduction()} vendorAdmit={false} component={InputProductionOrder} />
            <PrivateRoute exact path={routes.production.inputProductions} vendorAdmit={false} component={InputProductions} />
            <PrivateRoute exact path={routes.production.editInputProduction()} vendorAdmit={false} component={EditInputProductionOrder} />
            {/* Makro */}
            <PrivateRoute exact path={routes.makro.cobros} vendorAdmit={false} component={MakroPaymentsPage} />
            <PrivateRoute exact path={routes.makro.addMakroPayment} vendorAdmit={false} component={AddMakroPayment} />
            <PrivateRoute exact path={routes.makro.profitReport} vendorAdmit={false} component={MakroProfitReportPage} />
            <PrivateRoute path={routes.makro.utils} vendorAdmit={false} component={MakroUtilitiesPage} />
            {/* Reportes */}
            <PrivateRoute exact path={routes.reports.productsByCustomers} vendorAdmit={false} component={ProductByCustomerReportPage} />
            <PrivateRoute exact path={routes.reports.kardex} vendorAdmit={false} component={KardexReportPage} />
            <PrivateRoute exact path={routes.reports.accounting} vendorAdmit={false} component={AccountingReportPage} />
            <PrivateRoute exact path={routes.reports.various} vendorAdmit={false} component={VariosReportPage} />
            <PrivateRoute exact path={routes.reports.financial} hasRole={roles.admin} component={FinancialReportsIndex} />
            <PrivateRoute exact path={routes.reports.data} hasRole={roles.admin} component={DataExportMainPage} />
            {/* Dashboards */}
            <PrivateRoute exact path={routes.dashboards} hasRole={roles.admin} component={Dashboards} />
            {/* Almacenes */}
            <PrivateRoute path={routes.almacenes.productos} vendorAdmit={false} component={WarehousePage} />
            {/* Maestros */}
            <PrivateRoute path={routes.maestros.index} vendorAdmit={false} component={MaestrosIndex} />

            <Route path="*" component={NotFoundPage} />
        </Switch>
    );
}
import React from 'react';

const OrderForm: React.FC = () => {
  return (
    <div>
      <h1>Order Form</h1>
      {/* Add your form components here */}
    </div>
  );
};

export default OrderForm;
import React from 'react'

interface Props {
    title: string | undefined,
    subtitle: string | undefined
}

const BasicPageTitle = ({ title, subtitle }: Props) => {
    return (
        <div className="mb-2 d-flex">
            <div className="fs-1 fw-bolder">{title}</div>
            <div className="fw-light align-self-end ms-2 pb-2">{subtitle}</div>
        </div>
    )
}

export default BasicPageTitle

import React from 'react'
import { FullPageLayout } from '../../../components/FullPageLayout'
import RecipeForm from './RecipeForm'
import { axiosInstance } from '../../../utils/axios'
import toast from 'react-hot-toast'
import { Container } from './RecipesPage'

export default function NewRecipePage() {
  const [newRecipe, setNewRecipe] = React.useState<any>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  const handleSubmit = (recipe: any) => {
    setLoading(true)
    axiosInstance.post('/recipes/add-recipe', recipe).then((response) => {
      setNewRecipe(response.data.recipe)
      setLoading(false)
      toast.success('Receta creada')
    }).catch((error) => {
      setLoading(false)
      console.error(error)
      toast.error('Error creando receta')
    })
  }

  return (
    <FullPageLayout>
      <Container>
        {!newRecipe && <RecipeForm submitFunction={handleSubmit} loading={loading} />}
        {newRecipe && <div>
          <h2>Receta creada</h2>
          <p>Nombre: {newRecipe.name}</p>
          <p>Para base: {newRecipe.forBase ? 'Sí' : 'No'}</p>
          <p>Para venta: {newRecipe.forSale ? 'Sí' : 'No'}</p>
          <p>Acceso: {newRecipe.access}</p>
        </div>
        }
      </Container>
    </FullPageLayout>
  )
}

import React from 'react'
import { useForm } from 'react-hook-form'

const ReasonForm = ({ submitFunction }: any) => {
  const { register, handleSubmit, reset } = useForm();

  function onSubmit(data: any) {
    submitFunction(data);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex">
        <div className="input-group mb-3">
          <select style={{ flexGrow: 1.1 }} {...register('clasification')} className='form-select' name="clasification">
            <option value="admin">Admin</option>
            <option value="any">Cualquiera</option>
          </select>
          <input style={{ flexGrow: 3 }} {...register('name')} className='form-control' type="text" required />
          <button type='submit' className='btn btn-outline-primary'>+</button>
        </div>
      </div>
    </form>
  )
}

export default ReasonForm
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import routes from "../../helpers/routes";
import useAuth from "../../hooks/useAuth";
import useModal from "../../hooks/useModal";
import PayOrderModal from "../sells/orders/PayOrderModal";
import { OrderItem, OrderItemData, OrderItemDataBody, OrderItemDataHeader, OrderItemOptions, VendorBadge } from "./styleds";
import { useOrders } from "./useOrders";

function DeliveredOrderItem({ order, boxes }: any) {
  const { payOrder: apiPayOrder, mutate } = useOrders();
  const { user } = useAuth();
  let history = useHistory();
  const payModal = useModal();

  const payOrder = async (data: any) => {
    try {
      if (order) {
        await apiPayOrder({ ...data, order });
        toast.success("Pago registrado");
        mutate();
      }
    } catch (e) {
      alert(JSON.stringify(e, null, 2));
    }
  }

  return (
    <OrderItem>
      <OrderItemData>
        <OrderItemDataHeader>
          <div>
            <span style={{ fontWeight: 'bold' }}>Orden Nº{order.id}</span>
            <span style={{ fontWeight: 'bold', color: 'red', marginLeft: '.5rem' }}>S/. {order.toPay}</span>
          </div>
          <div><VendorBadge>{order.user.username}</VendorBadge></div>
        </OrderItemDataHeader>
        <OrderItemDataBody>{order.customer.name}</OrderItemDataBody>
      </OrderItemData>
      <OrderItemOptions>
        <button onClick={() => history.push(routes.viewOrder(order.id))}>Ver detalles</button>
        {user && user.role === 'admin' && order.state !== 'payed' && order.state !== 'finished' &&
          <button onClick={payModal.toogleModal}>Pagar</button>
        }
      </OrderItemOptions>
      <PayOrderModal show={payModal.isOpenModal} close={payModal.toogleModal} order={order} submitFunction={payOrder} boxes={boxes} />
    </OrderItem>
  );
}

export default DeliveredOrderItem;
import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`

export const BoxElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  border: 1px solid #007bff;
  border-radius: .5rem;
  margin-bottom: 1rem;
`

export const Text = styled.span`
  font-size: 1.2rem;
`

export const Button = styled.button`
  padding: .5rem;
  border: none;
  border-radius: .5rem;
  background-color: #007bff;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
`
import React from "react"
import { axiosInstance } from "../../utils/axios"

export function useRecipe(id: number) {
  const [recipe, setRecipe] = React.useState<any>(null)
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    // fetch
    (async () => {
      try {
        const response = await axiosInstance(`/recipes/get-recipe/${id}`)
        const data = await response.data;
        setRecipe(data)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [id])

  return { recipe, loading }
}
import dayjs from 'dayjs';
import React from 'react'
import { Table } from 'react-bootstrap';
import styled from 'styled-components'
import writeXlsxFile from 'write-excel-file';
import { axiosInstance } from '../../../utils/axios';
import { periods } from '../../purchase/common';

const INIT_YEAR = 2022;

const Header = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MyForm = styled.form`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const ResultSection = styled.div`
  flex: 1;
  margin-top: .5rem;
`

function AccountingPurchasesReport() {
  const [period, setPeriod] = React.useState("");
  const [year, setYear] = React.useState("");
  const [data, setData] = React.useState<any>(null);
  const [years, setYears] = React.useState<{ value: number, text: string }[]>([]);

  React.useEffect(() => {
    let year = dayjs().year();
    let arrayOfYears = [];
    while (year >= INIT_YEAR) {
      arrayOfYears.push({
        value: year,
        text: year.toString(),
      });
      year--;
    }
    setYears(arrayOfYears);
  }, []);

  async function downloadExcel() {
    try {
      const schema = [
        {
          column: "Fecha",
          type: String,
          value: (purchase: any) => purchase.date
        },
        {
          column: "Serie",
          type: String,
          value: (purchase: any) => purchase.serialNumber
        },
        {
          column: 'Numero',
          type: String,
          value: (purchase: any) => purchase.documentNumber
        },
        {
          column: 'Moneda',
          type: String,
          value: (purchase: any) => purchase.currency === "usd" ? "Dólares" : "Sol"
        },
        {
          column: 'Tipo de Cambio',
          type: Number,
          value: (purchase: any) => purchase.tipoDeCambio
        },
        {
          column: 'Sub Total',
          type: Number,
          value: (purchase: any) => Number(purchase.subTotal)
        },
        {
          column: 'IGV',
          type: Number,
          value: (purchase: any) => Number(purchase.igv)
        },
        {
          column: 'Total',
          type: Number,
          value: (purchase: any) => Number(purchase.total)
        },
        {
          column: 'Proveedor',
          type: String,
          value: (purchase: any) => purchase.provider
        },
      ];
      return await writeXlsxFile(data, { schema, fileName: `Compras_${period}.xlsx` });
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelection = async (e: any) => {
    e.preventDefault();
    const res = await axiosInstance.get('/reports/accounting/purchases', {
      params: {
        period: Number(period),
        year: Number(year),
      }
    });
    setData(res.data)
  }

  return (
    <>
      <Header>
        <MyForm onSubmit={handleSelection}>
          <h5>Selecciona el periodo contable a consultar: </h5>
          <select name="period" id="period" value={period} onChange={(e) => setPeriod(e.target.value)} required>
            <option value="">Selecciona el periodo</option>
            {periods.map((p, i) => (
              <option key={i} value={p.value}>{p.text}</option>
            ))}
          </select>
          <select id="year" value={year} onChange={(e) => setYear(e.target.value)} required>
            <option value="">Selecciona el año</option>
            {years.map((y, i) => (
              <option key={i} value={y.value}>{y.text}</option>
            ))}
          </select>
          <button className='btn btn-primary' type='submit'>Cargar datos</button>
        </MyForm>
        {data && data.length > 0 && <button className='btn btn-success' onClick={downloadExcel}>Descargar reporte</button>}
      </Header>
      {data &&
        <ResultSection>
          {data.length === 0 && <h1 style={{ color: 'red' }}>No se registran compras para este periodo</h1>}
          {data.length > 0 &&
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Serie</th>
                  <th>Número</th>
                  <th>Moneda</th>
                  <th>Tipo de cambio</th>
                  <th>Sub Total</th>
                  <th>IGV</th>
                  <th>Total</th>
                  <th>Proveedor</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.serialNumber}</td>
                    <td>{item.documentNumber}</td>
                    <td>{item.currency === "usd" ? "Dólares" : "Sol"}</td>
                    <td>{item.tipoDeCambio}</td>
                    <td>{item.subTotal}</td>
                    <td>{item.igv}</td>
                    <td>{item.total}</td>
                    <td>{item.provider}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </ResultSection>
      }
    </>
  )
}

export default AccountingPurchasesReport
import React, { useMemo } from "react"
import { paySellOrder } from "../utils/api/sellOrders";
import { axiosInstance } from '../utils/axios';

export interface Order {
    id: number
    state: string
    saleDate: string
    deliveryDate: string
    user: { id: string, username: string, role: string }
    customer: { name: string, documentType: string, watched: boolean, reference: string }
    total: number
    toPay: number
    payed: boolean
    futureDocument: string
    documentNumber: string
    emited: boolean
    items: { product_group: { name: string }, amount: number }[]
}

interface ISellOrderContext {
    q: string
    setQ: React.Dispatch<React.SetStateAction<string>>
    porConfirmar: Order[]
    confirmadas: Order[]
    filteredVenorsSales: Order[]
    filteredVenorsSalesBySearch: Order[]
    selectedVendor: string
    setSelectedVendor: React.Dispatch<React.SetStateAction<string>>
    oficinaSales: Order[]
    watchedSales: Order[]
    isLoading: boolean
    isError: any
    fetch: () => void
    payOrder: (orderId: number, amount: number, boxId: string, date: string) => void
    vendorsArray: { value: string, label: string }[]
    vendorsIdArray: string[]
    vendorsSumary: { numberOfSales: number, moneyTocollet: number }
    oficinaSumary: { numberOfSales: number, moneyTocollet: number }
    watchedSumary: { numberOfSales: number, moneyTocollet: number }
}

export const SellOrderContext = React.createContext({} as ISellOrderContext);

const SellOrdersProvider = ({ children }: any) => {
    const [orders, setOrders] = React.useState<Order[]>([]);
    const [error, setError] = React.useState<any>();
    const [vendorsArray, setVendorsArray] = React.useState<{ value: string, label: string }[]>([])
    const [vendorsIdArray, setVendorsIdArray] = React.useState<string[]>([])
    const [selectedVendor, setSelectedVendor] = React.useState("all");
    const [q, setQ] = React.useState("");

    const porConfirmar = useMemo(() => orders.filter(o => o.state === 'created'), [orders])
    const confirmadas = useMemo(() => orders.filter(o => (o.state === 'confirmed' || o.state === 'emited')), [orders]);
    const vendorsSales = useMemo(() => orders.filter(o => (o.state === 'delivered' && o.user.role === 'vendedor')), [orders]);
    const oficinaSales = useMemo(() => orders.filter(o => (o.state === 'delivered' && o.user.role !== 'vendedor' && !o.customer.watched)), [orders]);
    const watchedSales = useMemo(() => orders.filter(o => (o.state === 'delivered' && o.customer.watched)), [orders]);


    const filteredVenorsSales = useMemo(() => {
        if (selectedVendor !== 'all') {
            return vendorsSales.filter(order => order.user.id === selectedVendor);
        } else {
            return vendorsSales
        }
    }, [vendorsSales, selectedVendor])

    const filteredVenorsSalesBySearch = useMemo(() => {
        return filteredVenorsSales.filter((item) => {
            return ["id", "documentNumber"].some(field => {
                if (field === "id") {
                    const value = item.id;
                    if (typeof value === "number") {
                        return value.toString().toLowerCase().includes(q.toLowerCase())
                    }
                    return false;
                } else {
                    const value = item.documentNumber;
                    if (typeof value === "string") {
                        return value.toString().toLowerCase().includes(q.toLowerCase())
                    }
                    return false;
                }
            })
        });
    }, [q, filteredVenorsSales]);

    const vendorsSumary = React.useMemo(() => {
        let moneyTocollet = 0;
        for (let i = 0; i < filteredVenorsSales.length; i++) {
            const element = filteredVenorsSales[i];
            moneyTocollet += Number(element.toPay);
        }
        return {
            numberOfSales: filteredVenorsSales.length,
            moneyTocollet
        }
    }, [filteredVenorsSales]);

    const oficinaSumary = React.useMemo(() => {
        let moneyTocollet = 0;
        for (let i = 0; i < oficinaSales.length; i++) {
            const element = oficinaSales[i];
            moneyTocollet += Number(element.toPay);
        }
        return {
            numberOfSales: oficinaSales.length,
            moneyTocollet
        }
    }, [oficinaSales]);

    const watchedSumary = React.useMemo(() => {
        let moneyTocollet = 0;
        for (let i = 0; i < watchedSales.length; i++) {
            const element = watchedSales[i];
            moneyTocollet += Number(element.toPay);
        }
        return {
            numberOfSales: watchedSales.length,
            moneyTocollet
        }
    }, [watchedSales]);

    const getVendors = React.useCallback(() => {
        (async () => {
            try {
                const res = await axiosInstance.get('/vendors/data');
                setVendorsArray(res.data.vendorsArray);
                setVendorsIdArray(res.data.vendorsIdArray);
            } catch (error) {
                setError(error)
            }
        })()
    }, []);

    const fetch = React.useCallback(() => {
        (async () => {
            try {
                const res = await axiosInstance.get('/orders/currents');
                setOrders(res.data);
            } catch (error) {
                setError(error)
            }
        })()
    }, []);

    React.useEffect(() => {
        fetch()
        getVendors()
    }, [fetch, getVendors]);

    const payOrder = async (orderId: number, amount: number, boxId: string, date: string) => {
        try {
            await paySellOrder(orderId, amount, boxId, date);
        } catch (error) {
            throw (error)
        }
    }

    const contextValue = {
        q,
        setQ,
        porConfirmar,
        confirmadas,
        filteredVenorsSales,
        filteredVenorsSalesBySearch,
        selectedVendor,
        setSelectedVendor,
        oficinaSales,
        watchedSales,
        isLoading: orders.length === 0,
        isError: error,
        fetch,
        payOrder,
        vendorsArray,
        vendorsIdArray,
        vendorsSumary,
        oficinaSumary,
        watchedSumary,
    }

    return <SellOrderContext.Provider value={contextValue}>{children}</SellOrderContext.Provider>
}

export default SellOrdersProvider;
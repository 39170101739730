import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import routes from '../../helpers/routes';
import { axiosInstance } from '../../utils/axios';
import { FlexCenterContainer, TableCaption, TableContainer } from './styleds';

function useData() {
  const [purchases, setPurchases] = React.useState<any>(null);
  const [totalPurchases, setTotalPurchases] = React.useState<any>(null);
  const [totalOnPEN, setTotalOnPEN] = React.useState<any>(null);
  const [totalOnUSD, setTotalOnUSD] = React.useState<any>(null);

  const getData = async () => {
    try {
      const response = await axiosInstance.get('dashboards/admin-data-dashboards');
      setPurchases(response.data.inputPurchases);
      setTotalPurchases(response.data.inputPurchases.length);
      const penData = response.data.inputPurchases.filter((p: any) => p.currency === 'pen');
      const usdData = response.data.inputPurchases.filter((p: any) => p.currency === 'usd');
      setTotalOnPEN(penData.reduce((accumulator: any, current: any) => accumulator + Number(current.toPay), 0).toFixed(2));
      setTotalOnUSD(usdData.reduce((accumulator: any, current: any) => accumulator + Number(current.toPay), 0).toFixed(2));
    } catch (error) {
      alert(error);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  return { purchases, totalPurchases, totalOnPEN, totalOnUSD }
}

export default function InputPurchasesDashboard() {
  const { purchases, totalPurchases, totalOnPEN, totalOnUSD } = useData();

  if (!purchases) return <>
    <FlexCenterContainer>Cargando datos...</FlexCenterContainer>
  </>;

  return (
    <>
      <TableCaption>
        <div>Compras de insumos por pagar:</div>
        <div>{totalPurchases && totalPurchases} compras</div>
        <div style={{ display: 'flex', gap: '.4em' }}>
          <div>{totalOnPEN && totalOnPEN} Soles</div>
          <div>{totalOnUSD && totalOnUSD} Dólares</div>
        </div>
      </TableCaption>
      <TableContainer>
        <Table bordered size='sm' responsive>
          <thead>
            <tr>
              <th>Proveedor</th>
              <th>Factura</th>
              <th>Fecha de compra</th>
              <th>Deuda</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {purchases &&
              purchases.map((item: any, i: number) => (
                <tr key={i}>
                  <td>{item.provider.name}</td>
                  <td>{item.serialNumber}-{item.documentNumber}</td>
                  <td>{item.date}</td>
                  <td>{item.toPay} {item.currency}</td>
                  <td>
                    <Link to={routes.purchase.viewInput(item.id)}>Ver compra</Link>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </TableContainer>
    </>
  )
}
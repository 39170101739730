import React from 'react';
import styled from 'styled-components';
import { formatMoney } from '../../../../utils/functions';

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const ItemText = styled.div`
  grid-column: 1 / 2;
  text-align: right;
  margin-right: 1rem;
  font-weight: bold;
`

const ItemValue = styled.div`
  grid-column: 2 / 3
`


function Item(props: any) {
  return (
    <ItemContainer>
      <ItemText>{props.text}:</ItemText>
      <ItemValue>{props.money ? formatMoney(props.value) : props.value}</ItemValue>
    </ItemContainer>
  );
}


export function GeneralData(props: any) {
  return (
    <div className='mb-2'>
      Datos generales:
      <div className="d-flex flex-wrap border">
        <div className="d-flex-colum flex-grow-1">
          <Item text="Cliente" value={props.customer}></Item>
          <Item text="Serie" value={props.nubeDoc.serie}></Item>
          <Item text="Número" value={props.nubeDoc.numero}></Item>
        </div>
        <div className="d-flex-colum flex-grow-1">
          <Item text="Op. Gravadas" value={props.subTotal} money={true}></Item>
          <Item text="IGV" value={props.igv} money={true}></Item>
          <Item text="Total" value={props.total} money={true}></Item>
        </div>
      </div>
    </div>
  );
}
